import iconSchool from '@assets/img/icon_school.svg';
import './leidraadPointer.scss';

const LeidraadPointer = ({ pointer }) => {
  return (
    <div className="leidraad-pointer-container">
      <svg viewBox="0 0 8 34" preserveAspectRatio="xMaxYMid meet">
        <path className="leidraad-pointer" d="M 0 0 L 7 17 L 0 34 L 17 34 L 17 0 z"></path>
      </svg>

      <svg className="leidraad-pointer-logo">
        <image width="90%" height="80%" href={iconSchool}></image>
      </svg>

      <svg className="leidraad-pointer">
        {pointer && <image width="100%" height="80%" y="5" href={pointer.imageUrl}></image>}
      </svg>

      <svg viewBox="0 0 10 34" preserveAspectRatio="xMinYMid meet">
        <path className="leidraad-pointer" d="M 0 0 L 0 34 L 10 17 z"></path>
      </svg>
    </div>
  );
};

export default LeidraadPointer;
