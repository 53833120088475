import { HoveredPopover } from '@UI/index';

import './educationalComponentsList.scss';

const EducationalComponentsList = ({ educationalComponents }) => {
  return (
    educationalComponents?.length > 0 && (
      <div className="educational-components">
        {educationalComponents?.map((component) => (
          <div key={component.key}>
            <HoveredPopover content={component.title}>
              <img src={component.imageUrl} />
            </HoveredPopover>
          </div>
        ))}
      </div>
    )
  );
};

export default EducationalComponentsList;
