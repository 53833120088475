import { PayloadAction } from '@reduxjs/toolkit';
import { ModalStatus } from '../../types/modalTypes';

export type CreateActivityModalData = {
  createForClasses: string[];
  week: {
    startDate: string;
    endDate: string;
  };
  isCreate: true;
};

export type EditActivityModalData = {
  createForClasses: string[];
  isCreate: false;
  parentHrefs: string[];
  key: string;
};

// Type guard to check if data is of type CreateActivityModalData
// const isCreateActivityModalData = (
//   data: CreateActivityModalData | EditActivityModalData | null
// ): data is CreateActivityModalData => {
//   return data !== null && data.isCreate === true;
// };

// Type guard to check if data is of type EditActivityModalData
export const isEditActivityModalData = (
  data: CreateActivityModalData | EditActivityModalData | null
): data is EditActivityModalData => {
  return data !== null && data.isCreate === false;
};

export type CalendarState = Readonly<{
  calendarKeys: string[];
  merge: boolean;
  unselectedClasses: string[];
  selectionMode: boolean;
  selectionOptions: {
    selectionType: string | null;
    confirmLabel: string | null;
    cancelLabel: string | null;
    scrollPosition: string | null;
  };
  editActivityModal: {
    status: ModalStatus;
    data: CreateActivityModalData | EditActivityModalData | null;
  };
}>;
export type EditActivityModalOpenPayload = PayloadAction<
  CreateActivityModalData | EditActivityModalData
>;
export type MoveWeekPayload = PayloadAction<{ startDate: number; type: 'ADD' | 'SUBTRACT' }>;
export type DeleteActivityPayload = PayloadAction<{ parentHrefs: string[] }>;
