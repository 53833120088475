import { Modal } from '@kathondvla/react-shared-components/src/components';
import { FormGroup, FormTextEditorField, FormTextField } from '@UI/index';
import { useForm } from 'react-hook-form';

import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';

import useExpandSection from '@hooks/useExpandSection';
import {
  selectCurriculumIdentifier,
  selectCustomGoalIdentifiersSet,
  selectUniqueGoalId,
} from '@store/curriculum/curriculumSelectors';
import { useDispatch, useSelector } from 'react-redux';

import { addCustomGoal, editCustomGoal } from '@store/llinkidApis/llinkidApiState';
import {
  identifierUniqueOrUnchangedValidation,
  identifierUniqueValidation,
  requiredValidation,
} from '@utils/validations';

import iconAddWhite from '@assets/img/add_white.svg';
import iconEdit from '@assets/img/edit-white.svg';
import './CustomGoalModal.scss';

const CustomGoalModal = ({ isEditMode, goal, parent, onClose }) => {
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const expandSection = useExpandSection();
  const dispatch = useDispatch();

  const customGoalIdsSet = useSelector(selectCustomGoalIdentifiersSet);
  const curriculumId = useSelector(selectCurriculumIdentifier);
  const uniqueGoalIdFromSelector = useSelector((state) =>
    selectUniqueGoalId(state, { curriculumId })
  );
  const uniqueId = isEditMode ? goal.identifier : uniqueGoalIdFromSelector;

  const handleCloseModal = () => {
    onClose();
  };
  const handleAccept = (form) => {
    if (isEditMode) {
      dispatch(
        editCustomGoal({
          ...goal,
          identifier: form.identifier,
          description: form.description,
          mandatory: true,
        })
      );
    } else {
      dispatch(
        addCustomGoal({
          parentItem: parent,
          customItem: {
            identifier: form.identifier,
            description: form.description,
            mandatory: true,
          },
        })
      );
    }
    if (parent) expandSection(parent);
    onClose();
  };

  return (
    <div className="options-modal goal-select-modal">
      <Modal.Header
        title={isEditMode ? 'Een eigen doel bewerken' : 'Een eigen doel toevoegen'}
        icon={isEditMode ? iconEdit : iconAddWhite}
        onClose={handleCloseModal}
      />
      <Modal.Body>
        <FormGroup label="Nummer" required>
          <FormTextField
            name="identifier"
            value={uniqueId}
            control={control}
            validations={{
              unique: (value) =>
                isEditMode
                  ? identifierUniqueOrUnchangedValidation(uniqueId, customGoalIdsSet)(value)
                  : identifierUniqueValidation(value, customGoalIdsSet),
              required: (value) => requiredValidation(value),
            }}
          />
        </FormGroup>
        <FormGroup label="Beschrijving" required>
          <FormTextEditorField
            name="description"
            value={isEditMode ? goal.description : ''}
            control={control}
            validations={{
              required: (value) => requiredValidation(value),
            }}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={handleCloseModal}>
          Annuleren
        </Button>

        <Button
          variant="contained"
          startIcon={isEditMode ? <EditIcon /> : <AddBoxIcon />}
          onClick={handleSubmit((form) => handleAccept(form))}
        >
          {isEditMode ? 'Bewerken' : 'Toevoegen'}
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default CustomGoalModal;
