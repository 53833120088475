import DistributeCard from '@UI/distributeCard/DistributeCard';
import { Checkbox } from '@UI/index';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import {
  selectCurriculumGrade,
  selectIsCurriculumFoundational,
} from '@store/curriculum/curriculumSelectors';
import { func } from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button } from '@mui/material';
import { getStudyProgrammeTypeNameForGrade } from '@utils/curriculumHelper';
import './distributeModal.scss';
import { selectStudyProgrammeAndGroupsForCurriculum } from './distributeModalSelectors';

const BASE_HREF = '/sam/commons/studyprogrammes/';

const toggleItem = (list, item) => {
  if (!item) return list;
  const exists = list.find((sp) => sp === item);
  if (exists) return list.filter((sp) => sp !== item);
  return [...list, item];
};

const DistributeModal = ({ onConfirm, onClose }) => {
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const grade = useSelector(selectCurriculumGrade);
  const studIds = useSelector((state) => state.curriculum.studids);
  const foundational = useSelector(selectIsCurriculumFoundational);
  const { studyProgrammes, groups: streams } = useSelector((state) =>
    selectStudyProgrammeAndGroupsForCurriculum(state)
  );
  const gradeLiteral = getStudyProgrammeTypeNameForGrade(grade);
  const title = foundational
    ? 'Verdelen van een gemeenschappelijk leerplan'
    : `Selecteren ${gradeLiteral}`;

  const onConfirmHandler = (hrefs = []) => {
    const selection = hrefs.map((href) => href.split('/').pop());
    onConfirm?.(selection);
  };

  const selectStudyProgram = (href) => {
    setSelectedPrograms((prev) => toggleItem(prev, href));
  };

  useEffect(() => {
    if (studIds && studIds.length) {
      const hrefs = studIds.map((st) => BASE_HREF + st);
      setSelectedPrograms(hrefs);
    }
  }, [studIds]);

  return (
    <>
      <Modal.Header title={title} showCloseButton={false} />
      <Modal.Body>
        <div className="selection-content">
          <div className="global-section">
            <div className="title">
              <h4>Globaal</h4>
              {foundational && <span>Ga direct naar het verdeelscherm.</span>}
            </div>
            {streams?.map((stream) => (
              <DistributeCard
                key={stream.value}
                title={stream.name}
                description={stream.description}
                onClick={() => onConfirmHandler(stream.studyProgrammes)}
              />
            ))}
          </div>
          <div className="specific-section">
            <div className="title">
              <h4>Voor één of meerdere {gradeLiteral}</h4>
            </div>
            {studyProgrammes?.map((studyProgram) => (
              <Checkbox
                key={studyProgram.value}
                label={studyProgram.name}
                checked={selectedPrograms.includes(studyProgram.value)}
                onChange={() => selectStudyProgram(studyProgram.value)}
              />
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <span>
          Je hebt {selectedPrograms.length} {gradeLiteral} geselecteerd.
        </span>
        <Button variant="outlined" onClick={onClose}>
          Annuleren
        </Button>
        <Button
          variant="contained"
          endIcon={<KeyboardArrowRightIcon />}
          onClick={() => onConfirmHandler(selectedPrograms)}
        >
          <span>Verder gaan</span>
        </Button>
      </Modal.Footer>
    </>
  );
};

DistributeModal.propTypes = {
  onConfirm: func,
  onClose: func,
};

export default DistributeModal;
