import { rest } from 'msw';

import apis from '@config/apis';
import demo from '@config/demo';
import { getStateFile } from '../../../app/fileHelpers';
import { fetchJsonPath } from '../../helpers/fetchJson';
import { getDemoSchool1Href, getDemoSchool2Href, replaceDemoSchoolKeys } from '../persons/helpers';

const fetchJson = fetchJsonPath('api/activityplans');

const activitiesPath = 'activities_test_school.json';
const activitiesDeltaPath = 'activities_delta_empty.json';
const activityPlansPath = 'activityplans_test_school.json';
const activityPlansDeltaPath = 'activityplans_delta_empty.json';
const activityPlansCheckPath = 'activityplans_check_limit=0_empty.json';
// demo data
const demoActivitiesSchool1Path = 'demo-activities-school-1.json';
const demoActivityPlansSchool1Path = 'demo-activityplans-school-1.json';

const getActivityPlans = async (ctx, school) => {
  if (school === '/sam/organisationalunits/c64fe271-87df-43b5-9ec0-d85f0e8004af') {
    return fetchJson(ctx, activityPlansPath);
  }

  if (school === getDemoSchool1Href()) {
    return replaceDemoSchoolKeys(await fetchJson(ctx, demoActivityPlansSchool1Path));
  }

  if (school === getDemoSchool2Href()) {
    return fetchJson(ctx, activityPlansDeltaPath); // empty
  }

  return fetchJson(ctx, activityPlansDeltaPath); // empty
};

const getActivities = async (ctx, school) => {
  if (school === '/sam/organisationalunits/c64fe271-87df-43b5-9ec0-d85f0e8004af') {
    return fetchJson(ctx, activitiesPath);
  }

  if (school === getDemoSchool1Href()) {
    return fetchJson(ctx, demoActivitiesSchool1Path);
  }

  if (school === getDemoSchool2Href()) {
    return fetchJson(ctx, activitiesDeltaPath); // empty
  }

  return fetchJson(ctx, activitiesDeltaPath); // empty
};

export const getActivityPlansApiHandlerWithDelay = (ms) => {
  return rest.get(
    `${apis.activityplansapi.baseUrl}/llinkid/activityplanning/activityplans/`,
    async (req, res, ctx) => {
      if (req.url.searchParams.get('limit') === '0' && demo.enableDemoMode) {
        const context = req.url.searchParams.get('context.href') as string;

        const activityPlansForSchool = await getActivityPlans(ctx, context);

        const itemCount = activityPlansForSchool.results.length;

        const customCurriculaData = await getStateFile(context);

        const count =
          (customCurriculaData?.activityPlans &&
            Object.keys(customCurriculaData?.activityPlans).length) ||
          itemCount; // file might not exist. then return count from json

        const response = {
          $$meta: {
            schema: '/llinkid/activityplanning/activityplans/schema',
            docs: '/llinkid/activityplanning/activityplans/docs',
            count,
          },
          results: [],
        };

        return res(ctx.json(response), ctx.delay(ms));
      }

      if (req.url.searchParams.get('limit') === '0') {
        return res(ctx.json(await fetchJson(ctx, activityPlansCheckPath)), ctx.delay(ms));
      }

      if (req.url.searchParams.get('modifiedSince') === null) {
        return res(
          ctx.json(await getActivityPlans(ctx, req.url.searchParams.get('context.href'))),
          ctx.delay(ms)
        );
      }

      if (req.url.searchParams.get('modifiedSince') !== null) {
        return res(ctx.json(await fetchJson(ctx, activityPlansDeltaPath)), ctx.delay(ms));
      }

      if (!demo.enableDemoMode) {
        return req.passthrough();
      }
      return res(ctx.status(404), ctx.delay(ms));
    }
  );
};

export const activityplansApiHandlers = [
  getActivityPlansApiHandlerWithDelay(150),
  rest.get(
    `${apis.activityplansapi.baseUrl}/llinkid/activityplanning/activityplans/activities/`,
    async (req, res, ctx) => {
      if (req.url.searchParams.get('modifiedSince') === null) {
        return res(
          ctx.json(await getActivities(ctx, req.url.searchParams.get('rootWithContextContains'))),
          ctx.delay(150)
        );
      }
      if (req.url.searchParams.get('modifiedSince') !== null) {
        return res(ctx.json(await fetchJson(ctx, activitiesDeltaPath)), ctx.delay(150));
      }

      return req.passthrough();
    }
  ),
  rest.get(
    `${apis.activityplansapi.baseUrl}/llinkid/activityplanning/activityplans/:key`,
    async (req, res, ctx) => {
      if (req.params.key) {
        const context = sessionStorage.getItem('currentSchool');

        const customCurriculaData = await getStateFile(context);
        const activityplans = customCurriculaData?.activityPlans || {};

        const activityPlansForSchool = await getActivityPlans(ctx, context);
        const item =
          activityplans[req.params.key as string] ||
          activityPlansForSchool.results.find((e) => e.$$expanded.key === req.params.key);
        if (item) return res(ctx.json(item), ctx.delay(150));
      }
      return res(ctx.status(404), ctx.delay(150));
    }
  ),
  rest.post(
    // prevent changes from being made in MSW Mock mode
    `${apis.activityplansapi.baseUrl}/llinkid/*/batch`,
    async (req, res, ctx) => {
      const body = await req.json();

      // return 201 for each batchitem
      const resp = body.map((batch) => {
        return {
          status: 201,
          href: batch.href,
          verb: batch.verb,
        };
      });

      return res(ctx.json(resp));
    }
  ),
  rest.put(`${apis.activityplansapi.baseUrl}/llinkid/*/batch`, async (req, res, ctx) => {
    const body = await req.json();

    // return 201 for each batchitem
    const resp = body.map((batch) => {
      return {
        status: 201,
        href: batch.href,
        verb: batch.verb,
      };
    });

    return res(ctx.json(resp));
  }),
  rest.post(`${apis.activityplansapi.baseUrl}/llinkid/*`, (req, res, ctx) => {
    return res(ctx.status(405));
  }),
  rest.put(`${apis.activityplansapi.baseUrl}/llinkid/*`, (req, res, ctx) => {
    return res(ctx.status(405));
  }),
];
