import HeaderWrapper from '@components/layout/header/headerWrapper';

import Calendar from './Calendar';

const CalendarWrapper: React.FC<{ selector?: boolean }> = ({ selector }) => {
  const showHeader = !selector;

  return (
    <>
      <div id="fixed-header" className="fixed-header">
        {showHeader && <HeaderWrapper></HeaderWrapper>}
      </div>

      <Calendar />
    </>
  );
};

export default CalendarWrapper;
