import { Modal } from '@kathondvla/react-shared-components/src/components';

import useExpandSection from '@hooks/useExpandSection';

import './NonDerivedOptionsModal.scss';
import { Button } from '@mui/material';
import { selectParentTreeMap } from '@store/curriculum/curriculumSelectors';
import { hasParentSection } from '@utils/nonDerivedHelper';
import { useSelector, useDispatch } from 'react-redux';
import { CUSTOMCREATIONTYPES } from '@utils/curriculumHelper';
import ArrowRight from '@assets/img/icon_arrow_right.svg?react';
import { addCustomSection } from '@store/llinkidApis/llinkidApiState';
import shareIcon from '@assets/img/share-grey.svg';
import notitiesIcon from '@assets/img/notitie.png';
import schoolGreyIcon from '@assets/img/icon_school_so_grey.svg';
import addIcon from '@assets/img/add_white.svg';

const NonDerivedOptionsModal = ({
  parent,
  onClose,
  onOpenCopyGoalModal,
  onOpenCreateGoalModal,
}) => {
  const expandSection = useExpandSection();
  const parentTreeMap = useSelector(selectParentTreeMap);

  const dispatch = useDispatch();
  const openCopyModal = () => onOpenCopyGoalModal(parent);
  const openCreateModal = () => onOpenCreateGoalModal(parent);

  const onTitleClick = () => {
    dispatch(addCustomSection({ parent, type: CUSTOMCREATIONTYPES.section }));
    onClose();
  };

  const onSubtitleClick = () => {
    dispatch(addCustomSection({ parent, type: CUSTOMCREATIONTYPES.subSection }));
    expandSection(parent);
    onClose();
  };

  return (
    <div className="options-modal">
      <Modal.Header title="Wat wil je invoegen?" icon={addIcon} onClose={onClose} />
      <Modal.Body>
        <div className="content">
          <div className="modal-section">
            <div className="button-option" onClick={onTitleClick}>
              <div>
                <div className="button-icon">
                  <span>1 Titel</span>
                </div>
                <div className="button-text">
                  Titel
                  <ArrowRight className="arrow-right" />
                </div>
              </div>
              <span className="description">Voeg een titel toe.</span>
            </div>
            <div
              className={`button-option  ${
                hasParentSection(parent.key, parentTreeMap) ? '' : 'disabled'
              }`}
              onClick={onSubtitleClick}
            >
              <div>
                <div className="button-icon">
                  <span>1.2 Titel</span>
                </div>
                <div className="button-text">
                  Subtitel
                  <ArrowRight className="arrow-right" />
                </div>
              </div>
              <span className="description">Voeg een subtitel toe.</span>
            </div>
          </div>
          <div className="modal-section">
            <div className="button-option" onClick={() => openCopyModal()}>
              <div>
                <div className="button-icon">
                  <img src={shareIcon} />
                </div>
                <div className="button-text">
                  Doel kopiëren
                  <ArrowRight className="arrow-right" />
                </div>
              </div>
              <span className="description">Voeg een leerplandoel toe uit een ander leerplan.</span>
            </div>
            <div className="button-option" onClick={() => openCreateModal()}>
              <div>
                <div className="button-icon">
                  <img src={schoolGreyIcon} />
                </div>
                <div className="button-text">
                  Eigen doel
                  <ArrowRight className="arrow-right" />
                </div>
              </div>
              <span className="description">Voeg zelf eigen doel toe aan je leerplan.</span>
            </div>
          </div>
          <div className="modal-section">
            <div className="button-option disabled">
              <div>
                <div className="button-icon">
                  <img src={notitiesIcon} />
                </div>
                <div className="button-text">
                  Nota toevoegen
                  <ArrowRight className="arrow-right" />
                </div>
              </div>
              <span className="description">Voeg zelf eigen nota's toe aan je leerplan.</span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={onClose}>
          Annuleren
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default NonDerivedOptionsModal;
