import iconTeacher from '@assets/img/icon_user_grey.svg';
import iconTeam from '@assets/img/icon_people_grey.svg';
import iconSchool from '@assets/img/icon_school_so_grey.svg';
import iconPlan from '@assets/img/icon_curr_calendar.svg';

const levelType = {
  TEACHER: 1,
  TEAM: 2,
  SCHOOL: 3,
  PLAN: 4,
  properties: {
    TEACHER: { src: iconTeacher },
    TEAM: { src: iconTeam },
    SCHOOL: { src: iconSchool },
    PLAN: { src: iconPlan },
  },
};

export default levelType;
