import teacherOneStateJson from './teacherone.testperson.state.json';
import allClasses from './classes.vm.json';
import allTeachers from './teachers.vm.json';

export const schoolHref = '/sam/organisationalunits/c64fe271-87df-43b5-9ec0-d85f0e8004af';
export const groep1Href = '/sam/organisationalunits/b381f1be-930a-4f06-af45-19d81701215d';
export const groep2Href = '/sam/organisationalunits/0ab78576-a7e3-4e50-9dcc-79475ec65626';
export const groep3Href = '/sam/organisationalunits/301c2eb0-1431-4fa3-b7a1-18cffa23534a';
export const groep4Href = '/sam/organisationalunits/402e132f-0a03-4ced-b952-644f312ffa36';
export const groep5Href = '/sam/organisationalunits/013708dd-55ce-4b90-bf6b-df1724c368fa';
export const groep6Href = '/sam/organisationalunits/2bd31832-c0ac-4952-bb96-b43209f59f7e';
export const directorTestpersonHref = '/persons/00d71707-540d-40be-991b-e528dd758fcb';
export const teacheroneTestpersonHref = '/persons/78888b08-d95f-45b9-99ad-f85ae5a1b759';
export const teachertwoTestpersonHref = '/persons/dd46c986-52f7-467e-a2c7-a8c968d0f64c';
export const teacherthreeTestpersonHref = '/persons/73d4ba5a-5594-44d2-aba6-b084c836ec7d';
export const teamleadTestpersonHref = '/persons/7c38c613-2489-4e71-99f1-23e7351c3fae';

export const teacherOneState = teacherOneStateJson;
export const classes = allClasses;
export const teachers = allTeachers;

export const allTeamHrefs = [groep1Href, groep2Href, groep3Href, groep4Href, groep5Href];