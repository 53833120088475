import Dexie from 'dexie';
const db = new Dexie('LLINKID');
// db.on("ready", function() { console.log("DEXIE is ready!") });

db.version(6)
  .stores({
    items: '++id,session',
    sessions: '++id,session,date',
    curriculaItems: null, // delete old table
    content: '&key',
    contentRelations: '&key',
    cacheSettings: 'id',
    customcurricula: '&key,context',
    annotations: '&key,context',
    customcurriculagroups: '&key,context',
    activityplans: '&key,context',
    activities: '&key,context',
    schoolCache: '++id,[resource+context]',
  })
  .upgrade((tx) => {
    // An upgrade function for version 4 to 6
    return tx
      .table('cacheSettings')
      .toCollection()
      .modify((item) => {
        item.curriculaModifiedSince = null;
        item.contentRelationsModifiedSince = null;
      });
  });

// upgrade to v7
db.version(7).stores({
  items: '++id,session',
  sessions: '++id,session,date',
  content: '&key',
  contentRelations: '&key',
  cacheSettings: 'id',
  customcurricula: '&key,context',
  annotations: '&key,context',
  customitems: '&key,context', // new table here
  customcurriculagroups: '&key,context',
  activityplans: '&key,context',
  activities: '&key,context',
  schoolCache: '++id,[resource+context]',
});

// // upgrade to v7
db.version(8).stores({
  items: '++id,session',
  sessions: '++id,session,date',
  content: null,
  contentRelations: null,
  cacheSettings: null,
  customcurricula: '&key,context',
  annotations: '&key,context',
  customitems: '&key,context', // new table here
  customcurriculagroups: '&key,context',
  activityplans: '&key,context',
  activities: '&key,context',
  schoolCache: '++id,[resource+context]',
});

// // upgrade to v7
db.version(9).stores({
  items: '++id,session',
  sessions: '++id,session,date',
  content: null,
  contentRelations: null,
  cacheSettings: null,
  customcurricula: '&key,context',
  annotations: '&key,context',
  customitems: '&key,context',
  choices: '&key,context', // new table here
  customcurriculagroups: '&key,context',
  activityplans: '&key,context',
  activities: '&key,context',
  schoolCache: '++id,[resource+context]',
});

db.open()
  .then(function (db) {
    console.log('Found database: ' + db.name);
    console.log('Database version: ' + db.verno);
  })
  .catch('NoSuchDatabaseError', function (e) {
    // Database with that name did not exist
    console.error('Database not found');
  })
  .catch(function (e) {
    console.error('Oh uh: ' + e);
  });

export default db;
