import { selectLlinkidStateForFile } from '@store/llinkidApis/llinkidApiSelectors';
import { selectCurrentSchoolHref } from '@store/userAndSchool/userAndSchoolSelectors';
import { debounce, select } from 'redux-saga/effects';
import { saveLlinkidStateDataToFile } from '../../app/fileHelpers';

let oldCustomCurriculaData;
function* persistApiStateToFileSaga() {
  const customCurriculaData = yield select(selectLlinkidStateForFile);
  if (oldCustomCurriculaData !== customCurriculaData) {
    oldCustomCurriculaData = customCurriculaData;
    const schoolhref = yield select(selectCurrentSchoolHref);

    if (!schoolhref) return;

    yield saveLlinkidStateDataToFile(customCurriculaData, schoolhref);
  }
}

/**
 * Watches for changes in the llinkid api data state slice and saves the state to a file for faster loading on next visit.
 */
export function* watchPersistApiStateToFile() {
  yield debounce(2000, '*', persistApiStateToFileSaga);
}
