import { rest } from 'msw';

import apis from '@config/apis';
import demo from '@config/demo';
import db from '../../../app/db';
import { getDemoSchool1Href, getDemoSchool2Href, replaceDemoSchoolKeys } from '../persons/helpers';

import { getStateFile } from '../../../app/fileHelpers';
import { fetchJsonPath } from '../../helpers/fetchJson';
import { getChoicesFromPrivateFS, saveChoicesToPrivateFS } from '../../helpers/mockPersistence';

const fetchJson = fetchJsonPath('api/customCurricula');

const annotationsPath = 'annotations_test_school.json';
const annotationsDeltaPath = 'annotations_delta_empty.json';
const customCurriculaPath = 'customcurricula_test_school.json';
const customCurriculaDeltaPath = 'customcurricula_delta_empty.json';
const customCurriculaCheckPath = 'customcurricula_check_limit=0_empty.json';
const customCurriculaGroupsPath = 'customcurriculagroups_test_school.json';
const customCurriculaGroupsDeltaPath = 'customcurriculagroups_delta_empty.json';
const customItemsPath = 'customitems_test_school.json';
const customItemsDeltaPath = 'customitems_delta_empty.json';
const customStudyProgrammeGroupsPath = 'customstudyprogrammegroups_test_school.json';
const qualityInstrumentChoicesPath = 'qualityinstrument_choices_test_school.json';
const demoAnnotationsPath = 'demo-annotations-school-1.json';
const demoCustomCurriculaPath = 'demo-customcurricula-school-1.json';
const demoCustomItemsPath = 'demo-customitems-school-1.json';
const demoCustomCurriculaGroupsPath = 'demo-customcurriculagroups-school-1.json';
const demoQualityInstrumentChoicesPath = 'demo-choices-school-1.json';
const demoCustomStudyProgrammeGroupsSchool1Path = 'demo-customstudyprogrammegroups-school-1.json';
const demoCustomStudyProgrammeGroupsSchool2Path = 'demo-customstudyprogrammegroups-school-2.json';

const getCustomCurricula = async (ctx, school) => {
  if (school === '/sam/organisationalunits/c64fe271-87df-43b5-9ec0-d85f0e8004af') {
    return fetchJson(ctx, customCurriculaPath);
  }

  if (school === getDemoSchool1Href()) {
    return replaceDemoSchoolKeys(await fetchJson(ctx, demoCustomCurriculaPath));
  }

  if (school === getDemoSchool2Href()) {
    return fetchJson(ctx, customCurriculaDeltaPath); // empty
  }

  return fetchJson(ctx, customCurriculaDeltaPath); // empty
};

const getAnnotations = (ctx, school) => {
  if (school === '/sam/organisationalunits/c64fe271-87df-43b5-9ec0-d85f0e8004af') {
    return fetchJson(ctx, annotationsPath);
  }

  if (school === getDemoSchool1Href()) {
    return fetchJson(ctx, demoAnnotationsPath);
  }

  if (school === getDemoSchool2Href()) {
    return fetchJson(ctx, annotationsDeltaPath); // empty
  }

  return fetchJson(ctx, annotationsDeltaPath); // empty
};

const getCustomItems = async (ctx, school) => {
  if (school === '/sam/organisationalunits/c64fe271-87df-43b5-9ec0-d85f0e8004af') {
    return fetchJson(ctx, customItemsPath);
  }

  if (school === getDemoSchool1Href()) {
    return replaceDemoSchoolKeys(await fetchJson(ctx, demoCustomItemsPath));
  }

  if (school === getDemoSchool2Href()) {
    return fetchJson(ctx, customItemsDeltaPath); // empty
  }

  return fetchJson(ctx, customItemsDeltaPath); // empty
};

const getCustomCurriculaGroups = (ctx, school) => {
  if (school === '/sam/organisationalunits/c64fe271-87df-43b5-9ec0-d85f0e8004af') {
    return fetchJson(ctx, customCurriculaGroupsPath);
  }

  if (school === getDemoSchool1Href()) {
    return fetchJson(ctx, demoCustomCurriculaGroupsPath);
  }

  if (school === getDemoSchool2Href()) {
    return fetchJson(ctx, customCurriculaGroupsDeltaPath); // empty
  }

  return fetchJson(ctx, customCurriculaGroupsDeltaPath); // empty
};

const getQualityInstrumentChoices = async (ctx, school) => {
  if (school === '/sam/organisationalunits/c64fe271-87df-43b5-9ec0-d85f0e8004af') {
    return fetchJson(ctx, qualityInstrumentChoicesPath);
  }

  if (school === getDemoSchool1Href()) {
    return replaceDemoSchoolKeys(await fetchJson(ctx, demoQualityInstrumentChoicesPath));
  }

  if (school === getDemoSchool2Href()) {
    return {
      $$meta: {
        schema: '/llinkid/qualityinstrument/choices/schema',
        docs: '/llinkid/qualityinstrument/choices/docs',
      },
      results: [],
    }; // empty
  }

  return null;
};

const getCustomStudyProgrammeGroups = async (ctx, school) => {
  if (school === `/sam/organisationalunits/c64fe271-87df-43b5-9ec0-d85f0e8004af`) {
    return fetchJson(ctx, customStudyProgrammeGroupsPath);
  }

  if (school === getDemoSchool1Href()) {
    return replaceDemoSchoolKeys(await fetchJson(ctx, demoCustomStudyProgrammeGroupsSchool1Path));
  }

  if (school === getDemoSchool2Href()) {
    return replaceDemoSchoolKeys(await fetchJson(ctx, demoCustomStudyProgrammeGroupsSchool2Path));
  }
  return null;
};

export const getCustomCurriculaApiHandlerWithDelay = (ms) => {
  return rest.get(
    `${apis.customcurapi.baseUrl}/llinkid/customcurriculum/customcurricula/`,
    async (req, res, ctx) => {
      if (
        req.url.searchParams.get('limit') === '0' &&
        demo.enableDemoMode &&
        req.url.searchParams.get('context.href')
      ) {
        const context = req.url.searchParams.get('context.href') as string;
        const customcurriculaForSchool = await getCustomCurricula(ctx, context);
        const itemCount = customcurriculaForSchool.results.length;

        const customCurriculaData = await getStateFile(context);

        const count =
          (customCurriculaData?.customCurricula &&
            Object.keys(customCurriculaData?.customCurricula).length) ||
          itemCount; // file might not exist. then return count from json

        const response = {
          $$meta: {
            schema: '/llinkid/customcurriculum/customcurricula/schema',
            docs: '/llinkid/customcurriculum/customcurricula/docs',
            count,
          },
          results: [],
        };

        return res(ctx.json(response), ctx.delay(ms));
      }

      if (req.url.searchParams.get('limit') === '0') {
        const customcurriculaCheck = await fetchJson(ctx, customCurriculaCheckPath);
        return res(ctx.json(customcurriculaCheck), ctx.delay(ms));
      }

      if (req.url.searchParams.get('modifiedSince') === null) {
        return res(
          ctx.json(await getCustomCurricula(ctx, req.url.searchParams.get('context.href'))),
          ctx.delay(ms)
        );
      }

      if (req.url.searchParams.get('modifiedSince') !== null) {
        const customcurriculaDelta = await fetchJson(ctx, customCurriculaDeltaPath);
        return res(ctx.json(customcurriculaDelta), ctx.delay(ms));
      }

      return req.passthrough();
    }
  );
};

export const customCurriculaApiHandlers = [
  getCustomCurriculaApiHandlerWithDelay(150),

  rest.get(
    `${apis.customcurapi.baseUrl}/llinkid/customcurriculum/customcurricula/annotations/`,
    async (req, res, ctx) => {
      if (req.url.searchParams.get('modifiedSince') === null) {
        return res(
          ctx.json(await getAnnotations(ctx, req.url.searchParams.get('rootWithContextContains'))),
          ctx.delay(150)
        );
      }
      if (req.url.searchParams.get('modifiedSince') !== null) {
        const annotationsDelta = await fetchJson(ctx, annotationsDeltaPath);
        return res(ctx.json(annotationsDelta), ctx.delay(150));
      }

      return req.passthrough();
    }
  ),
  rest.get(
    `${apis.customcurapi.baseUrl}/llinkid/customcurriculum/customitems/`,
    async (req, res, ctx) => {
      if (req.url.searchParams.get('modifiedSince') === null) {
        return res(
          ctx.json(await getCustomItems(ctx, req.url.searchParams.get('context.href'))),
          ctx.delay(150)
        );
      }
      if (req.url.searchParams.get('modifiedSince') !== null) {
        const customitemsDelta = await fetchJson(ctx, customItemsDeltaPath);
        return res(ctx.json(customitemsDelta), ctx.delay(150));
      }

      return req.passthrough();
    }
  ),

  rest.get(
    `${apis.customcurapi.baseUrl}/llinkid/customcurriculum/customcurriculagroups/`,
    async (req, res, ctx) => {
      if (req.url.searchParams.get('modifiedSince') === null) {
        return res(
          ctx.json(
            await getCustomCurriculaGroups(
              ctx,
              req.url.searchParams.get('customCurriculaContextContains')
            )
          ),
          ctx.delay(150)
        );
      }
      if (req.url.searchParams.get('modifiedSince') !== null) {
        const customcurriculagroupsDelta = await fetchJson(ctx, customCurriculaGroupsDeltaPath);
        return res(ctx.json(customcurriculagroupsDelta), ctx.delay(150));
      }

      return req.passthrough();
    }
  ),
  rest.get(
    `${apis.customcurapi.baseUrl}/llinkid/qualityinstrument/choices`,
    async (req, res, ctx) => {
      const context = req.url.searchParams.get('creator.href') as string;
      let choices = await getChoicesFromPrivateFS(context);

      // get the choices from the DB if the file doesn't exist yet. Delete in 6 months.
      if (choices === null) {
        try {
          const dbChoices = await db.table('choices').where('context').equals(context).toArray();
          choices = dbChoices.map((z) => z.value);
        } catch (e) {
          console.debug('db error', e);
        }
      }

      if (choices !== null && choices.length) {
        const responseObj = {
          $$meta: {
            schema: '/llinkid/qualityinstrument/choices/schema',
            docs: '/llinkid/qualityinstrument/choices/docs',
          },
          results: choices.map((choice) => ({
            $$expanded: choice,
            href: choice.$$meta.permalink,
          })),
        };
        return res(ctx.json(responseObj), ctx.delay(150));
      }

      return res(
        ctx.json(await getQualityInstrumentChoices(ctx, req.url.searchParams.get('creator.href'))),
        ctx.delay(150)
      );
    }
  ),
  rest.get(
    `${apis.customcurapi.baseUrl}/llinkid/customstudyprogrammegroups`,
    async (req, res, ctx) => {
      return res(
        ctx.json(
          await getCustomStudyProgrammeGroups(ctx, req.url.searchParams.get('creator.href'))
        ),
        ctx.delay(150)
      );
    }
  ),
  rest.post(
    `${apis.customcurapi.baseUrl}/llinkid/qualityinstrument/choices/batch`,
    async (req, res, ctx) => {
      const body = await req.json();

      await saveChoicesToPrivateFS(body, sessionStorage.getItem('currentSchool') as string);

      // return 201 for each batchitem
      const resp = body.map((batch) => {
        return {
          status: 201,
          href: batch.href,
          verb: batch.verb,
        };
      });

      return res(ctx.json(resp));
    }
  ),
  rest.post(`${apis.customcurapi.baseUrl}/llinkid/*/batch`, async (req, res, ctx) => {
    const body = await req.json();

    // return 201 for each batchitem
    const resp = body.map((batch) => {
      return {
        status: 201,
        href: batch.href,
        verb: batch.verb,
      };
    });

    return res(ctx.json(resp));
  }),
  rest.put(`${apis.customcurapi.baseUrl}/llinkid/*/batch`, async (req, res, ctx) => {
    const body = await req.json();

    // return 201 for each batchitem
    const resp = body.map((batch) => {
      return {
        status: 201,
        href: batch.href,
        verb: batch.verb,
      };
    });

    return res(ctx.json(resp));
  }),
  rest.post(`${apis.customcurapi.baseUrl}/llinkid/*`, (req, res, ctx) => {
    return res(ctx.status(405));
  }),
  rest.put(`${apis.customcurapi.baseUrl}/llinkid/*`, (req, res, ctx) => {
    return res(ctx.status(405));
  }),
];
