const hideExtraEmpty = (mat) => {
  let matrix = [];
  let allEmpties = true;
  let i = 0;

  Object.keys(mat).forEach((key) => {
    Object.keys(mat[key]).forEach((key2) => matrix.push(mat[key][key2]));
  });
  matrix = matrix.reverse();

  while (allEmpties && i < matrix.length - 1) {
    allEmpties = matrix[i].reduce(
      (acc, curr) => curr.className.indexOf('empty') !== -1 && acc,
      true
    );
    if (allEmpties) {
      matrix[i].forEach((activity) => {
        if (activity.className.indexOf('to-hide') === -1) activity.className += ' to-hide';
      });
    }
    i += 1;
  }

  return matrix;
};

const setHeight = (matrix) => {
  matrix.forEach((row) => {
    row.row.forEach((activity) => {
      activity.style = `height: ${row.height + 1}px;`; // the +1 stops the screen from jumping. because we have a 1px border.
    });
  });
};

const getPlansAndActivitiesMatrixes = (row) => {
  const activitiesPlan = {};
  const activitiesRow = {};

  row.forEach((week) => {
    let id = 0;
    while (document.querySelectorAll(`.plan-${id}`).length !== 0) {
      // eslint-disable-next-line no-loop-func
      week.querySelectorAll(`.activity-plan.plan-${id}`).forEach((_plan) => {
        if (!activitiesRow[`plan${id}`]) {
          activitiesRow[`plan${id}`] = {};
        }

        _plan.querySelectorAll('.activity').forEach((activity, j) => {
          if (activitiesRow[`plan${id}`][j]) {
            activitiesRow[`plan${id}`][j].push(activity);
          } else {
            activitiesRow[`plan${id}`][j] = [activity];
          }
        });

        if (activitiesPlan[id]) {
          activitiesPlan[id].push(_plan);
        } else {
          activitiesPlan[id] = [_plan];
        }
      });
      id += 1;
    }
  });

  return { activitiesPlan, activitiesRow };
};

const getMatrixHeights = (matrix) => {
  const matrixHeights = [];
  matrix.forEach((row) => {
    Object.keys(row).forEach((key) => {
      const heights = row[key].map((activity) => activity.clientHeight);
      const maxHeight = Math.max(...heights);

      matrixHeights.push({ row: row[key], height: maxHeight });
    });
  });

  return matrixHeights;
};

const isSameRow = (week, i, weeks) => {
  if (i === 0) return true;
  return week.offsetTop === weeks[i - 1].offsetTop;
};

const splitIntoRows = (weeks) => {
  const matrix = [];
  let row = [];

  weeks.forEach((week, i) => {
    if (isSameRow(week, i, weeks)) {
      row.push(week);
    } else {
      matrix.push(row);
      row = [];
      row.push(week);
    }

    if (weeks.length - 1 === i) matrix.push(row);
  });

  return matrix;
};

const setSameHeight = () => {
  console.time('Height');

  const weeks = document.querySelectorAll('.week-container');
  const acts = document.getElementsByClassName('activity');
  const actPlans = document.getElementsByClassName('activity-plan');
  const STYLE = "min-height: 0; max-height: 'none'; height: 'auto';";

  [].forEach.call(acts, (elem) => {
    elem.style = STYLE;
    elem.className.replace('to-hide', '');
  });
  [].forEach.call(actPlans, (elem) => {
    elem.style = STYLE;
  });

  const weekMatrix = splitIntoRows(weeks);

  if (weekMatrix.some((e) => e.length > 1)) {
    const planRows = [];
    const heightActivties = [];

    weekMatrix.forEach((row) => {
      const mat = getPlansAndActivitiesMatrixes(row);
      planRows.push(mat.activitiesPlan);

      let matrix = [];
      matrix = hideExtraEmpty(mat.activitiesRow);
      matrix.forEach((_row) => {
        const heights = _row.map((activity) => activity.clientHeight);
        const maxHeight = Math.max(...heights);

        heightActivties.push({ row: _row, height: maxHeight });
      });
    });

    setHeight(heightActivties);

    const heightPlans = getMatrixHeights(planRows);
    setHeight(heightPlans);
  }

  console.timeEnd('Height');
  return Promise.resolve();
};

export default setSameHeight;
