import CustomSelect from '@UI/customSelect/CustomSelect';
import { Controller } from 'react-hook-form';

const FormSelect = ({
  name,
  value,
  options,
  placeholder = '',
  disabled = false,
  required = false,
  validations,
  control,
  onChange,
}) => {
  if (name == null) throw new Error('[FormSelect]: Please provide a unique form input name.');
  if (control == null) throw new Error('[FormSelect]: UseForm control not provided.');

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={{
        required,
        validate: validations,
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <CustomSelect
            placeholder={placeholder}
            options={options}
            disabled={disabled}
            isClearable={false}
            error={error}
            portal="body"
            {...field}
            onChange={(val) => {
              field.onChange(val);
              onChange?.(val);
            }}
          />
        );
      }}
    />
  );
};

export default FormSelect;
