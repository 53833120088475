import Select, { createFilter } from 'react-select';

import { forwardRef } from 'react';
import './CustomSelect.scss';

const CustomSelect = (
  {
    value,
    options,
    display = 'name',
    optionValue = 'value',
    placeholder = '',
    isClearable = true,
    disabled = false,
    error,
    onChange,
    onBlur,
    portal,
  },
  ref
) => {
  const getValue = (val) => {
    return typeof val === 'string' ? options.find((o) => o[optionValue] === val) : val;
  };
  const getDisplay = (option) => option?.[display];
  const getValueFromOption = (option) => option?.[optionValue];
  const onChangeHandler = (option) => onChange(getValueFromOption(option));
  const style = {
    placeholder: (provided) => ({
      ...provided,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      lineHeight: '1rem',
      maxHeight: '2.4rem',
    }),
    menu: (provided) => ({ ...provided, borderRadius: 0 }),
    menuPortal: (provided) => ({ ...provided, zIndex: portal ? 9999 : 1 }),
    container: (provided) => ({ ...provided, borderColor: error ? 'red' : 'inherit' }),
  };

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: (option) => `${option.label}`,
    trim: true,
  };

  return (
    <>
      <Select
        ref={ref}
        value={getValue(value)}
        className={error ? 'error' : ''}
        options={options}
        getOptionLabel={getDisplay}
        getOptionValue={getValueFromOption}
        filterOption={createFilter(filterConfig)}
        placeholder={placeholder}
        isClearable={isClearable}
        isDisabled={disabled}
        styles={style}
        menuPortalTarget={portal ? document.querySelector(portal) : null}
        onChange={onChangeHandler}
        onBlur={onBlur}
      ></Select>
      {error && <span className="error-message">{error.message}</span>}
    </>
  );
};

export default forwardRef(CustomSelect);
