import { studyProgrammeConstants } from '../../constants/apiConstants';
import * as grades from '../../constants/grades';

export function getGradeOfStudyProgram(studyProgram) {
  if (
    studyProgram.studyProgrammeGroups.some(
      (group) => group.studyProgrammeGroup.href === studyProgrammeConstants.firstGradeHref
    )
  ) {
    return grades.GRADE_1;
  }
  if (
    studyProgram.studyProgrammeGroups.some(
      (group) => group.studyProgrammeGroup.href === studyProgrammeConstants.secondGradeHref
    )
  ) {
    return grades.GRADE_2;
  }

  return grades.GRADE_3;
}

export function getStudyProgrammesForStreamOrFinality(streamHref, allPrograms) {
  return allPrograms.filter((e) =>
    e.studyProgrammeGroups.some((spg) => spg.studyProgrammeGroup.href === streamHref)
  );
}

export function getProgramsForGrade(grade, allPrograms) {
  return allPrograms.filter((program) => {
    return getGradeOfStudyProgram(program) === grade;
  });
}

export function convertHrefToGrade(href) {
  if (href === studyProgrammeConstants.firstGradeHref) {
    return grades.GRADE_1;
  }
  if (href === studyProgrammeConstants.secondGradeHref) {
    return grades.GRADE_2;
  }
  if (href === studyProgrammeConstants.thirdGradeHref) {
    return grades.GRADE_3;
  }
  return null;
}

export const hasAny = (list, listToCompare) => {
  return list.reduce((isInList, item) => {
    return listToCompare.includes(item) || isInList;
  }, false);
};
