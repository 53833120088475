import { PayloadAction, createSlice, original } from '@reduxjs/toolkit';
import { getInitials, getSchoolyears, toggleArrayItem } from '@utils/utils';
import { getKeyFromHref } from '@utils/getKeyFromHref';
import settings from '@config/settings';
import { LEIDRAAD_TABS } from '../../constants/leidraadTabs';
import { OrganisationalUnitViewItem } from '../../types/samApiTypes';
import { Schoolyear } from '../../types/schoolyear';
import { generatePreference, generateSectionChoices } from '../apihelpers';
import { loadState, saveState } from './persistUserSchoolState';
import { UserAndSchoolState } from './userAndSchoolTypes';

const { version } = settings.whatIsNewModal;
const stateSchemaVersion = 2; // change this number if breaking changes are made to the state format.
const currentModalVersionSkipped =
  sessionStorage.getItem(version) === 'skipped' || localStorage.getItem(version) === 'skipped';

function initialState(): UserAndSchoolState {
  const defaultSchoolyear = getSchoolyears().find((e) => e.default) as Schoolyear;
  const currentSchoolyear = defaultSchoolyear.key;
  return {
    currentSchoolHref: '', // this is the schoolcontext
    currentOrgHref: '', // this is what org we're working/editing as. could be school/team/teacher
    currentSchoolyear,
    privateState: undefined,
    currentModalVersionSkipped,
    user: undefined,
    userFetched: false,
    loginRequired: false,
    samResponsibilities: [], // <== to be renamed to samResponsibilities
    securitySyncedOUs: [],
    schoolsFetched: false,
    schoolsData: {},
    stateSchemaVersion,
  };
}

const usersAndSchoolState = createSlice({
  name: 'userAndSchools',
  initialState: initialState(),
  reducers: {
    init: () => {},
    // eslint-disable-next-line consistent-return
    userFetched: (state, action) => {
      const user = action.payload;
      if (user && user.key) {
        const localStorageState = loadState();
        if (
          localStorageState?.user?.$$meta?.permalink === user.$$meta.permalink &&
          localStorageState?.stateSchemaVersion === stateSchemaVersion
        ) {
          // WARNING!! dangerous if new things are added to the initialState they won't show until you clean up your localStorage!!
          return { ...localStorageState, currentModalVersionSkipped }; // currentModalVersionSkipped must always be overwritten by the current value.
        }
      }
      saveState(); // clear the saved state.
      state.user = user;
      state.userFetched = true;
    },
    userFetchFailed: (state) => {
      state.userFetched = true;
    },
    userPersonalDataFetched: (state, action) => {
      const user = action.payload;
      user.username = `${user.firstName} ${user.lastName}`;
      user.initials = getInitials(user);
      user.$$profilePicture = user.$$attachments?.filter(
        (e) => e.$$expanded.type === 'PROFILE_IMAGE' && e.$$expanded.name === 'profile-small.jpg'
      )[0];
      state.user = user;
    },
    preferencesFetched: (state, action) => {
      if (!state.user) return;
      const prefs = action.payload;
      if (prefs?.length) {
        const [personPrefs] = prefs;
        state.privateState = personPrefs;
      } else {
        state.privateState = generatePreference(state.user.$$meta.permalink);
      }
    },
    samResponsibilitiesFetched: (state, action) => {
      // const resps = filterSamResps(action.payload);
      // state.responsibilities = dateUtils.getActiveResources(resps);
      state.samResponsibilities = action.payload;
    },
    orgsFetched: (state, action: PayloadAction<OrganisationalUnitViewItem[]>) => {
      const defaultValues = {
        responsibilities: [],
        teachers: [],
        responsibilitiesPerTeam: {},
        responsibilitiesInSchoolFetched: false,
        teachersInSchoolFetched: false,
        leidraadChoicesFetched: false,
        studyProgrammeGroupsFetched: false,
        teachersForTeamsFetched: false,
      };

      const newSchoolsData = action.payload.reduce((acc, school) => {
        const { permalink } = school.$$meta;
        const existingSchoolState = state.schoolsData[permalink] || {};
        const { teacherGroups, classes, studyProgrammes, ...schoolDetails } = school;

        acc[permalink] = {
          ...defaultValues,
          ...existingSchoolState,
          school: schoolDetails,
          teacherGroups,
          classes,
          studyProgrammes,
        };

        return acc;
      }, {});

      state.schoolsData = newSchoolsData;
      state.schoolsFetched = true;

      if (action.payload.length === 1) {
        const { permalink } = action.payload[0].$$meta;
        state.currentSchoolHref = permalink;
        state.currentOrgHref = permalink;
      }
    },
    schoolDetailsFetchingFailed: (state, action) => {
      const { href } = action.payload;
      delete state.schoolsData[href];
      const schoolsLeft = Object.keys(state.schoolsData);
      if (schoolsLeft.length === 1) {
        // one school left, set the context to that school
        const [remainingHref] = schoolsLeft;
        state.currentSchoolHref = remainingHref;
        state.currentOrgHref = remainingHref;
      } else if (schoolsLeft.length === 0) {
        // no schools left, reset the context
        state.currentSchoolHref = '';
        state.currentOrgHref = '';
      }
    },
    setCurrentModalVersionSkipped: (state) => {
      state.currentModalVersionSkipped = true;
      sessionStorage.setItem(version, 'skipped');
    },
    responsibilitiesInSchoolFetched: (state, action) => {
      const { href, responsibilities } = action.payload;
      const school = state.schoolsData[href];
      school.responsibilities = responsibilities;
      school.responsibilitiesInSchoolFetched = true;
    },
    teachersInSchoolFetched: (state, action) => {
      const { href, teachers } = action.payload;
      const school = state.schoolsData[href];
      school.teachers = teachers;
      school.teachersInSchoolFetched = true;
    },
    leidraadChoicesFetched: (state, action) => {
      const { href, leidraadChoices } = action.payload;
      const school = state.schoolsData[href];

      const leidraadChoicesAndTemplates = LEIDRAAD_TABS.map((tab) => {
        const existingTab = leidraadChoices.find((e) => getKeyFromHref(e.section.href) === tab.key);
        if (existingTab) return existingTab;
        return generateSectionChoices(href, tab.key);
      });

      school.leidraadChoices = leidraadChoicesAndTemplates;
      school.leidraadChoicesFetched = true;
    },
    studyProgrammeGroupsFetched: (state, action) => {
      const { href, studyProgrammeGroups } = action.payload;
      const school = state.schoolsData[href];
      school.studyProgrammeGroups = studyProgrammeGroups;
      school.studyProgrammeGroupsFetched = true;
    },
    teachersForTeamsFetched: (state, action) => {
      const { href, allTeamResps } = action.payload;
      // RESULTS NOT PRESENT IN ACC

      // 0
      // :
      // "Nederlands - I A (A-stroom) (v1 - 2e leerjaar)"
      // 1
      // :
      // "Nederlands - I A (A-stroom) (v2 - 1e leerjaar)"
      // 2
      // :
      // "testen v2 ICT met andere doelen (Doorstroom) (v1)"
      // 3
      // :
      // "testkader wenken opnemen - Economische wetenschappen (v1)"
      // 4
      // :
      // "Nederlands - I A (v1 - 2e leerjaar)"
      // 5
      // :
      // "Nederlands - I A (v2 - 1e leerjaar)"
      const school = state.schoolsData[href];
      const responsibilitiesPerTeam = {};
      allTeamResps.forEach((r) => {
        responsibilitiesPerTeam[r.organisation.href] =
          responsibilitiesPerTeam[r.organisation.href] || [];
        responsibilitiesPerTeam[r.organisation.href].push(r);
      });
      school.responsibilitiesPerTeam = responsibilitiesPerTeam;
      // school.teacherGroups.forEach((team) => {
      //   team.responsibilities = allTeamResps.filter(
      //     (r) => r.organisation.href === team.$$meta.permalink
      //   );
      // });
      school.teachersForTeamsFetched = true;
    },
    saveLeidraadChoices: (state, action) => {
      const { schoolHref, leidraadChoices } = action.payload;
      const school = state.schoolsData[schoolHref];
      school.leidraadChoices = leidraadChoices;
    },
    setSchoolContext: (state, action: PayloadAction<string>) => {
      sessionStorage.setItem('currentSchool', action.payload);
      state.currentSchoolHref = action.payload;
    },
    setOrgContext: (state, action: PayloadAction<string>) => {
      sessionStorage.setItem('currentOrg', action.payload);
      state.currentOrgHref = action.payload;
    },
    setLoginRequired: (state, action) => {
      state.loginRequired = action.payload;
    },
    setCurrentSchoolyear: (state, action: PayloadAction<string>) => {
      state.currentSchoolyear = action.payload;
    },
    setStarredCurriculaPreferences: (state, action) => {
      const { curriculumHref, starred } = action.payload;
      if (state.user && state.privateState) {
        const list = (
          state.privateState.state.starredCurricula
            ? original(state.privateState.state.starredCurricula)
            : []
        ) as string[];

        const starredCurricula = toggleArrayItem({
          list,
          item: getKeyFromHref(curriculumHref),
          addItem: starred,
        });

        state.privateState.state = {
          ...state.privateState.state,
          starredCurricula,
        };
      }
    },
    setHideOptionalElementsPreferences: (state, action) => {
      const { key, hide } = action.payload;
      if (state.user && state.privateState) {
        const list = (
          state.privateState.state.hideOptionalElements
            ? original(state.privateState.state.hideOptionalElements)
            : []
        ) as string[];

        const hideOptionalElementsList = toggleArrayItem({
          list,
          item: key,
          addItem: hide,
        });

        state.privateState.state = {
          ...state.privateState.state,
          hideOptionalElements: hideOptionalElementsList,
        };
      }
    },
    setCollapseSectionsPreferences: (state, action) => {
      if (!state.privateState) return;
      const { curriculumKey, sectionKey, collapsed } = action.payload;
      const { currentSchoolHref } = state;
      const schoolHrefOrNone = currentSchoolHref || 'none';

      if (schoolHrefOrNone) {
        const collapseSectionsState = state.privateState.state?.collapseSections ?? {};
        const schoolContext = collapseSectionsState[schoolHrefOrNone] ?? {};
        const currContext = schoolContext[curriculumKey] ?? {};

        const newState = {
          ...collapseSectionsState,
          [schoolHrefOrNone]: {
            ...schoolContext,
            [curriculumKey]: {
              ...currContext,
              [sectionKey]: collapsed,
            },
          },
        };

        state.privateState.state = {
          ...state.privateState.state,
          collapseSections: newState,
        };
      }
    },
    setModalsPreferences: (state, action) => {
      const { modalName } = action.payload;
      localStorage.setItem(modalName, 'skipped');
      state.currentModalVersionSkipped = true;

      if (!state.privateState) return;
      const modalsState = state.privateState?.state?.modals ?? {};

      const newState = {
        ...modalsState,
        [modalName]: new Date().toISOString(),
      };

      state.privateState.state = {
        ...state.privateState.state,
        modals: newState,
      };
    },
    setAutoStarredPreferences: (state, action) => {
      if (!state.privateState) return;
      const { value } = action.payload;

      state.privateState.state.autoStarred = value;
    },
    setSchoolyearCopyCalendarModal: (_state, _action: PayloadAction<string>) => {},
  },
});

export const {
  init,
  userFetched,
  userFetchFailed,
  userPersonalDataFetched,
  preferencesFetched,
  samResponsibilitiesFetched,
  orgsFetched,
  responsibilitiesInSchoolFetched,
  teachersInSchoolFetched,
  leidraadChoicesFetched,
  studyProgrammeGroupsFetched,
  teachersForTeamsFetched,
  setSchoolContext,
  setOrgContext,
  setCurrentSchoolyear,
  setStarredCurriculaPreferences,
  setHideOptionalElementsPreferences,
  setCollapseSectionsPreferences,
  setModalsPreferences,
  setAutoStarredPreferences,
  saveLeidraadChoices,
  schoolDetailsFetchingFailed,
  setCurrentModalVersionSkipped,
  setSchoolyearCopyCalendarModal,
} = usersAndSchoolState.actions;
export default usersAndSchoolState;
