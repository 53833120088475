import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#9B009B',
      light: '#cc02cc',
      dark: '#800280',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FF751A',
      light: '#ff944f',
      dark: '#d96117',
      contrastText: '#fff',
    },
    grey: {
      main: '#676767',
      light: '#c5c5c5',
      dark: '#4d4d4d',
      contrastText: '#fff',
    },
    darkGreen: {
      main: 'rgb(47, 136, 109)',
      light: 'rgb(104, 190, 161)',
      dark: 'rgb(8, 110, 85)',
      contrastText: '#fff',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.8rem',
        },
      },
    },
  },
});

export default theme;
