import { forwardRef } from 'react';
import './textField.scss';

const TextField = (
  { value, placeholder, required, maxLength, error, disabled, autoFocus, className = '', onChange },
  ref
) => {
  const onChangeHandler = (ev) => onChange(ev.target.value);

  return (
    <div className={`text-field ${className}`}>
      <input
        ref={ref}
        type="text"
        className={error ? 'error' : ''}
        placeholder={placeholder}
        value={value}
        required={required}
        disabled={disabled}
        onChange={onChangeHandler}
        autoFocus={autoFocus}
      />
      <div className="input-info">
        <span className="error-msg">{error?.message}</span>
        {maxLength && (
          <span className={`char-counter ${error?.type === 'maxLength' ? 'error' : ''}`}>
            {value?.length ?? 0}/{maxLength ?? 0}
          </span>
        )}
      </div>
    </div>
  );
};

export default forwardRef(TextField);
