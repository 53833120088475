import useMissingGoals from '@hooks/useMissingGoals';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import { closeAlert, setAlert } from '@store/alerts/alertsState';
import { selectTeachersFromPlan } from '@store/calendar/calendarSelectors';
import {
  selectCurrentSchool,
  selectInitializedSchoolyearsForSchool,
  selectReadOnlySchools,
  selectSchoolVMs,
  selectSchoolyearsForSchool,
} from '@store/userAndSchool/userAndSchoolSelectors';
import {
  setOrgContext,
  setSchoolContext,
  setSchoolyearCopyCalendarModal,
} from '@store/userAndSchool/usersAndSchoolState';
import {
  CalendarInfoCard,
  ClassesSelect,
  FormGroup,
  FormSelect,
  FormTextField,
  MissingGoals,
  TeachersSelect,
} from '@UI/index';
import apiFormatters from '@utils/apiFormatters';
import { isExpiredValidation, requiredValidation } from '@utils/validations';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import iconCopyWhite from '@assets/img/icon_copy_white.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, CircularProgress } from '@mui/material';
import { copyCalendar, copyCalendarClose } from '@store/calendarList/calendarListState';
import { RootState } from '@store/storeSetup';
// import { useEffect } from 'react';
import {
  isCopyCalendarDataLoaded,
  selectCopyCalendarDefaultFormValues,
} from './calendarModalSelectors';
import CurriculumSelect from './curriculumSelect/CurriculumSelect';
import './modals.scss';

const CopyCalendarModal = () => {
  const plan = useSelector((state: RootState) => state.calendarList.copyCalendar.item);
  const isSaving = useSelector(
    (state: RootState) => state.calendarList.copyCalendar.status === 'SAVING'
  );
  const dispatch = useDispatch();
  const currentSchool = useSelector(selectCurrentSchool);
  const schoolyears = useSelector(selectInitializedSchoolyearsForSchool);
  const schools = useSelector(selectSchoolVMs);
  const activitiesLoaded = useSelector(isCopyCalendarDataLoaded);
  const allSchoolyears = useSelector(selectSchoolyearsForSchool);
  const readOnlySchools: string[] = useSelector(selectReadOnlySchools);
  const originalCalendar = useSelector(
    (state: RootState) => state.calendarList.copyCalendar.originalCalendar
  );
  const { teachers: teachersInitialValues, observers: observersInitialValues } = useSelector(
    (state: RootState) => selectTeachersFromPlan(state, { plan })
  );
  const defaultValues = useSelector(selectCopyCalendarDefaultFormValues);

  const [missingGoals, fetchMissingGoals, missingGoalsRef] = useMissingGoals(
    originalCalendar?.plans,
    originalCalendar?.curricula,
    originalCalendar?.schoolyear,
    currentSchool?.href
  );

  const methods = useForm({
    mode: 'onChange',
  });
  const { control, handleSubmit, setValue, getValues, trigger } = methods;

  const schoolyearSelect = useWatch({
    control,
    name: 'schoolyear',
  });

  // useEffect(() => {
  //   if (isSchoolReadOnly) {
  //     methods.setError('school.readOnly', {
  //       type: 'validate',
  //       message: 'Je bent niet meer actief in deze school. Kies een andere school.',
  //     });
  //   } else {
  //     methods.clearErrors('school.readOnly');
  //   }
  //   console.log('methods triggered, isSchoolReadOnly', isSchoolReadOnly);
  // }, [isSchoolReadOnly, methods, currentSchool]);

  const onSchoolSelected = (schoolKey) => {
    if (missingGoals.usedGoalsLoading) {
      setValue('school', defaultValues?.school?.key);
      dispatch(
        setAlert({
          key: 'school-selection-changed-blocked',
          title: 'Even geduld...',
          msg: 'Het vorderingsplan is nog niet volledig ingeladen. Probeer binnen enkele seconden opnieuw.',
          type: 'info',
          showClose: false,
          delay: 5000,
        })
      );
    }

    // close the alert when we managed to change schools.
    dispatch(
      closeAlert({
        key: 'school-selection-changed-blocked',
      })
    );

    const item = schools.find((s) => s.key === schoolKey);
    if (item) {
      dispatch(setSchoolContext(item.href));
      dispatch(setOrgContext(item.href));
    }
  };

  const onSchoolYearSelected = (schoolYear) => {
    dispatch(setSchoolyearCopyCalendarModal(schoolYear));
  };

  const onCurriculumChange = (values) => fetchMissingGoals(values);

  const onConfirmHandler = async (formValues) => {
    const formattedFormValues = apiFormatters.formatCalendarCloneAPI(formValues);
    dispatch(copyCalendar(formattedFormValues));
  };

  const onCloseHandler = () => {
    if (activitiesLoaded) {
      const originalSchoolKey = defaultValues?.school.key;
      const currentSchoolKey = getValues('school');

      if (originalSchoolKey !== currentSchoolKey) {
        const item = schools.find((s) => s.key === originalSchoolKey);
        if (!item) return;
        dispatch(setSchoolContext(item.href));
        dispatch(setOrgContext(item.href));
      }
    }
    dispatch(copyCalendarClose());
  };

  return (
    <>
      <Modal.Header title="Vorderingsplan kopiëren" icon={iconCopyWhite} onClose={onCloseHandler} />

      {activitiesLoaded && (
        <Modal.Body>
          <section className="modal-info">
            <CalendarInfoCard calendar={originalCalendar} />

            <div className="modal-section-name">BRON</div>
            <div className="modal-arrow to">
              <div className="single">
                <div className="body"></div>
                <div className="arrow-pointer"></div>
              </div>
            </div>
          </section>

          <section className="modal-form">
            <FormProvider {...methods}>
              <FormGroup label="School" required>
                <FormSelect
                  name="school"
                  value={defaultValues?.school?.key}
                  options={schools.map((e) => ({ name: e.$$displayName, value: e.key }))}
                  control={control}
                  onChange={onSchoolSelected}
                  validations={{
                    required: (value) => requiredValidation(value),
                    readOnly: (value) => {
                      if (readOnlySchools.includes(value)) {
                        return 'Je bent niet meer actief in deze school. Kies een andere school.';
                      }
                      return true;
                    },
                  }}
                />
              </FormGroup>

              <FormGroup label="Schooljaar" required>
                <FormSelect
                  name="schoolyear"
                  value={defaultValues?.schoolyear}
                  options={schoolyears}
                  validations={{
                    isExpiredValidation: (value) => isExpiredValidation(value, allSchoolyears),
                    required: (value) => requiredValidation(value),
                  }}
                  control={control}
                  onChange={onSchoolYearSelected}
                />
              </FormGroup>

              <FormGroup label="Naam">
                <FormTextField
                  name="title"
                  placeholder="naam vorderingsplan"
                  value={defaultValues?.title}
                  control={control}
                  validations={null}
                />
              </FormGroup>

              <FormGroup label="Leerplan" required>
                <CurriculumSelect
                  name="curriculum"
                  defaultValue={defaultValues?.curricula}
                  control={control}
                  required
                  onChange={onCurriculumChange}
                />
              </FormGroup>

              <FormGroup label="Klas(sen)" required>
                <ClassesSelect
                  name="classes"
                  defaultValue={defaultValues?.classes}
                  control={control}
                  schoolyear={schoolyearSelect}
                />
              </FormGroup>

              <FormGroup label="Leraren">
                <TeachersSelect
                  name="teachers"
                  defaultValue={teachersInitialValues}
                  schoolyear={schoolyearSelect}
                  control={control}
                />
              </FormGroup>

              <FormGroup label="Delen">
                <TeachersSelect
                  name="shared"
                  defaultValue={observersInitialValues}
                  schoolyear={schoolyearSelect}
                  control={control}
                />
              </FormGroup>

              {(missingGoals.missingGoals.length > 0 ||
                missingGoals.loading ||
                missingGoals.usedGoalsLoading) && (
                <section ref={missingGoalsRef} className="missing-goals-section">
                  <MissingGoals
                    goals={missingGoals.missingGoals}
                    total={missingGoals.usedGoals.length}
                    loading={missingGoals.loading}
                  />
                </section>
              )}

              <div className="modal-section-name">DOEL</div>
            </FormProvider>
          </section>
        </Modal.Body>
      )}

      {!activitiesLoaded && (
        <Modal.Body>
          <CircularProgress size={40} sx={{ m: 'auto', display: 'block' }} />
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button variant="outlined" onClick={onCloseHandler}>
          Annuleren
        </Button>
        <Button
          startIcon={
            isSaving ? (
              <CircularProgress
                size={20}
                sx={{ m: 'auto', display: 'block', color: 'primary.contrastText' }}
              />
            ) : (
              <ContentCopyIcon />
            )
          }
          variant="contained"
          disabled={missingGoals.loading || missingGoals.usedGoalsLoading || !activitiesLoaded}
          onClick={handleSubmit(async (formValues) => {
            const result = await trigger();
            if (!result || isSaving) return;
            onConfirmHandler({
              ...formValues,
              school: currentSchool,
              schoolyear: schoolyears.find((sy) => sy.value === formValues.schoolyear),
              missingGoals,
              originalCalendar,
            });
          })}
        >
          Kopiëren
        </Button>
      </Modal.Footer>
    </>
  );
};

export default CopyCalendarModal;
