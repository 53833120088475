import { orderBy } from 'lodash-es';
import { createSelector } from '@reduxjs/toolkit';
import { groupedHomePageListSelector } from '@store/leerplanList/leerplanListSelectors';
import {
  selectFullAnnotatedCurriculumViewModel,
  selectSelectedStudyPrograms,
} from '@store/curriculum/curriculumSelectors';

const CURRICULUM_TYPE = {
  PLAN: 0,
  SCHOOL: 1,
  TEAM: 2,
};

const filterByStudyProgram = (item, studyProgram) => {
  return (
    (item.studyProgram && item.studyProgram === studyProgram) ||
    (item.sources && item.sources.some((sp) => sp.studyProgram === studyProgram)) ||
    (item.type === 'PLAN' &&
      item.applicability &&
      item.applicability.studyProgrammes.some((sp) => sp.href === studyProgram))
  );
};
export const selectReferenceGoalCurricula = createSelector(
  [
    (state) => groupedHomePageListSelector(state),
    (state) => selectSelectedStudyPrograms(state),
    (state) => selectFullAnnotatedCurriculumViewModel(state),
  ],
  (curricula, selectedStudyPrograms, curriculumViewModel) => {
    const { curriculumType, nonderivedGroupKey } = curriculumViewModel;
    const curriculumPriority = CURRICULUM_TYPE[curriculumType] || 3;
    let filteredCurricula = curricula.filter((e) => curriculumPriority >= CURRICULUM_TYPE[e.type]);

    filteredCurricula = filteredCurricula.filter((item) =>
      selectedStudyPrograms
        .map((e) => e.$$meta.permalink)
        .some((e) => filterByStudyProgram(item, e))
    );

    filteredCurricula = filteredCurricula.filter(
      (e) => e.nonderivedGroupKey !== nonderivedGroupKey
    );

    return orderBy(filteredCurricula, ['fav', 'order', 'name'], ['desc', 'asc', 'asc']);
  }
);
