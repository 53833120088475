export const HOLIDAYS = [
  {
    title: 'Zomervakantie',
    duration: {
      startDate: '2018-07-01',
      endDate: '2018-08-31',
    },
  },
  {
    title: 'Herfstvakantie',
    duration: {
      startDate: '2018-10-29',
      endDate: '2018-11-04',
    },
  },
  {
    title: 'Kerstvakantie',
    duration: {
      startDate: '2018-12-24',
      endDate: '2019-01-06',
    },
  },
  {
    title: 'Krokusvakantie',
    duration: {
      startDate: '2019-03-04',
      endDate: '2019-03-10',
    },
  },
  {
    title: 'Paasvakantie',
    duration: {
      startDate: '2019-04-08',
      endDate: '2019-04-22',
    },
  },
  {
    title: 'Zomervakantie',
    duration: {
      startDate: '2019-07-01',
      endDate: '2019-08-31',
    },
  },
  {
    title: 'Herfstvakantie',
    duration: {
      startDate: '2019-10-28',
      endDate: '2019-11-03',
    },
  },
  {
    title: 'Kerstvakantie',
    duration: {
      startDate: '2019-12-23',
      endDate: '2020-01-05',
    },
  },
  {
    title: 'Krokusvakantie',
    duration: {
      startDate: '2020-02-24',
      endDate: '2020-03-01',
    },
  },
  {
    title: 'Paasvakantie',
    duration: {
      startDate: '2020-04-06',
      endDate: '2020-04-19',
    },
  },
  {
    title: 'Zomervakantie',
    duration: {
      startDate: '2020-07-01',
      endDate: '2020-08-31',
    },
  },

  {
    title: 'Herfstvakantie',
    duration: {
      startDate: '2020-11-02',
      endDate: '2020-11-08',
    },
  },
  {
    title: 'Kerstvakantie',
    duration: {
      startDate: '2020-12-21',
      endDate: '2021-01-03',
    },
  },
  {
    title: 'Krokusvakantie',
    duration: {
      startDate: '2021-02-15',
      endDate: '2021-02-21',
    },
  },
  {
    title: 'Paasvakantie',
    duration: {
      startDate: '2021-04-05',
      endDate: '2021-04-18',
    },
  },
  {
    title: 'Zomervakantie',
    duration: {
      startDate: '2021-07-01',
      endDate: '2021-08-31',
    },
  },

  {
    title: 'Herfstvakantie',
    duration: {
      startDate: '2021-11-01',
      endDate: '2021-11-07',
    },
  },
  {
    title: 'Kerstvakantie',
    duration: {
      startDate: '2021-12-27',
      endDate: '2022-01-09',
    },
  },
  {
    title: 'Krokusvakantie',
    duration: {
      startDate: '2022-02-28',
      endDate: '2022-03-06',
    },
  },
  {
    title: 'Paasvakantie',
    duration: {
      startDate: '2022-04-04',
      endDate: '2022-04-17',
    },
  },
  {
    title: 'Zomervakantie',
    duration: {
      startDate: '2022-07-01',
      endDate: '2022-08-31',
    },
  },

  {
    title: 'Herfstvakantie',
    duration: {
      startDate: '2022-10-31',
      endDate: '2022-11-06',
    },
  },
  {
    title: 'Kerstvakantie',
    duration: {
      startDate: '2022-12-26',
      endDate: '2023-01-08',
    },
  },
  {
    title: 'Krokusvakantie',
    duration: {
      startDate: '2023-02-20',
      endDate: '2023-02-26',
    },
  },
  {
    title: 'Paasvakantie',
    duration: {
      startDate: '2023-04-03',
      endDate: '2023-04-16',
    },
  },
  {
    title: 'Zomervakantie',
    duration: {
      startDate: '2023-07-01',
      endDate: '2023-08-31',
    },
  },

  {
    title: 'Herfstvakantie',
    duration: {
      startDate: '2023-10-30',
      endDate: '2023-11-05',
    },
  },
  {
    title: 'Kerstvakantie',
    duration: {
      startDate: '2023-12-25',
      endDate: '2024-01-07',
    },
  },
  {
    title: 'Krokusvakantie',
    duration: {
      startDate: '2024-02-12',
      endDate: '2024-02-18',
    },
  },
  {
    title: 'Paasvakantie',
    duration: {
      startDate: '2024-04-01',
      endDate: '2024-04-14',
    },
  },
  {
    title: 'Zomervakantie',
    duration: {
      startDate: '2024-07-01',
      endDate: '2024-08-31',
    },
  },

  {
    title: 'Herfstvakantie',
    duration: {
      startDate: '2024-10-28',
      endDate: '2024-11-03',
    },
  },
  {
    title: 'Kerstvakantie',
    duration: {
      startDate: '2024-12-23',
      endDate: '2025-01-05',
    },
  },
  {
    title: 'Krokusvakantie',
    duration: {
      startDate: '2025-03-03',
      endDate: '2025-03-09',
    },
  },
  {
    title: 'Paasvakantie',
    duration: {
      startDate: '2025-04-07',
      endDate: '2025-04-20',
    },
  },
  {
    title: 'Zomervakantie',
    duration: {
      startDate: '2025-07-01',
      endDate: '2025-08-31',
    },
  },
]; // https://onderwijs.vlaanderen.be/nl/schoolvakanties/schoolvakanties-volgende-schooljaren

export const DEFAULT_COLORS = [
  { background: '#FF751A', text: '#FFF' },
  { background: '#69c8cf', text: '#FFF' },
  { background: '#b8bd37', text: '#FFF' },
  { background: '#1e4fa8', text: '#FFF' },
  { background: '#006e56', text: '#FFF' },
  { background: '#C28F8F', text: '#FFF' },
  { background: '#4d82d1', text: '#FFF' },
  { background: '#2e9980', text: '#FFF' },
  { background: '#9B009B', text: '#FFF' },
];
export const EMPTY_ACTIVITY = {
  title: 'Activiteit',
  description: 'Korte inhoud',
  empty: true,
  position: 0,
};

export const WEEK_TITLE_TYPE = {
  FULL: 'FULL',
  DATE: 'DATE',
  NUMBER: 'NUMBER',
};

export const SELECTION_TYPE = {
  MINI: 'mini',
  FULL: 'full',
};
