import { generalLoadError, generalSaveError } from '@store/alerts/alertConstants';
import { setAlert } from '@store/alerts/alertsState';
import { loadCurriculum, loadGoals } from '@store/contentApi/contentApiState';
import { makeDistributionWindowObject } from '@store/distribute/distributeHelper';
import { selectDistributionData } from '@store/distribute/distributeSelectors';
import { selectCustomCurriculaData } from '@store/llinkidApis/llinkidApiSelectors';
import {
  addCacheItem,
  applyBatch,
  deltaUpdateCache,
  saveLlinkidApiBatch,
} from '@store/llinkidApis/llinkidApiState';
import { selectCustomCurriculaFromFilters } from '@store/llinkidApis/userActionsSelectors';
import { generateRemovedDistributeSectionBatch } from '@utils/distributionHelper';
import { waitForSaga } from '@utils/sagaUtils';
import { getNonDerivedGroupKey } from '@utils/utils';
import { getKeyFromHref } from '@utils/getKeyFromHref';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { tryCreateCustomCurriculaAndWaitForResponse } from '@store/leerplanList/leerplanListSagas';
import { logAndCaptureException } from '@utils/logAndCaptureException';
import {
  selectAreCustomCurriculaMissing,
  selectCustomCurriculaFromUrlOrParams,
  selectCustomCurriculumFromKey,
  selectExtraGoalsToLoad,
  selectParentTreeMap,
  selectSelectedStudyPrograms,
} from './curriculumSelectors';
import { loadGoalsForCurriculumParams, removeCustomSection, setUrlParams } from './curriculumState';

function* loadGoalsSaga({ payload }) {
  try {
    const { curriculumKey, preview } = payload;

    if (curriculumKey && curriculumKey !== 'nonderived') {
      yield put(loadCurriculum({ curriculumKey, refresh: preview }));
      yield call(waitForSaga, (state) => {
        return (
          curriculumKey === 'nonderived' ||
          state.contentApiData.curricula[curriculumKey] ||
          state.contentApiData.curriculaToLoad[curriculumKey]?.isFailed === true
        );
      });
    }

    const goalsToLoad = yield select(selectExtraGoalsToLoad, payload);

    yield put(loadGoals({ keys: goalsToLoad.map((e) => getKeyFromHref(e)), refresh: preview }));
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
  }
}

function* loadExtraData() {
  try {
    yield call(waitForSaga, (state) => selectCustomCurriculaData(state).length);
    yield call(waitForSaga, (state) => Object.keys(state.customCurriculaData.annotations).length);

    const goalsToLoad = yield select(selectExtraGoalsToLoad);
    if (goalsToLoad.length) {
      const refresh = yield select((state) => state.curriculum.preview);
      yield put(loadGoals({ keys: goalsToLoad.map((e) => getKeyFromHref(e)), refresh }));
    }
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
  }
}

// this function checks if there are custom curricula missing in the studyProgrammeGroup (for example: A-stroom) and creates them if so
function* checkForMissingCustomCur() {
  try {
    yield call(waitForSaga, (state) => selectCustomCurriculaData(state).length);
    const tryCreateCurrBatchInfo = yield select(selectAreCustomCurriculaMissing);
    if (tryCreateCurrBatchInfo) {
      yield tryCreateCustomCurriculaAndWaitForResponse(
        tryCreateCurrBatchInfo.curriculum,
        tryCreateCurrBatchInfo.creator,
        tryCreateCurrBatchInfo.studyPrograms
      );
    }
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalSaveError));
  }
}

function* removeCustomSectionSaga(action) {
  try {
    const { section } = action.payload;

    const parentVMap = yield select(selectParentTreeMap);

    const parentNode = parentVMap.get(section.key).next;
    const parent = parentNode.value;

    const customCurricula = yield select(selectCustomCurriculaData);

    const distributionData = yield select(selectDistributionData);

    const customCurriculumIds = yield select((state) =>
      selectCustomCurriculaFromUrlOrParams(state).map((c) => c.key)
    );
    const customCur = yield select((state) =>
      selectCustomCurriculumFromKey(state, customCurriculumIds[0])
    );
    const cur = distributionData.allSubjects.find(
      (c) => getNonDerivedGroupKey(customCurricula, customCur) === c.groupKey
    );
    const resource = makeDistributionWindowObject(cur, distributionData.allReferences);

    const selectedStudyProgramHrefs = yield select((state) =>
      selectSelectedStudyPrograms(state).map((e) => e.$$meta.permalink)
    );

    const selectedProgramsForCur = resource.studyProgrammes
      .map((e) => e.href)
      .filter((e) => selectedStudyProgramHrefs.includes(e));

    const customCurs = yield select((state) =>
      selectCustomCurriculaFromFilters(state, {
        curriculum: resource,
        studyPrograms: selectedProgramsForCur,
      })
    );

    let allBatches = [];
    allBatches.push({ verb: 'DELETE', href: section.$$meta.permalink });

    const batch = generateRemovedDistributeSectionBatch({
      section,
      selectedProgramsForCur,
      customCurs,
      allReferencesList: distributionData.allReferences,
      parent,
    });

    allBatches = allBatches.concat(batch);

    yield put(saveLlinkidApiBatch({ batch: allBatches }));
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalSaveError));
  }
}

export function* watchCurriculumSaga() {
  yield takeLatest([setUrlParams, applyBatch, addCacheItem, deltaUpdateCache], loadExtraData);
  yield takeEvery(setUrlParams, checkForMissingCustomCur);
  yield takeEvery(loadGoalsForCurriculumParams, loadGoalsSaga);
  yield takeEvery(removeCustomSection, removeCustomSectionSaga);
}
