import { Box, Skeleton } from '@mui/material';

const CurriculumTabsSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        width: '100%',
        height: '30px',
        alignItems: 'center',
      }}
    >
      <Skeleton
        variant="text"
        sx={{ backgroundColor: '#ffffff66', flexGrow: 2, height: '1.5rem', marginLeft: 5 }}
      />
      <Skeleton
        variant="text"
        sx={{ backgroundColor: '#ffffff66', flexGrow: 1, height: '1.5rem', marginLeft: 5 }}
      />
      <Skeleton
        variant="text"
        sx={{ backgroundColor: '#ffffff66', flexGrow: 3, height: '1.5rem', marginLeft: 5 }}
      />
      <Skeleton
        variant="text"
        sx={{ backgroundColor: '#ffffff66', flexGrow: 1, height: '1.5rem', marginLeft: 5 }}
      />
      <Skeleton
        variant="text"
        sx={{ backgroundColor: '#ffffff66', flexGrow: 1.5, height: '1.5rem', marginLeft: 5 }}
      />
      <Skeleton
        variant="text"
        sx={{
          backgroundColor: '#ffffff66',
          flexGrow: 1.5,
          height: '1.5rem',
          marginLeft: 5,
          marginRight: 5,
        }}
      />
    </Box>
  );
};
export default CurriculumTabsSkeleton;
