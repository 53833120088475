import { Controller } from 'react-hook-form';
import TextField from '@UI/textField/TextField';
import { maxLengthValidation } from '@utils/validations';

const FormTextField = ({
  name,
  value = '',
  placeholder = '',
  disabled = false,
  required = false,
  maxLength = null,
  validations,
  control,
}) => {
  if (name == null) throw new Error('[FormTextField]: Please provide a unique form input name.');
  if (control == null) throw new Error('[FormTextField]: UseForm control not provided.');

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={{
        required,
        maxLength: maxLength ? maxLengthValidation(maxLength) : null,
        validate: validations,
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            error={error}
            {...field}
          />
        );
      }}
    />
  );
};

export default FormTextField;
