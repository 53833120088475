import { Modal } from '@kathondvla/react-shared-components/src/components';

import { Button } from '@mui/material';
import { saveState } from '@store/userAndSchool/persistUserSchoolState';
import { resetDemoSchoolKeys } from '../../../mocks/api/persons/helpers';

const ResetDemoModal = ({ onClose }) => {
  const handleCancel = () => {
    onClose();
  };

  const handleAccept = () => {
    resetDemoSchoolKeys();
    saveState(); // resets the localstorage userstate cache
    window.location.href = '/home/leerplan';
    window.location.reload();
    onClose();
  };

  return (
    <>
      <Modal.Header title={'Demo data resetten?'} onClose={onClose} />
      <Modal.Body>
        <div data-testid="reset-demo-modal">
          Weet je zeker dat je de demo omgeving wilt resetten? <br />
          Hierbij verwijder je alle door jou ingebrachte gegevens, en start je met een schone lei.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={handleCancel}>
          Annuleren
        </Button>
        <Button variant="contained" onClick={handleAccept}>
          Reset
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ResetDemoModal;
