import dateUtils from '@kathondvla/sri-client/date-utils';
import { IApiResourceWithDates } from '../types/sriTypes';

export function filterResourcesActiveOnDate<T extends IApiResourceWithDates>(
  resources: T[],
  date: string | null
): T[] {
  if (!date) {
    return dateUtils.getActiveResources(resources);
  }
  return dateUtils.getActiveResources(resources, dateUtils.toString(new Date(date)));
}
