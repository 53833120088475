import { Alert, AlertTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectAlertByKey } from '@store/alerts/alertSelectors';
import { closeAlert } from '@store/alerts/alertsState';

const AlertMessage = ({ alertKey: key }) => {
  const alert = useSelector((state) => selectAlertByKey(state, key));
  const dispatch = useDispatch();

  return (
    <Alert
      data-testid="alert"
      severity={alert.type}
      variant="filled"
      onClose={alert.showClose ? () => dispatch(closeAlert({ key })) : null}
    >
      <AlertTitle>{alert.title}</AlertTitle>
      <span dangerouslySetInnerHTML={{ __html: alert.msg }}></span>
    </Alert>
  );
};

export default AlertMessage;
