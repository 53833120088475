import { createSlice } from '@reduxjs/toolkit';
import {
  CalendarState,
  DeleteActivityPayload,
  EditActivityModalOpenPayload,
  MoveWeekPayload,
} from './calendarTypes';

const initialState: CalendarState = {
  calendarKeys: [],
  merge: true,
  unselectedClasses: [],
  selectionMode: false,
  selectionOptions: {
    selectionType: null,
    confirmLabel: null,
    cancelLabel: null,
    scrollPosition: null,
  },
  editActivityModal: {
    status: 'CLOSED',
    data: null,
  },
};

const calendarState = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    loadGoalsForPlans: (_state, _action) => {},
    loadTranslationsToNewCurricula: (_state, _action) => {},
    setCalendarKeys: (state, action) => {
      const { calendarKeys } = action.payload;
      state.calendarKeys = calendarKeys;
      state.unselectedClasses = [];
    },
    setMerge: (state, action) => {
      const { merge } = action.payload;
      state.merge = merge;
    },
    selectClass: (state, action) => {
      const { classHref } = action.payload;
      const index = state.unselectedClasses.findIndex((href) => href === classHref);
      if (index !== -1) state.unselectedClasses.splice(index, 1);
    },
    unselectClass: (state, action) => {
      const { classHref } = action.payload;
      const index = state.unselectedClasses.findIndex((href) => href === classHref);
      if (index === -1) state.unselectedClasses.push(classHref);
    },
    setSelectedClasses: (state, action) => {
      const { classes } = action.payload;
      classes.forEach((clss) => {
        const classHref = clss.href;
        if (clss.selected) {
          const index = state.unselectedClasses.findIndex((href) => href === classHref);
          if (index !== -1) state.unselectedClasses.splice(index, 1);
        } else {
          const index = state.unselectedClasses.findIndex((href) => href === classHref);
          if (index === -1) state.unselectedClasses.push(classHref);
        }
      });
    },
    setUnSelectedClasses: (state, action) => {
      const { unselectedClasses } = action.payload;
      state.unselectedClasses = unselectedClasses;
    },
    deleteActivity: (state, _action: DeleteActivityPayload) => {
      state.editActivityModal.status = 'SAVING';
    },
    saveActivity: (state) => {
      state.editActivityModal.status = 'SAVING';
    },
    setSelectionMode: (state, action) => {
      state.selectionMode = !!action.payload;
    },
    setSelectionOptions: (state, action) => {
      const { selectionType, confirmLabel, cancelLabel, scrollPosition } = action.payload;
      if (selectionType) state.selectionOptions.selectionType = selectionType;
      if (confirmLabel) state.selectionOptions.confirmLabel = confirmLabel;
      if (cancelLabel) state.selectionOptions.cancelLabel = cancelLabel;
      if (scrollPosition) state.selectionOptions.scrollPosition = scrollPosition;
    },
    moveWeek: (_state, _action: MoveWeekPayload) => {},
    editActivityModalOpen: (state, action: EditActivityModalOpenPayload) => {
      state.editActivityModal.status = 'OPEN';
      state.editActivityModal.data = action.payload;
    },
    editActivityModalClose: (state) => {
      state.editActivityModal.status = 'CLOSED';
      state.editActivityModal.data = null;
    },
    saveActivityFailed: (state) => {
      state.editActivityModal.status = 'OPEN';
    },
  },
});

export const calendarActions = calendarState.actions;
export const {
  loadGoalsForPlans,
  loadTranslationsToNewCurricula,
  setCalendarKeys,
  setMerge,
  selectClass,
  unselectClass,
  setSelectedClasses,
  setUnSelectedClasses,
  deleteActivity,
  saveActivity,
  setSelectionMode,
  setSelectionOptions,
  moveWeek,
  editActivityModalOpen,
  editActivityModalClose,
  saveActivityFailed,
} = calendarState.actions;
export default calendarState;
