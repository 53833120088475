import { object } from 'prop-types';
import { HoveredPopover } from '@UI/index';

import './educationalPointer.scss';

const EducationalPointer = ({ pointer }) => {
  if (!pointer) return null;

  return (
    <div className="educational-pointer">
      <svg width="8" height="34">
        <path
          className="educational-pointer-icon purple"
          d="M 0 0 L 7 17 L 0 34 L 17 34 L 17 0 z"
        ></path>
      </svg>
      <HoveredPopover content={pointer.title}>
        <svg width="37" height="34" className="educational-pointer-image purple">
          <image x="0" y="3" width="33" height="25" href={pointer.icon}></image>
        </svg>
      </HoveredPopover>
      {pointer.pointers.map((item) => (
        <div key={item.href}>
          <HoveredPopover content={item.title}>
            <svg width="50" height="34" className="educational-pointer-image">
              <image width="45" height="30" x="0" y="0" href={item.imageUrl}></image>
            </svg>
          </HoveredPopover>
        </div>
      ))}
      <svg width="10" height="34">
        <path className="purple" d="M 0 0 L 0 34 L 10 17 z"></path>
      </svg>
    </div>
  );
};

EducationalPointer.propTypes = {
  pointer: object,
};

export default EducationalPointer;
