import { last } from 'lodash-es';
import { CUSTOMCREATIONTYPES, CUSTOMCURRICULATYPES, CUSTOMTYPES } from './curriculumHelper';
import { createAnnotation } from './annotationHelper';
import { calculateReadOrderSet, getNonDerivedGroupKey } from './utils';
import { logAndCaptureException } from './logAndCaptureException';

const getNewParent = (section, parent) => {
  const sectionIndex = parent.sections.findIndex(
    (s) => s.$$meta.permalink === section.$$meta.permalink
  );
  return sectionIndex > 0 ? parent.sections[sectionIndex - 1] : parent;
};

export const getFilteredCustomCurriculaHrefs = (
  resource,
  allCustomCurriculas,
  selectedProgramsForCur,
  customCurricula
) => {
  const filteredCurricula = allCustomCurriculas.filter((elem) => {
    const isSameGroupKey = getNonDerivedGroupKey(customCurricula, elem) === resource.key; // TODO: fix this dirty grouping when we have grouping by key propperly
    const isSameHref = resource.href === elem.source?.href;
    const isCustomCurriculaType = [
      CUSTOMCURRICULATYPES.adaptation,
      CUSTOMCURRICULATYPES.custom,
    ].includes(elem.type);
    const hasStudyProgrammes = Boolean(elem.applicability?.studyProgrammes);
    const isProgramSelected =
      elem.applicability?.studyProgrammes &&
      selectedProgramsForCur.includes(elem.applicability.studyProgrammes[0].href);

    return (
      (isSameGroupKey || isSameHref) &&
      isCustomCurriculaType &&
      hasStudyProgrammes &&
      isProgramSelected
    );
  });

  return filteredCurricula.map((c) => c.$$meta.permalink);
};

const makeAnnotForGoal = (goalHref, custCur, reference, readOrder, type) => {
  const annotations = [];
  const goalAnnot = createAnnotation({
    type,
    custCur,
    target: goalHref,
  });

  if (reference) {
    goalAnnot.reference = { href: reference };
  } else {
    goalAnnot.reference = null;
  }

  goalAnnot.readOrder = readOrder;

  annotations.push(goalAnnot);

  return annotations;
};

export const generateAnnotationsBatch = (
  resource,
  customCurricula,
  allReferences,
  goalReference,
  existing,
  goalHref,
  goalReadorder,
  type
) => {
  let annotations = [];
  const batch = [];

  const annotationAlreadyExists = existing.find(
    (i) => i.curriculum.href === customCurricula.$$meta.permalink && i.target.href === goalHref
  );

  if (annotationAlreadyExists) {
    console.log('this annotation/distribution already exists in the API');
  } else {
    let goalRef = goalReference;
    if (!goalRef || goalRef.type === CUSTOMTYPES.goalList) {
      if (resource.href.startsWith('/llinkid/')) {
        // /for non-derived
        goalRef = null;
      } else {
        goalRef = resource.goalList.$$meta.permalink;
      }
    } else {
      goalRef = allReferences.find(
        (i) =>
          i.curriculum.href === customCurricula.$$meta.permalink &&
          i.target.href === goalRef.$$meta.permalink
      ).$$meta.permalink;
    }

    const result = makeAnnotForGoal(
      goalHref,
      customCurricula.$$meta.permalink,
      goalRef,
      goalReadorder,
      type
    );
    annotations = annotations.concat(result);
  }

  annotations.forEach((obj) => {
    batch.push({ verb: 'PUT', href: obj.$$meta.permalink, body: obj });
  });

  return batch;
};

export const generateDistributeSectionBatch = ({
  section,
  sectionReadorder,
  customCurs,
  selectedProgramsForCur,
  allReferencesList,
  creationType,
}) => {
  const batch = [];

  selectedProgramsForCur.forEach((studyProgHref) => {
    const customCurricula = customCurs.find(
      (elem) => elem.applicability.studyProgrammes[0].href === studyProgHref
    );

    if (!customCurricula) {
      logAndCaptureException(
        `custom curricula not found for distribution Studyprogramme:${studyProgHref}`
      );
    }

    let sectionParentRef = null;
    if (
      creationType === CUSTOMCREATIONTYPES.subSection &&
      section.$$parent &&
      section.$$parent.key
    ) {
      const parentAnnotation = allReferencesList.find(
        (e) =>
          e.curriculum.href === customCurricula.$$meta.permalink &&
          e.target.href === section.$$parent.$$meta.permalink
      );
      sectionParentRef = parentAnnotation.$$meta.permalink || null;
    }

    const result = makeAnnotForGoal(
      section.$$meta.permalink,
      customCurricula.$$meta.permalink,
      sectionParentRef,
      sectionReadorder,
      CUSTOMTYPES.goalReference
    );
    const sectionAnnotationPermalink = result[0].$$meta.permalink;

    if (section.goals) {
      section.goals.forEach((goal) => {
        const annotation = allReferencesList.find(
          (e) =>
            e.curriculum.href === customCurricula.$$meta.permalink &&
            e.target.href === goal.$$meta.permalink
        );
        if (annotation) {
          annotation.reference = { href: sectionAnnotationPermalink };
          result.push(annotation);
        }
      });
    }

    if (section.sections) {
      section.sections.forEach((s) => {
        const annotation = allReferencesList.find(
          (e) =>
            e.curriculum.href === customCurricula.$$meta.permalink &&
            e.target.href === s.$$meta.permalink
        );
        if (annotation) {
          annotation.reference = { href: sectionAnnotationPermalink };
          result.push(annotation);
        }
      });
    }

    result.forEach((obj) => {
      batch.push({ verb: 'PUT', href: obj.$$meta.permalink, body: obj });
    });
  });

  console.log(batch);
  return batch;
};

export const generateRemovedDistributeSectionBatch = ({
  section,
  selectedProgramsForCur,
  customCurs,
  allReferencesList,
  parent,
}) => {
  const batch = [];

  selectedProgramsForCur.forEach((studyProgHref) => {
    const customCurricula = customCurs.find(
      (elem) => elem.applicability.studyProgrammes[0].href === studyProgHref
    );

    if (!customCurricula) {
      logAndCaptureException(
        `custom curricula not found for distribution Studyprogramme:${studyProgHref}`
      );
    }

    const sectionAnnotation = allReferencesList.find(
      (e) =>
        e.curriculum.href === customCurricula.$$meta.permalink &&
        e.target.href === section.$$meta.permalink
    );
    const sectionAnnotationPermalink = sectionAnnotation.$$meta.permalink;
    batch.push({ verb: 'DELETE', href: sectionAnnotationPermalink });

    const newParent = getNewParent(section, parent);
    let above = newParent.goals && last(newParent.goals);

    if (section.goals) {
      const readOrders = calculateReadOrderSet(above, undefined, section.goals.length);
      section.goals = section.goals.map((e, index) => {
        return { ...e, readOrder: readOrders[index] };
      });

      section.goals.forEach((goal) => {
        const annotation = allReferencesList.find(
          (e) =>
            e.curriculum.href === customCurricula.$$meta.permalink &&
            e.target.href === goal.$$meta.permalink
        );
        if (annotation) {
          annotation.reference = !newParent.key ? null : { href: newParent.$$meta.permalink };
          annotation.readOrder = goal.readOrder;
          batch.push({ verb: 'PUT', href: annotation.$$meta.permalink, body: annotation });
        }
      });
    }
    if (section.sections) {
      above = newParent.sections && last(newParent.sections);
      const readOrders = calculateReadOrderSet(above, undefined, section.sections.length);

      section.sections = section.sections.map((e, index) => {
        return { ...e, readOrder: readOrders[index] };
      });

      section.sections.forEach((goal) => {
        const annotation = allReferencesList.find(
          (e) =>
            e.curriculum.href === customCurricula.$$meta.permalink &&
            e.target.href === goal.$$meta.permalink
        );
        if (annotation) {
          annotation.reference = !newParent.key ? null : { href: newParent.$$meta.permalink };
          annotation.readOrder = section.readOrder;
          batch.push({ verb: 'PUT', href: annotation.$$meta.permalink, body: annotation });
        }
      });
    }
  });

  console.log(batch);
  return batch;
};
