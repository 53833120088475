import { createSlice } from '@reduxjs/toolkit';
import { CalendarListState } from './calendarListTypes';

const initialState: CalendarListState = {
  selectionMode: false,
  error: false,
  filters: {
    name: '',
    deletedCalendars: false,
  },
  copyCalendar: {
    status: 'CLOSED',
    item: null, // the item clicked in the list
    originalCalendar: null, // the item to keep when switching schools
  },
};

const calendarListState = createSlice({
  name: 'calendarList',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { property, value } = action.payload;
      state.filters[property] = value;
    },
    removeAllFilters: (state) => {
      state.filters = initialState.filters;
    },
    setSelectionMode: (state, action) => {
      state.selectionMode = !!action.payload;
    },
    editCalendar: () => {},
    createCalendar: () => {},
    deleteCalendar: () => {},
    undeleteCalendar: () => {},
    copyCalendarOpen: (state, action) => {
      state.copyCalendar.item = action.payload.item;
      state.copyCalendar.status = 'OPEN';
    },
    copyCalendarSetOriginalCalendar: (state, action) => {
      state.copyCalendar.originalCalendar = action.payload.originalCalendar;
    },
    copyCalendar: (state, _action) => {
      state.copyCalendar.status = 'SAVING';
    },
    copyCalendarClose: (state) => {
      state.copyCalendar.item = null;
      state.copyCalendar.originalCalendar = null;
      state.copyCalendar.status = 'CLOSED';
    },
  },
});

export const calendarListActions = calendarListState.actions;
export const {
  setFilter,
  removeAllFilters,
  setSelectionMode,
  editCalendar,
  createCalendar,
  deleteCalendar,
  undeleteCalendar,
  copyCalendar,
  copyCalendarClose,
  copyCalendarSetOriginalCalendar,
  copyCalendarOpen,
} = calendarListState.actions;

export default calendarListState;
