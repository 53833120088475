import { Compiler, Modal } from '@kathondvla/react-shared-components/src/components';
import { Button } from '@mui/material';

const RemoveSectionWarningModal = ({ title, description, onClose }) => {
  return (
    <div className="term-modal">
      <Modal.Header title={title} onClose={onClose} />

      <Modal.Body>
        <div className="demarcation-body">
          <Compiler text={description} />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="contained" onClick={onClose}>
          Ok
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default RemoveSectionWarningModal;
