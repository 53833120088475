import { IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import './demoLabel.scss';

const DemoLabel = () => {
  const onClickDemoHelpHandler = () => {
    window.open(
      'https://pro.katholiekonderwijs.vlaanderen/ondersteuning-leerplantool/demo',
      '_blank'
    );
  };

  return (
    <>
      <div className="demo">
        <div className="demo-label">
          <span data-testid="demo" className="demo-label-text">
            <strong>Demo</strong> modus is actief
          </span>
          {/* Uncomment this if we decide to use the text link instead of the button */}
          {/* <Link
                href="https://pro.katholiekonderwijs.vlaanderen/ondersteuning-leerplantool/demo"
                target="_blank"
                className="demo-help-link"
              >
                help?
              </Link> */}
          <Tooltip title="Meer informatie">
            <IconButton onClick={onClickDemoHelpHandler} sx={{ color: 'secondary.contrastText' }}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default DemoLabel;
