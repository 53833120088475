import { Skeleton } from '@mui/material';
import IconCurrCalendar from '@assets/img/icon_curr_calendar.svg?react';

const weekDescription = () => {
  return Array.from({ length: 2 }).map((_, i) => (
    <div key={i} className="description">
      <Skeleton variant="text" animation="wave" sx={{ width: 100, fontSize: '14px' }} />
      <Skeleton variant="text" animation="wave" sx={{ width: '100%', fontSize: '14px' }} />
    </div>
  ));
};

const WeekSkeleton = () => {
  return (
    <>
      {Array.from({ length: 40 }).map((_, index) => (
        <section key={index} className="week">
          {index % 5 === 0 && (
            <Skeleton variant="text" animation="wave" className="month-name" width={100} />
          )}
          <Skeleton
            variant="text"
            animation="wave"
            className="week-date"
            width={100}
            sx={{ color: 'black' }}
          />
          <div className="activity-plan" key={index}>
            <article key={index} className="activity empty">
              <header>
                <IconCurrCalendar width={30} className="icon-curr-calendar" />
                <Skeleton
                  variant="text"
                  animation="wave"
                  className="title"
                  sx={{ width: 70, fontSize: '14px', marginLeft: '5px', flexGrow: 'inherit' }}
                />
              </header>
              {weekDescription()}
            </article>
          </div>
        </section>
      ))}
    </>
  );
};
export default WeekSkeleton;
