import { array } from 'prop-types';

import './calendarPrint.scss';
import CalendarPrintItem from './CalendarPrintItem';

const CalendarPrint = ({ calendars }) => {
  if (!calendars) return null;

  return (
    <>
      {calendars.map((calendar, i) => (
        <CalendarPrintItem key={i} calendar={calendar} />
      ))}
    </>
  );
};

CalendarPrint.propTypes = {
  calendars: array,
};

export default CalendarPrint;
