import useMissingGoals from '@hooks/useMissingGoals';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import { setAlert } from '@store/alerts/alertsState';
import { selectTeachersFromPlan } from '@store/calendar/calendarSelectors';
import { selectUngroupedHomePageListIncludingPreviousYearList } from '@store/leerplanList/leerplanListSelectors';
import {
  selectActiveClassesInSchoolyear,
  selectAllClassesInSchoolyear,
  selectCurrentSchool,
  selectCurrentSchoolyear,
  selectIsUserSchoolValid,
} from '@store/userAndSchool/userAndSchoolSelectors';
import {
  FormGroup,
  FormResourcePicker,
  FormTextField,
  MissingGoals,
  TeachersSelect,
} from '@UI/index';
import { getClassesOptions, getCurriculaForPlan } from '@utils/utils';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import iconAddWhite from '@assets/img/add_white.svg';
import iconEditWhite from '@assets/img/edit-white.svg';

import { Button } from '@mui/material';
import { requiredValidation } from '@utils/validations';
import CurriculumSelect from './curriculumSelect/CurriculumSelect';
import './modals.scss';

const CreateOrEditCalendarModal = ({ school, plan, onConfirm, onClose }) => {
  const dispatch = useDispatch();
  const currentSchoolyear = useSelector(selectCurrentSchoolyear);
  const currentSchool = useSelector(selectCurrentSchool);
  const leerplanListWithUngrouped = useSelector(
    selectUngroupedHomePageListIncludingPreviousYearList
  );
  const { hasValidSchools, schoolsAlert } = useSelector(selectIsUserSchoolValid);
  const classes = useSelector(selectAllClassesInSchoolyear);
  const activeClasses = useSelector(selectActiveClassesInSchoolyear);
  const firstPlanItem = plan?.plans?.[0];
  const isEditMode = !!plan;

  const curriculaInitialValues = useMemo(
    () => getCurriculaForPlan(leerplanListWithUngrouped, firstPlanItem),
    [leerplanListWithUngrouped, firstPlanItem]
  );
  const classesInitialValues = classes.filter((c) =>
    plan?.plans.map((p) => p.$$class?.key).includes(c.key)
  );
  const { teachers: teachersInitialValues, observers: observersInitialValues } = useSelector(
    (state) => selectTeachersFromPlan(state, { plan })
  );

  const { control, handleSubmit, watch, setValue } = useForm({ mode: 'onChange' });
  const [curriculumSelect, classesSelect] = watch(['curriculum', 'classes']);

  const classesOptions = getClassesOptions(activeClasses, classesSelect ?? []);

  const [missingGoals, fetchMissingGoals, missingGoalsRef] = useMissingGoals(
    plan?.plans,
    curriculaInitialValues,
    currentSchoolyear?.value,
    currentSchool?.href
  );

  useEffect(() => {
    if (schoolsAlert) {
      dispatch(setAlert(schoolsAlert));
    }
  }, [schoolsAlert, dispatch]);

  const onCurriculumChange = (values) => fetchMissingGoals(values);

  if (!hasValidSchools) return null;

  return (
    <>
      <Modal.Header
        title={isEditMode ? 'Vorderingsplan(nen) bewerken' : 'Nieuw(e) vorderingsplan(nen) maken'}
        icon={isEditMode ? iconEditWhite : iconAddWhite}
        onClose={onClose}
      />

      <Modal.Body>
        <FormProvider control={control} setValue={setValue}>
          <FormGroup label="School">
            <span className="modal-school-name">{school?.$$displayName}</span>
          </FormGroup>

          <FormGroup label="Naam" subtitle="Naam vorderingsplan">
            <FormTextField
              name="title"
              value={firstPlanItem?.title ?? ''}
              placeholder="naam vorderingsplan"
              control={control}
            />
          </FormGroup>

          <FormGroup
            label="Leerplan"
            subtitle="Welk school-, groeps- of persoonlijk leerplan dient als basis?"
            required
          >
            <CurriculumSelect
              name="curriculum"
              defaultValue={isEditMode ? curriculaInitialValues : null}
              control={control}
              onChange={onCurriculumChange}
              required
            />
          </FormGroup>

          <FormGroup label="Klas(sen)" subtitle="Voor welke klassen?" required>
            <FormResourcePicker
              name="classes"
              placeholder="code"
              value={isEditMode ? classesInitialValues : null}
              localData={classesOptions}
              display={(elm) => elm?.$$displayName}
              multiple
              disabled={isEditMode}
              control={control}
              validations={{ required: (value) => requiredValidation(value) }}
            />
          </FormGroup>

          <FormGroup label="Leerkrachten" subtitle="Wie kan het bewerken?" required>
            <TeachersSelect
              name="teachers"
              defaultValue={teachersInitialValues}
              control={control}
              required
              disabled={!(curriculumSelect?.length > 0)}
            />
          </FormGroup>

          <FormGroup label="Delen" subtitle="Wie mag je vorderingsplan bekijken?">
            <TeachersSelect
              name="shared"
              defaultValue={isEditMode ? observersInitialValues : null}
              control={control}
              disabled={!(curriculumSelect?.length > 0)}
            />
          </FormGroup>

          {(missingGoals.missingGoals.length > 0 || missingGoals.loading) && (
            <section ref={missingGoalsRef} className="missing-goals-section">
              <MissingGoals
                goals={missingGoals.missingGoals}
                total={missingGoals.usedGoals.length}
                loading={missingGoals.loading}
              />
            </section>
          )}
        </FormProvider>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outlined" onClick={onClose}>
          Annuleren
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit((formValues) => {
            onConfirm({
              ...formValues,
              missingGoals,
            });
          })}
        >
          {isEditMode ? 'Opslaan' : 'Aanmaken'}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default CreateOrEditCalendarModal;
