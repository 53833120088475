import PopupModal from '@components/root/PopupModal';
import AlertList from '@UI/alertList/AlertList';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './assets/css/reset.css';
import './root.scss';

import { useSelector } from 'react-redux';
import { RootState } from '@store/storeSetup';
import { selectIsUserAndSchoolStateInitialized } from '@store/userAndSchool/userAndSchoolSelectors';
import { useEffect } from 'react';
import BigLoader from '@components/BigLoader';
import MyCookiesModal from '@components/layout/MyCookiesModal';

function Root() {
  const isBasicDataLoaded = useSelector(
    (state: RootState) =>
      selectIsUserAndSchoolStateInitialized(state) &&
      !state.studyProgrammeApiData.isLoading &&
      state.studyProgrammeApiData.allPrograms.length
  );

  const navigate = useNavigate();
  const location = useLocation();
  const shouldSelectSchool = useSelector(
    (state: RootState) =>
      !state.userAndSchools.currentSchoolHref &&
      Object.keys(state.userAndSchools.schoolsData).length > 1
  );

  useEffect(() => {
    if (isBasicDataLoaded && shouldSelectSchool && !location.pathname.startsWith('/schools')) {
      const currentPath = location.pathname;
      const pathsToCheck = [
        '/vorderingsplannen/miniselector',
        '/vorderingsplannen/selector',
        '/minijaarvorderingslijst',
      ];

      if (pathsToCheck.includes(currentPath)) {
        navigate('/schools/mini');
      } else {
        navigate('/schools');
      }
    }
  }, [isBasicDataLoaded, shouldSelectSchool, navigate, location.pathname]);

  return (
    <>
      {isBasicDataLoaded && (
        <div className="app-background">
          <PopupModal />
          <Outlet />
          <AlertList />
          <MyCookiesModal />
        </div>
      )}

      {!isBasicDataLoaded && <BigLoader />}
    </>
  );
}

export default Root;
