import HoveredPopover from '@UI/popovers/HoveredPopover';
import './optionItems.scss';

const CurriculaOptionItem = ({ option }) => (
  <div className={`curricula-option-item option-item ${option.latest ? 'latest' : ''}`}>
    <figure className={`result-icon ${option?.id === 'nonderived' ? 'nonderived' : ''}`}>
      <img src={option.src} />
    </figure>
    <div className="curricula-name">
      {option.name}
      {option?.versionNumber && <span className="small">({option.versionNumber})</span>}
      {option.valid === false && (
        <HoveredPopover
          className="curricula-disappear-popover"
          content="Dit leerplan is niet geldig is in de huidige context."
        >
          ⚠️
        </HoveredPopover>
      )}
    </div>
  </div>
);

export default CurriculaOptionItem;
