export const sleep = async (milliseconds) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};

export const getMaxModifiedSince = (array) => {
  const modifiedList = array.map((e) => e.$$meta.modified);
  const max = modifiedList.reduce(
    (maxValue, currentValue) => (maxValue >= currentValue ? maxValue : currentValue),
    modifiedList[0]
  );

  const maxPlusOne = new Date(new Date(max).getTime() + 1).toISOString();
  return maxPlusOne;
};
