import { createSelector } from '@reduxjs/toolkit';

import {
  selectCurriculumGrade,
  selectCurriculumStudyProgrammeHrefs,
  selectCustomCurriculaStudyProgrammeHrefs,
} from '@store/curriculum/curriculumSelectors';
import { hasAny } from '@store/studyProgrammesApi/studyProgrammeHelpers';
import {
  selectStudyProgrammeGroupsPerGradeForSchool,
  selectStudyProgrammesPerGradeForSchool,
} from '@store/studyProgrammesApi/studyProgrammeSelectors';

export const selectStudyProgrammeAndGroupsForCurriculum = createSelector(
  [
    selectStudyProgrammesPerGradeForSchool,
    selectStudyProgrammeGroupsPerGradeForSchool,
    selectCurriculumStudyProgrammeHrefs,
    (state) => selectCustomCurriculaStudyProgrammeHrefs(state),
    (state) => selectCurriculumGrade(state),
  ],
  (studyProgrammes, groups, curriculumStudyProgrammes, customCurriculaStudyProgrammes, grade) => {
    if (!grade) {
      return {
        studyProgrammes: [],
        groups: [],
      };
    }

    let curriculumStudyProgrammesHrefs = curriculumStudyProgrammes.map((sp) => sp.href);
    if (customCurriculaStudyProgrammes?.length > 0) {
      curriculumStudyProgrammesHrefs = curriculumStudyProgrammesHrefs.filter((z) =>
        customCurriculaStudyProgrammes.includes(z)
      );
    }
    const filteredStudyProgrammes = studyProgrammes[grade].filter((sp) =>
      curriculumStudyProgrammesHrefs.includes(sp.value)
    );

    const studyProgrammesValues = filteredStudyProgrammes.map((s) => s.value);
    const filteredGroups = groups[grade]
      .map((group) => {
        return {
          ...group,
          studyProgrammes: group.studyProgrammes.filter((sp) =>
            curriculumStudyProgrammesHrefs.includes(sp)
          ),
        };
      })
      .filter(
        (group) =>
          group.studyProgrammes.length > 0 && hasAny(group.studyProgrammes, studyProgrammesValues)
      );

    return {
      studyProgrammes: filteredStudyProgrammes,
      groups: filteredGroups,
    };
  }
);
