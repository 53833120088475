import { createSelector } from '@reduxjs/toolkit';
import {
  selectCurrentSchoolHref,
  selectPrincipalSchoolHrefs,
  selectPrincipalTeamHrefsForSchoolyear,
  selectPrincipalTeamsForSchoolyear,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { isEqual } from 'lodash-es';

export const selectLevelOptions = createSelector(
  [selectCurrentSchoolHref, selectPrincipalTeamHrefsForSchoolyear, selectPrincipalSchoolHrefs],
  (currentSchoolHref, principalTeamHrefs, principalSchoolHrefs) => {
    const options = [];
    if (principalSchoolHrefs.includes(currentSchoolHref)) {
      options.push({ name: 'School', value: 'school' });
    }
    if (principalTeamHrefs.length > 0) {
      options.push({ name: 'Groep', value: 'group' });
    }
    return options;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectGroupOptions = createSelector(
  [selectPrincipalTeamsForSchoolyear],
  (principalTeams) => {
    return principalTeams.map((team) => {
      return { name: team.$$displayName, value: team.href };
    });
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);
