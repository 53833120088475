import { array, object, string } from 'prop-types';

import './leerplanHeader.scss';
import LeerplanHeaderSection from '@components/leerplan/section/header/headerSection/LeerplanHeaderSection';
import { useSelector } from 'react-redux';
import { selectCurriculumHeaderInfo } from './leerplanHeaderSelectors';
import LeerplanHeaderSkeleton from './headerSkeleton/LeerplanHeaderSkeleton';

const LeerplanHeader = ({ curriculumParams }) => {
  const info = useSelector((state) => selectCurriculumHeaderInfo(state, curriculumParams));

  return info ? (
    <div
      style={{
        background: `white url(${info.backgroundImage}) no-repeat`,
        backgroundSize: 'cover',
      }}
      className="leerplan-header"
    >
      <LeerplanHeaderSection
        className="leerplan-header__general-section"
        title={info.curriculumTitle}
        version={info.version}
        subtitle={info.subtitle}
        curriculumType={info.curriculumType}
        educationalPointers={info.educationalPointers}
        principles={info.generalLevel?.principles}
        educationalComponents={info.educationalComponents}
        validityPeriod={info.validityPeriod}
        studyProgram={info.studyProgram}
        isNonderived={info.isNonderived}
      />
      {info.schoolLevel?.principles && (
        <LeerplanHeaderSection
          className="leerplan-header__school-section"
          educationalPointers={info.schoolLevel?.educationalPointers}
          principles={info.schoolLevel?.principles}
        />
      )}
    </div>
  ) : (
    <LeerplanHeaderSkeleton />
  );
};

LeerplanHeader.propTypes = {
  backgroundImage: string,
  curriculumTitle: string,
  version: string,
  educationalPointers: array,
  generalLevel: object,
  schoolLevel: object,
  educationalComponents: array,
  curriculumType: string,
  subtitle: string,
  validityPeriod: string,
  studyProgram: string,
  curriculumParams: object,
};

export default LeerplanHeader;
