import { FormControlLabel } from '@mui/material';
import { selectHideOptionalElements } from '@store/curriculum/curriculumSelectors';
import { useDispatch, useSelector } from 'react-redux';

import { setHideOptionalElementsPreferences } from '@store/userAndSchool/usersAndSchoolState';
import CustomSwitch from '@UI/switch';

const HideOptionalElementsSwitch = () => {
  const dispatch = useDispatch();

  const hideOptionalElements = useSelector(selectHideOptionalElements);

  const handleHideOptionalElements = (event) => {
    const params = new URLSearchParams(window.location.search);
    const key = params.get('setid') || params.get('custid') || params.get('id');
    dispatch(setHideOptionalElementsPreferences({ key, hide: event.target.checked }));
  };

  return (
    <>
      <FormControlLabel
        sx={{ mr: 0, color: 'primary.main' }}
        label="Toon alleen geselecteerde elementen"
        control={
          <CustomSwitch
            data-testid="hide-optional-elements-switch"
            sx={{ ml: 1 }}
            checked={hideOptionalElements}
            onChange={handleHideOptionalElements}
            color="primary"
            size="small"
          />
        }
        labelPlacement="start"
      />
    </>
  );
};

export default HideOptionalElementsSwitch;
