import SchoolYearSelector from '@components/layout/header/schoolYearSelector/schoolYearSelector';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button } from '@mui/material';
import { createCalendar } from '@store/calendarList/calendarListState';
import {
  selectCurrentSchool,
  selectCurrentSchoolyear,
  selectIsSchoolyearReadOnly,
} from '@store/userAndSchool/userAndSchoolSelectors';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import apiFormatters from '@utils/apiFormatters';
import { printAddress } from '@utils/utils';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLlinkidPostMessage, useNavigatePostMessage } from '@hooks/usePostMessage';
import { useNavigate } from 'react-router-dom';
import { POST_MESSAGE_ACTION, POST_MESSAGE_EVENT } from '../../constants/postMessageEvents';

import './calendarHome.scss';
import CalendarList from './calendarList/CalendarList';
import CalendarListFilter from './calendarListFilter/CalendarListFilter';
import CreateOrEditCalendarModal from './modals/CreateOrEditCalendarModal';

const CalendarHome = () => {
  const createCalendarModalRef = useRef();
  const selectionMode = useSelector((state) => state.calendarList.selectionMode);
  const schoolyear = useSelector(selectCurrentSchoolyear);
  const readOnly = useSelector(selectIsSchoolyearReadOnly);
  const [cancelLabel, setCancelLabel] = useState('Annuleren');
  const [sendMessage] = useLlinkidPostMessage({
    [POST_MESSAGE_ACTION.SELECT_FROM_YEAR_PLANS]: (data) => {
      if (data.cancelButton) setCancelLabel(data.cancelButton);
    },
  });
  useNavigatePostMessage();
  const currentSchool = useSelector(selectCurrentSchool);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toSelector = () => {
    navigate('/schools/mini');
  };
  const createCalendarHandler = (formValues) => {
    const formattedFormValues = apiFormatters.formatCalendarCreationAPI({
      ...formValues,
      schoolyear,
      school: currentSchool,
    });
    return dispatch(createCalendar(formattedFormValues));
  };

  const openCreateCalendarModal = () =>
    createCalendarModalRef.current.open({
      school: currentSchool,
      onConfirm: createCalendarHandler,
    });

  return (
    <div className="calendar-home">
      {!selectionMode && (
        <div className="calendar-home-header">
          <div className="calendar-title"> Mijn vorderingsplannen </div>
          <div className="calendar-actions">
            <Button
              variant="contained"
              startIcon={<AddBoxIcon />}
              disabled={readOnly}
              disableElevation
              onClick={openCreateCalendarModal}
            >
              Toevoegen
            </Button>
          </div>
        </div>
      )}
      {selectionMode && (
        <div className="school-button-wrapper">
          {currentSchool && (
            <span className="school-link">
              <span onClick={toSelector}>{currentSchool.$$displayName}</span>
              {currentSchool.$$addresses?.map((address) => (
                <span key={address.key} onClick={toSelector}>{`; ${printAddress(
                  address.address
                )}`}</span>
              ))}
            </span>
          )}
          <div className="school-year-selector">
            <SchoolYearSelector />
          </div>
        </div>
      )}
      <CalendarListFilter />
      <CalendarList />
      {selectionMode && (
        <div className="controls">
          <Button
            variant="outlined"
            onClick={() => {
              sendMessage({ event: POST_MESSAGE_EVENT.SELECTION_CANCELLED });
            }}
          >
            {cancelLabel}
          </Button>
        </div>
      )}

      <Modal
        ref={createCalendarModalRef}
        component={<CreateOrEditCalendarModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </div>
  );
};

export default CalendarHome;
