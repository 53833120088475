import { bool } from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePostMessage, { useNavigatePostMessage } from '@hooks/usePostMessage';
import { selectSchoolVMs, selectUser } from '@store/userAndSchool/userAndSchoolSelectors';
import { setOrgContext, setSchoolContext } from '@store/userAndSchool/usersAndSchoolState';
import { useNavigate } from 'react-router-dom';
import { POST_MESSAGE_EVENT } from '../../constants/postMessageEvents';
import SchoolsIframeView from './SchoolsIframeView';
import SchoolsModalView from './SchoolsModalView';

import './schoolsHome.scss';

const SchoolsHome = ({ selectionMini }) => {
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState();
  const user = useSelector(selectUser);
  const schools = useSelector(selectSchoolVMs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useNavigatePostMessage();

  const [sendMessage] = usePostMessage();

  useEffect(() => {
    if (!user || !schools.length) {
      navigate('/home/leerplan');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectHandler = (id) => {
    setLoading(true);
    setSelected(id);

    const currSchool = schools.find((e) => e.key === id);

    dispatch(setOrgContext(currSchool.href));
    dispatch(setSchoolContext(currSchool.href));

    if (selectionMini) {
      sendMessage({
        event: POST_MESSAGE_EVENT.SELECTION_DONE,
        selection: currSchool.href,
      });
    }

    const currentUrl = window.location.href;
    window.history.back();
    setTimeout(() => {
      // if location was not changed, then there is no history back or the back left you stranded in /schools
      if (currentUrl === window.location.href) {
        // redirect to site root
        navigate('/home/leerplan');
      }
    }, 10);
  };

  if (selectionMini) {
    return (
      <div className="schools-home">
        <SchoolsIframeView
          schools={schools}
          selected={selected}
          loading={loading}
          onSelect={onSelectHandler}
        />
      </div>
    );
  }

  return (
    <div className="schools-home fullscreen">
      {user && (
        <SchoolsModalView
          schools={schools}
          user={user}
          selected={selected}
          loading={loading}
          onSelect={onSelectHandler}
        />
      )}
    </div>
  );
};

SchoolsHome.propTypes = {
  schoolRedirect: bool,
  selectionMini: bool,
};

export default SchoolsHome;
