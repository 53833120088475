import { Modal } from '@kathondvla/react-shared-components/src/components';

import './confirmModal.scss';
import { Button } from '@mui/material';

const ConfirmModal = ({
  title,
  message,
  children,
  cancelLabel = 'Annuleren',
  confirmLabel = 'Accept',
  onConfirm,
  onClose,
}) => {
  return (
    <>
      <Modal.Header title={title} onClose={onClose} />
      <Modal.Body>
        {message && !children && <div className="confirm-message text">{message}</div>}
        {children && <div className="confirm-message">{children}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={onClose}>
          {cancelLabel}
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ConfirmModal;
