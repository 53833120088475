import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeerplanListState } from './leerplanListTypes';

const initialState: LeerplanListState = {
  selectionMode: false,
  filters: {
    name: '',
    grades: null,
    studyProgrammes: null,
    streams: null,
    versions: null,
    relevantCurricula: true,
  },
  expandedItems: [],
};

const leerplanListState = createSlice({
  name: 'leerplanList',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<{ property: string; value: string }>) => {
      const { property, value } = action.payload;
      state.filters[property] = value;
    },
    removeAllFilters: (state) => {
      state.filters = initialState.filters;
    },
    toggleListItemExpand: (state, action: PayloadAction<{ key: string }>) => {
      const item = action.payload;
      const itemPosition = state.expandedItems.findIndex((e) => e === item.key);

      if (itemPosition === -1) state.expandedItems.push(item.key);
      else state.expandedItems.splice(itemPosition, 1);
    },
    setSelectionMode: (state, action: PayloadAction<boolean>) => {
      state.selectionMode = !!action.payload;
    },
    deleteCurricula: () => {},
    editNonderived: () => {},
    createCurricula: () => {},
    createNonderivedCurricula: () => {},
  },
});

export const leerplanListActions = leerplanListState.actions;
export const {
  setFilter,
  removeAllFilters,
  toggleListItemExpand,
  setSelectionMode,
  deleteCurricula,
  editNonderived,
  createCurricula,
  createNonderivedCurricula,
} = leerplanListState.actions;
export default leerplanListState;
