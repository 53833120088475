import './resourcePickerOption.scss';

const TestUserOption = ({ option }) => {
  return (
    <div className="display-test-user">
      <span data-testid="display-test-user">{`${option.firstName} ${option.lastName}`}</span>
    </div>
  );
};

export default TestUserOption;
