import schoolIcon from '@assets/img/icon_school_so_grey.svg';
import './schoolsHome.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const addressUtils = require('@kathondvla/sri-client/address-utils');

const SchoolsIframeView = ({ schools, selected, loading = false, onSelect }) => {
  const printAddress = (address) => {
    if (!address) return '';
    return addressUtils.printAddress(address);
  };

  return (
    <div className="schools-iframe-view">
      <main>
        {schools.map((school) => (
          <div
            key={school.key}
            className={`school-item ${loading ? 'disabled' : ''} ${
              school.key === selected ? 'selected' : ''
            }`}
            onClick={() => onSelect?.(school.key)}
          >
            <div className="school-icon">
              {(!loading || school.key !== selected) && <img src={schoolIcon} />}
              {loading && school.key === selected && <div className="spinner-loader"></div>}
            </div>
            <div>{school.$$displayName}</div>
            {school?.$$addresses.map((address) => (
              <div key={address.key} className="address">
                {printAddress(address?.address)}
              </div>
            ))}
          </div>
        ))}
      </main>
    </div>
  );
};

export default SchoolsIframeView;
