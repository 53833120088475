import expandCloseImg from '@assets/img/expand_close.png';
import { Compiler } from '@kathondvla/react-shared-components/src/components';
import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import './concordance.scss';

const Concordance = ({ concordance }) => {
  const [collapsed, setCollapsed] = useState(true);

  const collapseSection = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div data-testid="concordance" className="concordance">
      <div className="concordance-title-container">
        <button
          className={`collapse-icon ${collapsed ? 'collapsed' : ''}`}
          onClick={collapseSection}
          data-testid="collapse-button"
        >
          <img src={expandCloseImg} />
        </button>
        <h4 className="concordance-title" data-testid="concordance-title">
          {concordance.title}
        </h4>
      </div>
      <Collapse className="concordance-description" in={!collapsed} timeout={500} unmountOnExit>
        <Compiler text={concordance.description} />
      </Collapse>
    </div>
  );
};

export default Concordance;
