import settings from '@config/settings';

export const apiEndpoints = {
  customCurricula: '/llinkid/customcurriculum/customcurricula/',
  annotations: '/llinkid/customcurriculum/customcurricula/annotations/',
  customCurriculaGroups: '/llinkid/customcurriculum/customcurriculagroups/',
  customItems: '/llinkid/customcurriculum/customitems/',
  activityPlans: '/llinkid/activityplanning/activityplans/',
  activities: '/llinkid/activityplanning/activityplans/activities/',
};

export const apiConstants = {
  customCurricula: {
    timeout: settings.cacheTimeout.customcurAndAnnot,
    endpoint: apiEndpoints.customCurricula,
    tableName: 'customcurricula',
  },
  annotations: {
    timeout: settings.cacheTimeout.customcurAndAnnot,
    endpoint: apiEndpoints.annotations,
    tableName: 'annotations',
  },
  customItems: {
    timeout: settings.cacheTimeout.customcurAndAnnot,
    endpoint: apiEndpoints.customItems,
    tableName: 'customitems',
  },
  customCurriculaGroups: {
    timeout: settings.cacheTimeout.customcurAndAnnot,
    endpoint: apiEndpoints.customCurriculaGroups,
    tableName: 'customcurriculagroups',
  },
  activityPlans: {
    timeout: settings.cacheTimeout.customcurAndAnnot,
    endpoint: apiEndpoints.activityPlans,
    tableName: 'activityplans',
  },
  activities: {
    timeout: settings.cacheTimeout.customcurAndAnnot,
    endpoint: apiEndpoints.activities,
    tableName: 'activities',
  },
};

export const studyProgrammeConstants = {
  firstGradeHref: '/sam/commons/studyprogrammegroups/b1a7f179-6e7c-497b-be9a-7ca30e4e8c65',
  secondGradeHref: '/sam/commons/studyprogrammegroups/2ea33583-8819-4137-bb4e-c3efd9e91346',
  thirdGradeHref: '/sam/commons/studyprogrammegroups/954369e2-99c1-4ef4-be19-f5496956a270',
  firstGradeStreamGroup:
    '/sam/commons/studyprogrammegroups/types/8807fba6-4cd5-43fd-ad20-b4237c29c02e',
  secondAndThirdGradeFinalityGroup:
    '/sam/commons/studyprogrammegroups/types/064df602-d7f6-40c1-be84-36cb935a309f',
  gradeGroup: '/sam/commons/studyprogrammegroups/types/16212f35-a563-48a0-a453-76bf06f0b6ee',
};

export const streamsOrderMap = new Map([
  ['Doorstroom', 1],
  ['Dubbele finaliteit', 2],
  ['Arbeidsmarkt', 3],
  ['A-stroom', 4],
  ['B-stroom', 5],
]);
