import { Compiler, Modal } from '@kathondvla/react-shared-components/src/components';
import './alertModal.scss';

import { func, string } from 'prop-types';
import { Button } from '@mui/material';

const AlertModal = ({ title, titleIcon = '', text, onClose }) => {
  return (
    <>
      <Modal.Header title={title} icon={titleIcon || null} onClose={onClose} />
      <Modal.Body>
        <Compiler text={text} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" onClick={onClose}>
          Ok
        </Button>
      </Modal.Footer>
    </>
  );
};

AlertModal.propTypes = {
  title: string,
  titleIcon: string,
  text: string,
  onClose: func,
};

export default AlertModal;
