import { sortBy } from 'lodash-es';
import { getVskoReqHeader } from '@utils/utils';
import * as grades from '../../constants/grades';
import { cachedApi } from '../apihelpers';
import { studyProgrammeConstants } from '../../constants/apiConstants';
import { getGradeOfStudyProgram } from './studyProgrammeHelpers';

const TYPES = {
  packet: '/sam/commons/studyprogrammes/types/e1d35687-d2e0-4917-8afc-fca13886eb07',
};

async function getAllGrades() {
  return cachedApi.getAll(
    '/sam/commons/studyprogrammegroups',
    { type: studyProgrammeConstants.gradeGroup },
    getVskoReqHeader()
  );
}

function isPacket(studyProgram) {
  return studyProgram.type.href === TYPES.packet;
}

function filterOutPackets(list) {
  return list.filter((item) => !isPacket(item));
}

async function requestProgramsAndFilterOutPackets() {
  const programs = await cachedApi.getAll(
    '/sam/commons/studyprogrammes',
    undefined,
    getVskoReqHeader()
  );
  const filtered = filterOutPackets(programs);
  return filtered;
}

async function getAllPrograms() {
  // return requestProgramsAndFilterOutPackets();
  const allPrograms = await sortBy(await requestProgramsAndFilterOutPackets(), 'title');
  allPrograms.forEach((program) => {
    const grade = getGradeOfStudyProgram(program);
    program.$$grade = grade;
  });

  return allPrograms;
}

async function getFirstGradeStreams() {
  const firstGradeStreams = await cachedApi.getAll(
    '/sam/commons/studyprogrammegroups',
    { type: studyProgrammeConstants.firstGradeStreamGroup },
    getVskoReqHeader()
  );
  return firstGradeStreams.map((s) => ({ ...s, $$grade: grades.GRADE_1 }));
}

async function getSecondGradeFinalities() {
  const secondGradeFinalities = await cachedApi.getAll(
    '/sam/commons/studyprogrammegroups',
    { type: studyProgrammeConstants.secondAndThirdGradeFinalityGroup },
    getVskoReqHeader()
  );
  return secondGradeFinalities.map((s) => ({ ...s, $$grade: grades.GRADE_2 }));
}

async function getEdActivityTypes() {
  return cachedApi.getAll('/sam/commons/educationalactivitytypes/');
}

export const loadStudyProgrammes = async () => {
  const parr = [];
  parr.push(getAllGrades());
  parr.push(getAllPrograms());
  parr.push(getFirstGradeStreams());
  parr.push(getSecondGradeFinalities());
  parr.push(getEdActivityTypes());
  return Promise.all(parr);
};
