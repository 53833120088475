export const identifierUniqueValidation = (id, existentIdSet) => {
  const isUnique = !existentIdSet.has(id);
  return isUnique || 'De code is reeds in gebruik';
};

export const identifierUniqueOrUnchangedValidation = (origValue, existentIdSet) => {
  return (value) => {
    if (value === origValue) return true;
    return identifierUniqueValidation(value, existentIdSet);
  };
};

export const requiredValidation = (value) => {
  const isNonEmptyArray = Array.isArray(value) && value.length > 0;
  const isNonEmptyObject =
    typeof value === 'object' && value !== null && Object.keys(value).length > 0;
  const isNotEmptyString = typeof value === 'string' && value.trim() !== '';
  const isValidNumber = typeof value === 'number' && !Number.isNaN(value);

  return (
    isNotEmptyString || isNonEmptyArray || isNonEmptyObject || isValidNumber || `Verplicht veld`
  );
};

export const maxLengthValidation = (max) => ({
  value: max,
  message: `Maximum ${max} karakters`,
});

export const isExpiredValidation = (value, schoolYears) => {
  const schoolyear = schoolYears.find((sy) => sy.initialized && sy.value === value);
  return !schoolyear.expired || 'Dit schooljaar ligt in het verleden.';
};

export const isCurriculumValid = (value) => {
  if (Array.isArray(value)) {
    return value.every((z) => z.valid) || 'Een van de leerplannen is niet geldig in dit schooljaar';
  }
  return true;
};

export const oneClassRequiredValidation = (formClassesValue) => {
  if (!formClassesValue) return 'Selecteer ten minste 1 klas';
  let selectedClasses = [];

  Object.keys(formClassesValue).forEach((key) => {
    if (formClassesValue[key]) {
      selectedClasses = [...selectedClasses, ...formClassesValue[key]];
    }
  });

  return selectedClasses.length > 0 || 'Selecteer ten minste 1 klas';
};
