import { Compiler } from '@kathondvla/react-shared-components/src/components';
import ReadOrderViz from '@UI/readOrderViz/ReadOrderViz';
import NonderivedMenuOptions from '@UI/nonderivedMenuOptions/NonderivedMenuOptions';
import GoalLink from '@UI/goalLink/GoalLink';
import { CONTENTTYPES } from '@utils/curriculumHelper';
import Divider from '@UI/divider/Divider';
import { useDispatch } from 'react-redux';
import { includeItem } from '@store/llinkidApis/llinkidApiState';
import TipIcon from '@assets/img/icon_pedagogical_tip.svg?react';
import InitialSituationIcon from '@assets/img/icon_initial_situation.svg?react';
import GoalExplanationIcon from '@assets/img/icon_goal_explanation.svg?react';
import GoalExtraIcon from '@assets/img/icon_extra_white.svg?react';
import settings from '@config/settings';

import './goalSubItem.scss';

const ICON = {
  [CONTENTTYPES.tip]: {
    title: 'Wenk',
    component: <TipIcon />,
  },
  [CONTENTTYPES.goalExplanation]: {
    title: 'Duiding',
    component: <GoalExplanationIcon />,
  },
  [CONTENTTYPES.goalExtra]: {
    title: 'Extra',
    component: <GoalExtraIcon style={{ transform: 'scale(1)' }} />,
  },
  [CONTENTTYPES.initialSituation]: {
    title: 'Beginsituatie',
    component: <InitialSituationIcon style={{ transform: 'scale(1)' }} />,
  },
};

const GoalSubItem = ({ item, onTermClick, onShowEditGoalModal, onShowNonderivedModal }) => {
  const dispatch = useDispatch();
  const includeGoalSubItem = (goal) => {
    dispatch(includeItem({ item: goal }));
  };
  const compilerOptions = {
    term: {
      compile: true,
      onClick: (key) => {
        const term = item.terms?.find((t) => t.key === key);
        if (term) {
          onTermClick?.({ term });
        }
      },
    },
    markedText: {
      compile: true,
      modes: settings.hardcodings.markExplanation,
      onHover: (key, text, setter) => {
        const explanation = item.markExplanations?.find((mark) => mark.key === key);
        if (explanation) setter(explanation.description);
      },
    },
  };

  if (item.hidden) return null;

  if (
    item.type === CONTENTTYPES.goalExplanation ||
    item.type === CONTENTTYPES.tip ||
    item.type === CONTENTTYPES.goalExtra ||
    item.type === CONTENTTYPES.initialSituation
  ) {
    return (
      <div
        className={`goal-sub-item ${item.excluded ? 'excluded' : ''} ${
          item.partialInclusion ? 'partial' : ''
        }`}
      >
        <div className="left-section">
          <div className="item-icon">{ICON[item.type].component}</div>
          <Divider mandatory={item.mandatory} />
        </div>
        <NonderivedMenuOptions
          goal={item}
          onShowEditGoalModal={onShowEditGoalModal}
          onShowNonderivedModal={onShowNonderivedModal}
          onExclude={includeGoalSubItem}
        >
          <div className="content">
            <span className="title">{ICON[item.type].title}</span>
            <div className="description">
              <Compiler text={item.description} options={compilerOptions} />
            </div>
            {item.type !== CONTENTTYPES.initialSituation && <ReadOrderViz item={item} />}
          </div>
          {item.type === CONTENTTYPES.initialSituation && (
            <div className="right-section">
              <GoalLink links={item.relatedGoals} />
              <ReadOrderViz item={item} />
            </div>
          )}
        </NonderivedMenuOptions>
      </div>
    );
  }

  console.warn('item of unknown item.type', item);
  return null;
};

export default GoalSubItem;
