import { setAlert } from '@store/alerts/alertsState';
import { selectCurrentActivityPlans } from '@store/calendar/calendarSelectors';
import { setCalendarKeys } from '@store/calendar/calendarState';
import { selectCustomCurriculaFromUrlOrParams } from '@store/curriculum/curriculumSelectors';
import { setUrlParams } from '@store/curriculum/curriculumState';
import { selectIsDataLoadedForTypes } from '@store/llinkidApis/llinkidApiSelectors';
import { RootState } from '@store/storeSetup';
import {
  selectCurrentSchoolyear,
  selectIsUserAndSchoolStateInitialized,
  selectIsUserLoaded,
  selectIsUserNotLoggedIn,
  selectSchoolyearsForSchool,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { setCurrentSchoolyear, setSchoolContext } from '@store/userAndSchool/usersAndSchoolState';
import { filterBySchoolyear, filterCustomCurriculaWithFilters } from '@utils/filters';
import { waitForSaga } from '@utils/sagaUtils';
import { RESOURCES } from '@utils/utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { logAndCaptureException } from '@utils/logAndCaptureException';
import { ApiActivityPlan, ApiCustomCurriculum } from '../../types/llinkidApiTypes';
import { Schoolyear } from '../../types/schoolyear';
import {
  getActivityPlansFromParams,
  getCustomCurriculaFromParams,
  getLoginUrl,
} from '../apihelpers';

/**
 * check if we're logged in, if not, redirect to the login page.
 * @returns {boolean} true if we're logged in, false if we're not.
 */
function* checkUserLoggedIn() {
  yield waitForSaga((state: RootState) => selectIsUserLoaded(state));

  const loggedOut: boolean = yield select(selectIsUserNotLoggedIn);

  if (loggedOut) {
    const oauthUrl = getLoginUrl(window.location.href);
    window.location.href = oauthUrl;
    return false;
  }
  return true;
}

function* checkIsSchoolSelected() {
  // some of this logic also happens in the root.tsx, but that file doesn't run when the react-router 'loader' is still busy
  yield waitForSaga(
    (state: RootState) =>
      selectIsUserAndSchoolStateInitialized(state) &&
      !state.studyProgrammeApiData.isLoading &&
      state.studyProgrammeApiData.allPrograms.length
  );

  const shouldSelectSchool = yield select(
    (state: RootState) =>
      !state.userAndSchools.currentSchoolHref &&
      Object.keys(state.userAndSchools.schoolsData).length > 1
  );

  return !shouldSelectSchool;
}

function* setSchoolyearFromCurricula(curricula) {
  const schoolyear: Schoolyear = yield select(selectCurrentSchoolyear);
  if (filterCustomCurriculaWithFilters(curricula, [filterBySchoolyear(schoolyear)]).length) {
    // we're in the right schoolyear.
    return;
  }

  const schoolyearsForSchool: Schoolyear[] = yield select(selectSchoolyearsForSchool);

  for (const schoolyearItem of schoolyearsForSchool.filter((z) => z.initialized).reverse()) {
    // go down the schoolyears till we find one that matches
    if (filterCustomCurriculaWithFilters(curricula, [filterBySchoolyear(schoolyearItem)]).length) {
      // we found the right schoolyear.
      yield put(setCurrentSchoolyear(schoolyearItem.value));
      return;
    }
  }
}

function* checkUrlParamsMatchSchoolAndSchoolyear() {
  const curriculum: RootState['curriculum'] = yield select((state: RootState) => state.curriculum);

  const { curriculumKey, custids, setid } = curriculum;

  if (!custids.length && setid === null) {
    // base curriculum
    return;
  }

  const loggedIn = yield call(checkUserLoggedIn);

  if (!loggedIn) return;

  const isSchoolSelected = yield call(checkIsSchoolSelected);

  if (isSchoolSelected) {
    // if no school is selected, this waitFor will never resolve, so don't wait for it.
    yield call(waitForSaga, (state: RootState) =>
      selectIsDataLoadedForTypes(state, [RESOURCES.CUSTOM_CURRICULA])
    );
  }

  const curricula: ApiCustomCurriculum[] = yield select(selectCustomCurriculaFromUrlOrParams);

  if (curricula.length) {
    // we're in the right school. we could be in the wrong schoolyear.

    yield setSchoolyearFromCurricula(curricula);
  } else {
    // we're in the wrong school or schoolyear, we don't have the data in the store.
    // we need to fetch the data.

    try {
      const [customCurriculum]: [ApiCustomCurriculum] = yield getCustomCurriculaFromParams(
        curriculumKey,
        custids,
        setid
      );
      if (customCurriculum) {
        // we got a customcurriculum from the API, let's set the right params
        const school = customCurriculum.context.href;
        yield put(setSchoolContext(school));
        yield setSchoolyearFromCurricula([customCurriculum]);
      }
    } catch (ex) {
      logAndCaptureException(ex);
      // we have no access to this curriculum, or it doesn't exist. Go to the home
      yield put(
        setAlert({
          key: 'curriculum-not-found',
          type: 'error',
          title: 'Leerplan',
          msg: 'Het eigen leerplan bestaat niet (meer) of kan niet gevonden worden.',
          showClose: true,
          delay: 8000,
        })
      );
      globalThis.router.navigate({
        pathname: '/home/leerplan',
      });
    }
  }
}

function* setSchoolyearFromActivityPlan(activityPlan: ApiActivityPlan) {
  const schoolyearsForSchool: Schoolyear[] = yield select(selectSchoolyearsForSchool);
  for (const schoolyearItem of schoolyearsForSchool.filter((z) => z.initialized).reverse()) {
    // go down the schoolyears till we find one that matches
    if (activityPlan.issued.startDate === schoolyearItem.isoDates.startDate) {
      // we found the right schoolyear.
      yield put(setCurrentSchoolyear(schoolyearItem.value));
      return;
    }
  }
}

function* checkCalendarParamsMatchSchoolAndSchoolyear() {
  const loggedIn = yield call(checkUserLoggedIn);

  if (!loggedIn) return;

  const isSchoolSelected = yield call(checkIsSchoolSelected);

  if (isSchoolSelected) {
    // if no school is selected, this waitFor will never resolve, so don't wait for it.
    yield call(waitForSaga, (state: RootState) =>
      selectIsDataLoadedForTypes(state, [RESOURCES.ACTIVITY_PLANS])
    );
  }

  const schoolyear: Schoolyear = yield select(selectCurrentSchoolyear);
  const activityplans: ApiActivityPlan[] = yield select(selectCurrentActivityPlans);

  if (activityplans.length) {
    // we're in the right school. we could be in the wrong schoolyear.
    const [activityPlan] = activityplans;

    if (activityPlan.issued.startDate !== schoolyear.isoDates.startDate) {
      // we're in the wrong schoolyear.
      yield setSchoolyearFromActivityPlan(activityPlan);
    }
  } else {
    // we're in the wrong school or schoolyear, we don't have the data in the store.
    // we need to fetch the data.
    const ids: string[] = yield select((state: RootState) => state.calendar.calendarKeys);

    try {
      const [activityPlan]: [ApiActivityPlan] = yield getActivityPlansFromParams(ids);
      if (activityPlan) {
        // we got a customcurriculum from the API, let's set the right params
        const school = activityPlan.context?.href;
        if (school) yield put(setSchoolContext(school));

        yield setSchoolyearFromActivityPlan(activityPlan);
      }
    } catch (ex) {
      logAndCaptureException(ex);
      // we have no access to this curriculum, or it doesn't exist. Go to the home
      yield put(
        setAlert({
          key: 'activityplan-not-found',
          type: 'error',
          title: 'Vorderingsplan',
          msg: 'Het vorderingsplan bestaat niet (meer) of kan niet gevonden worden.',
          showClose: true,
          delay: 8000,
        })
      );
      globalThis.router.navigate({
        pathname: '/home/vorderingsplannen',
      });
    }
  }
}

/**
 * this saga watches the url params for /leerplan and /vorderingsplan and makes sure that the data is present in the store.
 * if the data is not present, we might be in the wrong schoolyear or in the wrong school.
 * in that case we need to fetch the customcurricula or activityplan and figure what school and schoolyear to set.
 */

export function* watchUrlParamsMatchSchoolAndSchoolyear() {
  yield takeLatest(setUrlParams, checkUrlParamsMatchSchoolAndSchoolyear);
  yield takeLatest(setCalendarKeys, checkCalendarParamsMatchSchoolAndSchoolyear);
}
