import './resourcePickerOption.scss';

const SchoolOption = ({ option }) => {
  return (
    <div className="display-school">
      <span data-testid="display-school" className={option.missing && 'missing-class'}>
        {option.$$displayName}
      </span>
    </div>
  );
};

export default SchoolOption;
