import { HoveredPopover } from '@UI/index';

import './basisLink.scss';

const BasisLink = ({ basis }) => {
  if (!basis || basis.length === 0) return null;

  const title = basis.map((item) => `${item.identifier} ${item.title}`).join('<br />');

  return (
    <span className="basis-link">
      🡒
      <HoveredPopover content={title} compile={true}>
        <a
          href="https://pincette.katholiekonderwijs.vlaanderen/meta/properties/dc-identifier/Cur-20190531-1"
          target="_blank"
          rel="noreferrer"
        >
          bg
        </a>
      </HoveredPopover>
    </span>
  );
};

export default BasisLink;
