import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSchoolContext } from '@store/userAndSchool/usersAndSchoolState';
import { Alert, AlertState } from './alertTypes';

const initialState: AlertState = {
  alerts: {},
};

const alertsState = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<Alert>) => {
      const { key, ...alert } = action.payload;
      state.alerts[key] = alert;
    },
    closeAlert: (state, action: PayloadAction<{ key: string }>) => {
      const { key } = action.payload;
      delete state.alerts[key];
    },
    closeAllAlerts: (state) => {
      state.alerts = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSchoolContext, (state) => {
      state.alerts = {};
    });
  },
});

export const { setAlert, closeAlert, closeAllAlerts } = alertsState.actions;

export default alertsState;
