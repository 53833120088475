import { createSelector } from '@reduxjs/toolkit';

export const selectAlertKeys = createSelector(
  (state) => state.alerts.alerts,
  (alerts) => Object.keys(alerts)
);

export const selectAlertByKey = createSelector(
  [(state, alertKey) => state.alerts.alerts[alertKey]],
  (alert) => {
    if (!alert) return undefined;

    const showClose = alert.showClose || alert.delay === -1;

    return { ...alert, showClose };
  }
);
