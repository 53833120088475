import { Controller } from 'react-hook-form';
import StudyProgramsOptions from './StudyProgramsOptions';

const StudyProgramsForm = ({ control, streams, programs, validations, onChange }) => {
  return (
    <Controller
      name="studyPrograms"
      control={control}
      defaultValue={{ streams: [], programs: [] }}
      rules={{
        validate: validations,
      }}
      render={({ field, fieldState: { error } }) => (
        <>
          <StudyProgramsOptions
            streams={streams}
            programs={programs}
            error={error}
            onChange={(options) => {
              field.onChange(options);
              onChange(options);
            }}
          />
        </>
      )}
    />
  );
};

export default StudyProgramsForm;
