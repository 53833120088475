const printCalendar = (title, onafterprint) => {
  if (title) {
    document.querySelector('title').innerHTML = title;
  }
  const defaultAfterPrint = window.onafterprint;
  window.onafterprint = () => {
    onafterprint?.();
  };
  setTimeout(() => {
    window.print();
    window.onafterprint = defaultAfterPrint;
  }, 500);
};

export default printCalendar;
