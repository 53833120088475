import './optionItems.scss';

const TeacherOptionItem = ({ option }) => (
  <div className="teacher-option-item option-item">
    <div className="result-icon">
      <img src={option.src} />
    </div>
    <span>{option.$$displayName}</span>
  </div>
);

export default TeacherOptionItem;
