export const MAIN_TAB_ID = '3b754443-dc47-4ebf-8ec5-58d6da24eabe';
export const SPECIAL_TAB_ID = 'b16196c4-a455-43e4-8ac8-fb7fb0d7eb5a';

export const LEIDRAAD_TABS = [
  {
    title: 'Leidraad so',
    key: '3b754443-dc47-4ebf-8ec5-58d6da24eabe',
    tabClass: 'leidraad-tab-italic',
    state: 'home.leidraad.tab',
    params: {
      id: '3b754443-dc47-4ebf-8ec5-58d6da24eabe',
    },
    completed: false,
    tabData: {},
  },
  {
    title: 'Gedragen visie en beleidskeuzes',
    key: '321fc383-c25a-42dc-9270-65b589ce9f8e',
    tabClass: 'leidraad-tab-italic',
    state: 'home.leidraad.tab',
    params: {
      id: '321fc383-c25a-42dc-9270-65b589ce9f8e',
    },
    completed: false,
    tabData: {},
  },
  {
    title: 'Visie op goed onderwijs',
    key: 'b16196c4-a455-43e4-8ac8-fb7fb0d7eb5a',
    tabClass: 'leidraad-tab-italic',
    state: 'home.leidraad.tab',
    params: {
      id: 'b16196c4-a455-43e4-8ac8-fb7fb0d7eb5a',
    },
    completed: false,
    tabData: {},
  },
  {
    title: 'Overgang basisonderwijs - eerste graad',
    key: '0f1968de-a854-454f-bdcb-b7297f59781b',
    tabClass: '',
    state: 'home.leidraad.tab',
    params: {
      id: '0f1968de-a854-454f-bdcb-b7297f59781b',
    },
    completed: false,
    tabData: {},
  },
  {
    title: 'Brede algemene vorming voor alle leerlingen',
    key: '177e27da-dded-4f00-b870-667474d43b7f',
    tabClass: '',
    state: 'home.leidraad.tab',
    params: {
      id: '177e27da-dded-4f00-b870-667474d43b7f',
    },
    completed: false,
    tabData: {},
  },
  {
    title: 'Differentiatie: remediëring en verdieping',
    key: 'b978d3db-aab9-4d6d-ab70-76c7e1464c53',
    tabClass: 'regular',
    state: 'home.leidraad.tab',
    params: {
      id: 'b978d3db-aab9-4d6d-ab70-76c7e1464c53',
    },
    completed: false,
    tabData: {},
  },
  {
    title: 'Observatie en oriëntatie',
    key: '0758f303-686b-4bd3-be63-1a5089eb32a6',
    tabClass: '',
    state: 'home.leidraad.tab',
    params: {
      id: '0758f303-686b-4bd3-be63-1a5089eb32a6',
    },
    completed: false,
    tabData: {},
  },
  {
    title: 'Evaluatie en studiebekrachtiging',
    key: '36c1a9d5-d24f-41a1-842d-248c105a769e',
    tabClass: '',
    state: 'home.leidraad.tab',
    params: {
      id: '36c1a9d5-d24f-41a1-842d-248c105a769e',
    },
    completed: false,
    tabData: {},
  },
];
