import { createTypedSelector } from '@store/genericHelpers';
import { selectHaveActivitiesBeenLoadedRecently } from '@store/llinkidApis/llinkidApiSelectors';
import {
  selectCurrentSchoolyearKey,
  selectUserHref,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { getAllCreatorsWithIcon } from '@utils/utils';

export const selectCopyCalendarDefaultFormValues = createTypedSelector(
  [
    (state) => state.calendarList.copyCalendar.originalCalendar,
    (state) => selectUserHref(state),
    (state) => selectCurrentSchoolyearKey(state),
  ],
  (originalCalendar, userHref, schoolyear) => {
    if (originalCalendar) {
      const { school, plans, curricula, classes, keys, plan, allCreators } = originalCalendar;

      const key = keys?.join('-');
      const firstPlanItem = plans?.[0];
      const allCreatorsWithIcon = getAllCreatorsWithIcon(allCreators);
      const creators = firstPlanItem.creators.map((c) => c.href);

      let teachers = creators.map((c) => allCreatorsWithIcon.find((e) => e.href === c));
      if (!creators.includes(userHref)) {
        const me = allCreatorsWithIcon.find((e) => e.href === userHref);
        teachers = [me, ...teachers];
      }
      const shared = allCreatorsWithIcon.filter((o) =>
        firstPlanItem?.observers?.find((p) => p.href === o.$$meta.permalink)
      );

      return {
        key,
        title: plan,
        school,
        schoolyear,
        curricula,
        classes,
        teachers,
        shared,
      };
    }
    return null;
  }
);

export const isCopyCalendarDataLoaded = createTypedSelector(
  [
    (state) => selectHaveActivitiesBeenLoadedRecently(state),
    (state) => selectCopyCalendarDefaultFormValues(state),
  ],
  (activitiesLoaded, originalCalendar) => activitiesLoaded && originalCalendar !== null
);
