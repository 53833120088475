import { getCurriculumKeyFromStateOrParams } from '@store/curriculum/curriculumAndAnnotationHelper';
import {
  selectCurriculumAttachments,
  selectFullAnnotatedCurriculumViewModel,
} from '@store/curriculum/curriculumSelectors';
import { createTypedSelector } from '@store/genericHelpers';
import { getImageUrl } from '@utils/curriculumHelper';

export const selectCurriculumHeaderInfo = createTypedSelector(
  [
    // @ts-expect-error type inferrence not correct
    (state, params) => selectFullAnnotatedCurriculumViewModel(state, params),
    (state, params) => selectCurriculumAttachments(state, params),
    (state, params) => getCurriculumKeyFromStateOrParams(state, params) === 'nonderived',
  ],
  (model, curriculumAttachments, isNonderived) => {
    if (!model) {
      return null;
    }

    return {
      backgroundImage: getImageUrl(model?.grade, curriculumAttachments),
      curriculumTitle: model.title,
      version: model.versionNumber,
      educationalPointers: model.educationalPointers,
      generalLevel: model.generalLevel,
      schoolLevel: model.schoolLevel,
      educationalComponents: model.educationalComponents,
      curriculumType: model.curriculumType,
      subtitle: model.subTitle,
      validityPeriod: model.validityPeriodString,
      studyProgram: model.studyProgram,
      isNonderived,
    };
  }
);
