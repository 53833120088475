import {
  KovContentBlock,
  KovContentBlockProvider,
} from '@kathondvla/react-shared-components/src/components/contentBlock';
import { H5, InputList, SelectableLeidraadPointer, TextEditor } from '@UI/index';
import SaveIcon from '@mui/icons-material/Save';
import { Button } from '@mui/material';
import leidraadImg from '@assets/img/leidraad_so.png';
import settings from '@config/settings';
import { MAIN_TAB_ID, SPECIAL_TAB_ID } from '../../../constants/leidraadTabs';
import './leidraadTab.scss';

const LeidraadTab = ({ tab, saveDisabled, onSave, onTabUpdate }) => {
  const isMainPage = tab.key === MAIN_TAB_ID;
  const isSpecialPage = tab.key === SPECIAL_TAB_ID;
  const applicableList = isSpecialPage ? 'coreItems' : 'priorities';

  const onSelectPointer = (pointer) => {
    if (tab.editable) {
      const educationalPointers = [...tab.tabData.educationalPointers];
      const index = educationalPointers.findIndex((e) => e.pointer.key === pointer.pointer.key);
      educationalPointers[index] = {
        ...educationalPointers[index],
        selected: !educationalPointers[index].selected,
      };

      const newValue = { ...tab, tabData: { ...tab.tabData, educationalPointers } };
      onTabUpdate(newValue);
    }
  };

  const onAddHandler = () => {
    if (tab.editable) {
      const newValue = {
        ...tab,
        tabData: {
          ...tab.tabData,
          [applicableList]: [
            ...tab.tabData[applicableList],
            { text: '', includeInCurriculum: false },
          ],
        },
      };
      // newValue.tabData[applicableList].push({ text: '', includeInCurriculum: false });
      onTabUpdate(newValue);
    }
  };

  const onChangeHandler = (item, index) => {
    const newList = [...tab.tabData[applicableList]];
    newList[index] = item;
    const newValue = { ...tab, tabData: { ...tab.tabData, [applicableList]: newList } };
    onTabUpdate(newValue);
  };

  const setApproachText = (text) => {
    const newValue = { ...tab, tabData: { ...tab.tabData, approach: text } };
    onTabUpdate(newValue);
  };

  const setEvaluationText = (text) => {
    const newValue = { ...tab, tabData: { ...tab.tabData, evaluation: text } };
    onTabUpdate(newValue);
  };

  return (
    <div className="leidraad-tab">
      <header>
        <img src={leidraadImg} />
        {!isMainPage && (
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            disabled={saveDisabled}
            onClick={() => onSave(tab)}
          >
            Bewaren
          </Button>
        )}
      </header>
      <main className="leidraad-tab-scrollable">
        <KovContentBlockProvider baseUrl={settings.apisAndUrls.api} allTitlesH1={false}>
          <KovContentBlock
            item={tab.root}
            itemList={tab.items}
            showCollapsed={false}
            displayRootItem={false}
          />
        </KovContentBlockProvider>

        {!isMainPage && (
          <section className="leidraad-tab-data">
            <div className="leidraad-approach">
              <H5 title="Welke aanpak stellen jullie als school voorop?" highlight="aanpak" />
              <TextEditor
                value={tab.tabData.approach}
                disabled={!tab.editable}
                onChange={setApproachText}
              />
            </div>

            {!isSpecialPage && (
              <div className="leidraad-evaluation">
                <H5 title="Hoe evalueren jullie de huidige aanpak?" highlight="evalueren" />
                <TextEditor
                  value={tab.tabData.evaluation}
                  disabled={!tab.editable}
                  onChange={setEvaluationText}
                />
              </div>
            )}

            <div className="leidraad-inputlist">
              {isSpecialPage ? (
                <H5
                  title="Wat zijn de kernpunten van jullie pedagogisch project?"
                  highlight="kernpunten"
                />
              ) : (
                <H5
                  title="Welke prioriteiten stellen jullie als school?"
                  highlight="prioriteiten"
                />
              )}
              <p>Vink ook aan welke gevisualiseerd moeten worden in de leerplannen.</p>
              <InputList
                list={tab?.tabData?.[applicableList]}
                editable={tab.editable}
                onAdd={onAddHandler}
                onChange={onChangeHandler}
              />
            </div>

            {tab?.tabData?.educationalPointers && (
              <div className="leidraad-pointers">
                <H5 title="Op welke wegwijzers zetten jullie in?" highlight="wegwijzers" />

                <div className="pointers-container">
                  {tab.tabData.educationalPointers?.map((pointer, index) => (
                    <SelectableLeidraadPointer
                      key={index}
                      pointer={pointer}
                      onSelect={onSelectPointer}
                    />
                  ))}
                </div>
              </div>
            )}
          </section>
        )}
      </main>
    </div>
  );
};

export default LeidraadTab;
