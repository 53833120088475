import commonUtils from '@kathondvla/sri-client/common-utils';
import { CONTENTTYPES, CUSTOMCREATIONTYPES, CUSTOMTYPES } from './curriculumHelper';
import { calculateReadOrderSet } from './utils';

function getAboveAndBelowItems(originator, vmItems, creationType) {
  const propertyType =
    creationType === CUSTOMCREATIONTYPES.llinkidGoal ||
    creationType === CUSTOMCREATIONTYPES.customGoal
      ? 'goals'
      : 'sections';
  let above;
  let below;
  let parent;
  let originatorCopy = { ...originator };

  if (
    originatorCopy.type === CONTENTTYPES.goalList ||
    originatorCopy.type === CUSTOMTYPES.goalList
  ) {
    return {
      above: undefined,
      below: originatorCopy[propertyType] && originatorCopy[propertyType][0],
    };
  }

  if (
    originatorCopy.type === CONTENTTYPES.tip ||
    originatorCopy.type === CONTENTTYPES.goalExplanation ||
    originatorCopy.type === CONTENTTYPES.goalExtra ||
    originatorCopy.type === CONTENTTYPES.initialSituation
  ) {
    originatorCopy = vmItems.find(
      (e) => e.subItems && e.subItems.some((z) => z.key === originatorCopy.key)
    );
  }

  if (originatorCopy.type === CONTENTTYPES.goal || originatorCopy.type === CUSTOMTYPES.goal) {
    parent = vmItems.find(
      (e) => e.goals && e.goals.some((goal) => goal.key === originatorCopy.key)
    );

    if (parent.type === CONTENTTYPES.goal || parent.type === CUSTOMTYPES.goal) {
      originatorCopy = parent;
      parent = vmItems.find((e) => e.goals && e.goals.some((goal) => goal.key === parent.key));
    }

    let index;
    let parentTemp = parent;
    if (creationType === CUSTOMCREATIONTYPES.llinkidGoal || creationType === CUSTOMTYPES.goal) {
      index =
        parent && parent[propertyType]
          ? parent[propertyType].findIndex((g) => g.key === originatorCopy.key) || 0
          : 0;
      above = originatorCopy;
      below = parent[propertyType][index + 1];
    } else {
      const grandParent = vmItems.find(
        (e) => e[propertyType] && e[propertyType].some((item) => item.key === parent.key)
      );
      index =
        grandParent && grandParent[propertyType]
          ? grandParent[propertyType].findIndex((s) => s.key === parent.key) || 0
          : 0;
      above = parent;
      if (grandParent) parentTemp = grandParent;
      below = parentTemp[propertyType][index + 1];

      if (
        creationType === CUSTOMCREATIONTYPES.section &&
        parent.type === CUSTOMTYPES.section &&
        grandParent.type === CUSTOMTYPES.section
      ) {
        const goalList = vmItems.find(
          (e) => e[propertyType] && e[propertyType].some((item) => item.key === grandParent.key)
        );
        const i =
          goalList && goalList[propertyType]
            ? goalList[propertyType].findIndex((s) => s.key === grandParent.key) || 0
            : 0;

        above = grandParent;
        below = goalList[propertyType] && goalList[propertyType][i + 1];
      }
    }

    return {
      above,
      below,
      parent,
    };
  }

  if (originatorCopy.type === CUSTOMTYPES.section) {
    if (
      creationType === CUSTOMCREATIONTYPES.llinkidGoal ||
      creationType === CUSTOMCREATIONTYPES.customGoal
    ) {
      above = undefined;
      below = originatorCopy[propertyType] && originatorCopy[propertyType][0];
      parent = originatorCopy;
    } else if (creationType === CUSTOMCREATIONTYPES.subSection) {
      above = undefined;
      below = originatorCopy[propertyType] && originatorCopy[propertyType][0];
      parent = originatorCopy;

      const grandParent = vmItems.find(
        (e) => e[propertyType] && e[propertyType].some((item) => item.key === parent.key)
      );

      if (grandParent.type === CUSTOMTYPES.section) {
        // so in this case, we are making a subsection originating from a subsection.
        // this means the new subsection will come below the originator, as a sibling.
        parent = grandParent;
        const index =
          parent && parent[propertyType]
            ? parent[propertyType].findIndex((s) => s.key === originatorCopy.key) || 0
            : 0;

        above = originatorCopy;
        below = parent[propertyType] && parent[propertyType][index + 1];
      }
    } else {
      parent = vmItems.find(
        (e) => e[propertyType] && e[propertyType].some((goal) => goal.key === originatorCopy.key)
      );
      const index =
        parent && parent[propertyType]
          ? parent[propertyType].findIndex((s) => s.key === originatorCopy.key) || 0
          : 0;

      above = originatorCopy;
      below = parent[propertyType] && parent[propertyType][index + 1];

      if (creationType === CUSTOMCREATIONTYPES.section && parent.type === CUSTOMTYPES.section) {
        const grandParent = vmItems.find(
          (e) => e[propertyType] && e[propertyType].some((item) => item.key === parent.key)
        );
        const i =
          grandParent && grandParent[propertyType]
            ? grandParent[propertyType].findIndex((s) => s.key === parent.key) || 0
            : 0;

        above = parent;
        below = grandParent[propertyType] && grandParent[propertyType][i + 1];
      }
    }

    return { above, below, parent };
  }

  return { above, below };
}

/**
 *
 * creationType: type of creation: custom tip, goal, reference, move, subgoal, etc
 * @returns {Array<import('@store/llinkidApis/userActionTypes').PositioningObject}
 */
export function getPositioningObject(originator, items, creationType, vmItems) {
  const tmpOriginator = originator;
  const { above, below, parent } = getAboveAndBelowItems(tmpOriginator, vmItems, creationType);

  const readOrders = calculateReadOrderSet(above, below, items.length);
  return readOrders.map((e) => {
    const position = { parentHref: parent ? parent.$$meta.permalink : null, readOrder: e, parent };
    return position;
  });
}

export function createAnnotation({ type, custCur, target, reference }) {
  const key = commonUtils.generateUUID();
  const baseAnnotation = {
    $$meta: {
      permalink: `/llinkid/customcurriculum/customcurricula/annotations/${key}`,
    },
    key,
    curriculum: { href: custCur },
  };

  if (target) baseAnnotation.target = { href: target };
  if (type) baseAnnotation.type = type;
  if (reference) baseAnnotation.reference = { href: reference };

  return baseAnnotation;
}
