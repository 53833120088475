import { rest } from 'msw';

import apis from '@config/apis';

export const studyProgrammeApiDemoHandlers = [
  rest.get(`${apis.cachedApi.baseUrl}/sam/commons/studyprogrammes`, (req) => {
    return req.passthrough();
  }),
  rest.get(`${apis.cachedApi.baseUrl}/sam/commons/educationalactivitytypes`, (req) => {
    return req.passthrough();
  }),
  rest.get(`${apis.cachedApi.baseUrl}/sam/commons/studyprogrammegroups`, (req) => {
    return req.passthrough();
  }),
];
