import RadioGroup from '@UI/radioGroup/RadioGroup';
import { Controller } from 'react-hook-form';

const FormRadioGroup = ({ name, value, options, direction, required = false, control }) => {
  if (name == null) throw new Error('[FormRadioGroup]: Please provide a unique form input name.');
  if (control == null) throw new Error('[FormRadioGroup]: UseForm control not provided.');

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={{
        required,
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <RadioGroup
            direction={direction}
            options={options}
            required={required}
            error={error}
            {...field}
          />
        );
      }}
    />
  );
};

export default FormRadioGroup;
