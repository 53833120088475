import { Highlight, HoveredPopover } from '@UI/index';
import { CircularProgress } from '@mui/material';
import { selectUser } from '@store/userAndSchool/userAndSchoolSelectors';
import { formatDate, renameTo } from '@utils/utils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CalendarListItemActions from './CalendarListItemActions';

import './calendarListItem.scss';

const CalendarListItem = ({ item, onClick, onEdit, onDelete, onUndelete }) => {
  const [activityPlanClicked, setActivityPlanClicked] = useState(false);
  const filter = useSelector((state) => state.calendarList.filters.name);
  const selectionMode = useSelector((state) => state.calendarList.selectionMode);
  const user = useSelector(selectUser);
  const showActions = !selectionMode && (!item.deleted || (!item.readOnly && item.deleted));

  const handleClickItem = () => {
    if (!onClick) return;
    setActivityPlanClicked(true);
    onClick(item);
  };

  return (
    <article className={`calendar-list-item ${item.deleted ? 'deleted' : ''}`}>
      <div className="item-classes" onClick={handleClickItem} title={item.classes}>
        <Highlight text={item.classes} search={filter} />
      </div>
      <div className={`item-plan ${item.latest ? 'latest' : ''}`} onClick={handleClickItem}>
        <Highlight text={item.plan} search={filter} />
        {activityPlanClicked && !selectionMode && (
          <CircularProgress color="primary" size={20} sx={{ ml: 2, mr: 2 }} />
        )}
        {item.isCurriculaMissing && (
          <div className="curricula-disappear">
            <HoveredPopover
              className="curricula-disappear-popover"
              content="Dit vorderingsplan is gebaseerd op een leerplan dat niet geldig is in de huidige context."
            >
              ⚠️
            </HoveredPopover>
          </div>
        )}
      </div>
      {item.deleted && (
        <div className="item-deleted">{formatDate(new Date(item.deleted), 'dd/MM/yyyy')}</div>
      )}
      <div className="item-creators" onClick={handleClickItem}>
        <Highlight text={renameTo(item.teachers, 'Jij', user)} search={filter} />
      </div>
      <div className="item-actions">
        {showActions && (
          <CalendarListItemActions
            item={item}
            onEdit={onEdit}
            onDelete={onDelete}
            onUndelete={onUndelete}
          />
        )}
      </div>
    </article>
  );
};

export default CalendarListItem;
