import { setupWorker } from 'msw';
import demo from '@config/demo';
import { studyProgrammeApiHandlers } from './api/studyProgrammes/handlers';
import { contentApiHandlers } from './api/content/handlers';
import { oauthHandlers } from './api/oauth/handlers';
import { personsApiHandlers } from './api/persons/handlers';
import { samApiHandlers } from './api/sam/handlers';
import { privateStateApiHandlers } from './api/privateState/handlers';
import { activityplansApiHandlers } from './api/activityplans/handlers';
import { customCurriculaApiHandlers } from './api/customCurricula/handlers';

import { studyProgrammeApiDemoHandlers } from './api/studyProgrammes/demoHandlers';
import { contentApiDemoHandlers } from './api/content/demoHandlers';

const handlers = [
  // ...studyProgrammeApiHandlers,
  // ...contentApiHandlers,
  ...oauthHandlers,
  ...personsApiHandlers,
  ...samApiHandlers,
  ...privateStateApiHandlers,
  ...activityplansApiHandlers,
  ...customCurriculaApiHandlers,
];

if (demo.enableDemoMode) {
  handlers.push(...studyProgrammeApiDemoHandlers);
  handlers.push(...contentApiDemoHandlers);
} else {
  handlers.push(...studyProgrammeApiHandlers);
  handlers.push(...contentApiHandlers);
}

// This configures a Service Worker with the given request handlers.

export const worker = process.env.VITEST === undefined ? setupWorker(...handlers) : undefined;
