import { Controller } from 'react-hook-form';
import { KovResourcePicker } from '@kathondvla/react-shared-components/src';

const FormResourcePicker = ({
  name,
  value,
  placeholder,
  disabled = false,
  required = false,
  localData,
  display,
  filter,
  className,
  multiple = false,
  optionValue,
  optionTemplate,
  onChange,
  control,
  validations,
}) => {
  if (name == null)
    throw new Error('[FormResourcePicker]: Please provide a unique form input name.');
  if (control == null) throw new Error('[FormResourcePicker]: UseForm control not provided.');

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={{
        required,
        validate: validations,
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <KovResourcePicker
              localData={localData}
              display={display}
              filter={filter}
              className={`${className} ${error ? 'error' : ''}`}
              multiple={multiple}
              optionValue={optionValue}
              optionTemplate={optionTemplate}
              placeholder={placeholder}
              disabled={disabled}
              error={error}
              portal="body"
              {...field}
              onChange={(val) => {
                field.onChange(val);
                onChange?.(val);
              }}
            />
            {error && <span className="error-message">{error.message}</span>}
          </>
        );
      }}
    />
  );
};

export default FormResourcePicker;
