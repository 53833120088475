import { useSelector, useDispatch } from 'react-redux';
import { leerplanListActions } from '@store/leerplanList/leerplanListState';
import { CustomSelect, SearchInput, Checkbox } from '@UI/index';

import './leerplanListFilter.scss';
import {
  selectAllGrades,
  selectAllProgrammes,
  selectAllStreamsAndFinalities,
} from '@store/studyProgrammesApi/studyProgrammeSelectors';
import { selectHasCurrentSchoolStudyProgrammes } from '@store/leerplanList/leerplanListSelectors';

const LeerplanListFilter = () => {
  const dispatch = useDispatch();
  const { name, grades, studyProgrammes, streams, relevantCurricula } = useSelector(
    (state) => state.leerplanList.filters
  );
  const selectionMode = useSelector((state) => state.leerplanList.selectionMode);
  const gradeOptions = useSelector(selectAllGrades);
  const studyProgrammeOptions = useSelector(selectAllProgrammes);
  const streamOptions = useSelector(selectAllStreamsAndFinalities);

  const updateFilter = (property) => (value) => {
    dispatch(leerplanListActions.setFilter({ property, value }));
  };
  const removeAll = () => dispatch(leerplanListActions.removeAllFilters());
  const hasCurrentSchoolStudyProgrammes = useSelector(selectHasCurrentSchoolStudyProgrammes);

  return (
    <div className="leerplan-list-filter">
      {hasCurrentSchoolStudyProgrammes && !selectionMode && (
        <div
          className="relevant-curricula-checkbox-wrapper"
          data-testid="relevant-curricula-checkbox"
        >
          <div className="relevant-curricula-checkbox">
            <Checkbox
              small
              label="Toon enkel relevante leerplannen"
              checked={relevantCurricula}
              onChange={updateFilter('relevantCurricula')}
            />
          </div>
        </div>
      )}
      <div className="remove-all">
        <span onClick={removeAll}>Huidige zoekopdracht wissen.</span>
      </div>
      <div className="filter-wrapper">
        <div className="filter w-30">
          <SearchInput value={name} placeholder="zoekterm" onChange={updateFilter('name')} />
        </div>
        <div className="filter w-15" data-testid="grade-filter">
          <CustomSelect
            placeholder="Alle graden"
            value={grades}
            options={gradeOptions}
            onChange={updateFilter('grades')}
          />
        </div>
        <div className="filter w-25" data-testid="study-programmes-filter">
          <CustomSelect
            placeholder="Alle studierichtingen / basis opties / ..."
            value={studyProgrammes}
            options={studyProgrammeOptions}
            onChange={updateFilter('studyProgrammes')}
          />
        </div>
        <div className="filter w-20" data-testid="stream-filter">
          <CustomSelect
            placeholder="Alle finaliteiten"
            value={streams}
            options={streamOptions}
            onChange={updateFilter('streams')}
          />
        </div>
        {/* <div className="filter w-15">
          <CustomSelect
            placeholder="Leerplannenversie"
            value={versions}
            options={optionsMap.versions}
            display="shortName"
            optionValue="key"
            onChange={updateFilter('versions')}
          />
        </div> */}
      </div>
    </div>
  );
};

export default LeerplanListFilter;
