import { useDispatch } from 'react-redux';
import { CUSTOMTYPES } from '@utils/curriculumHelper';
import { setCollapseSectionsPreferences } from '@store/userAndSchool/usersAndSchoolState';
import { selectCurriculumKey } from '@store/curriculum/curriculumSelectors';
import { useAppSelector } from './typedHooks';

const useExpandSection = () => {
  const dispatch = useDispatch();
  const curriculumKey = useAppSelector(selectCurriculumKey);

  return (element) => {
    if (!element.collapsed) return;
    if (element.type === CUSTOMTYPES.section) {
      dispatch(
        setCollapseSectionsPreferences({
          curriculumKey,
          sectionKey: element.key,
          collapsed: false,
        })
      );
    }
  };
};

export default useExpandSection;
