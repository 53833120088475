import { FormGroup, FormTextField } from '@UI/index';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import { identifierUniqueOrUnchangedValidation, requiredValidation } from '@utils/validations';

import { useForm } from 'react-hook-form';

import { Button } from '@mui/material';
import { selectCustomCurriculaIdentifiers } from '@store/llinkidApis/llinkidApiSelectors';
import { useSelector } from 'react-redux';
import './modals.scss';

import iconEditWhite from '@assets/img/edit-white.svg';

const EditNonderivedModal = ({ curricula, onClose, onConfirm }) => {
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const customCurriculaIds = useSelector(selectCustomCurriculaIdentifiers);

  return (
    <div data-testid="nd-edit-modal">
      <Modal.Header title="Een doelenkader bewerken" icon={iconEditWhite} onClose={onClose} />

      <Modal.Body>
        <FormGroup label="Code" subtitle="Herkenbare code voor je doelenkader" required>
          <FormTextField
            name="code"
            value={curricula?.identifiers}
            maxLength={8}
            validations={{
              unique: identifierUniqueOrUnchangedValidation(
                curricula?.identifiers,
                customCurriculaIds
              ),
              required: (value) => requiredValidation(value),
            }}
            control={control}
          />
        </FormGroup>
        <FormGroup label="Naam" subtitle="Volledige naam van je doelenkader" required>
          <FormTextField
            name="name"
            value={curricula?.title}
            control={control}
            validations={{
              required: (value) => requiredValidation(value),
            }}
          />
        </FormGroup>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outlined" onClick={onClose}>
          Annuleren
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit((form) => {
            onConfirm({ ...form, curricula });
          })}
        >
          Opslaan
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default EditNonderivedModal;
