import { PayloadAction } from '@reduxjs/toolkit';
import { generalLoadError, generalSaveError } from '@store/alerts/alertConstants';
import { setAlert } from '@store/alerts/alertsState';
import { processPendingBatches } from '@store/calendar/calendarSagaHelpers';
import {
  selectIsLastRefreshValidPerTypeAndSchoolyear,
  selectIsLlinkidInitialized,
  selectIsLlinkidInitializing,
} from '@store/llinkidApis/llinkidApiSelectors';
import { RootState } from '@store/storeSetup';
import {
  selectCurrentSchoolHref,
  selectCurrentSchoolyear,
  selectLastActiveDateForSchool,
} from '@store/userAndSchool/userAndSchoolSelectors';
import {
  setCurrentSchoolyear,
  setSchoolyearCopyCalendarModal,
} from '@store/userAndSchool/usersAndSchoolState';
import { filterBySchoolyear, filterCustomCurriculaWithFilters } from '@utils/filters';
import { blockingDebounce, dispatchWhenSelectorChanges, waitForSaga } from '@utils/sagaUtils';
import { convertArrayToObject, getSchoolyears } from '@utils/utils';
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import settings from '@config/settings';
import { logAndCaptureException } from '@utils/logAndCaptureException';
import { deleteStateFile, getStateFile } from '../../app/fileHelpers';
import { apiConstants } from '../../constants/apiConstants';
import { Schoolyear } from '../../types/schoolyear';
import {
  activityPlansApi,
  customCurApi,
  getAllActivitiesProm,
  getAllActivityPlansProm,
  getAllAnnotationsProm,
  getAllCustomCurriculaGroupsProm,
  getAllCustomCurriculaProm,
  getAllCustomItemsProm,
  getApiAndUrlForBatch,
  getApiItemsCount,
  isActivity,
  isActivityPlan,
  isAnnotation,
  isCustomCurricula,
  isCustomcurriculagroup,
  isCustomItem,
} from '../apihelpers';
import {
  addPendingBatch,
  applyBatch,
  deltaUpdateCache,
  deltaUpdateSkipped,
  init,
  initActivities,
  initActivityPlans,
  initAnnotations,
  initCustomCurricula,
  initCustomCurriculaGroups,
  initCustomItems,
  initOfTypeFailed,
  restoreCache,
  saveLlinkidApiBatch,
  setAutoSync,
  setBatchCompleted,
  setBatchFailed,
  setBatchStarted,
  setHasStartedInitializing,
  setStateFromFile,
} from './llinkidApiState';
import {
  InitPayloadAction,
  LlinkidAPiCacheStatePerYear,
  LlinkidApiStateDataPart,
  LlinkidApiStateType,
  SaveLlinkidApiBatchAction,
} from './llinkidApiTypes';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const db = require('../../app/db').default;

const functionsForType = {
  customCurricula: getAllCustomCurriculaProm,
  annotations: getAllAnnotationsProm,
  customItems: getAllCustomItemsProm,
  customCurriculaGroups: getAllCustomCurriculaGroupsProm,
  activityPlans: getAllActivityPlansProm,
  activities: getAllActivitiesProm,
};

function* logActions() {
  // console.log(action);
  yield;
}

async function deltaUpdateCacheStorageItem(
  type: LlinkidApiStateType,
  cacheSettings: LlinkidAPiCacheStatePerYear & {
    endpoint: string;
  },
  schoolhref: string,
  schoolyear: Schoolyear,
  lastActiveDate: string | null
) {
  const items = await functionsForType[type](cacheSettings, schoolhref, schoolyear, lastActiveDate);

  return items;
}

function* getDataFromLocalDB(tableName, schoolhref) {
  try {
    console.time(`dexie-get-${tableName}`);
    const currentTable = db.table(tableName);

    const dbItems = yield currentTable.where('context').equals(schoolhref).toArray();
    console.timeEnd(`dexie-get-${tableName}`);

    return convertArrayToObject(
      dbItems.map((e) => e.value),
      'key'
    );
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
    return undefined;
  }
}

function* resetDatabaseAndRestart(schoolhref) {
  try {
    yield db.delete();
  } catch (e) {
    logAndCaptureException(e);
  }
  yield deleteStateFile(schoolhref);
  window.location.reload();
}

function* loadDelta(
  type: LlinkidApiStateType,
  mergedCacheSettings: LlinkidAPiCacheStatePerYear & {
    endpoint: string;
  },
  schoolhref: string,
  schoolyear: Schoolyear,
  lastActiveDate: string | null
) {
  try {
    return yield call(
      deltaUpdateCacheStorageItem,
      type,
      mergedCacheSettings,
      schoolhref,
      schoolyear,
      lastActiveDate
    );
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
    return undefined;
  }
}

function* doDeltaUpdateForType(type: LlinkidApiStateType, action: InitPayloadAction) {
  const { schoolHref, schoolyear: schoolyearKey, force } = action.payload;
  const cacheSettingsFromState: LlinkidAPiCacheStatePerYear = yield select(
    (state: RootState) => state.customCurriculaData.settings[type][schoolyearKey]
  );
  const mergedCacheSettings: LlinkidAPiCacheStatePerYear & {
    endpoint: string;
  } = {
    ...apiConstants[type],
    ...cacheSettingsFromState,
  };

  const schoolyear: Schoolyear = schoolyearKey
    ? getSchoolyears().find((schoolyearItem) => schoolyearItem.value === schoolyearKey)
    : yield select(selectCurrentSchoolyear);

  const isLastRefreshValidPerTypeAndSchoolyear: boolean = yield select((state: RootState) =>
    selectIsLastRefreshValidPerTypeAndSchoolyear(state, [type], schoolyear.key)
  );

  if (!force && isLastRefreshValidPerTypeAndSchoolyear) {
    console.log('skipping delta update for', type);
    // call deltaUpdateSkipped to make sure all the loading status booleans are set correctly.
    yield put(deltaUpdateSkipped({ type, schoolyear: schoolyear.key }));
    return;
  }

  const lastActiveDate = selectLastActiveDateForSchool(yield select(), schoolHref);

  try {
    const deltaResult = yield loadDelta(
      type,
      mergedCacheSettings,
      schoolHref,
      schoolyear,
      lastActiveDate
    );
    if (!deltaResult) {
      yield put(initOfTypeFailed({ type, schoolyear: schoolyear.key }));
      yield put(setAlert(generalLoadError));
      return;
    }
    yield put(deltaUpdateCache({ items: deltaResult, type, schoolyear: schoolyear.key }));
  } catch (ex) {
    logAndCaptureException(ex);
    yield put(initOfTypeFailed({ type, schoolyear: schoolyear.key }));
    yield put(setAlert(generalLoadError));
  }
}

function* doInitialFromDB(schoolhref) {
  console.log('Loading initial from IndexedDB!');
  const settingsFromState = yield select((state) => state.customCurriculaData.settings);
  const results = { settings: {} };
  for (const type of Object.keys(settingsFromState)) {
    results[type] = yield getDataFromLocalDB(apiConstants[type].tableName, schoolhref);
    results.settings[type] = {};
  }
  return results;
}

function* checkLocalCache() {
  yield delay(100); // wait for the other sagas to run
  for (const type of ['customCurricula', 'activityPlans']) {
    try {
      const schoolyear = yield select(selectCurrentSchoolyear);
      const schoolhref = yield select(selectCurrentSchoolHref);
      if (!schoolhref) {
        return;
      }
      if (!schoolyear) {
        console.error('schoolyear not set');
        return;
      }

      yield call(waitForSaga, selectIsLlinkidInitialized);

      const cacheSettingsFromState: LlinkidAPiCacheStatePerYear = yield select(
        (state: RootState) => state.customCurriculaData.settings[type][schoolyear.key]
      );
      const mergedCacheSettings = {
        ...apiConstants[type],
        ...cacheSettingsFromState,
        function: functionsForType[type],
      };

      let api = customCurApi;
      let param: object = { 'context.href': schoolhref };
      if (type === 'annotations' || type === 'activities') {
        param = { rootWithContextContains: schoolhref };
      }
      if (type === 'activities' || type === 'activityPlans') {
        api = activityPlansApi;
      }

      if (type === 'activityPlans') {
        param = {
          ...param,
          'issued.startDateAfter': schoolyear.isoDates.startDate,
          'issued.endDateBefore': schoolyear.isoDates.endDate,
        };
      } else if (type === 'customCurricula') {
        param = {
          ...param,
          'issued.startDateBefore': schoolyear.isoDates.startDate,
          'issued.endDateAfterOrNull': schoolyear.isoDates.endDate,
        };
      }

      const apiCount = yield call(getApiItemsCount, api, mergedCacheSettings.endpoint, param);
      const localItems = yield select((state) => Object.values(state.customCurriculaData[type]));

      const filters = [filterBySchoolyear(schoolyear)];
      let localCount = 0;

      // the filtering below is how it is done for the Leerplanlist and the CalendarList, to find the items of a specific schoolyear.
      if (type === 'customCurricula') {
        localCount = filterCustomCurriculaWithFilters(localItems, filters).length;
      } else if (type === 'activityPlans') {
        localCount = localItems.filter(
          (e) => e.issued.startDate === schoolyear.isoDates.startDate
        ).length;
      }

      if (apiCount !== localCount) {
        // debugger;
        console.log('comparing', type, 'counts. Local:', localCount, 'vs api:', apiCount);
        console.error('local cache out of sync');
        if (
          settings.enableDemoMode ||
          settings.enableMswInBrowser ||
          (typeof process !== 'undefined' && process?.env?.VITEST)
        ) {
          // this should not happen in demo mode, but sometimes it does?
          // seems like a race condition, but I can't find it yet.
          // debugger;
        } else {
          yield call(resetDatabaseAndRestart, schoolhref);
        }
      }
    } catch (e) {
      logAndCaptureException(e);
      yield put(setAlert(generalSaveError));
    }
  }
}

/**
 * this function triggers the delta-sync to keep the local state up to date.
 * @param {*} param0
 */
function* deltaCache({ payload }) {
  yield put(initCustomCurricula(payload));
  yield put(initAnnotations(payload));
  yield put(initCustomItems(payload));
  yield put(initCustomCurriculaGroups(payload));
  yield put(initActivityPlans(payload));
  yield put(initActivities(payload));
}

/**
 * the function that loads the cache on init of the store.
 * it loads from the local file.
 * @param {*} param0
 */
function* initCache() {
  const schoolContext = yield select((state: RootState) => state.userAndSchools.currentSchoolHref);

  if (!schoolContext) {
    console.error('no schoolcontext');
    return;
  }

  try {
    const customCurriculaData = yield getStateFile(schoolContext);
    if (customCurriculaData === null) {
      // the idea here is, in the FIRST time we move from indexedDB to file, we still take the indexeddb values and put them in the state.
      // this is important because of demo-mode where persistent storage is achieved in indexeddb.
      const result = yield doInitialFromDB(schoolContext);
      yield put(setStateFromFile({ customCurriculaData: result }));
    } else {
      yield put(setStateFromFile({ customCurriculaData }));
    }
  } catch (e) {
    yield put(setStateFromFile({ customCurriculaData: null }));
    logAndCaptureException(e);
  }

  yield put(setHasStartedInitializing());
}

function* keepCacheUpToDate() {
  try {
    let keepUpToDate = yield select((state) => state.customCurriculaData.autoSyncEnabled);
    while (keepUpToDate) {
      const schoolHref: string = yield select(selectCurrentSchoolHref);
      const schoolyear: Schoolyear = yield select(selectCurrentSchoolyear);

      if (!schoolHref) {
        return;
      }
      if (!schoolyear) {
        console.error('no schoolyear');
        return;
      }

      const isInitializing: boolean = yield select(selectIsLlinkidInitializing);

      if (!isInitializing && schoolHref) {
        console.log('keeping local cache up to date for', schoolHref);
        yield deltaCache({ payload: { schoolHref, schoolyear: schoolyear.key } });
      } else {
        console.log('initializing was still busy for', schoolHref);
      }
      if (schoolyear.expired) {
        yield delay(settings.cacheTimeout.updateCacheExpiredSchoolyear * 1000);
      } else {
        yield delay(settings.cacheTimeout.updateCacheEvery * 1000);
      }

      keepUpToDate = yield select((state) => state.customCurriculaData.autoSyncEnabled);
    }
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
  }
}

/**
 * This saga is used to sync data of not-current schoolyear when the user changes schoolyear in the copy calendar modal.
 * @param {*} action
 */
function* getDataForSchoolyear({ payload }: PayloadAction<string>) {
  const schoolHref: string = yield select(selectCurrentSchoolHref);
  const schoolyear = payload;

  try {
    yield deltaCache({ payload: { schoolHref, schoolyear } });
  } catch (ex) {
    logAndCaptureException(ex);
  }
}

function* saveLlinkidApiBatchSaga(action: SaveLlinkidApiBatchAction) {
  const { batch, applyInstantly, batchKey } = action.payload;
  if (batchKey) yield put(setBatchStarted({ batchKey }));
  if (!batch.length) {
    if (batchKey) yield put(setBatchCompleted({ batchKey }));
    return;
  }
  const previousVersion: Partial<LlinkidApiStateDataPart> = {};
  if (applyInstantly) {
    if (batch.some((e) => isCustomCurricula(e.href)))
      previousVersion.customCurricula = yield select(
        (state) => state.customCurriculaData.customCurricula
      );
    if (batch.some((e) => isAnnotation(e.href)))
      previousVersion.annotations = yield select((state) => state.customCurriculaData.annotations);
    if (batch.some((e) => isCustomItem(e.href)))
      previousVersion.customItems = yield select((state) => state.customCurriculaData.customItems);
    if (batch.some((e) => isCustomcurriculagroup(e.href)))
      previousVersion.customCurriculaGroups = yield select(
        (state) => state.customCurriculaData.customCurriculaGroups
      );
    if (batch.some((e) => isActivityPlan(e.href)))
      previousVersion.activityPlans = yield select(
        (state) => state.customCurriculaData.activityPlans
      );
    if (batch.some((e) => isActivity(e.href)))
      previousVersion.activities = yield select((state) => state.customCurriculaData.activities);
    yield put(applyBatch({ batch }));
  }
  try {
    // throw 'oeps';
    // yield delay(2000);
    const { api, url } = getApiAndUrlForBatch(batch);

    const resp = yield api.post(url, batch, {
      keepBatchAlive: false,
    });
    if (!applyInstantly) {
      yield put(applyBatch({ batch }));
    }
    if (batchKey) yield put(setBatchCompleted({ batchKey, response: resp }));
  } catch (ex) {
    logAndCaptureException(ex);
    if (applyInstantly) {
      // roll back the applyBatch we did.

      for (const key of Object.keys(previousVersion) as LlinkidApiStateType[]) {
        yield put(restoreCache({ cacheList: previousVersion[key], type: key }));
      }
    }
    const response = { status: ex.status, body: ex.body };
    if (batchKey) {
      yield put(setBatchFailed({ batchKey }));
    }

    if (response.status === 409) {
      const schoolHref = yield select(selectCurrentSchoolHref);
      yield deltaCache({ payload: { schoolHref, force: true } });
      yield put(
        setAlert({
          key: 'save-validation-error',
          msg: `Er is een probleem opgetreden bij het bewaren. We halen de gegevens opnieuw op. Probeer daarna opnieuw.`,
          title: 'Fout',
          type: 'error',
          showClose: true,
          delay: 10000,
        })
      );
    } else {
      yield put(setAlert(generalSaveError));
    }
  }
}

export function* watchLogActionsSaga() {
  yield takeEvery('*', logActions);
}

export function* watchInitDataSaga() {
  // yield takeEvery(init, initCache);
  yield takeLatest(init, initCache);
  yield takeLatest([setStateFromFile, setAutoSync, setCurrentSchoolyear], keepCacheUpToDate);
  yield takeLatest(setSchoolyearCopyCalendarModal, getDataForSchoolyear);
  // i think getDataForSchoolyear and keepCacheUpToDate do the same thing in different ways.
  yield takeLatest(initCustomCurricula.type, doDeltaUpdateForType, 'customCurricula');
  yield takeLatest(initAnnotations.type, doDeltaUpdateForType, 'annotations');
  yield takeLatest(initCustomItems.type, doDeltaUpdateForType, 'customItems');
  yield takeLatest(initCustomCurriculaGroups.type, doDeltaUpdateForType, 'customCurriculaGroups');
  yield takeLatest(initActivityPlans.type, doDeltaUpdateForType, 'activityPlans');
  yield takeLatest(initActivities.type, doDeltaUpdateForType, 'activities');

  yield blockingDebounce(settings.asyncSaving.minDelay, addPendingBatch, processPendingBatches);
  // yield takeEvery(deltaUpdateCache, saveDataLocalSaga);
}

export function* watchDataInSyncSaga() {
  // check the local cache every time we load the state from file or when we change schoolyears.
  yield takeLatest([setStateFromFile, setCurrentSchoolyear], checkLocalCache);

  // this watches the currentSchoolHref and dispatches the init() action when it changes.
  yield dispatchWhenSelectorChanges(
    (state: RootState) => state.userAndSchools.currentSchoolHref,
    init()
  );
}

export function* watchSavingDataSaga() {
  yield takeEvery([saveLlinkidApiBatch], saveLlinkidApiBatchSaga);
}

// // function that allows you to monitor state changes to fire sagas
// function* monitorSelector(selector, previousValue, takePattern = '*') {
//   while (true) {
//     const nextValue = yield select(selector);
//     if (nextValue !== previousValue) {
//       return nextValue;
//     }
//     yield take(takePattern);
//   }
// }

// /**
//  * triggers the init of the api cache when a school is changed.
//  * this can probably be done in a cleaner and simpler way, with less magic.
//  */
// export function* watchChangedSchoolContext() {
//   let previousSchoolContext;
//   while (true) {
//     const schoolContext = yield* monitorSelector(
//       (state) => state.customCurriculaData.schoolContext,
//       previousSchoolContext
//     );
//     console.log('Schoolcontext changed from ', previousSchoolContext, ' to ', schoolContext);
//     if (schoolContext) {
//       yield spawn(initCache, schoolContext);
//     }
//     previousSchoolContext = schoolContext;
//   }
// }
