import {
  selectIsDirectorInCurrentSchool,
  selectPrincipalTeamHrefsForSchoolyear,
} from '@store/userAndSchool/userAndSchoolSelectors';

export const selectShowNonDerived = (state) => {
  const isDirector = selectIsDirectorInCurrentSchool(state);
  const principalTeamHrefs = selectPrincipalTeamHrefsForSchoolyear(state);
  return isDirector || principalTeamHrefs.length > 0;
};
