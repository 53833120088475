import { createTypedSelector } from '@store/genericHelpers';
import { getCustomCurriculaForTeamlead } from '@store/leerplanList/leerplanListHelpers';
import { ungroupedHomePageListSelector } from '@store/leerplanList/leerplanListSelectors';
import { getRecoverCalendarBatch } from '@store/llinkidApis/llinkidApiHelper';
import {
  selectActivityPlansData,
  selectActivityPlansFilteredByKeys,
  selectCustomCurriculaData,
  selectIsDataFreshNoSpinner,
  selectSettingsForCurrentSchoolyear,
} from '@store/llinkidApis/llinkidApiSelectors';
import {
  selectAllClassesInSchoolyear,
  selectAllCreatorsActiveInSchoolyear,
  selectCurrentSchool,
  selectCurrentSchoolHref,
  selectCurrentSchoolyear,
  selectCurrentSchoolyearKey,
  selectIsSchoolyearReadOnly,
  selectOrgsTeacherMapForSchoolyear,
  selectPrincipalSchoolHrefs,
  selectPrincipalTeamHrefsForSchoolyear,
  selectTeamsWithTeachersActiveInSchoolyear,
  selectUser,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { getCurriculaForPlan, getHrefSet, getPlansForUsers } from '@utils/utils';
import { isEqual, sortBy, uniqBy } from 'lodash-es';
import { ApiActivityPlan, ApiCustomCurriculum } from '../../types/llinkidApiTypes';
import {
  filterData,
  getPlanCreatorsAndObservers,
  getRemoveCalendarBatch,
} from './calendarListHelper';
import { ActivityPlanWithClass, PlanListItem } from './calendarListTypes';

export const selectPlanCreatorsAndObserversMap = createTypedSelector(
  [selectOrgsTeacherMapForSchoolyear, selectActivityPlansData],
  (orgsTeacherMap, activityPlans) => {
    return activityPlans.reduce((planCreatorsAndObservers, plan) => {
      planCreatorsAndObservers[plan.key] = getPlanCreatorsAndObservers(plan, orgsTeacherMap);
      return planCreatorsAndObservers;
    }, {});
  }
);

export const selectIsCalendarListLoading = createTypedSelector(
  [
    selectCurrentSchoolHref,
    (state) => state.customCurriculaData.hasStartedInitializing,
    (state) => selectSettingsForCurrentSchoolyear(state).customCurricula,
    (state) => selectSettingsForCurrentSchoolyear(state).activityPlans,
    ungroupedHomePageListSelector,
    (state) =>
      selectIsDataFreshNoSpinner(
        state,
        ['customCurricula', 'activityPlans'],
        selectCurrentSchoolyearKey(state)
      ),
  ],
  (
    currentSchool,
    hasStartedInitializing,
    customCurriculaSettings,
    activityPlansSettings,
    ungroupedHomePageList,
    isApiDataLastRefreshValid
  ) => {
    return (
      !hasStartedInitializing ||
      (currentSchool &&
        hasStartedInitializing &&
        customCurriculaSettings.isLoading &&
        !isApiDataLastRefreshValid) ||
      (activityPlansSettings.isLoading && !isApiDataLastRefreshValid) ||
      ungroupedHomePageList.length === 0
    );
  }
);

export const selectHasCalendarListError = createTypedSelector(
  [
    (state) => state.customCurriculaData.hasStartedInitializing,
    (state) => selectSettingsForCurrentSchoolyear(state).customCurricula,
    (state) => selectSettingsForCurrentSchoolyear(state).activityPlans,
    (state) => selectSettingsForCurrentSchoolyear(state).activities,
  ],
  (hasStartedInitializing, customCurriculaSettings, activityPlansSettings, activitiesSettings) => {
    return (
      hasStartedInitializing &&
      (customCurriculaSettings.isError ||
        activityPlansSettings.isError ||
        activitiesSettings.isError)
    );
  }
);

export const selectFullCalendarList = createTypedSelector(
  [
    (state) => ungroupedHomePageListSelector(state),
    selectUser,
    selectCurrentSchoolyear,
    selectPrincipalSchoolHrefs,
    selectPrincipalTeamHrefsForSchoolyear,
    selectCurrentSchool,
    selectPlanCreatorsAndObserversMap,
    selectActivityPlansData,
    selectCustomCurriculaData,
    selectIsCalendarListLoading,
    selectTeamsWithTeachersActiveInSchoolyear,
    selectAllCreatorsActiveInSchoolyear,
    (state) => selectAllClassesInSchoolyear(state),
    selectIsSchoolyearReadOnly,
  ],
  (
    allCurrs,
    user,
    currentSchoolyear,
    principalSchoolHrefs,
    principalTeamHrefs,
    currentSchool,
    activityPlansUsersExpandedMap,
    activityPlans,
    customCurricula,
    isCalendarListLoading,
    teamsWithTeachersActiveInSchoolyear,
    allCreatorsActiveInSchoolyear,
    allClasses,
    schoolyearReadOnly
  ) => {
    if (!user || isCalendarListLoading || !currentSchoolyear) return [];

    // console.time('selectFullCalendarList');
    // NEVER TRUE?
    // const onlyActiveClassesForSchoolyear = false;
    const userHrefs = new Set([user.$$meta.permalink]);
    const school = currentSchool;
    const schoolyear = currentSchoolyear;

    let plans: ApiActivityPlan[] = [];
    const planList: PlanListItem[] = [];
    if (school) {
      if (principalSchoolHrefs.includes(school.href)) {
        allCreatorsActiveInSchoolyear.forEach((e) => userHrefs.add(e.href));
      } else {
        const principalTeams = teamsWithTeachersActiveInSchoolyear.filter((e) =>
          principalTeamHrefs.includes(e.href)
        );

        if (principalTeams.length) {
          principalTeams.forEach((team) => {
            // get the teachers that aren't in my userHrefs set yet.
            const teachers = team.teachers.filter((t) => !userHrefs.has(t.href));

            // get plans from teachers i can't see yet
            let teacherPlans = getPlansForUsers(
              activityPlans,
              activityPlansUsersExpandedMap,
              teachers.map((e) => e.href),
              schoolyear
            );

            const customCurriculaForTeamLead: ApiCustomCurriculum[] = getCustomCurriculaForTeamlead(
              customCurricula,
              team,
              teachers,
              schoolyear
            );

            const ccHrefs = new Set([
              ...customCurriculaForTeamLead.map((e) => e.$$meta.permalink),
              ...customCurriculaForTeamLead
                .map((e) => e.customCurriculaGroup?.href)
                .filter(Boolean),
            ]);

            // filter the plans based on cc's i can see.
            teacherPlans = teacherPlans.filter((e) => e.curricula.some((z) => ccHrefs.has(z.href)));

            plans = plans.concat(teacherPlans);
          });
        }
      }

      plans = plans.concat(
        getPlansForUsers(
          activityPlans,
          activityPlansUsersExpandedMap,
          [...userHrefs],
          schoolyear,
          true
        )
      );

      plans = plans.filter((e) => e.issued.startDate === schoolyear.isoDates.startDate);
      plans = uniqBy(plans, 'key');

      const plansObj: Record<string, ActivityPlanWithClass[]> = {};

      for (const plan of plans) {
        if (plan.activityplangroup) {
          const group = plan.activityplangroup.href;

          if (!plansObj[group]) {
            plansObj[group] = [];
          }
          const $$class = allClasses.find((c) => c.$$meta.permalink === plan.class.href);
          plansObj[group].push({ ...plan, $$class });
        }
      }

      for (const key of Object.keys(plansObj)) {
        const planGroup = plansObj[key];
        const planCurSet = getHrefSet(planGroup[0].curricula);
        const curs = getCurriculaForPlan(allCurrs, planGroup[0]);

        const anyDeleted = planGroup.find((plan) => plan.softDeleted);
        const deleted = anyDeleted ? anyDeleted.softDeleted : null;

        const plan: string = planGroup[0].title || curs.map((e) => e.name).join(', ');

        const filteredClasses = planGroup.filter((e) => e.$$class);
        const hardDeletedClasses = planGroup.filter((e) => !e.$$class);

        // if (onlyActiveClassesForSchoolyear) {
        //   filteredClasses = planGroup.filter((e) => {
        //     const { startDate } = schoolyear;
        //     const { endDate: classEndDate } = e.$$class;
        //     return !classEndDate || isAfter(new Date(classEndDate), startDate);
        //   });
        // }

        let classesName = filteredClasses
          .map((cls) => {
            return cls.$$class?.$$displayName;
          })
          .sort()
          .join(', ');

        if (hardDeletedClasses.length) {
          if (filteredClasses.length)
            classesName = `${classesName}, ${hardDeletedClasses.length} verwijderde klassen`;
          else classesName = `${hardDeletedClasses.length} verwijderde klassen`;
        }

        const isCurriculaMissing: boolean =
          curs.length !== planCurSet.size || curs.some((z) => z.valid === false);
        if (curs.length !== planCurSet.size) {
          planCurSet.forEach((href) => {
            if (!curs.find((e) => href === (e.customCurriculum || e.customCurriculaGroup))) {
              // console.warn(`CALENDAR.LIST.HOME Curricula ${href} not found for user.`);
            }
          });
        }

        const planListItem = {
          keys: planGroup.map((e) => e.key),
          classes: classesName,
          plan,
          version: curs?.[0]?.version,
          versionNumber: curs?.[0]?.versionNumber,
          latest: curs?.[0]?.latest,
          planGroup: planGroup[0].activityplangroup.href,
          plans: planGroup,
          readOnly:
            schoolyearReadOnly ||
            !planGroup.every((pg) =>
              activityPlansUsersExpandedMap[pg.key].$$creatorsSet.has(user.$$meta.permalink)
            ),
          teachers: planGroup[0].creators
            .map((e) => {
              const teach = allCreatorsActiveInSchoolyear.find(
                (t) => t.$$meta.permalink === e.href
              );
              return teach ? teach.$$displayName : undefined;
            })
            .filter((e) => e)
            .join(', '),
          deleted,
          visible: !deleted,
          isCurriculaMissing,
        };

        planList.push(planListItem);
      }
    }
    // console.timeEnd('selectFullCalendarList');
    return sortBy(planList, [(e) => e.plan.toLowerCase()]);
  },
  {
    memoizeOptions: {
      // resultEqualityCheck: deepEqualWithLog('selectFullCalendarList'),
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectCalendarList = createTypedSelector(
  [selectFullCalendarList, (state) => state.calendarList.filters],
  (list, filters) => {
    const filteredList = filterData(filters, list);

    return filteredList;
  }
);

export const selectRemoveCalendarBatch = (state, params) => {
  const { keys } = params;
  const filteredActivityPlans = selectActivityPlansFilteredByKeys(state, { keys });
  return getRemoveCalendarBatch(filteredActivityPlans);
};

export const selectRecoverCalendarBatch = (state, params) => {
  const { keys } = params;
  const filteredActivityPlans = selectActivityPlansFilteredByKeys(state, { keys });
  return getRecoverCalendarBatch(filteredActivityPlans);
};
