import { Modal } from '@kathondvla/react-shared-components/src/components';
import { FormGroup } from '@UI/index';

import './modals.scss';
import { Button } from '@mui/material';

import iconUndeleteWhite from '@assets/img/icon_undelete_white.svg';

const UndeleteCalendarModal = ({ plan, onConfirm, onClose }) => {
  return (
    <>
      <Modal.Header
        title="Vorderingsplan(nen) herstellen"
        subtitle="Ben je zeker dat je dit/deze vorderingsplannen wil herstellen?"
        icon={iconUndeleteWhite}
        onClose={onClose}
      />

      <Modal.Body>
        <FormGroup label="Leerplan" subtitle="Welk leerplan dient als basis?">
          <span className="modal-text-name">{plan?.plan}</span>
        </FormGroup>

        <FormGroup label="Klas(sen)" subtitle="Voor welke klassen?">
          <span className="modal-text-name">{plan?.classes}</span>
        </FormGroup>

        <FormGroup label="Leerkrachten" subtitle="Wie kan het bewerken?">
          <span className="modal-text-name">{plan?.teachers}</span>
        </FormGroup>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outlined" onClick={onClose}>
          Annuleren
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          Herstellen
        </Button>
      </Modal.Footer>
    </>
  );
};

export default UndeleteCalendarModal;
