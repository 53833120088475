import { setAlert } from '@store/alerts/alertsState';
import { selectTeacherValuesForCalendar } from '@store/calendar/calendarSelectors';
import FormResourcePicker from '@UI/formResourcePicker/FormResourcePicker';
import TeacherOptionItem from '@UI/optionItems/TeacherOptionItem';
import { requiredValidation } from '@utils/validations';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const TeachersSelect = ({
  control,
  name,
  defaultValue,
  schoolyear,
  required = false,
  disabled = false,
}) => {
  if (!name || !control) {
    throw new Error("TeachersSelectors must have a form 'control' and a 'name'");
  }

  const { setValue } = useFormContext();
  const dispatch = useDispatch();
  const teachersSelect = useWatch({
    control,
    name,
  });

  const { values, options, alert } = useSelector((state) =>
    selectTeacherValuesForCalendar(state, {
      teachers: teachersSelect,
      schoolyear: schoolyear || state.userAndSchools.currentSchoolyear,
    })
  );

  useEffect(() => {
    if (alert) {
      dispatch(setAlert(alert));
    }
  }, [alert, dispatch]);

  useEffect(() => {
    if (teachersSelect && Array.isArray(values)) {
      setValue(name, values, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    setValue(name, defaultValue, { shouldValidate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormResourcePicker
      name={name}
      placeholder="naam"
      localData={options}
      optionTemplate={(option) => <TeacherOptionItem option={option} />}
      multiple
      disabled={disabled}
      control={control}
      validations={required ? { required: (value) => requiredValidation(value) } : null}
    />
  );
};

export default TeachersSelect;
