export const POST_MESSAGE_STATE = {
  CORRECT: '1',
  ERROR: '-1',
};

export const POST_MESSAGE_EVENT = {
  SELECTION_DONE: 'SELECTION_DONE',
  SELECTION_EXTRA: 'SELECTION_EXTRA',
  SELECTION_CANCELLED: 'SELECTION_CANCELLED',
  LOADED: 'LOADED',
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  ERROR: 'ERROR',
};

export const POST_MESSAGE_ACTION = {
  SELECT_FROM_CURRICULUMS: 'SELECT_FROM_CURRICULUMS',
  SELECT_CURRICULUM_GOALS: 'SELECT_CURRICULUM_GOALS',
  SELECT_YEAR_PLAN_ACTIVITIES: 'SELECT_YEAR_PLAN_ACTIVITIES',
  SELECT_FROM_YEAR_PLANS: 'SELECT_FROM_YEAR_PLANS',
  NAVIGATE: 'NAVIGATE',
};
