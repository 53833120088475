let temporaryGoalsOcurrence = new Map();

export function addUnsaveGoalOcurrence(goal) {
  const alreadyUnsaved = temporaryGoalsOcurrence.has(goal);
  if (alreadyUnsaved) temporaryGoalsOcurrence.delete(goal);
  else temporaryGoalsOcurrence.set(goal, 1);
}

export function removeUnsaveGoalOcurrence(goal) {
  const alreadyUnsaved = temporaryGoalsOcurrence.has(goal);
  if (alreadyUnsaved) temporaryGoalsOcurrence.delete(goal);
  else temporaryGoalsOcurrence.set(goal, -1);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function resetTemporaryGoalsOcurrence() {
  temporaryGoalsOcurrence = new Map();
}
