import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel } from '@mui/material';
import { selectIsDistributionMode } from '@store/distribute/distributeSelectors';
import { selectIsCurriculumFoundational } from '@store/curriculum/curriculumSelectors';

import { setDistributionMode } from '@store/distribute/distributeState';
import CustomSwitch from '@UI/switch';
import { useLocation, useNavigate } from 'react-router-dom';

const DistributionModeSwitch = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isFoundational = useSelector(selectIsCurriculumFoundational);
  const isDistributionMode = useSelector(selectIsDistributionMode);
  const navigate = useNavigate();

  const handleDistributionModeChange = (event) => {
    dispatch(setDistributionMode(!event.target.checked));
    if (event.target.checked) {
      navigate({
        pathname: 'distributie',
        search: location.search,
      });
    } else {
      navigate({
        pathname: 'doelenlijst',
        search: location.search,
      });
    }
  };

  return (
    <>
      <FormControlLabel
        sx={{ mr: 0, color: 'primary.main' }}
        label={isFoundational ? 'Verdelen leerplandoelen' : 'Verdelen naar doelenkader'}
        control={
          <CustomSwitch
            data-testid="distribution-mode-switch"
            sx={{ ml: 1 }}
            checked={isDistributionMode}
            onChange={handleDistributionModeChange}
            color="primary"
            size="small"
          />
        }
        labelPlacement="start"
      />
    </>
  );
};

export default DistributionModeSwitch;
