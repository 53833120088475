import { useState, useEffect, Dispatch, SetStateAction } from 'react';

const useCountdown = (): [number, Dispatch<SetStateAction<number>>] => {
  const [countDown, setCountDown] = useState<number>(0);

  useEffect(() => {
    if (countDown > 0) {
      const interval = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);

      return () => clearInterval(interval);
    }

    return undefined;
  }, [countDown]);

  return [countDown, setCountDown];
};

export default useCountdown;
