/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useModal from '@hooks/useModal';

import { leerplanListActions } from '@store/leerplanList/leerplanListState';
import DeleteCurriculaModal from '@components/leerplanHome/modals/DeleteCurriculaModal';
import EditNonderivedModal from '@components/leerplanHome/modals/EditNonderivedModal';
import DistributeMultipleTeamsModal from '@components/leerplanHome/modals/DistributeMultipleTeamsModal';
import CreateCurriculaModal from '@components/leerplanHome/modals/CreateCurriculaModal';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import {
  selectCurrentSchool,
  selectCurrentSchoolyear,
  selectPrincipalTeamsForSchoolyear,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { setOrgContext } from '@store/userAndSchool/usersAndSchoolState';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line no-empty-pattern
const LeerplanListModals = ({}, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const school = useSelector(selectCurrentSchool);
  const schoolYear = useSelector(selectCurrentSchoolyear);
  const teams = useSelector(selectPrincipalTeamsForSchoolyear);
  const createPersonalCurriculaModalRef = useRef();
  const createTeamCurriculaModalRef = useRef();
  const createSchoolCurriculaModalRef = useRef();
  const deleteCurriculaModalRef = useRef();
  const editNonDerivedModalRef = useRef();
  const distributeMultipleTeamsModalRef = useRef();

  const deleteCurriculaHandler = ({ curricula }) => {
    const curriculaToDelete = curricula.curricula ? curricula.curricula : [curricula];
    dispatch(
      leerplanListActions.deleteCurricula({ custIds: curriculaToDelete.map((e) => e.custid) })
    );
  };
  const editNonderivedHandler = ({ curricula, ...formValues }) =>
    dispatch(leerplanListActions.editNonderived({ item: curricula, formValues }));

  const createCurriculaHandler = ({ curricula, ...formValues }) =>
    dispatch(leerplanListActions.createCurricula({ item: curricula, formValues }));

  const distributeMultipleTeamsHandler = ({ curricula, version, ...formValues }) => {
    if (formValues.team) dispatch(setOrgContext(formValues.team));

    const params = new URLSearchParams({
      custid: curricula.custid || '',
      schoolyear: schoolYear.key,
    });

    const url = `/leerplan/${curricula.id}/distributie?${params.toString()}`;
    navigate(url);
  };

  const { open: openDeleteModal, ...deleteModalProps } = useModal(deleteCurriculaHandler);
  const { open: openEditNonderivedModal, ...editNonderivedModalProps } =
    useModal(editNonderivedHandler);
  const { open: openCreatePersonalCurriculaModal, ...createPersonalCurriculaModalProps } =
    useModal(createCurriculaHandler);
  const { open: openCreateTeamCurriculaModal, ...createTeamCurriculaModalProps } =
    useModal(createCurriculaHandler);
  const { open: openCreateSchoolCurriculaModal, ...createSchoolCurriculaModalProps } =
    useModal(createCurriculaHandler);
  const { open: openDistributeMultipleTeamsModal, ...distributeMultipleTeamsModalProps } = useModal(
    distributeMultipleTeamsHandler
  );

  useImperativeHandle(ref, () => ({
    open(type, { item, version }) {
      switch (type) {
        case 'delete':
          deleteCurriculaModalRef.current?.open({ curricula: item, ...deleteModalProps });
          break;
        case 'editNonderived':
          editNonDerivedModalRef.current?.open({ curricula: item, ...editNonderivedModalProps });
          break;
        case 'createPersonalCurricula':
          createPersonalCurriculaModalRef.current?.open({
            curricula: item,
            school,
            type: 'personal',
            ...createPersonalCurriculaModalProps,
          });
          break;
        case 'createTeamCurricula':
          createTeamCurriculaModalRef.current?.open({
            curricula: item,
            school,
            teams,
            type: 'team',
            ...createTeamCurriculaModalProps,
          });
          break;
        case 'createSchoolCurricula':
          createSchoolCurriculaModalRef.current?.open({
            curricula: item,
            school,
            type: 'school',
            ...createSchoolCurriculaModalProps,
          });
          break;
        case 'distributeMultipleTeams':
          distributeMultipleTeamsModalRef.current?.open({
            curricula: item,
            version,
            school,
            ...distributeMultipleTeamsModalProps,
          });
          break;
        default:
          break;
      }
    },
  }));

  return (
    <div className="leerplanlist-modals">
      <Modal
        ref={createPersonalCurriculaModalRef}
        size="md"
        component={<CreateCurriculaModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={createTeamCurriculaModalRef}
        size="md"
        component={<CreateCurriculaModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={createSchoolCurriculaModalRef}
        size="md"
        component={<CreateCurriculaModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={deleteCurriculaModalRef}
        size="sm"
        component={<DeleteCurriculaModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={editNonDerivedModalRef}
        size="md"
        component={<EditNonderivedModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={distributeMultipleTeamsModalRef}
        size="md"
        component={<DistributeMultipleTeamsModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </div>
  );
};

export default React.forwardRef(LeerplanListModals);
