import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CurriculumState = Readonly<{
  curriculumKey: string;
  curriculumLoading: boolean;
  isFailed: boolean;
  preview: boolean;
  selectionMode: boolean;
  selectionOptions: {
    allowSubitem: boolean;
    allowOptional: boolean;
    readonlyMode: boolean;
  };
  selectedItems: Array<{ href: string }> | null;
  baseGoalOccurence: Record<string, number> | null;
  custids: string[];
  studids: string[];
  groupid: string | null;
  setid: string | null;
}>;

function init(): CurriculumState {
  return {
    curriculumKey: '',
    curriculumLoading: false,
    isFailed: false,
    preview: false,
    selectionMode: false,
    selectionOptions: {
      allowSubitem: false,
      allowOptional: false,
      readonlyMode: false,
    },
    selectedItems: null,
    baseGoalOccurence: null,
    custids: [],
    studids: [],
    groupid: null,
    setid: null,
  };
}

const initialState = init();

const curriculumState = createSlice({
  name: 'curriculum',
  initialState,
  reducers: {
    setUrlParams: (
      state,
      action: PayloadAction<
        | {
            id: string;
            curriculumKey?: never;
            custid: string | null;
            custids?: never;
            studid: string | null;
            studids?: never;
            groupid: string | null;
            setid: string | null;
            preview: boolean;
          }
        | {
            curriculumKey: string;
            id?: never;
            custid?: never;
            custids: string[] | null;
            studid?: never;
            studids: string[] | null;
            groupid: string | null;
            setid: string | null;
            preview: boolean;
          }
      >
    ) => {
      const { id, curriculumKey, custid, custids, studid, studids, groupid, setid, preview } =
        action.payload;
      state.curriculumKey = id || curriculumKey || '';
      state.custids = custids || custid?.split(',') || [];
      state.studids = studids || studid?.split(',') || [];
      state.groupid = groupid;
      state.setid = setid;
      state.preview = preview;
    },
    setSelectionMode: (state, action) => {
      state.selectionMode = !!action.payload;
      if (action.payload === false) state.selectedItems = null;
    },
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
    toggleSelectedItem: (state, action) => {
      const { item } = action.payload;
      const itemHref = typeof item === 'string' ? item : item.href;
      const selectedItems = state.selectedItems || [];
      if (selectedItems?.some((g) => g.href === itemHref)) {
        state.selectedItems = selectedItems.filter((i) => i.href !== itemHref);
      } else {
        state.selectedItems = [...selectedItems, item];
      }
    },
    setSelectionOptions: (state, action) => {
      state.selectionOptions = { ...state.selectionOptions, ...action.payload };
    },
    setBaseGoalOccurence: (
      state,
      action: PayloadAction<{ goalOccurence: Record<string, number> | null }>
    ) => {
      const { goalOccurence } = action.payload;
      state.baseGoalOccurence = goalOccurence;
    },
    loadGoalsForCurriculumParams: () => {},
    removeCustomSection: () => {},
  },
});

export const curriculumActions = curriculumState.actions;
export const {
  setUrlParams,
  setSelectedItems,
  setSelectionOptions,
  toggleSelectedItem,
  setBaseGoalOccurence,
  loadGoalsForCurriculumParams,
  removeCustomSection,
} = curriculumState.actions;
export default curriculumState;
