import './schoolsCard.scss';
import { Card, CardContent, CircularProgress } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const addressUtils = require('@kathondvla/sri-client/address-utils');

const SchoolsCard = ({ title, selected, loading, addresses, onClick = () => {} }) => {
  const printAddress = (address) => {
    if (!address) return '';
    return addressUtils.printAddress(address);
  };

  return (
    <Card className={`schools-card ${selected ? 'selected' : ''} ${loading ? 'disabled' : ''}`}>
      <CardContent onClick={onClick}>
        <div>
          {title && <div className="title">{title}</div>}
          <div>
            {addresses.map((address) => (
              <div className="address" key={address.key}>
                {printAddress(address.address)}
              </div>
            ))}
          </div>
        </div>
        <div className="schools-card-loader">{loading && <CircularProgress />}</div>
      </CardContent>
    </Card>
  );
};

export default SchoolsCard;
