import Attachment from '@UI/attachment/Attachment';

const AttachmentsPopover = ({ attachments = [] }) => {
  const title = 'Bijlage(n) & link(en)';

  return (
    <div className="attachments-popover">
      <div className="popover-title">{title}</div>
      {attachments
        .filter((att) => !att.deleted)
        .map((att) => (
          <Attachment key={att.href} item={att.$$expanded} />
        ))}
    </div>
  );
};

export default AttachmentsPopover;
