import Week from '../week/Week';
import CalendarPrint from './CalendarPrint';

const CalendarPrintView = ({ view, model }) => {
  if (!model || !view) return null;

  if (view === 'regular') {
    return (
      <main className="calendar print-view">
        {model.weeks.map((week) => (
          <section key={week.id} className="week-container">
            <Week week={week} display={model.settings.weekTitle}></Week>
          </section>
        ))}
      </main>
    );
  }

  if (view === 'other') {
    return (
      <div className="print-view other-view">
        <CalendarPrint calendars={model}></CalendarPrint>
      </div>
    );
  }

  return null;
};

export default CalendarPrintView;
