import { useEffect, useRef, useState } from 'react';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import { CurriculaOptionItem, FormGroup, FormResourcePicker } from '@UI/index';
import ActivityGoalSelector from '@UI/activityGoalSelector/ActivityGoalSelector';

import { FormProvider, useForm } from 'react-hook-form';
import { uniqBy } from 'lodash-es';

import { useDispatch, useSelector } from 'react-redux';
import { mapCurriculaToParams } from '@store/leerplanList/leerplanListHelpers';

import './ReferenceGoalModal.scss';
import { selectReferenceGoalCurricula } from '@store/goalsTab/goalsTabSelectors';
import {
  selectGoalsFromCurriculum,
  selectIsCurriculumLoaded,
} from '@store/curriculum/curriculumSelectors';
import { getParamsFromCurriculum } from '@utils/utils';
import { curriculumActions, loadGoalsForCurriculumParams } from '@store/curriculum/curriculumState';
import { Button } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import iconAddWhite from '@assets/img/add_white.svg';
import GoalSelectorModal from '../goalSelectorModal/GoalSelectorModal';

const ReferenceGoalModal = ({ parent, action, onClose }) => {
  const dispatch = useDispatch();
  const { control, setValue } = useForm({ mode: 'onChange' });
  const referenceGoalCurricula = useSelector(selectReferenceGoalCurricula);
  const [curriculaOptions, setCurriculaOptions] = useState([]);
  const [selectedCurriculum, setSelectedCurriculum] = useState(null);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const goalsListRef = useRef();
  const goalSelectorModalRef = useRef();
  const params = selectedCurriculum ? getParamsFromCurriculum(selectedCurriculum) : null;
  const goalsList = useSelector((state) =>
    params ? selectGoalsFromCurriculum(state, params) : null
  );

  goalsListRef.current = goalsList; // the saveSelectedGoals callback we give to the angularjs keeps a ref to the old goalsList, so we need to send it a ref that keeps up to date.

  const isCurriculumLoaded = useSelector((state) =>
    params ? selectIsCurriculumLoaded(state, params) : false
  );

  useEffect(() => {
    setCurriculaOptions(referenceGoalCurricula);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCurriculumChange = (curriculum) => {
    setSelectedCurriculum(curriculum);
  };

  useEffect(() => {
    setErrorMessage(null);
    if (selectedCurriculum) {
      dispatch(loadGoalsForCurriculumParams(getParamsFromCurriculum(selectedCurriculum)));
    }
  }, [selectedCurriculum, dispatch]);

  useEffect(() => {
    if (isCurriculumLoaded) {
      if (!goalsList || goalsList.length === 0) {
        setErrorMessage('Dit leerplan heeft geen doelen');
      } else {
        setErrorMessage(null);
      }
    }
  }, [goalsList, isCurriculumLoaded]);

  const handleCloseModal = () => {
    onClose();
  };
  const handleAccept = () => {
    onClose({ goals: selectedGoals, parent });
  };

  const changeGoals = (goals) => {
    setSelectedGoals(goals);
  };

  const saveSelectedGoals = (selection) => {
    // we can swap between curricula, so to find all the goals, we merge both arrays
    const goals = uniqBy(
      [...selectedGoals, ...(goalsListRef.current || [])].filter((g) => selection.includes(g.href)),
      'key'
    );
    setSelectedGoals(goals);
  };

  const selectLeerplan = (cur) => {
    const [curParams] = mapCurriculaToParams([cur]);

    dispatch(curriculumActions.setSelectedItems(selectedGoals.map((z) => ({ href: z.href }))));

    return goalSelectorModalRef.current.open({
      params: curParams,
      onClose: () => {},
      onConfirm: (selection) => {
        saveSelectedGoals(selection);
      },
    });
  };

  return (
    <div className="options-modal goal-select-modal">
      <Modal.Header
        title="Leerplandoel(en) kopiëren"
        subtitle="Selecteer het leerplan en het doel dat je wil toevoegen of overnemen."
        icon={iconAddWhite}
        onClose={handleCloseModal}
      />
      <Modal.Body>
        <FormProvider control={control} setValue={setValue}>
          <FormGroup label="Bron leerplan(nen)" required>
            <FormResourcePicker
              name="curriculum"
              placeholder="naam leerplan"
              optionValue={(option) => option.key}
              localData={curriculaOptions}
              optionTemplate={(option) => <CurriculaOptionItem option={option} />}
              multiple={false}
              control={control}
              onChange={onCurriculumChange}
            />
          </FormGroup>
          <div className="error-msg">{errorMessage}</div>
          <FormGroup label="Leerplandoelen" required>
            <ActivityGoalSelector
              goals={selectedGoals}
              curricula={selectedCurriculum && [selectedCurriculum]}
              options={goalsList?.filter((z) => !selectedGoals.map((g) => g.key).includes(z.key))}
              onChange={changeGoals}
              onLeerplanSelection={(cur) => selectLeerplan(cur)}
            />
          </FormGroup>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={handleCloseModal}>
          Annuleren
        </Button>
        {action === 'reference' && (
          <Button
            variant="contained"
            startIcon={<AddBoxIcon />}
            image={iconAddWhite}
            onClick={handleAccept}
          >
            Kopiëren
          </Button>
        )}
        {action === 'move' && (
          <Button variant="contained" startIcon={<ContentCutIcon />} onClick={handleAccept}>
            Verplaatsen
          </Button>
        )}
      </Modal.Footer>

      <Modal
        ref={goalSelectorModalRef}
        component={<GoalSelectorModal />}
        errorBoundaryComponent={ModalErrorBoundary}
        size="xl"
      />
    </div>
  );
};

export default ReferenceGoalModal;
