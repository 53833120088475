import DemarcatonIcon from '@assets/img/icon_demarcation_high.svg';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import {
  KovContentBlock,
  KovContentBlockProvider,
} from '@kathondvla/react-shared-components/src/components/contentBlock';
import Divider from '@UI/divider/Divider';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import { HoveredPopover } from '@UI/index';
import TermModal from '@UI/termModal/TermModal';
import { useRef } from 'react';
import settings from '@config/settings';

import './goalDemarcation.scss';

const GoalDemarcation = ({ demarcation, className, mandatory }) => {
  const termModalRef = useRef();

  const compilerOptions = {
    term: {
      compile: true,
      onClick: (key) => {
        const allTerms = demarcation.items
          .map((z) => z.terms)
          .flat()
          .filter((z) => z);
        const term = allTerms.find((t) => t.key === key);
        if (term) {
          termModalRef.current?.open({ term });
        }
      },
    },
    markedText: {
      compile: true,
      modes: settings.hardcodings.markExplanation,
      onHover: (key, text, setter) => {
        const allMarkExplanations = demarcation.items
          .map((z) => z.markExplanations)
          .flat()
          .filter((z) => z);
        const explanation = allMarkExplanations.find((mark) => mark.key === key);
        if (explanation) setter(explanation.description);
      },
    },
  };

  return (
    <div className={`goal-demarcation ${className}`}>
      <div className="demarcation-left">
        <HoveredPopover content="Afbakening">
          <img className="icon-demarcation" src={DemarcatonIcon} />
        </HoveredPopover>
        <Divider mandatory={mandatory} />
      </div>
      <div className="demarcation-content">
        <KovContentBlockProvider
          baseUrl={settings.apisAndUrls.api}
          compilerOptions={compilerOptions}
        >
          <KovContentBlock key={demarcation.key} item={demarcation} showCollapsed={false} />
        </KovContentBlockProvider>
      </div>
      <Modal
        ref={termModalRef}
        size="sm"
        component={<TermModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </div>
  );
};

export default GoalDemarcation;
