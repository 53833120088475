import { KovResourcePicker } from '@kathondvla/react-shared-components/src';
import { useEffect, useState } from 'react';
import settings from '@config/settings';
import {
  getAllPersonsForTest,
  getPersonForDemoOrTest,
  getUsernameForDemoOrTest,
} from '../../../../mocks/api/persons/helpers';
import TestUserOption from '../selectorOption/testUserOption';

import './testUserSelector.scss';

const anonymousTestUser = {
  firstName: 'Niet aangemeld',
  lastName: '',
  username: null,
};

const TestUserSelector = () => {
  const [selectedPerson, setSelectedPerson] = useState(null);
  const persons = getAllPersonsForTest(settings.enableDemoMode);
  if (!settings.enableDemoMode) persons.unshift(anonymousTestUser);

  const changeUser = (item) => {
    setSelectedPerson(item);
    const itemName = settings.enableDemoMode ? 'usernameForDemo' : 'usernameForTest';
    localStorage.setItem(itemName, item.username);
    window.location.href = '/home/leerplan';
    window.location.reload();
  };

  useEffect(() => {
    const username = getUsernameForDemoOrTest();
    const person =
      username && username !== 'null' ? getPersonForDemoOrTest(username) : anonymousTestUser;
    setSelectedPerson(person);
  }, []);

  return (
    <>
      <div className="selector-label">Kies je rol:</div>
      <div data-testid="test-user-selector" className="selector picker test-user">
        <KovResourcePicker
          localData={persons}
          value={selectedPerson}
          display={() => selectedPerson?.firstName}
          optionTemplate={(option) => <TestUserOption option={option} />}
          multiple={false}
          clearable={false}
          disableSearch={true}
          onChange={changeUser}
          style={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
        />
      </div>
    </>
  );
};

export default TestUserSelector;
