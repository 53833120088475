export const generalSaveError = {
  key: 'general-save-error',
  msg: `Er is een probleem opgetreden bij het bewaren.`,
  title: 'Fout',
  type: 'error',
  showClose: true,
  delay: 10000,
};

export const generalLoadError = {
  key: 'general-load-error',
  msg: `Er is een probleem opgetreden bij het laden.`,
  title: 'Fout',
  type: 'error',
  showClose: true,
  delay: 10000,
};

export const contentApiLoadError = {
  key: 'content-api-fetch-error',
  msg: `Er is een probleem opgetreden bij het ophalen van leerplannen. Probeer later opnieuw.`,
  title: 'Netwerk probleem',
  type: 'error',
  showClose: true,
  delay: -1,
};
