import { HoveredPopover } from '@UI/index';

import './goalLink.scss';
import { useNavigate } from 'react-router-dom';

const GoalLink = ({ links, partial, descriptionPreface = '' }) => {
  const navigate = useNavigate();
  if (!links || links.length === 0) return null;

  const onClickHandler = (link) => {
    const params = new URLSearchParams({
      anchor: link.identifier,
    });

    const url = `/leerplan/${link.rootKey}/doelenlijst?${params.toString()}`;
    navigate(url);
  };

  return (
    <div data-testid="goal-link" className="goal-link">
      <span className="arrow-icon">🡒 </span>
      <span className="link-names">
        {partial && <span>(partim)</span>}
        {links.map((link) => (
          <HoveredPopover
            key={link.key}
            content={descriptionPreface + (link.description ?? link.goal.description)}
            compile={true}
          >
            <span onClick={() => onClickHandler(link)}>{link.identifier || link.identifiers}</span>
          </HoveredPopover>
        ))}
      </span>
    </div>
  );
};

export default GoalLink;
