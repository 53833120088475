import settings from '@config/settings';

import './watermark.scss';

const Watermark = () => {
  return (
    settings.enableDemoMode && (
      <div data-testid="watermark" className="demo-watermark">
        DEMO
      </div>
    )
  );
};

export default Watermark;
