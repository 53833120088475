import { useMemo } from 'react';

import './leerplanList.scss';

const LeerplanListLoader = () => {
  const itemsBuilder = useMemo(() => {
    const items = [];
    for (let i = 0; i < 40; i += 1) {
      items.push(
        <div key={i} data-testid="leerplanlist-loading-bar">
          <div className="ghost-icon"></div>
          <div className="ghost-item"></div>
        </div>
      );
    }
    return items;
  }, []);

  return <div className="leerplan-list-loader">{itemsBuilder}</div>;
};

export default LeerplanListLoader;
