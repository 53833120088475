import { useMemo, useState } from 'react';

import iconMore from '@assets/img/icon_more.png';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { copyCalendarOpen } from '@store/calendarList/calendarListState';
import { selectCurrentSchool } from '@store/userAndSchool/userAndSchoolSelectors';
import { useDispatch, useSelector } from 'react-redux';
import './calendarListItem.scss';

const CalendarListItemActions = ({ item, onEdit, onDelete, onUndelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const school = useSelector(selectCurrentSchool);
  const dispatch = useDispatch();

  const actionsList = useMemo(() => {
    return [
      {
        name: 'Bewerken',
        image: <EditIcon color="primary" />,
        isVisible: (itemToCheck) => {
          return !itemToCheck.readOnly && !itemToCheck.deleted;
        },
        onClick: () => onEdit?.(school, item),
      },
      {
        name: 'Kopiëren (naar)...',
        image: <ContentCopyIcon color="primary" />,
        isVisible: (itemToCheck) => {
          return !itemToCheck.deleted;
        },
        onClick: () => dispatch(copyCalendarOpen({ item })),
      },
      {
        name: 'Verwijderen',
        image: <DeleteIcon color="primary" />,
        isVisible: (itemToCheck) => {
          return !itemToCheck.readOnly && !itemToCheck.deleted;
        },
        onClick: () => onDelete?.(item),
      },
      {
        name: 'Herstellen',
        image: <RestoreFromTrashIcon color="primary" />,
        isVisible: (itemToCheck) => {
          return !itemToCheck.readOnly && itemToCheck.deleted;
        },
        onClick: () => onUndelete?.(item),
      },
    ];
  }, [dispatch, item, onDelete, onEdit, onUndelete, school]);

  const actionsToShow = useMemo(
    () => actionsList.filter((x) => x.isVisible(item)),
    [actionsList, item]
  );

  return (
    <div className="calendar-list-item-actions dropdown">
      <IconButton
        data-testid="calendar-item-dropdown-toggle"
        className="dropdown-toggle"
        aria-describedby="popper"
        onClick={handleClick}
      >
        <img className="more-icon" src={iconMore} />
      </IconButton>

      <Popper
        id="popper"
        sx={{
          zIndex: 1200,
        }}
        open={open}
        placement="bottom-end"
        transition
        anchorEl={anchorEl}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {actionsToShow.map((option) => (
                    <MenuItem
                      data-testid="calendar-item-dropdown-item"
                      key={option.name}
                      className={option.className}
                      onClick={option.onClick}
                    >
                      <ListItemIcon>{option.image}</ListItemIcon>
                      <ListItemText sx={{ color: 'primary.main' }}>{option.name}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default CalendarListItemActions;
