import { rest } from 'msw';
import demo from '@config/demo';
import { filterResourcesActiveOnDate } from '@utils/filterResourcesActiveOnDate';
import apis from '@config/apis';
import { fetchJsonPath } from '../../helpers/fetchJson';

import {
  getAllPersonsForTest,
  replaceDemoSchoolKeys,
  getDemoSchool1Href,
  getDemoSchool2Href,
  getEndDateForResponsibilities,
  setEndDateForResults,
} from './helpers';
import {
  allTeamHrefs,
  schoolHref,
  teacheroneTestpersonHref,
} from '../../../../test/mock-data/state.constants';

const fetchJson = fetchJsonPath('api/persons');

const responsibilitesAllTeamsPath = 'responsibilities_test_school_all_teams.json';
const responsibilitesSchoolPath = 'responsibilities_test_school.json';
// TODO: add resps etc for teacherthree who has an enddate.

const responsibilitiesTeamsSchool1Path = 'responsibilities_school_1_all_teams.json';
const responsibilitiesTeamsSchool2Path = 'responsibilities_school_2_all_teams.json';
const responsibilitiesSchool1Path = 'responsibilities_school_1.json';
const responsibilitiesSchool2Path = 'responsibilities_school_2.json';

const replacePersonOneHref = (obj, newHref) => {
  return JSON.parse(JSON.stringify(obj).replaceAll(teacheroneTestpersonHref, newHref));
};

export const personsApiHandlers = [
  rest.get(`${apis.api}/persons/:key`, (req, res, ctx) => {
    const person = getAllPersonsForTest(demo.enableDemoMode).find((p) => p.key === req.params.key);
    if (!person) {
      return res(ctx.status(404));
    }
    return res(ctx.json(person), ctx.delay(150));
  }),
  rest.get(`${apis.api}/responsibilities`, async (req, res, ctx) => {
    if (req.url.searchParams.get('persons')) {
      const person = getAllPersonsForTest(demo.enableDemoMode).find(
        (p) => p.$$meta.permalink === req.url.searchParams.get('persons')
      );

      const fileName = `responsibilities_${person.username}.json`;
      let result = await fetchJson(ctx, fileName);

      if (globalThis.mergedTeacherOneHref) {
        result = replacePersonOneHref(result, globalThis.mergedTeacherOneHref);
      }

      if (getEndDateForResponsibilities()) {
        result.results = setEndDateForResults(result.results, getEndDateForResponsibilities());
      }

      return res(ctx.json(replaceDemoSchoolKeys(result)), ctx.delay(150));
    }

    /**
     * below is for TESTS
     */
    if (req.url.searchParams.get('organisations')) {
      const hrefsInParam = req.url.searchParams.get('organisations').split(',');
      if (allTeamHrefs.some((z) => hrefsInParam.includes(z))) {
        // the responsibilitesAllTeamsPath contains ALL resps for ALL teams.
        let responseObj = await fetchJson(ctx, responsibilitesAllTeamsPath);

        if (globalThis.mergedTeacherOneHref) {
          responseObj = replacePersonOneHref(responseObj, globalThis.mergedTeacherOneHref);
        }

        // filter only the ones the API would return.
        let results = responseObj.results.filter((r) =>
          hrefsInParam.includes(r.$$expanded.organisation.href)
        );

        if (getEndDateForResponsibilities()) {
          results = setEndDateForResults(results, getEndDateForResponsibilities());
        }

        if (req.url.searchParams.get('date')) {
          results = filterResourcesActiveOnDate(results, req.url.searchParams.get('date'));
        }

        responseObj = {
          ...responseObj,
          $$meta: {
            ...responseObj.$$meta,
            count: results.length,
          },
          results,
        };

        return res(ctx.json(responseObj), ctx.delay(150));
      }
    }

    if (req.url.searchParams.get('organisations') === schoolHref) {
      let responsibilitiesSchool = await fetchJson(ctx, responsibilitesSchoolPath);
      if (globalThis.mergedTeacherOneHref) {
        responsibilitiesSchool = replacePersonOneHref(
          responsibilitiesSchool,
          globalThis.mergedTeacherOneHref
        );

        const relationsWithPerson = responsibilitiesSchool.results.filter(
          (r) => r.$$expanded.person.href === globalThis.mergedTeacherOneHref
        );

        // add the mergedPersons
        relationsWithPerson.forEach((r) => {
          r.$$expanded.person.$$expanded.mergedPersons = [teacheroneTestpersonHref];
        });
      }

      // filter only the ones the API would return.
      let { results } = responsibilitiesSchool;

      if (getEndDateForResponsibilities()) {
        results = setEndDateForResults(results, getEndDateForResponsibilities());
      }

      if (req.url.searchParams.get('date')) {
        results = filterResourcesActiveOnDate(results, req.url.searchParams.get('date'));
      }

      const responseObj = {
        ...responsibilitiesSchool,
        $$meta: {
          ...responsibilitiesSchool.$$meta,
          count: results.length,
        },
        results,
      };

      return res(ctx.json(responseObj), ctx.delay(150));
    }

    /** for DEMO we just take all orgs, and filter them manually from the json */
    if (req.url.searchParams.get('organisations') && demo.enableDemoMode) {
      const orgHrefs = req.url.searchParams.get('organisations').split(',');

      if (orgHrefs.length === 1) {
        if (orgHrefs[0] === getDemoSchool1Href()) {
          const responsibilitiesSchool1 = await fetchJson(ctx, responsibilitiesSchool1Path);
          const school1Response = replaceDemoSchoolKeys(responsibilitiesSchool1);
          return res(ctx.json(school1Response), ctx.delay(150));
        }
        if (orgHrefs[0] === getDemoSchool2Href()) {
          const responsibilitiesSchool2 = await fetchJson(ctx, responsibilitiesSchool2Path);
          const school2Response = replaceDemoSchoolKeys(responsibilitiesSchool2);
          return res(ctx.json(school2Response), ctx.delay(150));
        }
      }

      const responsibilitiesTeamsSchool1 = await fetchJson(ctx, responsibilitiesTeamsSchool1Path);

      const school1Response = replaceDemoSchoolKeys(responsibilitiesTeamsSchool1);
      const school1results = school1Response.results.filter((r) =>
        orgHrefs.includes(r.$$expanded.organisation.href)
      );

      if (school1results.length > 0) {
        return res(
          ctx.json({
            ...school1Response,
            results: school1results,
            $$meta: { ...school1Response.$$meta, count: school1results.length },
          }),
          ctx.delay(150)
        );
      }

      const responsibilitiesTeamsSchool2 = await fetchJson(ctx, responsibilitiesTeamsSchool2Path);
      const school2Response = replaceDemoSchoolKeys(responsibilitiesTeamsSchool2);
      const school2results = school2Response.results.filter((r) =>
        orgHrefs.includes(r.$$expanded.organisation.href)
      );

      /** return school2 result or empty list if none are found. */
      return res(
        ctx.json({
          ...school2Response,
          results: school2results,
          $$meta: { ...school2Response.$$meta, count: school2results.length },
        }),
        ctx.delay(150)
      );
    }

    return req.passthrough();
  }),
];
