import TextField from '@UI/textField/TextField';
import Checkbox from '@UI/checkbox/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import './inputList.scss';

const InputList = ({ list, editable = true, onAdd = () => {}, onChange = () => {} }) => {
  const onChangeHandler = (item, index, value) => {
    onChange({ ...item, title: value }, index);
  };

  const onSelectHandler = (item, index) => {
    onChange({ ...item, includeInCurriculum: !item.includeInCurriculum }, index);
  };

  return (
    <div className="input-list">
      {list?.map((item, index) => (
        <div className="input-item" key={index}>
          <TextField
            placeholder="Vul hier je prioriteit in.."
            value={item.title ?? ''}
            disabled={!editable}
            onChange={(value) => onChangeHandler(item, index, value)}
          />
          <Checkbox
            checked={item.includeInCurriculum}
            disabled={!editable || !item.title || item.title === ''}
            onChange={() => onSelectHandler(item, index)}
          />
        </div>
      ))}
      <div className="add-item" onClick={onAdd}>
        <AddIcon />
        <span> Kernpunt toevoegen </span>
      </div>
    </div>
  );
};

export default InputList;
