import { Box, Skeleton } from '@mui/material';
import { useState } from 'react';

const Section = () => {
  return (
    <div className="react-goal-section" style={{ paddingBottom: '1rem' }}>
      <Box
        className="header-container"
        sx={{
          display: 'flex',
          height: '50px',
          alignItems: 'center',
          marginBlock: '1rem',
        }}
      >
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{ width: 20, height: 20, marginLeft: 1, marginRight: 2 }}
        />
        <Skeleton animation="wave" variant="text" sx={{ width: 250, fontSize: '2rem' }} />
      </Box>
    </div>
  );
};

const Subgoal = () => {
  const [randomLines] = useState(Math.floor(Math.random() * 2) + 1);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', padding: '.8rem 6rem 0 7.2rem' }}>
      <Skeleton animation="wave" variant="circular" width={27} height={27} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          borderLeft: '2px solid lightgray',
          padding: '0 .8rem',
          margin: '0 .8rem',
          minHeight: '40px',
        }}
      >
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ width: 40, height: '1.3rem', flexGrow: 1 }}
        />
        {Array.from({ length: randomLines }).map((_, index) => (
          <div key={index}>
            {
              <Skeleton
                animation="wave"
                variant="text"
                sx={{ width: '60%', height: '1.3rem', flexGrow: 1 }}
              />
            }
          </div>
        ))}
      </Box>
    </Box>
  );
};

const Goal = () => {
  const [randomSubGoals] = useState(Math.floor(Math.random() * 4) + 1);
  const [randomWidth] = useState(Math.random() * (0.6 - 0.3) + 0.3);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0.2rem 0.8rem',
        margin: '1rem',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ width: 90, height: '2.5rem', alignSelf: 'start' }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: '50px',
            backgroundColor: '#f0f0f0',
            alignItems: 'flex-start',
            marginLeft: 1,
            borderLeft: '6px solid gray',
            marginRight: 10,
          }}
        >
          <Skeleton
            animation="wave"
            variant="text"
            sx={{
              width: randomWidth,
              height: '1.5rem',
              margin: '.5rem 1rem',
            }}
          />
        </Box>
      </Box>

      {Array.from({ length: randomSubGoals }).map((_, index) => (
        <div key={index}>
          <Subgoal />
        </div>
      ))}
    </Box>
  );
};

const GoalListSkeleton = () => {
  const [randomGoals] = useState(Math.floor(Math.random() * 5) + 3);

  return (
    <>
      <Section />
      {Array.from({ length: randomGoals }).map((_, index) => (
        <div key={index}>
          <Goal />
        </div>
      ))}
      <Section />
      {Array.from({ length: randomGoals }).map((_, index) => (
        <div key={index}>
          <Goal />
        </div>
      ))}
    </>
  );
};
export default GoalListSkeleton;
