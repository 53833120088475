import { selectIsDistributionMode } from '@store/distribute/distributeSelectors';
import { selectFullAnnotatedCurriculumViewModel } from '@store/curriculum/curriculumSelectors';

export const selectShouldStudyProgrammeAlertBeShown = (state) => {
  const isSelectionMode = state.curriculum.selectionMode;
  if (isSelectionMode) {
    return false;
  }

  const isDistributionMode = selectIsDistributionMode(state);
  const fullAnnotatedCurriculumVM = selectFullAnnotatedCurriculumViewModel(state);
  return (
    (state.curriculum.groupid && state.curriculum.setid) ||
    (isDistributionMode && fullAnnotatedCurriculumVM?.foundational)
  );
};
