import { last } from 'lodash-es';
import { apiConstants } from '../constants/apiConstants';
import { getNonDerivedGroupKey } from './utils';

export function filterByTitle(title) {
  return (e) => e.title === title;
}

export function filterByNonDerivedGroupKey(nonDerivedGroupKey, allCurricula) {
  return (e) => getNonDerivedGroupKey(allCurricula, e) === nonDerivedGroupKey;
}

export function filterBySchoolyear(schoolyear) {
  return (e) =>
    schoolyear.isoDates.startDate >= e.issued.startDate &&
    (!e.issued.endDate || e.issued.endDate > schoolyear.isoDates.startDate);
}

export function filterByIdentifier(identifier) {
  return (e) => e.identifier === identifier;
}

export function filterByCreator(creator) {
  return (e) => e.creator.href === creator;
}

export function filterByCustomCurriculaGroup(customCurriculaGroup) {
  return (e) =>
    e.customCurriculaGroup &&
    e.customCurriculaGroup.href ===
      apiConstants.customCurriculaGroups.endpoint + customCurriculaGroup;
}

export function filterByKeys(keys) {
  const keysSet = new Set(keys);
  return (e) => keysSet.has(e.key);
}

export function filterByPermalinks(hrefs) {
  const hrefsSet = new Set(hrefs);
  return (e) => hrefsSet.has(e.$$meta.permalink);
}

export function filterByApplicability(studyPrograms) {
  return (e) =>
    e.applicability &&
    e.applicability.studyProgrammes &&
    e.applicability.studyProgrammes.find((s) =>
      studyPrograms.map((parameter) => last(parameter.split('/'))).includes(last(s.href.split('/')))
    );
}

export function filterByOrgs(orgs) {
  const orgHrefs = new Set(orgs.map((o) => o.href));
  return (e) => orgHrefs.has(e.creator.href);
}

export function filterBySourceOrNonDerivedBasePlan(source) {
  return (e) => (e.source && e.source.href === source) || e.$$meta.permalink === source;
}

export function filterBySources(sources) {
  return (e) => e.source && sources.includes(e.source.href);
}

export function filterByCustId(custIds) {
  return (e) => custIds.includes(e?.key);
}

export function filterCustomCurriculaWithFilters(customcurriucla, filters) {
  let filteredCurricula = customcurriucla;
  filters.forEach((f) => {
    filteredCurricula = filteredCurricula.filter((e) => f(e));
  });
  return filteredCurricula;
}
