import { GoalSimplified } from '@UI/index';

import { useNavigate } from 'react-router-dom';

const GoalsPopover = ({ goals = [] }) => {
  const navigate = useNavigate();

  const toCurriculum = (goal) => {
    const key = goal.rootKey;
    const params = new URLSearchParams({
      anchor: goal.identifier,
    });
    const url = `/leerplan/${key}/doelenlijst?${params.toString()}`;
    navigate(url);
  };

  return (
    <div className="goals-popover">
      {goals.map((goal) => (
        <div key={goal.key} onClick={() => toCurriculum(goal)}>
          <GoalSimplified goal={goal} />
        </div>
      ))}
    </div>
  );
};

export default GoalsPopover;
