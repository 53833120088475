import { Modal } from '@kathondvla/react-shared-components/src/components';
import './errorBoundaries.scss';
import { Button } from '@mui/material';
import logoLlinkid from '@assets/img/llinkid-logo.svg';
import { Component } from 'react';

class ModalErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {}

  render() {
    if (!this.state.hasError) return this.props.children;

    return (
      <>
        <Modal.Header title={'Fout'} onClose={this.props.onClose} />
        <Modal.Body>
          <div className="home-error-boundary">
            <img src={logoLlinkid} alt="llinkid" />
            <p>Oeps, er is een fout opgetreden!</p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outlined" onClick={this.props.onClose}>
            Sluiten
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

export default ModalErrorBoundary;
