import commonUtils from '@kathondvla/sri-client/common-utils';
import { CUSTOMCURRICULATYPES, CUSTOMTYPES } from '@utils/curriculumHelper';
import { flatten, last } from 'lodash-es';
import { filterByApplicability } from '@utils/filters';
import { calculateReadOrder } from '@utils/utils';

export const getRecoverCalendarBatch = (plans) => {
  const batch = [];

  plans.forEach((plan) => {
    batch.push({
      verb: 'PUT',
      href: plan.$$meta.permalink,
      body: { ...plan, softDeleted: null },
    });
  });

  return batch;
};

export const getCreatePlanBatch = (model) => {
  const batch = [];

  const { teachers } = model;
  const { schoolyear } = model;
  const { school } = model;

  const activityGroup = {
    key: commonUtils.generateUUID(),
  };

  batch.push({
    verb: 'PUT',
    href: `/llinkid/activityplanning/activityplangroups/${activityGroup.key}`,
    body: activityGroup,
  });

  model.classes.forEach((cl) => {
    const key = commonUtils.generateUUID();
    const body = {
      $$meta: { permalink: `/llinkid/activityplanning/activityplans/${key}` },
      key,
      creators: teachers,
      observers: model.shared ?? null,
      issued: {
        startDate: schoolyear.startDate,
        endDate: schoolyear.endDate,
      },
      title: model.title,
      curricula: model.curriculum,
      class: {
        href: cl.$$meta.permalink,
      },
      context: {
        href: school.href,
      },
      activityplangroup: {
        href: `/llinkid/activityplanning/activityplangroups/${activityGroup.key}`,
      },
    };

    batch.push({
      verb: 'PUT',
      href: `/llinkid/activityplanning/activityplans/${body.key}`,
      body,
    });
  });

  return batch;
};

export const getActivityBatch = ({ key, parentHref, activity, period }) => {
  const body = {
    ...activity,
    $$meta: { permalink: `/llinkid/activityplanning/activityplans/activities/${key}` },
    key,
    parent: { href: parentHref },
    period: {
      startDate: period.startDate.toISOString(),
      endDate: period.endDate.toISOString(),
    },
  };

  return { verb: 'PUT', href: body.$$meta.permalink, body };
};

export const getAttachmentBatch = ({ activityKey, attachment }) => {
  const key = commonUtils.generateUUID();
  const href = `/llinkid/activityplanning/activityplans/activities/${activityKey}`;
  let data;

  if (attachment.contentType) {
    const name = attachment.href.split('/').pop();
    data = {
      attachment: {
        key,
        description: attachment.description,
        href: `${href}/attachments/${name}`,
      },
      resource: {
        href,
      },
    };

    if (attachment.href) {
      data.fileHref = attachment.href;
    } else {
      data.file = name;
    }
  } else {
    data = {
      attachment: {
        key,
        href: attachment.href,
        description: attachment.description,
      },
      resource: {
        href,
      },
    };
  }

  return data;
};

function createAnnotation(targetHref, custCurHref) {
  const key = commonUtils.generateUUID();
  return {
    $$meta: {
      permalink: `/llinkid/customcurriculum/customcurricula/annotations/${key}`,
    },
    key,
    target: { href: targetHref },
    curriculum: { href: custCurHref },
  };
}

export const excludeItem = (item, layeredAnnotationsObj) => {
  const batch = [];

  const exclusion = item.partialInclusion ? false : !item.excluded;

  for (const key of Object.keys(layeredAnnotationsObj)) {
    const custCurHref = `/llinkid/customcurriculum/customcurricula/${key}`;
    const currentLayer = last(layeredAnnotationsObj[key]);

    const currentLayerAnnot = currentLayer.annotations || [];

    let customAnnot = currentLayerAnnot.find(
      (ann) => ann.type === CUSTOMTYPES.inclusion && ann.target.href === item.$$meta.permalink
    );
    customAnnot = customAnnot ? { ...customAnnot } : null;

    const shouldSkip = customAnnot && customAnnot.type === CUSTOMTYPES.inclusion && !exclusion;

    if (!customAnnot && !exclusion) {
      // /if there is no item, and we're doing an inclusion...
      customAnnot = createAnnotation(item.$$meta.permalink, custCurHref);
    }

    if (!shouldSkip && customAnnot) {
      customAnnot.type = CUSTOMTYPES.inclusion;

      const bItem = { href: customAnnot.$$meta.permalink };

      if (!exclusion) {
        bItem.verb = 'PUT';
        bItem.body = customAnnot;
      } else {
        bItem.verb = 'DELETE';
      }

      batch.push(bItem);
    }
  }

  return batch;
};

function makeReposition(curriculaHref) {
  const key = commonUtils.generateUUID();
  return {
    key,
    $$meta: { permalink: `/llinkid/customcurriculum/customcurricula/annotations/${key}` },
    type: CUSTOMTYPES.reposition,
    curriculum: { href: curriculaHref },
  };
}

function setAfterAndReference(annotation, after, reference) {
  if (annotation) {
    if (reference) {
      annotation.reference = { href: reference };
    } else {
      annotation.reference = null;
    }
    if (after) {
      annotation.after = { href: after };
    } else {
      annotation.after = null;
    }

    if (annotation.annotation) {
      setAfterAndReference(annotation.annotation, after, reference); // /i hope this doesn't go recursive forever one day
    }
  }
}

function createMoveObject({
  draggedItem,
  parentHref,
  siblings,
  ownedLayerAnnotations,
  layeredAnnotations,
}) {
  const ownedAnnotation = ownedLayerAnnotations.annotations.filter(
    (e) =>
      draggedItem.target.href.includes(e.key) ||
      (e.annotation && e.annotation.$$meta.permalink === draggedItem.$$meta.permalink) ||
      draggedItem.$$meta.permalink === e.$$meta.permalink ||
      (e.type === CUSTOMTYPES.reposition && e.target.href === draggedItem.$$meta.permalink)
  ); // /find the annotation that's draged. either by its permalink or the permalink of the annotation being the same or it being the target of a reposition)

  let notOwnedAnnotation;
  let putAnnotation;
  let ownerLayer;

  if (ownedAnnotation.length > 0) {
    console.log(`${ownedAnnotation.length} annotation(s) found`); // it is possible we find a REPOSITION and a REFERENCE if the lower-layer distribution happened later on. in this case we want to edit the REFERENCE.
    console.log(ownedAnnotation);
    // we own this annotation, so we can edit it

    // /in case we have a REPOSITION AND a REFRENCE in this layer, try to get the REFERENCE. If that doesnt work, just get the first.
    putAnnotation = ownedAnnotation.find((e) => e.type !== CUSTOMTYPES.reposition);
    if (!putAnnotation) {
      [putAnnotation] = ownedAnnotation;
    }
    putAnnotation = { ...putAnnotation };
  } else {
    console.log('annotation not owned');
    layeredAnnotations.some((layer) => {
      // /SOME is just a foreach that stops once you return a true.
      notOwnedAnnotation = layer.annotations.find(
        (e) =>
          (e.annotation && e.annotation.$$meta.permalink === draggedItem.$$meta.permalink) ||
          draggedItem.$$meta.permalink === e.$$meta.permalink ||
          (e.type === CUSTOMTYPES.reposition && e.target.href === draggedItem.$$meta.permalink)
      );
      ownerLayer = layer;
      return !!notOwnedAnnotation;
    });

    if (ownerLayer.priority < ownedLayerAnnotations.priority) {
      // /this is just an IF that checks if the owner of the item you dragged is ABOVE you in the tree. this will be obsolete in the future, but it's needed for now since we don't actually limit what people see yet.
      // you can only make a REPOSITION of an item that's owned by someone above you. (in the future you won't see things from ppl below you)
      console.warn('createMoveObject: should be an obsolete case.');
      putAnnotation = makeReposition(ownedLayerAnnotations.$$meta.permalink);
      putAnnotation.target = {
        href: notOwnedAnnotation.annotation
          ? notOwnedAnnotation.annotation.$$meta.permalink
          : notOwnedAnnotation.$$meta.permalink,
      };
    }
  }

  setAfterAndReference(putAnnotation, null, parentHref);

  if (ownedAnnotation) {
    // /in case a goal was a reference, the reference is replaced with the goal, and the original annotation is placed in .annotation
    putAnnotation = putAnnotation.annotation || putAnnotation;
  }

  putAnnotation.readOrder = siblings.readOrder; // we're just setting this the same for all custom curricula. is this enough? will this become trouble? maybe yes, maybe not. we're keeping it simple for now.

  return putAnnotation;
}

export function moveItemHelper({ draggedItem, parent, siblings, layeredAnnotationsObj }) {
  // studyProgrammes, baseCurricula
  // /figure out which annotation brought the goal here
  // /figure out wether or not we're in the same layer
  // /edit the annotation's positioning, or make a new annotation with type REPOSITION
  // /don't forget the item could be a custom goal
  // /find item that had an after of the moved item and fix their after (trailingAnnotation)
  // /find item that have an after equal to the new after of the moved item, and change their after to the new item (newTrailingAnnotation)

  console.log('parent', parent);
  console.log('siblings', siblings);

  if (parent.href === undefined) {
    return null;
  }

  const batch = [];

  for (const key of Object.keys(layeredAnnotationsObj)) {
    const layeredAnnotations = layeredAnnotationsObj[key];
    const allAnnotations = flatten(layeredAnnotations.map((e) => e.annotations));
    // /let's find the original goal, which has its originating .annoation (type reference) to edit. It also might not exist at all, in which case we need to create it.
    const originalAnnotation = allAnnotations
      .slice()
      .reverse()
      .find((e) => e.target.href === draggedItem.href && e.type === CUSTOMTYPES.goalReference); // added .slice().reverse() to find the lowest level goal in case of double distribution.

    if (originalAnnotation) {
      const annotation = originalAnnotation.annotation || originalAnnotation;
      /** Will this always be the lower layer? right? Is the find needed? todo * */
      const ownedLayerAnnotations = layeredAnnotations.find((custcur) => custcur.key === key);
      let parentHref;

      if (parent.annotation) {
        // this happens when you drag an item under a customItem (section)
        // in this case, the real parent is the annotation. so we need to find the correct annotation for each studyprogramme (= what we are looping in the for loop above)

        const parentAnnotation = allAnnotations.find(
          (e) => e.target.href === parent.href && e.type === CUSTOMTYPES.goalReference
        );
        parentHref = parentAnnotation.$$meta.permalink;

        if (!parentHref) {
          console.warn(`The local annotation for parent ${parent.href} can not be found.`);
          return null;
        }
      } else {
        parentHref = parent.href;
      }

      const readOrder = calculateReadOrder(siblings.above, siblings.below);
      console.log('readOrder:', readOrder);
      siblings.readOrder = readOrder;

      console.debug(`parent:${parentHref}`);
      console.debug(`readOrder:${readOrder}`);

      // /do this last so we can fix the other stuff first before changing the draggedItem object.
      const putAnnotation = createMoveObject({
        draggedItem: annotation,
        parentHref,
        siblings,
        ownedLayerAnnotations,
        layeredAnnotations,
      });
      batch.push({
        verb: 'PUT',
        href: putAnnotation.$$meta.permalink,
        body: putAnnotation,
      });
    }
  }

  return batch;
}

export function createCustomCurriculaGroup(title) {
  const key = commonUtils.generateUUID();
  const group = {
    $$meta: {
      permalink: `/llinkid/customcurriculum/customcurriculagroups/${key}`,
    },
    key,
    title,
  };
  return group;
}

export function generateCustomCurricula(
  roothref,
  type,
  studyPrograms,
  creator,
  context,
  schoolyear,
  versionNumber
) {
  const title = '';
  const key = commonUtils.generateUUID();

  return {
    key,
    title,
    type,
    creator: {
      href: creator,
    },
    context: {
      href: context,
    },
    issued: {
      startDate: schoolyear.startDate.toISOString(),
    },
    source: roothref ? { href: roothref } : null,
    $$meta: { permalink: `/llinkid/customcurriculum/customcurricula/${key}` },
    applicability: {
      studyProgrammes: studyPrograms.map((e) => ({ href: e })),
    },
    version: versionNumber,
    $$version: versionNumber
      ? `${versionNumber.major}.${versionNumber.minor}.${versionNumber.patch}`
      : null,
  };
}

function getVersionForCustomCur(customcur) {
  return customcur.issued.startDate + customcur.issued.endDate;
}

function getNonDerivedGroupKeyDeprecated(item, allCustomCurricula) {
  if (item) {
    if (item.type === CUSTOMCURRICULATYPES.adaptation && !item.source.href.startsWith('/content')) {
      const parentCurr = allCustomCurricula[item.source.href];
      return getNonDerivedGroupKeyDeprecated(parentCurr);
    }

    const version = getVersionForCustomCur(item);
    return `${item.identifiers || item.identifier}~#~${item.title}~#~${version}`;
  }
  return undefined;
}

function getDerivedGroupKey(cc) {
  if (cc.source) {
    const version = getVersionForCustomCur(cc);
    return cc.source.href + version;
  }
  return null;
}

export function groupCurricula(studyProgrammeGroups, allCustomCurricula, newBatch = []) {
  console.time('groupCurricula');
  // console.time('groupCurriculaGetCCs');

  const newBatchCCs = newBatch.filter(
    (e) =>
      e.href.startsWith('/llinkid/customcurriculum/customcurricula/') &&
      !e.href.includes('annotation')
  );
  const batch = [];

  if (newBatchCCs.length === 0 && newBatch.length > 0) {
    // no customcurricula created or edited.
    console.timeEnd('groupCurricula');
    return newBatch;
  }

  let customCurricula = Object.values(allCustomCurricula);
  customCurricula = customCurricula.concat(
    newBatchCCs.filter((b) => b.verb === 'PUT').map((b) => b.body)
  );
  customCurricula = customCurricula.filter(
    (c) => !newBatchCCs.find((b) => b.verb === 'DELETE' && b.href === c.$$meta.permalink)
  );

  // console.timeEnd('groupCurriculaGetCCs');

  // console.time('groupCurriculaSetGroupKeys');
  const sources = new Set();

  customCurricula.forEach((cc) => {
    if (cc.source && cc.source.href.startsWith('/content')) {
      sources.add(getDerivedGroupKey(cc));
    } else {
      sources.add(getNonDerivedGroupKeyDeprecated(cc, allCustomCurricula));
    }
  });

  // console.timeEnd('groupCurriculaSetGroupKeys');

  const usedCurricula = new Set();

  // console.time('groupCurricula2');
  const creators = [...new Set(customCurricula.map((e) => e.creator.href))];

  for (const group of studyProgrammeGroups) {
    const curriculaForGroup = customCurricula.filter((e) =>
      filterByApplicability(group.studyProgrammes.map((sp) => sp.href))(e)
    );

    for (const source of sources) {
      const currForSource = curriculaForGroup.filter(
        (c) =>
          getNonDerivedGroupKeyDeprecated(c, allCustomCurricula) === source ||
          getDerivedGroupKey(c) === source
      );

      for (const creator of creators) {
        const currForThis = currForSource.filter((c) => c.creator.href === creator);

        if (currForThis.length >= 2) {
          currForThis.forEach((e) => usedCurricula.add(e.$$meta.permalink));

          // get the set.
          const customcur = currForThis;

          let groupHref;

          if (customcur.find((e) => e.customCurriculaGroup)) {
            groupHref = customcur.find((e) => e.customCurriculaGroup).customCurriculaGroup.href;
          } else {
            const customCurriculaGroup = createCustomCurriculaGroup();
            batch.push({
              verb: 'PUT',
              href: customCurriculaGroup.$$meta.permalink,
              body: customCurriculaGroup,
            });
            groupHref = customCurriculaGroup.$$meta.permalink;
          }

          customcur.forEach((e) => {
            if (!e.customCurriculaGroup) {
              batch.push({
                verb: 'PUT',
                href: e.$$meta.permalink,
                body: { ...e, customCurriculaGroup: { href: groupHref } },
              });
            }
          });

          const wronglyGrouped = curriculaForGroup.filter(
            (e) =>
              e.customCurriculaGroup &&
              e.customCurriculaGroup.href === groupHref &&
              !customcur.some((z) => z.key === e.key)
          ); /// find items in the group that don't belong in it (wrong version or something)

          if (wronglyGrouped.length) {
            console.error('some customcurricula are wrongly grouped');
            // wronglyGrouped.forEach(c=> delete c.customCurriculaGroup);
          }
        }
      }
    }
  }
  // console.timeEnd('groupCurricula2');

  // insert those newBatch items if they aren't already
  newBatch.forEach((b) => {
    if (!batch.some((e) => e.href === b.href)) {
      batch.push(b);
    }
  });

  console.timeEnd('groupCurricula');
  console.log('groupCurricula batch items: ', batch.length);

  return batch;
}

/** *
 *
 * @returns {import('../../types/sriTypes').BatchRequest}
 */
export function generateCustomItemBatch(item) {
  const { key } = item;
  const itemForBatch = {};
  itemForBatch.$$meta = item.$$meta;
  itemForBatch.creator = item.creator;
  itemForBatch.context = item.context;
  itemForBatch.type = item.type;
  itemForBatch.key = item.key;
  itemForBatch.identifier = item.identifier;
  itemForBatch.description = item.description;
  if ('mandatory' in item) {
    itemForBatch.mandatory = item.mandatory;
  }

  return [
    { verb: 'PUT', href: `/llinkid/customcurriculum/customitems/${key}`, body: itemForBatch },
  ];
}
