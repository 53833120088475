import { useEffect } from 'react';
import setSameHeight from '@utils/heightCalculation';

const useSameHeight = (dependencies = []) => {
  useEffect(() => {
    window.addEventListener('resize', setSameHeight);
    return () => {
      window.removeEventListener('resize', setSameHeight);
    };
  }, []);

  useEffect(() => {
    setSameHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return setSameHeight;
};

export default useSameHeight;
