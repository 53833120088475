import { escapeRegExp } from 'lodash-es';
import { useEffect, useState } from 'react';

import './highlight.scss';

const Highlight = ({ text, search, minLength = 2, highlightClass }) => {
  const [hlText, setHlText] = useState([]);
  const hasToSearch = search && search.trim() !== '' && search.length >= minLength;

  useEffect(() => {
    if (hasToSearch) {
      const splitted = text.split(new RegExp(`(${escapeRegExp(search)})`, 'gi'));
      setHlText(splitted);
    }
  }, [text, search, hasToSearch]);

  return (
    <span className={`highlight-wrapper`}>
      {!hasToSearch && text}
      {hasToSearch &&
        hlText.map((_text, i) =>
          _text.toUpperCase() === search.toUpperCase() ? (
            <mark key={i} className={`highlight-text ${highlightClass ?? ''}`}>
              {_text}
            </mark>
          ) : (
            _text
          )
        )}
    </span>
  );
};

export default Highlight;
