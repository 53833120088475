import LeidraadPointer from '@UI/leidraadPointer/LeidraadPointer';
import './selectableLeidraadPointer.scss';

const SelectableLeidraadPointer = ({ pointer, onSelect }) => {
  if (!pointer.pointer) return null;
  return (
    <div className="pointer-leidraad">
      <div
        className={`pointer-component ${pointer.selected ? 'selected-pointer' : ''}`}
        onClick={() => onSelect(pointer)}
      >
        <LeidraadPointer pointer={pointer.pointer} />
        <span className="col-sm-12 nopadding pointer-name">{pointer.pointer.title}</span>
      </div>
    </div>
  );
};

export default SelectableLeidraadPointer;
