import { Box, Skeleton } from '@mui/material';
import { useState } from 'react';

const Title = () => {
  return (
    <div className="content">
      <p className="title">
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ display: 'inline-block', width: 400, fontSize: '45px' }}
        />
        <Skeleton
          variant="text"
          animation="wave"
          className="sub"
          sx={{
            display: 'inline-block',
            width: 200,
            fontSize: '21px',
            marginLeft: '1rem',
            marginBottom: '4px',
          }}
        />
      </p>
      <Skeleton
        variant="text"
        animation="wave"
        className="subtitle"
        sx={{ width: '400px', fontSize: '14px' }}
      />
      <Skeleton
        variant="text"
        animation="wave"
        className="subtitle"
        sx={{ width: '300px', fontSize: '14px' }}
      />
    </div>
  );
};

const Classes = () => {
  const [randomClasses] = useState(Math.floor(Math.random() * 6) + 2);
  return Array.from({ length: randomClasses }).map((_, index) => (
    <Skeleton
      key={index}
      variant="rectangular"
      animation="wave"
      width={64}
      height={33}
      sx={{ marginRight: 1 }}
    />
  ));
};

const Footer = () => {
  return (
    <div className="footer" style={{ justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex' }}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width={74}
          height={33}
          sx={{ marginRight: 3 }}
        />
        <Classes />
      </Box>
      <div className="actions">
        <Skeleton variant="rounded" animation="wave" width={171} height={33} />
        <Skeleton variant="rounded" animation="wave" width={128} height={33} />
      </div>
    </div>
  );
};

const CalendarHeaderSkeleton = () => {
  return (
    <header className="calendar-header">
      <Title />
      <Footer />
    </header>
  );
};
export default CalendarHeaderSkeleton;
