import { forwardRef } from 'react';
import './radioGroup.scss';
import RadioButton from '@UI/radioButton/RadioButton';

const RadioGroup = ({ direction = 'column', value, options, onChange }, ref) => {
  const onChangeHandler = (_value) => onChange(_value);

  return (
    <div className={`radio-group fx-${direction}`}>
      {options.map((option, i) => (
        <RadioButton
          key={i}
          ref={ref}
          label={option.name}
          value={option.value}
          name={option.name}
          checked={value === option.value}
          onChange={onChangeHandler}
        />
      ))}
    </div>
  );
};

export default forwardRef(RadioGroup);
