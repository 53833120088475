import { useCallback, forwardRef } from 'react';

import RadioButton from '@UI/radioButton/RadioButton';
import Checkbox from '@UI/checkbox/Checkbox';
import './studyProgramsOptions.scss';

const StudyProgramsOptions = ({ streams = [], programs = [], error, onChange }, ref) => {
  const selectStream = useCallback(
    (stream) => {
      const selectedStreams = streams.map((s) => ({
        ...s,
        selected: s.value === stream.value,
      }));
      const selectedPrograms = programs.map((p) => ({
        ...p,
        selected: stream.studyProgrammes.includes(p.value),
      }));
      onChange({ streams: selectedStreams, programs: selectedPrograms });
    },
    [onChange, programs, streams]
  );

  const selectProgram = useCallback(
    (program) => {
      const selectedStreams = streams.map((s) => ({ ...s, selected: false }));
      const selectedPrograms = programs.map((p) => {
        if (p.value === program.value) return { ...p, selected: !p.selected };
        return p;
      });
      onChange({ streams: selectedStreams, programs: selectedPrograms });
    },
    [onChange, programs, streams]
  );

  return (
    <div ref={ref} className={`study-programs-options ${error ? 'error' : ''}`}>
      <section className="distribute-options">
        {streams.map((stream, i) => (
          <div key={i} className="distribute-card" onClick={() => selectStream(stream)}>
            <div className="title-wrapper">
              <h4>{stream.name}</h4>
              <p>{stream.description}</p>
            </div>
            <div className="radio-wrapper">
              <RadioButton value={stream.value ?? ''} checked={!!stream.selected} />
            </div>
          </div>
        ))}
      </section>
      <section className="propgrams-options">
        {programs.map((program, i) => (
          <div className="list-item" key={i}>
            <Checkbox
              label={program.name}
              checked={program.selected ?? false}
              small
              onChange={() => selectProgram(program)}
            />
          </div>
        ))}
        {error && <div className="error-text">{error.message}</div>}
      </section>
    </div>
  );
};

export default forwardRef(StudyProgramsOptions);
