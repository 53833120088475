import { rest } from 'msw';

import oauth from '@config/oauth';
import authenticate from './authenticate.json';
import { getPersonForDemoOrTest, getUsernameForDemoOrTest } from '../persons/helpers';

const meTemplate = {
  roles: 'SSO_ROLE',
  uuid: null,
  username: null,
};

export const oauthHandlers = [
  rest.get(`${oauth.oauthURL}/me`, (req, res, ctx) => {
    // console.log(`logged in for test as: ${getUsernameForTest()}`);
    const username = getUsernameForDemoOrTest();

    if (username) {
      const person = getPersonForDemoOrTest(username);
      return res(
        ctx.json({ ...meTemplate, uuid: person.key, username: person.username }),
        ctx.delay(150)
      );
    }

    return res(ctx.status(403));
  }),
  rest.get(oauth.me, (req, res, ctx) => {
    // we have //me and /me requests
    const username = getUsernameForDemoOrTest();

    if (username) {
      const person = getPersonForDemoOrTest(username);
      return res(
        ctx.json({ ...meTemplate, uuid: person.key, username: person.username }),
        ctx.delay(150)
      );
    }

    return res(ctx.status(403));
  }),
  rest.get(oauth.authenticate, (req, res, ctx) => {
    return res(ctx.json(authenticate), ctx.delay(150));
  }),
];
