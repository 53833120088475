import './formGroup.scss';

const FormGroup = ({ label, subtitle = '', required = false, children }) => {
  return (
    <div className="form-group">
      <section className="label-wrapper">
        <span className={`label ${required ? 'required' : ''}`}>{label}</span>
        <span className="subtitle">{subtitle}</span>
      </section>
      <section className="input-wrapper">{children}</section>
    </div>
  );
};

export default FormGroup;
