import { useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';

import './ScrollHelpers.scss';

export function ScrollTopDropTarget() {
  const scrollUp = () => {
    window.scrollBy(0, -10);
  };

  const scrollTopDropRef = useRef(null);
  const [{ isOver, show }, drop] = useDrop({
    accept: 'goal',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      show: monitor.canDrop(),
    }),
  });

  drop(scrollTopDropRef);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isOver) {
        scrollUp();
      } else {
        clearInterval(intervalId);
      }
    }, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, [isOver]);

  return <div ref={scrollTopDropRef} className={`scrollTopGuide ${show ? '' : 'hidden'}`} />;
}

export function ScrollBottomDropTarget() {
  const scrollDown = () => {
    window.scrollBy(0, 10);
  };

  const scrollBottomDropRef = useRef(null);
  const [{ isOver, show }, drop] = useDrop({
    accept: 'goal',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      show: monitor.canDrop(),
    }),
  });

  drop(scrollBottomDropRef);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isOver) {
        scrollDown();
      } else {
        clearInterval(intervalId);
      }
    }, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, [isOver]);

  return <div ref={scrollBottomDropRef} className={`scrollBottomGuide ${show ? '' : 'hidden'}`} />;
}
