import { createSlice } from '@reduxjs/toolkit';

type DistributeState = Readonly<{
  filters: {
    text: string;
  };
  distributionMode: boolean;
}>;

const initialState: DistributeState = {
  filters: {
    text: '',
  },
  distributionMode: false,
};

const distributeState = createSlice({
  name: 'distribute',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { property, value } = action.payload;
      state.filters[property] = value;
    },
    setDistributionMode: (state, action) => {
      state.distributionMode = action.payload;
    },
  },
});

export const { setFilter, setDistributionMode } = distributeState.actions;
export default distributeState;
