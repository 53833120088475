import { take, cancel, fork, put, delay } from 'redux-saga/effects';
import { closeAlert, setAlert } from './alertsState';

function* handleCloseDelay({ payload }) {
  const alert = payload;
  const delayms = alert.delay || 10000;
  if (delayms !== -1) {
    yield delay(delayms);
    yield put(closeAlert({ key: alert.key }));
  }
}

/**
 * Watcher saga for the alerts slice of the store.
 * This saga will watch for the setAlert action and will fork a new saga to handle the closing delay.
 * If a new setAlert action is dispatched before the delay is over, the previous saga will be cancelled.
 */
export function* watchAlertsSaga() {
  const forks = {};

  while (true) {
    const action = yield take(setAlert);
    const { key } = action.payload;

    if (forks[key]) {
      yield cancel(forks[key]);
    }

    forks[key] = yield fork(handleCloseDelay, action);
  }
}
