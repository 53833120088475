import './bigLoader.css'; // Assuming you have corresponding CSS for styling

const BigLoader: React.FC = () => {
  return (
    <div id="loader-wrapper">
      <div id="loader"></div>
      <div className="loader-section section-left"></div>
      <div className="loader-section section-right"></div>
      <div id="loader-info" className="loader-section section-text"></div>
    </div>
  );
};

export default BigLoader;
