import { rest } from 'msw';

import apis from '@config/apis';

export const contentApiDemoHandlers = [
  rest.get(`${apis.cachedApi.baseUrl}/content`, (req) => {
    return req.passthrough();
  }),
  rest.get(`${apis.cachedApi.baseUrl}/content/:key/llinkidCurricula`, (req) => {
    return req.passthrough();
  }),
  rest.get(`${apis.cachedApi.baseUrl}/content/:key/llinkidGoal`, async (req) => {
    return req.passthrough();
  }),
  rest.get(`${apis.cachedApi.baseUrl}/content/:key/llinkidCurricula/hash`, (req) => {
    return req.passthrough();
  }),
  rest.get(`${apis.cachedApi.baseUrl}/content/:key/llinkidGoal/hash`, (req) => {
    return req.passthrough();
  }),
  rest.get(`${apis.cachedApi.baseUrl}/content/:key/hash`, (req) => {
    return req.passthrough();
  }),

  rest.get(`${apis.cachedApi.baseUrl}/content/relations`, (req) => {
    return req.passthrough();
  }),
];
