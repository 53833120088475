export const getWindowOpener = () => {
  let originWindow = window.opener; // Communication between tabs
  if (!originWindow) {
    if (window !== window.parent) {
      originWindow = window.parent; // Communication with an iframe
    }
  }

  return originWindow;
};
