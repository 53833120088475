import iconCurrCalendar from '@assets/img/icon_curr_calendar.svg';
import rulesIcon from '@assets/img/rules_icon.svg';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { createSelector } from '@reduxjs/toolkit';
import {
  selectCurrentSchoolHref,
  selectUserHref,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './sidebarMenu.scss';

const selectSidebarMenu = createSelector(
  [selectUserHref, selectCurrentSchoolHref],
  (userHref, schoolHref) => {
    const tabs = [
      {
        title: 'Leerplannen',
        icon: iconCurrCalendar,
        state: 'home.leerplan',
        route: '/home/leerplan',
      },
    ];

    if (userHref && schoolHref) {
      tabs.push({
        title: 'Vorderingsplannen',
        iconComponent: CalendarTodayOutlinedIcon,
        state: 'home.vorderingsplannen',
        route: '/home/vorderingsplannen',
      });
      tabs.push({
        title: 'Leidraad',
        icon: rulesIcon,
        state: 'home.leidraad.tab',
        params: { id: '3b754443-dc47-4ebf-8ec5-58d6da24eabe' },
        route: '/home/leidraad',
      });
    }
    return tabs;
  }
);

const SidebarMenu = () => {
  const tabs = useSelector((state) => selectSidebarMenu(state));

  return (
    <div className="ll-sidebar-menu">
      {tabs &&
        tabs.map((tab) => (
          <NavLink className={`kov-tab`} key={tab.state} to={tab.route}>
            {tab.iconComponent && (
              <div className="kov-tab-icon">
                <tab.iconComponent fontSize={'20px'} />
              </div>
            )}
            {!tab.iconComponent && (
              <div className="kov-tab-icon">
                {tab.icon && <img src={tab.icon} alt={tab.title} />}
              </div>
            )}
            <div className="kov-tab-title">
              <div className="font-standard">
                <strong>{tab.title}</strong>
              </div>
            </div>
          </NavLink>
        ))}
    </div>
  );
};

export default SidebarMenu;
