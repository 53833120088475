import {
  selectClassesOptionsForCalendar,
  selectClassesValuesForCalendar,
} from '@store/calendar/calendarSelectors';
import FormResourcePicker from '@UI/formResourcePicker/FormResourcePicker';
import { alwaysArray, getFirstItemOfObject } from '@utils/utils';
import { oneClassRequiredValidation } from '@utils/validations';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

const ClassesSelect = ({ control, name, defaultValue, schoolyear }) => {
  if (!name || !control) {
    throw new Error("ClassesSelectors must have a form 'control' and a 'name'");
  }

  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const classesSelect = useWatch({
    control,
    name,
  });

  const options = useSelector((state) =>
    selectClassesOptionsForCalendar(state, {
      classes: classesSelect,
      schoolyear,
    })
  );

  const values = useSelector((state) =>
    selectClassesValuesForCalendar(state, {
      classes: classesSelect,
      schoolyear,
    })
  );

  /**
   * this useEffect sets the default values. this needs to happen BEFORE setting updated values.
   */
  useEffect(() => {
    defaultValue?.forEach((cls) => {
      setValue(`${name}.${cls.key}`, cls.endDate ? [] : alwaysArray(cls));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values) {
      defaultValue?.forEach((cls) => {
        setValue(`${name}.${cls.key}`, values?.[cls.key]);
      });
    }
  }, [values, defaultValue, name, setValue]);

  return (
    <>
      {defaultValue?.map((cls) => (
        <div key={cls.key} className="classes">
          <div className="name">{cls.$$displayName}</div>
          <div className="modal-arrow">
            <div className="single">
              <div className="body" style={{ backgroundColor: cls.color?.background }}></div>
              <div
                className="arrow-pointer"
                style={{ borderLeftColor: cls.color?.background }}
              ></div>
            </div>
          </div>
          <div className="selector">
            <FormResourcePicker
              name={`${name}.${cls.key}`}
              localData={options}
              display={(elm) => elm?.$$displayName}
              multiple
              control={control}
              validations={{
                oneRequiredValidation: () => oneClassRequiredValidation(getValues('classes')),
              }}
            />
          </div>
        </div>
      ))}
      {errors?.classes && (
        <span className="classes-error">{getFirstItemOfObject(errors?.classes)?.message}</span>
      )}
    </>
  );
};

export default ClassesSelect;
