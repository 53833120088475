import settings from '@config/settings';

import './readOrderViz.scss';

const ReadOrderViz = ({ item }) => {
  if (!settings.demo.showReadOrder) return null;

  const readOrder = item.$$readOrder ?? item.readOrder;

  return <span className="read-order-viz">{readOrder}</span>;
};

export default ReadOrderViz;
