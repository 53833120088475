import { object } from 'prop-types';
import {
  KovContentBlock,
  KovContentBlockProvider,
} from '@kathondvla/react-shared-components/src/components/contentBlock';
import { useSelector } from 'react-redux';
import { selectFullAnnotatedCurriculumViewModel } from '@store/curriculum/curriculumSelectors';
import settings from '@config/settings';

import './richTextTab.scss';

const RichTextTab = ({ tabName }) => {
  const tabInfo = useSelector((state) => selectFullAnnotatedCurriculumViewModel(state)?.[tabName]);

  return (
    <div className="rich-text-tab">
      <KovContentBlockProvider baseUrl={settings.apisAndUrls.api} allTitlesH1={false}>
        <KovContentBlock item={tabInfo} />
      </KovContentBlockProvider>
    </div>
  );
};

RichTextTab.propTypes = {
  tabInfo: object,
};

export default RichTextTab;
