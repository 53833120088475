import { oauth, api, customCurApi } from '@store/apihelpers';

import commonUtils from '@kathondvla/sri-client/common-utils';
import orderBy from 'lodash-es/orderBy';
import { getStudyProgrammesForStreamOrFinality } from '@store/studyProgrammesApi/studyProgrammeHelpers';
import { distinctBy, getVskoReqHeader } from '@utils/utils';
import settings from '@config/settings';

export const getMe = async () => {
  let user;
  try {
    user = await oauth.get('/me', undefined, { credentials: 'include' });
  } catch (e) {
    console.log('not logged in');
  }
  return user;
};

export const getPrivateState = async (user) => {
  if (user) {
    const prefs = await api.getAll(
      '/privatestates',
      {
        context: settings.component,
        owner: user.$$meta.permalink,
      },
      getVskoReqHeader()
    );
    return prefs;
  }
  return null;
};

export async function getResponsibilities(personHref) {
  return api.getAll(
    `/responsibilities?persons=${personHref}&expandPosition=none`,
    undefined,
    getVskoReqHeader()
  );
}

export function filterSamResps(resps) {
  return resps.filter((e) => e.organisation.href.startsWith('/sam/'));
}

export async function getOrgsForResps(responsibilities) {
  const hrefs = new Set(responsibilities.map((e) => e.organisation.href));
  if (hrefs.size) {
    const allOrganisations = await api.getRaw('/sam/organisationalunits/view', {
      hrefs: [...hrefs],
      omit: ['ags', 'governingInstitutions', 'clbs'],
    });

    return allOrganisations.results;
  }

  return [];
}

export function getAllPersonsFromExpandedResponsibilities(responsibilities) {
  return distinctBy(
    responsibilities.map((elm) => elm.person.$$expanded),
    'key'
  );
}

export async function deDupeLeidraadChoices(originalChoices) {
  const batch = [];
  let choices = [...originalChoices];

  const sections = new Set(choices.map((c) => c.section.href));

  for (const sectionHref of sections) {
    const choicesForSection = choices.filter((c) => c.section.href === sectionHref);
    if (choicesForSection.length > 1) {
      const keeper = orderBy(choicesForSection, '$$meta.modified')[0];
      choicesForSection
        .filter((c) => c.key !== keeper.key)
        // eslint-disable-next-line no-loop-func
        .forEach((c) => {
          batch.push({ verb: 'DELETE', href: c.$$meta.permalink });
          choices = choices.filter((co) => co.key !== c.key); // /remove from choices.
        });
    }
  }

  if (batch.length) {
    console.warn('We have found duplicate choices for the leidraad. #leidraadDutplicateChoices');
    console.log('POSTING');
    console.log(batch);

    await customCurApi.post('/llinkid/qualityinstrument/choices/batch', batch);
  }

  return choices;
}

/**
 *
 * @param {*} school
 * @returns a list of customstudyprogrammegroups, with all studyprogrammes selected.
 */
async function generateStudyProgrammeGroups(
  school,
  firstGradeStreams,
  secondAndThirdGradeFinalities,
  allPrograms
) {
  const groups = [];
  const finalities = secondAndThirdGradeFinalities;

  if (school.studyProgrammes.length) {
    const schoolSelectedSPKeys = school.studyProgrammes.map((i) => i.$$meta.permalink); // /map all hrefs

    firstGradeStreams.forEach((stream) => {
      let streamSPs = getStudyProgrammesForStreamOrFinality(stream.$$meta.permalink, allPrograms);
      streamSPs = streamSPs.filter((sp) => schoolSelectedSPKeys.includes(sp.$$meta.permalink));
      const key = commonUtils.generateUUID();
      groups.push({
        $$meta: { permalink: `/llinkid/customstudyprogrammegroups/${key}` },
        key,
        title: stream.title,
        creator: { href: school.$$meta.permalink },
        issued: { startDate: '2019-07-01T00:00:00.000Z' },
        studyProgrammes: streamSPs.map((e) => ({ href: e.$$meta.permalink })),
      });
    });

    // 2nd and 3rd grade logic
    finalities.forEach((fin) => {
      let finSps = getStudyProgrammesForStreamOrFinality(fin.$$meta.permalink, allPrograms);
      finSps = finSps.filter((sp) => schoolSelectedSPKeys.includes(sp.$$meta.permalink));

      if (finSps.length) {
        const newKey = commonUtils.generateUUID();
        groups.push({
          $$meta: { permalink: `/llinkid/customstudyprogrammegroups/${newKey}` },
          key: newKey,
          title: fin.title,
          creator: { href: school.$$meta.permalink },
          issued: { startDate: '2021-09-01T00:00:00.000Z' },
          studyProgrammes: finSps.map((e) => ({ href: e.$$meta.permalink })),
        });
      }
    });
  }
  return groups;
}

/**
 * this function has to make it into redux-saga, but it links into the studyprogrammeservice so i didn't want to move it right now.
 * it basically checks if the school has made any changes in their studyprogrammes (in MIJN) and edits the customstudyprogrammegroups accordingly.
 * @param {*} schoolsVM
 */
export async function checkAndFixStudyProgrammeGroups(
  school,
  studyProgrammeGroups,
  firstGradeStreams,
  secondAndThirdGradeFinalities,
  allPrograms
) {
  const batch = customCurApi.createBatch();
  const newStudyProgrammeGroups = [...studyProgrammeGroups];
  // TODO (make this propperly) TEMPORARILY filter out the unselected studyprogrammes from the groups.
  const tmpStudyProgrammeGroups = await generateStudyProgrammeGroups(
    school,
    firstGradeStreams,
    secondAndThirdGradeFinalities,
    allPrograms
  );

  for (const group of tmpStudyProgrammeGroups) {
    let schoolGroup = newStudyProgrammeGroups.find((g) => group.title === g.title);
    const schoolSelectedSPKeys = school.studyProgrammes?.map((i) => i.$$meta.permalink); // map all hrefs
    if (!schoolGroup) {
      newStudyProgrammeGroups.push(group);
      schoolGroup = group;
    } else {
      // schoolGroup already exists. let's see if we have any changes.
      const schoolSPsSet = new Set(
        group.studyProgrammes
          .filter((sp) => schoolSelectedSPKeys.includes(sp.href))
          .map((e) => e.href)
      );
      const schoolGroupSet = new Set(schoolGroup.studyProgrammes.map((e) => e.href));
      if (
        [...schoolSPsSet].every((sp) => schoolGroupSet.has(sp)) &&
        [...schoolGroupSet].every((sp) => schoolSPsSet.has(sp))
      ) {
        // no changes, skip this one.
        // eslint-disable-next-line no-continue
        continue;
      }
    }
    if (school.studyProgrammes.length) {
      schoolGroup.studyProgrammes = group.studyProgrammes.filter((sp) =>
        schoolSelectedSPKeys.includes(sp.href)
      );
    }
    if (schoolGroup.studyProgrammes.length > 0) {
      // /can't PUT groups without studyprogrammes
      batch.put(schoolGroup.$$meta.permalink, schoolGroup);
    } else {
      // /TODO  should probably delete the group?
    }
  }

  if (batch.array.length) {
    await batch.send('/llinkid/customstudyprogrammegroups/batch');
  }
  return newStudyProgrammeGroups.filter((e) => e.studyProgrammes.length);
}

export async function savePreferences(preferences) {
  const href = preferences.$$meta.permalink;
  const headers = getVskoReqHeader();

  return api.put(href, preferences, headers);
}
