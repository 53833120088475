import { Modal } from '@kathondvla/react-shared-components/src/components';

import WhatIsNewContent from '@UI/whatIsNewModal/WhatIsNewContent';
import {
  setCurrentModalVersionSkipped,
  setModalsPreferences,
} from '@store/userAndSchool/usersAndSchoolState';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import settings from '@config/settings';

const WhatIsNewModal = ({ title, onClose }) => {
  const dispatch = useDispatch();
  const { version } = settings.whatIsNewModal;

  const handleSkip = () => {
    dispatch(setCurrentModalVersionSkipped());
    onClose();
  };

  const handleAccept = () => {
    dispatch(setModalsPreferences({ modalName: version }));
    onClose();
  };

  return (
    <>
      <Modal.Header title={title} onClose={onClose} />
      <Modal.Body>
        <WhatIsNewContent />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={handleSkip}>
          Overslaan
        </Button>
        <Button variant="contained" onClick={handleAccept}>
          OK
        </Button>
      </Modal.Footer>
    </>
  );
};

export default WhatIsNewModal;
