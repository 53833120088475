import { rest } from 'msw';

// import privateStateDirector from './privatestate_director.testperson.json';
// import privateStateEmpty from './privatestate_empty.json';
import demo from '@config/demo';
import apis from '@config/apis';
import { fetchJsonPath } from '../../helpers/fetchJson';

const fetchJson = fetchJsonPath('api/privateState');

export const privateStateApiHandlers = [
  rest.get(`${apis.api}/privatestates`, async (req, res, ctx) => {
    if (
      req.url.searchParams.get('owner').toLowerCase() ===
      '/persons/00d71707-540d-40be-991b-e528dd758fcb'
    ) {
      const privateStateDirector = await fetchJson(ctx, 'privatestate_director.testperson.json');
      return res(ctx.json(privateStateDirector), ctx.delay(150));
    }
    const privateStateEmpty = await fetchJson(ctx, 'privatestate_empty.json');
    return res(ctx.json(privateStateEmpty), ctx.delay(150));
  }),
  // prevent changes from being made in MSW Mock mode
  rest.post(`${apis.api}/*`, (req, res, ctx) => {
    if (demo.enableDemoMode) {
      return res(ctx.status(200));
    }
    return res(ctx.status(405));
  }),
  rest.put(`${apis.api}/*`, (req, res, ctx) => {
    if (demo.enableDemoMode) {
      return res(ctx.status(200));
    }
    return res(ctx.status(405));
  }),
];
