import { KovResourcePicker } from '@kathondvla/react-shared-components/src';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import { selectCurrentSchool, selectUser } from '@store/userAndSchool/userAndSchoolSelectors';
import { setOrgContext, setSchoolContext } from '@store/userAndSchool/usersAndSchoolState';
import AlertModal from '@UI/alertModal/AlertModal';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import iconAddWhite from '@assets/img/add_white.svg';
import { selectSchoolsWithMissingOption } from '../HeaderSelectors';
import SchoolOption from '../selectorOption/schoolOption';

const SchoolSelector = () => {
  const schoolAlertModalRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const schools = useSelector(selectSchoolsWithMissingOption);
  const selectedSchool = useSelector(selectCurrentSchool);

  const showMissingClassModal = () => {
    schoolAlertModalRef.current.open({
      title: 'Toevoegen aan schoolteam',
      titleIcon: iconAddWhite,
      text: `Je directeur (of pedagogisch verantwoordelijke) kan je uitnodigen op het schoolteam via</br><a target="_blank" href="https://mijn.katholiekonderwijs.vlaanderen">mijn.katholiekonderwijs.vlaanderen</a>`,
    });
  };

  const changeSchool = (item) => {
    if (item.missing) {
      showMissingClassModal();
    } else {
      sessionStorage.setItem('currentSchool', item ? item.href : null);
      dispatch(setSchoolContext(item ? item.href : null));
      sessionStorage.setItem('currentOrg', item ? item.$$meta.permalink : null);
      dispatch(setOrgContext(item ? item.href : null));
    }
  };

  return (
    <>
      {schools && selectedSchool && (
        <div data-testid="school-selector" className="selector picker school">
          <KovResourcePicker
            localData={schools}
            value={selectedSchool}
            display={() => selectedSchool.$$displayName}
            optionTemplate={(option) => <SchoolOption option={option} />}
            multiple={false}
            clearable={false}
            disableSearch={true}
            onChange={changeSchool}
            style={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
          />
          {user && schools && schools.length === 0 && (
            <span className="missing-class" onClick={showMissingClassModal}>
              Ontbreekt er een schoolteam?
            </span>
          )}
        </div>
      )}
      <Modal
        ref={schoolAlertModalRef}
        size="sm"
        component={<AlertModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </>
  );
};

export default SchoolSelector;
