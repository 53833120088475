import { LLINKID_GOAL_TYPES } from '@utils/curriculumHelper';
import { HoveredPopover } from '@UI/index';
import DeepeningIcon from '@assets/img/icon_deepening.svg?react';
import ExtraIcon from '@assets/img/icon_extra.svg?react';
import CustomIcon from '@assets/img/icon_school_so_grey.svg?react';
import ChoiceIcon from '@assets/img/icon_choice.svg?react';

import './goalTypeIcon.scss';

const ICON = {
  [LLINKID_GOAL_TYPES.CHOICE]: {
    title: 'Keuzedoel',
    component: <ChoiceIcon className="goal-choice-icon" />,
  },
  [LLINKID_GOAL_TYPES.DEEPENING]: {
    title: 'Verdiepend doel',
    component: <DeepeningIcon className="goal-deep-icon" />,
  },
  [LLINKID_GOAL_TYPES.EXTRA]: {
    title: 'Extra leerplandoel',
    component: <ExtraIcon className="goal-extra-icon" />,
  },
  [LLINKID_GOAL_TYPES.CUSTOM]: {
    title: 'Eigen leerplandoel',
    component: <CustomIcon className="goal-custom-icon" />,
  },
};

const GoalTypeIcon = ({ type }) => {
  if (!type || type === LLINKID_GOAL_TYPES.REGULAR) return null;
  return (
    <HoveredPopover content={ICON[type].title}>
      <div className={`goal-type-icon`}> {ICON[type].component}</div>
    </HoveredPopover>
  );
};

export default GoalTypeIcon;
