import { array, string, bool } from 'prop-types';

import DistributeIcon from '@UI/distributeIcon/DistributeIcon';
import HoveredPopover from '@UI/popovers/HoveredPopover';
import './distributeButtonList.scss';

const DistributeButtonList = ({ list = [], type, small = false }) => {
  if (!list.length) return null;

  return (
    <div className={`distribute-list ${small ? 'small' : ''}`} data-testid="distribute-list">
      <div className="distribute-button">
        <DistributeIcon type={type} />

        <div className="identifier-list">
          {list
            .filter((e) => !e.merged)
            .map((item) => (
              <HoveredPopover key={item.key} content={item.description}>
                <span className={item.partial ? 'partial-selection' : ''}>
                  {item.identifiers}{' '}
                  {item.version && <span className="version">({item.version})</span>}
                </span>
              </HoveredPopover>
            ))}
        </div>
      </div>
    </div>
  );
};

DistributeButtonList.propTypes = {
  list: array,
  type: string,
  small: bool,
};

export default DistributeButtonList;
