import { createSlice } from '@reduxjs/toolkit';
import { StudyProgrammeApiState } from './studyProgrammeTypes';

function initialState(): StudyProgrammeApiState {
  return {
    isLoading: false,
    isFailed: false,
    allPrograms: [],
    allGrades: [],
    firstGradeStreams: [],
    secondGradeFinalities: [],
    educationalActivityTypes: [],
  };
}

const studyProgrammeApiDataState = createSlice({
  name: 'studyProgrammeApiData',
  initialState: initialState(),
  reducers: {
    init: (state) => {
      state.isLoading = true;
      state.isFailed = false;
    },
    setAllData: (state, action) => {
      const [grades, programs, streams, finalities, educationalActivityTypes] = action.payload;
      state.allPrograms = programs;
      state.allGrades = grades;
      state.firstGradeStreams = streams;
      state.secondGradeFinalities = finalities;
      state.educationalActivityTypes = educationalActivityTypes;
      state.isLoading = false;
    },
    initFailed: (state) => {
      state.isLoading = false;
      state.isFailed = true; // TODO: nothing happens with this yet.
    },
  },
});

export const { init, setAllData, initFailed } = studyProgrammeApiDataState.actions;
export default studyProgrammeApiDataState;
