import { useRef } from 'react';
import { bool, func, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { CUSTOMTYPES } from '@utils/curriculumHelper';
import { selectCurriculaReadOnly } from '@store/curriculum/curriculumSelectors';
import { Compiler, Modal } from '@kathondvla/react-shared-components/src/components';
import { changeItemTitle, moveItem } from '@store/llinkidApis/llinkidApiState';
import TermModal from '@UI/termModal/TermModal';
import Concordance from '@UI/concordance/Concordance';
import Goal from '@UI/goal/Goal';
import ReadOrderViz from '@UI/readOrderViz/ReadOrderViz';
import EducationalPointer from '@UI/educationalPointer/EducationalPointer';
import EditableTitle from '@UI/editableTitle/EditableTitle';
import NonderivedMenuOptions from '@UI/nonderivedMenuOptions/NonderivedMenuOptions';
import DropZone from '@UI/dropZone/DropZone';
import { setCollapseSectionsPreferences } from '@store/userAndSchool/usersAndSchoolState';

import './section.scss';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import Collapse from '@mui/material/Collapse';
import { selectIsDistributionMode } from '@store/distribute/distributeSelectors';
import expandCloseImg from '@assets/img/expand_close.png';
import settings from '@config/settings';

const Section = ({
  section,
  isFirstItem,
  isSubSection,
  disabled,
  onShowEditGoalModal,
  onShowNonderivedModal,
  onRemove,
}) => {
  const { collapsed } = section;
  const readonly = useSelector(selectCurriculaReadOnly);
  const curriculumKey = useSelector((state) => state.curriculum.curriculumKey);
  const canEditTitle = section.type === CUSTOMTYPES.section && !readonly;
  const termModalRef = useRef();
  const dispatch = useDispatch();
  const staticPart = section.staticpart?.$$html;
  const isDistributionMode = useSelector(selectIsDistributionMode);
  const { concordance } = section;

  const collapseSection = () => {
    dispatch(
      setCollapseSectionsPreferences({
        curriculumKey,
        sectionKey: section.key,
        collapsed: !collapsed,
      })
    );
  };

  const compilerOptions = {
    term: {
      compile: true,
      onClick: (key) => {
        // ADD terms to Section
        const term =
          section.terms?.find((t) => t.key === key) ||
          section.staticpart?.terms?.find((t) => t.key === key);
        if (term) {
          termModalRef.current?.open({ term });
        }
      },
    },
    markedText: {
      compile: true,
      modes: settings.hardcodings.markExplanation,
      onHover: (key, text, setter) => {
        // ADD markExplanations to Section
        const explanation =
          section.markExplanations?.find((mark) => mark.key === key) ||
          section.staticpart?.markExplanations?.find((mark) => mark.key === key);
        if (explanation) setter(explanation.description);
      },
    },
  };

  const [{ isHovering }, dropRef] = useDrop(() => ({
    accept: 'goal',
    drop: (item) => {
      dispatch(
        moveItem({
          parent: section.$$meta.permalink,
          item: item.href,
        })
      );
    },
    collect: (monitor) => ({
      isHovering: monitor.isOver(),
    }),
  }));

  return (
    <section
      className={`react-goal-section ${collapsed ? 'collapsed' : ''} 
      ${isSubSection ? 'sub-section' : ''} 
      ${isDistributionMode ? 'distribution-mode' : ''}`}
    >
      <header ref={dropRef}>
        <NonderivedMenuOptions
          goal={section}
          isFirstItem={isFirstItem}
          onShowEditGoalModal={onShowEditGoalModal}
          onShowNonderivedModal={onShowNonderivedModal}
          onExclude={() => onRemove?.(section)}
        >
          <div className="header-container">
            {!disabled && (
              <button className="collapse-icon" onClick={collapseSection}>
                <img src={expandCloseImg} className="expand" />
              </button>
            )}
            <div className="section-title">
              <EditableTitle
                editable={canEditTitle}
                placeholder={section.placeholder}
                compilerOptions={compilerOptions}
                debounce={500}
                onEdit={(title) => dispatch(changeItemTitle({ title, item: section }))}
              >
                {section.title}
              </EditableTitle>
            </div>
            {section.educationalPointers?.map((pointer, index) => (
              <EducationalPointer key={index} pointer={pointer} />
            ))}
            <ReadOrderViz item={section} />
          </div>
        </NonderivedMenuOptions>
        <Collapse in={!collapsed || disabled} timeout={500} unmountOnExit>
          {!isSubSection && staticPart && (
            <div className="static-section">
              <Compiler options={compilerOptions} text={staticPart} />
            </div>
          )}
        </Collapse>
      </header>

      <DropZone visible={isHovering}>
        {section.$$meta.permalink === '/content/FOUNDATIONALPLACEHOLDER' && (
          <span className="goal-drop-error">
            De doelen onder deze titel zijn alfabetisch gesorteerd
          </span>
        )}
      </DropZone>

      <Collapse in={!collapsed || disabled} timeout={500} unmountOnExit>
        <main className="goal-section-content">
          {concordance ? <Concordance key={concordance.key} concordance={concordance} /> : null}

          <div className="goals">
            {section.goals?.map((g) => (
              <Goal
                key={g.key}
                goal={g}
                parentKey={section.$$meta.permalink}
                onShowEditGoalModal={onShowEditGoalModal}
                onShowNonderivedModal={() => onShowNonderivedModal?.(false, g)}
                onSelect={() => {}}
              />
            ))}
          </div>

          <div className="sub-sections">
            {section.sections?.map((s) => (
              <Section
                key={s.key}
                section={s}
                isSubSection={true}
                disabled={disabled}
                onShowEditGoalModal={onShowEditGoalModal}
                onShowNonderivedModal={onShowNonderivedModal}
                onRemove={onRemove}
              />
            ))}
          </div>
        </main>
      </Collapse>

      <Modal
        ref={termModalRef}
        size="md"
        component={<TermModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </section>
  );
};

Section.propTypes = {
  section: object,
  isFirstItem: bool,
  disabled: bool,
  onShowNonderivedModal: func,
  onRemove: func,
};

export default Section;
