import { Compiler, Modal } from '@kathondvla/react-shared-components/src/components';
import { Button } from '@mui/material';

const WrongRouteModal = ({ onClose }) => {
  return (
    <div className="term-modal">
      <Modal.Header title="Fout" onClose={onClose} />

      <Modal.Body>
        <div className="demarcation-body">
          <Compiler text="Je bent op deze pagina terecht gekomen door de browser knoppen te gebruiken of door rechtstreeks hier naartoe te surfen. Je kan deze pagina om doelen te selecteren enkel gebruiken via de nagivatie van de website zelf (vanuit een eigen doelenkader of een vorderingsplan). We sturen je terug naar de homepagina. " />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="contained" onClick={onClose}>
          Ok
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default WrongRouteModal;
