import { useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';

import './popovers.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const he = require('he');

const HoveredPopover = ({
  disabled,
  delay = 0,
  content,
  children,
  compile,
  className = '',
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const mouseEnterHandler = () => {
    if (disabled) return;
    if (timeoutId) clearTimeout(timeoutId);
    setTimeoutId(null);
    setIsOpen(true);
  };
  const mouseLeaveHandler = () => {
    const timeout = setTimeout(() => setIsOpen(false), delay);
    setTimeoutId(timeout);
  };

  return (
    <Popover
      {...props}
      isOpen={isOpen}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          {compile ? (
            <div
              className={`popover-content ${className}`}
              onMouseEnter={mouseEnterHandler}
              onMouseLeave={mouseLeaveHandler}
              dangerouslySetInnerHTML={{ __html: he.decode(content) }}
            ></div>
          ) : (
            <div
              className={`popover-content ${className}`}
              onMouseEnter={mouseEnterHandler}
              onMouseLeave={mouseLeaveHandler}
            >
              {content}
            </div>
          )}
        </ArrowContainer>
      )}
    >
      <div className="hover-item" onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler}>
        {children}
      </div>
    </Popover>
  );
};

export default HoveredPopover;
