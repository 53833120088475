import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectPendingBatchesStatus } from '@store/llinkidApis/llinkidApiSelectors';
import { setPendingBatchesStatus } from '@store/llinkidApis/llinkidApiState';
import { RootState } from '@store/storeSetup';

import SaveIcon from '@mui/icons-material/Save';
import BackupIcon from '@mui/icons-material/Backup';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import useCountdown from '@hooks/useCountdown';
import { COMPLETE, PendingBatchesStatus, pendingBatchesStatusMap } from '../../../constants/status';
import './asyncStatus.scss';

const AsyncStatus: React.FC = () => {
  const dispatch = useDispatch();
  const pendingBatchesStatus: PendingBatchesStatus | null = useSelector((state: RootState) => {
    const selectedStatus = selectPendingBatchesStatus(state);
    return selectedStatus === null ? null : pendingBatchesStatusMap[selectedStatus];
  });
  const delay = useSelector(
    (state: RootState) => state.customCurriculaData.pendingBatchesStatus?.delay
  );
  const [countdown, setCountdown] = useCountdown();

  useEffect(() => {
    if (delay) setCountdown(delay);
  }, [delay, setCountdown]);

  useEffect(() => {
    if (pendingBatchesStatus?.status === COMPLETE.status) {
      const timer = setTimeout(() => {
        dispatch(setPendingBatchesStatus(null));
      }, 5000);

      return () => clearTimeout(timer);
    }
    return () => {};
  }, [pendingBatchesStatus, dispatch]);

  if (!pendingBatchesStatus) return null;

  return (
    <div className="async-status info">
      <div className="wrapper">
        {pendingBatchesStatus.status === 'UPLOADING_ITEM' && (
          <div className="loader-icon save">
            <SaveIcon color="action" />
          </div>
        )}
        {pendingBatchesStatus.status === 'UPLOADING_FILE' && (
          <div className="loader-icon upload">
            <BackupIcon color="disabled" />
          </div>
        )}
        {pendingBatchesStatus.status === 'COMPLETE' && (
          <CheckCircleIcon
            sx={{ width: '32px', height: '32px', marginBottom: '1rem' }}
            color="success"
          />
        )}
        {pendingBatchesStatus.status === 'ERROR_AND_RETRY' && (
          <ErrorIcon sx={{ width: '32px', height: '32px', marginBottom: '1rem' }} color="warning" />
        )}

        {pendingBatchesStatus.status !== 'COMPLETE' && (
          <div className="loader-bar-wrapper">
            <div className="loader-bar"></div>
          </div>
        )}

        <div className="loader-text">
          {pendingBatchesStatus.status === 'COMPLETE' && <div>Alle aanpassingen zijn bewaard</div>}
          {pendingBatchesStatus.status === 'UPLOADING_FILE' && <div>Bijlagen uploaden...</div>}
          {pendingBatchesStatus.status === 'UPLOADING_ITEM' && <div>Aanpassingen opslaan...</div>}
          {pendingBatchesStatus.status === 'ERROR_AND_RETRY' && (
            <div>Probleem opgetreden bij bewaren... Opnieuw proberen binnen {countdown}s...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AsyncStatus;
