import AddBoxIcon from '@mui/icons-material/AddBox';
import './resourcePickerOption.scss';

const SchoolYearOption = ({ option }) => {
  return (
    <div className={`display-schoolyear ${!option.initialized ? 'create' : ''}`}>
      <span>{option.name}</span>
      {!option.initialized && <AddBoxIcon className="result-icon" />}
    </div>
  );
};

export default SchoolYearOption;
