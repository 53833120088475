import { sortBy, last } from 'lodash-es';
import { cloneWithJson } from './utils';

function isSelectable(studyProgram, applicability) {
  return applicability.studyProgrammes.some(
    (studyProgramme) => studyProgramme.href === studyProgram.$$meta.permalink
  );
}

function getSelectablePrograms(applicability, schoolStudyPrograms) {
  let selectablePrograms = [];

  if (applicability) {
    selectablePrograms = schoolStudyPrograms
      .filter((program) => isSelectable(program, applicability))
      .map((program) => ({
        name: program.title,
        value: program.$$meta.permalink,
      }));
  }
  return selectablePrograms;
}

function getGroupsAndPrograms(selectablePrograms, currentSchool) {
  if (!currentSchool) return selectablePrograms;
  const groupsToAdd = [];
  currentSchool.studyProgrammeGroups.forEach((group) => {
    if (
      group.studyProgrammes.some((sp) => selectablePrograms.some((sel) => sel.value === sp.href))
    ) {
      groupsToAdd.push({
        name: group.title,
        value: group.$$meta.permalink,
      });
    }
  });
  return sortBy(groupsToAdd.concat(selectablePrograms), 'name');
}

export function getSelectableProgramsForCurriculumListItem(curriculumListItem, currentSchool) {
  const selectablePrograms = getSelectablePrograms(
    curriculumListItem.applicability,
    currentSchool.studyProgrammes
  );
  const selectableProgramsWithGroups = getGroupsAndPrograms(selectablePrograms, currentSchool);
  return selectableProgramsWithGroups;
}

export function getRichting(item, currentSchool) {
  const selectablePrograms = getSelectableProgramsForCurriculumListItem(item, currentSchool);
  if (item.studyProgram.split(',').length > 1) {
    if (item.studyProgrammeGroup) {
      const selectable = selectablePrograms.find(
        (sp) => sp.name === item.studyProgrammeGroup.title
      );
      return selectable?.value;
    }
    return null;
  }

  return item.studyProgram;
}

export function getCurriculaCustIds(item, customCurricula) {
  const custIds = item.curricula ? item.curricula.map((i) => i.custid) : [item.custid];
  if (item.setid) {
    custIds.push(item.setid);
  } else {
    const custIdSet = new Set(custIds);
    const results = customCurricula.filter((curr) => custIdSet.has(curr.key));
    const customCurrs = cloneWithJson(results);

    customCurrs.forEach((e) => {
      if (e.customCurriculaGroup) {
        custIds.push(last(e.customCurriculaGroup.href.split('/')));
      }
    });
  }

  return custIds;
}

export function checkHasDerived(custIds, customCurricula) {
  return custIds.some((custId) => {
    const results = customCurricula.filter(
      (curr) => curr.source?.href === `/llinkid/customcurriculum/customcurricula/${custId}`
    );
    return results.length > 0;
  });
}
