import { RestContext } from 'msw';

export const fetchJsonPath = (pathName: string) => {
  const fetchJson = async (ctx: RestContext, fileName: string) => {
    if (process.env.NODE_ENV === 'test') {
      const { promises: fs } = await import('fs');
      const path = await import('path');
      const filePath = path.join(__dirname, `../${pathName}/${fileName}`);
      const data = await fs.readFile(filePath, 'utf8');
      const json = JSON.parse(data);
      return json;
    }

    const resp = await ctx.fetch(`/mocks/${fileName}`);
    const result = await resp.json();
    return result;
  };

  return fetchJson;
};
