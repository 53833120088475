import { FormGroup, FormRadioGroup, FormSelect, FormTextField } from '@UI/index';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import { identifierUniqueValidation, requiredValidation } from '@utils/validations';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@mui/material';
import {
  selectGroupOptions,
  selectLevelOptions,
} from '@store/leerplanList/nonDerivedModalSelectors';
import { selectCustomCurriculaIdentifiers } from '@store/llinkidApis/llinkidApiSelectors';
import { convertHrefToGrade } from '@store/studyProgrammesApi/studyProgrammeHelpers';
import {
  selectAllGrades,
  selectStudyProgrammeGroupsPerGradeForSchool,
  selectStudyProgrammesPerGradeForSchool,
} from '@store/studyProgrammesApi/studyProgrammeSelectors';
import { useSelector } from 'react-redux';
import './modals.scss';
import StudyProgramsForm from '@UI/studyProgramsOptions/StudyProgramsForm';
import iconAddWhite from '@assets/img/add_white.svg';

const CreateNonderivedModal = ({ onConfirm, onClose }) => {
  const schoolStudyProgrammesPerGrade = useSelector(selectStudyProgrammesPerGradeForSchool);
  const studyProgrammeGroupsPerGrade = useSelector(selectStudyProgrammeGroupsPerGradeForSchool);
  const customCurriculaIds = useSelector(selectCustomCurriculaIdentifiers);

  const levels = useSelector(selectLevelOptions);

  const [studyProgramOptions, setStudyProgramOptions] = useState({
    streams: [],
    programs: [],
  });
  const groupSelectOptions = useSelector(selectGroupOptions);
  const gradeSelectOptions = useSelector(selectAllGrades);

  const { control, handleSubmit, watch, trigger } = useForm({
    mode: 'onChange',
  });

  const niveauRadioGroup = watch('niveau');
  const gradeSelect = watch('grade');
  const selectedStudyPrograms = studyProgramOptions.programs.filter((p) => p.selected);

  useEffect(() => {
    if (gradeSelect) {
      const programs = schoolStudyProgrammesPerGrade[convertHrefToGrade(gradeSelect)];
      const streams = studyProgrammeGroupsPerGrade[convertHrefToGrade(gradeSelect)];

      setStudyProgramOptions({ programs, streams });
    }
  }, [gradeSelect, schoolStudyProgrammesPerGrade, studyProgrammeGroupsPerGrade]);

  return (
    <div data-testid="nd-create-modal">
      <Modal.Header title="Een doelenkader maken" icon={iconAddWhite} onClose={onClose} />

      <Modal.Body>
        <FormGroup label="Niveau" subtitle="Op welk niveau?" required>
          <FormRadioGroup
            name="niveau"
            value={levels[0].value}
            direction="row"
            options={levels}
            control={control}
            validations={{
              required: (value) => requiredValidation(value),
            }}
          />
        </FormGroup>

        {niveauRadioGroup === 'group' && (
          <FormGroup label="Groep" subtitle="Binnen welke groep?" required>
            <FormSelect
              name="group"
              options={groupSelectOptions}
              control={control}
              validations={{
                required: (value) => requiredValidation(value),
              }}
            />
          </FormGroup>
        )}

        <FormGroup label="Graad" required>
          <div data-testid="grade-selector">
            <FormSelect
              name="grade"
              options={gradeSelectOptions}
              control={control}
              validations={{
                required: (value) => requiredValidation(value),
              }}
            />
          </div>
        </FormGroup>

        <FormGroup label="Code" subtitle="Herkenbare code voor je doelenkader" required>
          <FormTextField
            name="code"
            maxLength={8}
            validations={{
              unique: (value) => identifierUniqueValidation(value, customCurriculaIds),
              required: (value) => requiredValidation(value),
            }}
            control={control}
          />
        </FormGroup>
        <FormGroup label="Naam" subtitle="Volledige naam van je doelenkader" required>
          <FormTextField
            name="name"
            control={control}
            validations={{
              required: (value) => requiredValidation(value),
            }}
          />
        </FormGroup>

        <StudyProgramsForm
          control={control}
          streams={studyProgramOptions.streams}
          programs={studyProgramOptions.programs}
          validations={{
            required: (value) =>
              (value.programs.length > 0 && value.programs.some((p) => p.selected)) ||
              'Verplicht veld',
          }}
          onChange={(options) => setStudyProgramOptions(options)}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outlined" onClick={onClose}>
          Annuleren
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(async (form) => {
            const result = await trigger();
            if (!result || selectedStudyPrograms.length === 0) return;
            onConfirm({
              ...form,
              studyProgram: selectedStudyPrograms,
            });
          })}
        >
          Aanmaken
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default CreateNonderivedModal;
