import { Button } from '@mui/material';

const ActionListButton = ({ latest = false, disabled, icon, label, onClick, testId }) => {
  return (
    <Button
      variant={latest ? 'contained' : 'outlined'}
      color={latest ? 'primary' : 'grey'}
      size="small"
      startIcon={icon}
      sx={{
        height: '1.4rem',
        marginRight: '1rem',
        whiteSpace: 'nowrap',
      }}
      onClick={onClick}
      disabled={disabled}
      data-testid={testId}
    >
      {label}
    </Button>
  );
};

export default ActionListButton;
