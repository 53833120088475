import { eachWeekOfInterval } from 'date-fns/eachWeekOfInterval';
import { isAfter } from 'date-fns/isAfter';
import { isWeekend } from 'date-fns/isWeekend';
import { setDay } from 'date-fns/setDay';
import { startOfDay } from 'date-fns/startOfDay';
import { addDays } from 'date-fns/addDays';
import { getMonth } from 'date-fns/getMonth';
import { isEqual } from 'date-fns/isEqual';
import { eachDayOfInterval } from 'date-fns/eachDayOfInterval';
import { isWithinInterval } from 'date-fns/isWithinInterval';
import { utc, UTCDate } from '@date-fns/utc';

import { min, max } from 'lodash-es';
import { endOfDay } from 'date-fns';
import { createWeek } from '../module/models/week';

export function endOfDayUTC(date) {
  const eod = endOfDay(new UTCDate(date));
  return eod;
}

function isNextMonth(week, index, weeks) {
  if (index === 0) return true;
  const currMonth = getMonth(week);
  const prevMonth = getMonth(weeks[index - 1]);
  return currMonth !== prevMonth;
}

function eachWeekOfIntervalUTC(interval) {
  const utcdays = eachWeekOfInterval(interval, { weekStartsOn: 1, in: utc });
  return utcdays;
}

export function eachDayOfIntervalUTC(interval) {
  return eachDayOfInterval(interval, { in: utc });
}

export function generateCalendarWeeks(startDate, endDate, minActDate, maxActDate) {
  const firstActivityDate = minActDate ? new UTCDate(minActDate) : undefined;
  const lastActivityDate = maxActDate ? new UTCDate(maxActDate) : undefined;

  const startPlan = new UTCDate(startDate);
  const endPlan = new UTCDate(endDate);

  const start = firstActivityDate < startPlan ? firstActivityDate : startPlan;
  const end = lastActivityDate > endPlan ? lastActivityDate : endPlan;

  let weeks = [];
  const firstDayOfWeek = setDay(start, 1);

  let startWeek = setDay(startPlan, 1);
  if (!(isAfter(startWeek, startOfDay(startPlan)) || !isWeekend(startPlan))) {
    // console.log(startWeek.add(7, 'days'));
    startWeek = addDays(startWeek, 7);
  }

  // if (isAfter(firstDayOfWeek, startOfDay(start)) || !isWeekend(start)) {
  //   weeks.push(firstDayOfWeek);
  // }

  weeks = eachWeekOfIntervalUTC({ start: firstDayOfWeek, end });
  //   return weeks;
  const baseIndex = weeks.indexOf(weeks.find((e) => isEqual(e, startWeek)));

  return weeks.map((week, index, totalWeeks) =>
    createWeek(
      index + 1 - baseIndex,
      { start: week, end: endOfDayUTC(addDays(week, 4, { in: utc })) },
      isNextMonth(week, index, totalWeeks)
    )
  );
}

export function getCalendarWeeks(plans) {
  const [plan] = plans;
  const weeks = generateCalendarWeeks(plan.issued.startDate, plan.issued.endDate);
  return weeks?.map((w) => ({ startDate: w.startDate, endDate: w.endDate }));
}

export function generateCalendarWeeksForPlans(plans) {
  const [plan] = plans;

  const allPlansActivities = plans.reduce((prev, acc) => [...prev, ...acc.activities], []);
  const minActDate = min(allPlansActivities.map((e) => e.period.startDate));
  const maxActDate = max(allPlansActivities.map((e) => e.period.endDate));

  const weeks = generateCalendarWeeks(
    plan.issued.startDate,
    plan.issued.endDate,
    minActDate,
    maxActDate
  );
  return weeks;
}

/** Holdays */
export function addHolidays(weeks, holidays) {
  const weeksWithHolidays = weeks.map((week) => {
    const holidaysForWeek = holidays.find(
      (ho) =>
        isWithinInterval(week.startDate, ho.interval) && isWithinInterval(week.endDate, ho.interval)
    );
    return { ...week, holidays: holidaysForWeek ? { ...holidaysForWeek } : undefined };
  });

  return weeksWithHolidays;
}

function isWeekLong(holiday) {
  return eachDayOfInterval(holiday.interval).length >= 5;
}

export function getHolidays(allHolidays) {
  const holidays = allHolidays.map((ho) => {
    return {
      ...ho,
      interval: {
        start: new Date(ho.duration.startDate),
        end: endOfDayUTC(new Date(ho.duration.endDate)),
      },
    };
  });
  return holidays.filter((h) => isWeekLong(h));
}
