const demo = {
  enableDemoMode: false,
  demo: {
    logoDirectsTo: 'home.leerplan',
    showReadOrder: false,
    demoVersion: '2024-2025', // changing this resets any locally stored demo-data. needs to be used when initializing a new schoolyear.
  },
};

export default demo;
