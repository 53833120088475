import './divider.scss';

const Divider = ({ mandatory }) => {
  return (
    <div
      data-testid="goal-divider"
      className={`divider 
      ${mandatory ? 'mandatory' : 'non-mandatory'}
      `}
    >
      <div className="line"></div>
    </div>
  );
};

export default Divider;
