import { useSelector } from 'react-redux';
import { DurationArrow, HoveredPopover } from '@UI/index';
import { selectIsActivityPlanReadOnly } from '@store/calendar/calendarSelectors';
import CurriculumGoalsIcon from '@UI/curriculumGoalsIcon/CurriculumGoalsIcon';
import IconCurrCalendar from '@assets/img/icon_curr_calendar.svg?react';
import IconPaperClip from '@assets/img/icon_paperclip.svg';
import AttachmentsPopover from '../popovers/AttachmentsPopover';

import './activity.scss';

// TODO: selection + selection mini
const Activity = ({ activity, onSelectActivity, onAddActivity, onModifyActivity }) => {
  const isEmpty = !!activity?.empty;
  const isSelection = useSelector((state) => state.calendar.selectionMode);
  const readOnly = useSelector(selectIsActivityPlanReadOnly);

  const onClickActivityHandler = () => {
    if (readOnly || isSelection) return;
    if (activity.empty) onAddActivity?.(activity);
    else onModifyActivity?.(activity);
  };

  if (!activity) return null;

  return (
    <article
      className={`activity ${isEmpty ? 'empty' : ''} ${readOnly ? 'read-only' : ''} ${
        isSelection ? 'selection' : ''
      }`}
      onClick={onClickActivityHandler}
    >
      <header>
        {isEmpty || activity.goals.length === 0 ? (
          <IconCurrCalendar width={30} className="icon-curr-calendar" />
        ) : (
          <CurriculumGoalsIcon goals={activity.goals} width={30} />
        )}

        <div className="class-info">
          <span>{activity.$$classesName}</span>
        </div>
        <div className="title" title={activity.title} style={{ color: activity.color?.text }}>
          {activity.title}
        </div>
      </header>

      <DurationArrow state={activity.arrowState} color={activity.color} />

      <main
        className="description"
        dangerouslySetInnerHTML={{ __html: activity.description }}
      ></main>

      {isSelection && !isEmpty && (
        <div className="selector-button" onClick={() => onSelectActivity?.(activity)}>
          overnemen
        </div>
      )}

      <footer>
        {activity.attachments?.length > 0 && (
          <HoveredPopover
            delay={500}
            content={<AttachmentsPopover attachments={activity.attachments} />}
          >
            <div className="att-button">
              <img src={IconPaperClip} width={20} />
            </div>
          </HoveredPopover>
        )}
      </footer>
    </article>
  );
};

export default Activity;
