import { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { VerticalTabs } from '@UI/index';
import { selectLeidraadTabs } from '@store/leidraad/leidraadSelectors';
import { saveLeidraadChoices } from '@store/userAndSchool/usersAndSchoolState';
import {
  selectCurrentSchoolData,
  selectCurrentSchoolHref,
} from '@store/userAndSchool/userAndSchoolSelectors';

import './leidraad.scss';
import { setAlert } from '@store/alerts/alertsState';
import { loadDocument } from '@store/contentApi/contentApiState';
import { CONTENTAPI_DOCUMENT_KEYS } from '@store/contentApi/contentApiDataAccess';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import LeidraadTab from './leidraadTab/LeidraadTab';

const Leidraad = () => {
  const { id } = useParams();
  const [saveDisabled, setSaveDisabled] = useState(false);
  const leidraadTabs = useSelector(selectLeidraadTabs);
  const currentSchoolHref = useSelector(selectCurrentSchoolHref);
  const leidraadChoices = useSelector((state) => selectCurrentSchoolData(state)?.leidraadChoices);

  const [tabs, setTabs] = useState(leidraadTabs);
  const dispatch = useDispatch();

  const onSaveHandler = async () => {
    setSaveDisabled(true);
    const leidraadChoicesToSave = tabs.map((tab) => {
      const response = { ...tab.tabData };
      const href = `/content/${tab.key}`;

      if (response.coreItems)
        response.coreItems = response.coreItems?.filter((item) => !!item.title);
      if (response.priorities)
        response.priorities = response.priorities?.filter((item) => !!item.title);
      if (response.educationalPointers)
        response.educationalPointers = response?.educationalPointers
          ?.filter((pointer) => pointer.selected)
          .map((pointer) => ({ href: pointer.pointer.$$meta.permalink }));

      const choicesToSave = leidraadChoices.find((choice) => choice.section.href === href);

      return { ...choicesToSave, response };
    });

    dispatch(
      saveLeidraadChoices({ schoolHref: currentSchoolHref, leidraadChoices: leidraadChoicesToSave })
    );
    setSaveDisabled(false);
  };

  useEffect(() => {
    setTabs(leidraadTabs);
    if (leidraadTabs === null) {
      dispatch(loadDocument({ key: CONTENTAPI_DOCUMENT_KEYS.LEIDRAAD }));
      dispatch(loadDocument({ key: CONTENTAPI_DOCUMENT_KEYS.EDUCATIONAL_POINTERS }));
    }
  }, [leidraadTabs, dispatch]);

  const getCurrentlySelectedPriorities = (tabList) => {
    return tabList.reduce((counter, tab) => {
      const applicableList = tab.tabData.priorities ? 'priorities' : 'coreItems';
      if (Array.isArray(tab.tabData[applicableList])) {
        const priorities = tab.tabData[applicableList].filter(
          (priority) => priority.includeInCurriculum
        );
        return counter + priorities.length;
      }
      return counter;
    }, 0);
  };

  const onTabUpdateHandler = (tab) => {
    const newTabs = tabs.map((t) => (t.key === tab.key ? tab : t));
    // validate the selected priorities
    const priosInChangedTab = getCurrentlySelectedPriorities([tab]);
    const priosInOriginalTab = getCurrentlySelectedPriorities([
      tabs.find((t) => t.key === tab.key),
    ]);
    const isSelectable =
      priosInChangedTab !== priosInOriginalTab && getCurrentlySelectedPriorities(newTabs) <= 5;
    if (isSelectable || priosInChangedTab <= priosInOriginalTab) {
      setTabs(newTabs);
    } else {
      dispatch(
        setAlert({
          key: 'max-prio-warning',
          msg: 'Je kan maximaal 5 prioriteiten visualiseren in de leerplannen',
          title: 'Opgelet',
          type: 'warning',
        })
      );
    }
  };

  if (!tabs) {
    return (
      <div className="leidraad">
        <CircularProgress size={50} sx={{ m: 'auto', display: 'block', marginTop: '150px' }} />
      </div>
    );
  }

  return (
    <div className="leidraad">
      <VerticalTabs headers={tabs} activeTabKey={id}>
        {tabs.map((tab) => (
          <LeidraadTab
            tab={tab}
            key={tab.key}
            saveDisabled={saveDisabled || !tab.editable}
            onSave={onSaveHandler}
            onTabUpdate={onTabUpdateHandler}
          />
        ))}
      </VerticalTabs>
    </div>
  );
};

Leidraad.propTypes = {
  id: string,
};

export default Leidraad;
