import { uniqBy } from 'lodash-es';
import { HoveredPopover } from '@UI/index';
import GoalsPopover from '@components/calendar/popovers/GoalsPopover';
import IconCurrCalendar from '@assets/img/icon_curr_calendar.svg?react';

import './curriculumGoalsIcon.scss';

const CurriculumGoalsIcon = ({ goals = [] }) => {
  const hasDisappearGoals = goals.some((g) => g.disappeared);
  const getSortedGoals = (activityGoals) => {
    const sorted = activityGoals.sort((a, b) =>
      a.identifier.localeCompare(b.identifier, undefined, { numeric: true, sensitivity: 'base' })
    );
    return uniqBy(sorted, 'key');
  };
  const sortedGoals = getSortedGoals(goals);

  const icon = (
    <div className={`goals-icon ${sortedGoals?.length === 0 ? 'disabled' : ''}`}>
      <IconCurrCalendar width={30} />
    </div>
  );

  return (
    <div className="curriculum-goals-icon">
      {sortedGoals?.length === 0 && icon}
      {sortedGoals?.length !== 0 && (
        <HoveredPopover
          className="goals-popover-wrapper"
          delay={500}
          content={<GoalsPopover goals={sortedGoals} />}
        >
          {icon}
        </HoveredPopover>
      )}
      {hasDisappearGoals && (
        <div className="disappear-goal-wrapper">
          <HoveredPopover content="Deze activiteit bevat doelen uit een leerplan dat niet geldig is in de huidige context">
            <div className="disappear-goal-icon">⚠️</div>
          </HoveredPopover>
        </div>
      )}
    </div>
  );
};

export default CurriculumGoalsIcon;
