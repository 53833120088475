import { Compiler } from '@kathondvla/react-shared-components/src/components';
import { TextField } from '@UI/index';
import { useRef, useState } from 'react';

import './editableTitle.scss';

const EditableTitle = ({ editable, compilerOptions, children, debounce, onEdit, placeholder }) => {
  const [value, setValue] = useState(children);
  const debounceRef = useRef();

  const onChangeHandler = (newValue) => {
    if (debounce) {
      if (debounceRef.current) clearTimeout(debounceRef.current);
      debounceRef.current = setTimeout(() => {
        onEdit?.(newValue);
      }, debounce);
    } else {
      onEdit?.(newValue);
    }
    setValue(newValue);
  };

  if (editable) {
    return (
      <TextField
        className="editable-title input"
        value={value || ''}
        placeholder={placeholder}
        onChange={onChangeHandler}
      />
    );
  }

  return (
    <h3 className="editable-title">
      <Compiler text={children} options={compilerOptions} />
    </h3>
  );
};

export default EditableTitle;
