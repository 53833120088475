import { extractResourceName } from '@store/apihelpers';
import { getKeyFromHref } from '@utils/getKeyFromHref';
import { BatchRequest } from '../../types/sriTypes';
import { ApiMeta, TableItemsMap } from '../../types/genericApiTypes';
import { getLlinkidDataFromFile, saveLlinkidDataToFile } from '../../app/fileHelpers';
import { LeidraadChoice } from '../../types/leidraadApiTypes';

export async function getChoicesFromPrivateFS(context: string): Promise<LeidraadChoice[] | null> {
  return getLlinkidDataFromFile('choices', context, 'no-schoolyear');
}

export async function saveChoicesToPrivateFS(batch: BatchRequest, context: string): Promise<void> {
  const modified = new Date().toISOString();

  const tableItemsMap = batch.reduce((acc, batchItem) => {
    const { href, verb, body: batchitem } = batchItem;
    const table = extractResourceName(href);
    // we do not keep activityplangroups in indexedDB
    if (!table) return acc;

    const $$meta = { permalink: href, modified } as Partial<ApiMeta>;
    acc[table] = acc[table] || [];

    if (verb === 'PUT' || verb === 'POST') {
      acc[table].push({ ...batchitem, $$meta });
    } else if (verb === 'DELETE') {
      $$meta.deleted = true;
      acc[table].push({ $$meta, key: getKeyFromHref(href) }); // create an object with a key, which the indexedDB worker needs to do the delete.
    }

    return acc;
  }, {} as TableItemsMap);

  await saveLlinkidDataToFile(tableItemsMap.choices, 'choices', context, 'no-schoolyear');
  console.log('changes saved to privateFS');
}
