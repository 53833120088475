import { Modal } from '@kathondvla/react-shared-components/src/components';
import { FormGroup, FormSelect, RadioButton, ToggableButton } from '@UI/index';
import { formatCalendarPrintOptionWeeks, setToDateAfterFromDate } from '@utils/utils';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import iconPrinterWhite from '@assets/img/icon_printer_white.svg';
import iconPrintOther from '@assets/img/print_view_other.jpg';
import iconPrintRegular from '@assets/img/print_view_regular.jpg';
import { Button } from '@mui/material';
import { requiredValidation } from '@utils/validations';
import './modals.scss';

const VIEW_TYPES = {
  REGULAR: 'regular',
  OTHER: 'other',
};

const CalendarPrintModal = ({ weeks, classes, onConfirm, onClose }) => {
  const periodOptions = formatCalendarPrintOptionWeeks(weeks);
  const [periodFromOptions] = useState(periodOptions.startDates);
  const [periodToOptions, setPeriodToOptions] = useState(periodOptions.endDates);
  const [allClasses, setAllClasses] = useState(classes);
  const [isClassSelectorValid, setClassSelectorValid] = useState(false);

  const toggleActiveClass = (key) => {
    setAllClasses(allClasses.map((c) => (c.key === key ? { ...c, selected: !c.selected } : c)));
  };

  useEffect(() => {
    setClassSelectorValid(allClasses.some((c) => c.selected));
  }, [allClasses]);

  const { control, handleSubmit, watch, setValue, getValues, register, trigger } = useForm();
  const [fromSelect] = watch(['from']);

  useEffect(() => {
    const { from: fromValue, to: toValue } = getValues();
    const { to, toOptions } = setToDateAfterFromDate(fromValue, toValue, periodOptions);
    setPeriodToOptions(toOptions);
    setValue('to', to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromSelect]);

  return (
    <>
      <Modal.Header title="Afdrukken" icon={iconPrinterWhite} onClose={onClose} />

      <Modal.Body>
        <FormGroup label="Periode" required>
          <section className="modal-period">
            <FormSelect
              name="from"
              value={periodFromOptions[0]?.value}
              options={periodFromOptions}
              control={control}
              validations={{
                required: (value) => requiredValidation(value),
              }}
            />
            <span>tot</span>
            <FormSelect
              name="to"
              value={periodToOptions[periodToOptions.length - 1]?.value}
              options={periodToOptions}
              control={control}
              validations={{
                required: (value) => requiredValidation(value),
              }}
            />
          </section>
        </FormGroup>

        <FormGroup label="Klassen" subtitle="Welk leerplan dient als basis?" required>
          <section className="modal-classes">
            {allClasses?.map((c) => (
              <ToggableButton
                key={c.key}
                isActive={c.selected}
                activeColor={c?.color?.background}
                onClick={() => toggleActiveClass(c.key)}
              >
                {c.$$displayName}
              </ToggableButton>
            ))}
          </section>
          {!isClassSelectorValid && <span className="error-msg">Verplicht veld</span>}
        </FormGroup>

        <FormGroup label="Print type" required>
          <section className="modal-type">
            <RadioButton
              {...register('view', { value: VIEW_TYPES.REGULAR })}
              value={VIEW_TYPES.REGULAR}
            >
              <img className="regular-type" src={iconPrintRegular} alt="regular view" />
            </RadioButton>
            <RadioButton {...register('view')} value={VIEW_TYPES.OTHER}>
              <img className="other-type" src={iconPrintOther} alt="other view" />
            </RadioButton>
          </section>
        </FormGroup>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outlined" onClick={onClose}>
          Annuleren
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(async (form) => {
            const result = await trigger();
            if (!result || !isClassSelectorValid) return;
            onConfirm({
              ...form,
              classes: allClasses,
            });
          })}
        >
          Afdrukken
        </Button>
      </Modal.Footer>
    </>
  );
};

export default CalendarPrintModal;
