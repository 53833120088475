import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ApiContentRelation,
  ApiContentWith$$Relations,
  CurriculumRoot,
} from '../../types/contentApiTypes';
import {
  ContentApiState,
  LoadCurriculumPayload,
  LoadDocumentPayload,
  LoadGoalsPayload,
  SetCurriculumPayload,
  SetDocumentPayload,
  SetGoalPayload,
  SetGoalsCorrelationsPayload,
} from './ContentApiStateTypes';

function initDocumentLoadStatus() {
  return {
    isLoaded: false,
    isFailed: false,
  };
}

function init(): ContentApiState {
  return {
    curriculaRoots: [],
    curriculaRootsLoading: false,
    curriculaGoalLists: [],
    curriculaGoalListsLoading: false,
    curriculaRelations: [],
    curriculaRelationsLoading: false,
    isFailed: false,
    curriculaToLoad: {},
    curricula: {},
    goalsToLoad: {},
    goals: {},
    documentsToLoad: {},
    documents: {},
    goalsCorrelationsLoading: false,
    goalsCorrelationsFailed: false,
    goalsCorrelations: {},
  };
}

const initialState = init();

const contentApiDataState = createSlice({
  name: 'contentApiData',
  initialState,
  reducers: {
    initCurriculaGoalLists: (state) => {
      state.curriculaGoalListsLoading = true;
      state.isFailed = false;
    },
    initCurriculaRoots: (state) => {
      state.curriculaRootsLoading = true;
      state.isFailed = false;
    },
    initCurriculaRelations: (state) => {
      state.curriculaRelationsLoading = true;
      state.isFailed = false;
    },

    setCurriculaGoalLists: (state, action: PayloadAction<ApiContentWith$$Relations[]>) => {
      state.curriculaGoalListsLoading = false;
      state.curriculaGoalLists = action.payload;
    },
    setCurriculaRoots: (state, action: PayloadAction<CurriculumRoot[]>) => {
      state.curriculaRootsLoading = false;
      state.curriculaRoots = action.payload;
    },
    setCurriculaRelations: (state, action: PayloadAction<ApiContentRelation[]>) => {
      state.curriculaRelationsLoading = false;
      state.curriculaRelations = action.payload;
    },

    initCurriculaRootsFailed: (state) => {
      state.curriculaRootsLoading = false;
      state.isFailed = true;
    },
    initCurriculaGoalListsFailed: (state) => {
      state.curriculaGoalListsLoading = false;
      state.isFailed = true;
    },
    initCurriculaRelationsFailed: (state) => {
      state.curriculaRelationsLoading = false;
      state.isFailed = true;
    },

    loadDocument: (state, action: PayloadAction<LoadDocumentPayload>) => {
      const { key, refresh } = action.payload;
      if (!state.documents[key] || refresh) {
        state.documentsToLoad[key] = initDocumentLoadStatus();
      }
    },
    setDocument: (state, action: PayloadAction<SetDocumentPayload>) => {
      const { key, document } = action.payload;
      state.documentsToLoad[key].isLoaded = true;
      state.documents[key] = document;
    },
    loadDocumentFailed: (state, action: PayloadAction<{ key: string }>) => {
      const { key } = action.payload;
      state.documentsToLoad[key].isLoaded = false;
      state.documentsToLoad[key].isFailed = true;
    },

    loadCurriculum: (state, action: PayloadAction<LoadCurriculumPayload>) => {
      const { curriculumKey, refresh } = action.payload;
      if (!state.curricula[curriculumKey] || refresh) {
        state.curriculaToLoad[curriculumKey] = initDocumentLoadStatus();
      }
    },
    setCurriculum: (state, action: PayloadAction<SetCurriculumPayload>) => {
      const { curriculumKey, curriculum } = action.payload;
      state.curriculaToLoad[curriculumKey].isLoaded = true;
      state.curricula[curriculumKey] = curriculum;
    },
    loadCurriculumFailed: (state, action: PayloadAction<{ curriculumKey: string }>) => {
      const { curriculumKey } = action.payload;
      state.curriculaToLoad[curriculumKey].isLoaded = false;
      state.curriculaToLoad[curriculumKey].isFailed = true;
    },
    loadGoals: (state, action: PayloadAction<LoadGoalsPayload>) => {
      const { keys, refresh } = action.payload;
      keys.forEach((key) => {
        if (!state.goalsToLoad[key] || refresh) {
          state.goalsToLoad[key] = initDocumentLoadStatus();
        }
      });
    },
    setGoal: (state, action: PayloadAction<SetGoalPayload>) => {
      const { key, goal } = action.payload;
      state.goalsToLoad[key].isLoaded = true;
      state.goals[key] = goal;
    },
    loadGoalFailed: (state, action: PayloadAction<{ key: string }>) => {
      const { key } = action.payload;
      state.goalsToLoad[key].isLoaded = false;
      state.goalsToLoad[key].isFailed = true;
    },
    loadGoalsCorrelations: (state) => {
      state.goalsCorrelationsLoading = true;
    },
    loadGoalsCorrelationsFailed: (state) => {
      state.goalsCorrelationsLoading = false;
      state.goalsCorrelationsFailed = true;
    },
    setGoalsCorrelations: (state, action: PayloadAction<SetGoalsCorrelationsPayload>) => {
      const { curriculaCorrelation } = action.payload;
      state.goalsCorrelationsLoading = false;
      state.goalsCorrelations = curriculaCorrelation;
    },
  },
});

export const {
  initCurriculaGoalLists,
  initCurriculaRoots,
  initCurriculaRelations,
  setCurriculaGoalLists,
  setCurriculaRoots,
  setCurriculaRelations,
  initCurriculaGoalListsFailed,
  initCurriculaRootsFailed,
  initCurriculaRelationsFailed,
  loadCurriculum,
  setCurriculum,
  loadCurriculumFailed,
  loadDocument,
  setDocument,
  loadDocumentFailed,
  loadGoals,
  setGoal,
  loadGoalFailed,
  loadGoalsCorrelations,
  loadGoalsCorrelationsFailed,
  setGoalsCorrelations,
} = contentApiDataState.actions;
export default contentApiDataState;
