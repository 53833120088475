import SchoolSelector from '@components/layout/header/schoolSelector/schoolSelector';
import SchoolYearSelector from '@components/layout/header/schoolYearSelector/schoolYearSelector';
import Header from '@kathondvla/react-shared-components/src/components/header/Header';
import { getLoginUrl } from '@store/apihelpers';
import { selectUser } from '@store/userAndSchool/userAndSchoolSelectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LlinkidLogo from '@assets/img/llinkid-logo.svg';
import {
  selectShouldRenderDistributionMode,
  selectShouldRenderHideOptionalElements,
  selectUserProfileImageUrl,
} from '@components/layout/header/HeaderSelectors';
import HomeIcon from '@mui/icons-material/Home';
import { IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import settings from '@config/settings';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import SettingsIcon from '@mui/icons-material/Settings';
import DemoLabel from './demoLabel/DemoLabel';
import DistributionModeSwitch from './distributionModeSwitch';
import EndResponsibilitiesCheckbox from './endResponsibilitiesCheckbox/EndResponsibilitiesCheckbox';
import HideOptionalElementsSwitch from './hideOptionalElementsSwitch';
import SchoolEndDateField from './schoolEndDateField/SchoolEndDateField';
import TestUserSelector from './testUserSelector/testUserSelector';
import './headerWrapper.scss';

const HeaderWrapper = () => {
  const user = useSelector(selectUser);
  const [isCurriculumTab, setIsCurriculumTab] = useState(true);
  const shouldRenderHideOptionalElements = useSelector(selectShouldRenderHideOptionalElements);
  const shouldRenderDistributionMode = useSelector(selectShouldRenderDistributionMode);
  const loginUrl = getLoginUrl(window.location.href);
  const userProfileImageUrl = useSelector(selectUserProfileImageUrl);

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setIsCurriculumTab(
      location.pathname.includes('/leerplan') &&
        (location.pathname.includes('/doelenlijst') || location.pathname.includes('/distributie'))
    );
  }, [location.pathname]);

  const menuOptions = [
    {
      title: 'Afmelden',
      icon: LogoutIcon,
      onClick: () =>
        window.open(
          `${settings.oauth.oauthURL}/logout?redirect_uri=${window.location.origin}`,
          '_self'
        ),
    },
    {
      title: 'Instellingen',
      icon: SettingsIcon,
      onClick: () => window.open(settings.apisAndUrls.myVsko, '_blank'),
    },
  ];

  const handleHomeClick = () => {
    navigate('/home/leerplan');
  };

  const isHome = () => location.pathname.includes('home');

  const isCalendar = () => location.pathname.includes('vorderingsplannen');

  return (
    <div className="ll-header-bar">
      <Header
        logoImage={LlinkidLogo}
        onLogoClick={handleHomeClick}
        menuOptions={menuOptions}
        user={user}
        userProfileImageUrl={userProfileImageUrl}
        loginUrl={loginUrl}
        anonymousUserIcon={LoginIcon}
      >
        <div className="header-left">
          {!isHome() && (
            <IconButton data-testid="home-icon" id="homeIcon" onClick={handleHomeClick}>
              <HomeIcon />
            </IconButton>
          )}
          {settings.enableDemoMode && <DemoLabel />}
          {settings.enableMswInBrowser && !settings.enableDemoMode && (
            <div data-testid="mock-label" className="mock-label">
              MOCK
            </div>
          )}
        </div>
        {isHome() && (
          <>
            {settings.enableMswInBrowser && !settings.enableDemoMode && (
              <>
                <SchoolEndDateField />
                <EndResponsibilitiesCheckbox />
              </>
            )}
            {(settings.enableMswInBrowser || settings.enableDemoMode) && <TestUserSelector />}
            <SchoolSelector />
            <SchoolYearSelector />
          </>
        )}
        {!isHome() && (
          <>
            {!isCalendar() && shouldRenderHideOptionalElements && <HideOptionalElementsSwitch />}
            {!isCalendar() && shouldRenderDistributionMode && isCurriculumTab && (
              <DistributionModeSwitch />
            )}
          </>
        )}
      </Header>
    </div>
  );
};

export default HeaderWrapper;
