import { Children } from 'react';
import VerticalTabsHeader from './VerticalTabsHeader';
import './verticalTabs.scss';

const VerticalTabs = ({ headers = [], children, activeTabKey }) => {
  const selectedTab = headers.findIndex((h) => h.key === activeTabKey) ?? 0;
  const tabs = Children.toArray(children);

  return (
    <div className="vertical-tabs">
      <VerticalTabsHeader items={headers} />
      <main className="tabs-content">{tabs[selectedTab]}</main>
    </div>
  );
};

export default VerticalTabs;
