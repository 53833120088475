import { useState } from 'react';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { IconButton } from '@mui/material';
import './favButton.scss';

const FavButton = ({ faved, readOnly, onClick }) => {
  const [isFav, setFav] = useState(faved);
  const toggleFav = (ev) => {
    ev.preventDefault();
    if (readOnly) return null;

    setFav((prevVal) => !prevVal);
    return onClick(isFav);
  };

  return (
    <>
      <IconButton size="small" className="fav-button" onClick={toggleFav}>
        {isFav && <StarIcon htmlColor="#ffac1c" />}
        {!isFav && !readOnly && <StarBorderIcon color="gray" className="empty-fav" />}
      </IconButton>
    </>
  );
};

export default FavButton;
