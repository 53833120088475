import { createSelector } from '@reduxjs/toolkit';
import { CONTENTAPI_DOCUMENT_KEYS } from '@store/contentApi/contentApiDataAccess';
import { selectEducationalPointers } from '@store/contentApi/contentApiSelectors';
import { groupPointers, translatePointers } from '@store/curriculum/curriculumAndAnnotationHelper';
import {
  selectCurrentSchoolData,
  selectCurrentSchoolHref,
  selectIsSchoolyearReadOnly,
  selectPrincipalSchoolHrefs,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { cloneWithJson, translatePointer } from '@utils/utils';
import { LEIDRAAD_TABS } from '../../constants/leidraadTabs';

function tabIsComplete(tabData) {
  return Object.keys(tabData).every((property) => {
    const value = tabData[property];
    if (Array.isArray(value)) {
      return !!value.length;
    }
    return !!value;
  });
}

function getTabSection(origTab, leidraadChoices, editable, leidraadData, allPointers) {
  const tab = cloneWithJson(origTab);
  const sectionHref = `/content/${tab.params.id}`;

  if (leidraadChoices) {
    const choices = leidraadChoices.find((choice) => choice.section.href === sectionHref);
    if (choices && choices.response) {
      Object.assign(tab.tabData, choices.response);
      tab.completed = tabIsComplete(tab.tabData);
    }
    tab.editable = editable;

    const tabRoot = leidraadData.find((item) => item.key === tab.params.id);
    tab.root = tabRoot;
    tab.items = leidraadData;

    const applicableList =
      tab.params.id === 'b16196c4-a455-43e4-8ac8-fb7fb0d7eb5a' ? 'coreItems' : 'priorities';

    tab.tabData[applicableList] = tab.tabData?.[applicableList]?.length
      ? tab.tabData[applicableList]
      : [
          { title: '', includeInCurriculum: false },
          { title: '', includeInCurriculum: false },
          { title: '', includeInCurriculum: false },
        ];

    if (tab.params.id === 'b16196c4-a455-43e4-8ac8-fb7fb0d7eb5a') {
      const educationalPointers = allPointers
        .map((pointer) => translatePointer(pointer, tab.tabData.educationalPointers))
        .filter((e) => e);
      tab.tabData.educationalPointers = educationalPointers;
    }
  }
  return tab;
}

export const selectLeidraadTabs = createSelector(
  [
    (state) => state.contentApiData.documents[CONTENTAPI_DOCUMENT_KEYS.LEIDRAAD],
    selectEducationalPointers,
    (state) => selectCurrentSchoolData(state)?.leidraadChoices,
    (state) =>
      !!selectPrincipalSchoolHrefs(state).find((org) => org === selectCurrentSchoolHref(state)),
    (state) => selectIsSchoolyearReadOnly(state),
  ],
  (leidraad, educationalPointers, leidraadChoices, isDirector, isSchoolyearReadOnly) => {
    if (!leidraad || !leidraadChoices) {
      return null;
    }
    const editable = isDirector && !isSchoolyearReadOnly;
    const result = LEIDRAAD_TABS.map((tab) =>
      getTabSection(tab, leidraadChoices, editable, leidraad, educationalPointers)
    );
    console.log('leidraadresult', result);
    return result;
  }
);

export const selectLeidraadTop5AndEduPointers = createSelector(
  [(state) => selectCurrentSchoolData(state)?.leidraadChoices, selectEducationalPointers],
  (leidraadChoices, allEducationalPointers) => {
    const choices = leidraadChoices || [];
    let priorities = [];
    let educationalPointers = [];
    choices.forEach((item) => {
      if (item.response.priorities) {
        priorities = priorities.concat(
          item.response.priorities.filter((e) => e.includeInCurriculum === true)
        );
      }

      if (item.response.coreItems) {
        priorities = priorities.concat(
          item.response.coreItems.filter((e) => e.includeInCurriculum === true)
        );
        educationalPointers = [
          ...educationalPointers,
          ...(item.response.educationalPointers || []),
        ]; // to avoid API duplicates... this is bad tho
      }
    });

    const principles = priorities.map((priority) => ({
      description: priority.title,
    }));

    let translatedAndGroupedPointers = [];
    if (allEducationalPointers) {
      translatedAndGroupedPointers = groupPointers(
        translatePointers(educationalPointers, allEducationalPointers),
        'SCHOOL'
      );
    }
    const result = {
      principles,
      educationalPointers: translatedAndGroupedPointers,
    };

    // console.log('selectLeidraadTop5AndEduPointers', result);
    return result;
  }
);
