import { SchoolsCard } from '@UI/index';
import { Stack } from '@mui/material';
import './schoolsHome.scss';

const SchoolsModalView = ({ user, schools, selected, loading = false, onSelect }) => {
  const name = `${user.firstName} ${user.lastName}`;

  return (
    <div className="schools-modal-view">
      <header>
        <span className="user-name">{name}</span>
        <span className="subtitle">Kies je school.</span>
      </header>
      <main>
        <Stack spacing={3}>
          {schools.map((school) => (
            <SchoolsCard
              key={school.key}
              title={school.$$displayName}
              loading={loading}
              selected={school.key === selected}
              addresses={school.$$addresses}
              onClick={() => onSelect?.(school.key)}
            ></SchoolsCard>
          ))}
        </Stack>
      </main>
      <footer>
        <span>Ontbreekt er een school ?</span>
        <span>Contacteer de ICT-coördinator of directeur van die school.</span>
      </footer>
    </div>
  );
};

export default SchoolsModalView;
