import { useMemo } from 'react';

import './calendarList.scss';

const CalendarListLoader = () => {
  const itemsBuilder = useMemo(() => {
    const items = [];
    for (let i = 0; i < 4; i += 1) {
      items.push(
        <div className="results leerplan-list ghost" key={i}>
          <div className="ghost-class"></div>
          <div className="ghost-plan"></div>
          <div className="ghost-teachers"></div>
        </div>
      );
    }
    return items;
  }, []);

  return (
    <div className="calendar-list-loader" data-testid="calendar-list-loader">
      {itemsBuilder}
    </div>
  );
};

export default CalendarListLoader;
