import Footer from '@components/layout/footer/Footer';
import HeaderWrapper from '@components/layout/header/headerWrapper';
import SidebarMenu from '@components/layout/sidebarMenu';

import { Outlet } from 'react-router-dom';

import './home.scss';

const Home: React.FC = () => {
  return (
    <div className="ll-home">
      <div className="home-group">
        <HeaderWrapper></HeaderWrapper>

        <div className="home-content">
          <div className="sidebar-menu">
            <div className="tabs-pane">
              <div className="tabs-pane-titles">
                <SidebarMenu />
              </div>

              <div className="tabs-pane-containers">
                <Outlet />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Home;
