import { array } from 'prop-types';
import { HoveredPopover } from '@UI/index';
import iconSuggestion from '@assets/img/icon_suggestion.svg';
import './suggestionLink.scss';

const SuggestionLink = ({ suggestions }) => {
  if (!suggestions || suggestions.length === 0) return null;
  const title = suggestions.map((e) => e.description).join(', ');

  return (
    <div className="suggestion-link">
      <span className="suggestion-name">
        <HoveredPopover className="suggestion-link-tooltip" content={title}>
          <img src={iconSuggestion} />
        </HoveredPopover>
      </span>
    </div>
  );
};

SuggestionLink.propTypes = {
  suggestions: array,
};

export default SuggestionLink;
