import { useState } from 'react';

const useModal = (onConfirm, onClose) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState();

  const onConfirmHandler = (ev) => {
    if (onConfirm) onConfirm(ev);
    setIsOpen(false);
  };

  const onCloseHandler = (ev) => {
    if (onClose) onClose(ev);
    setIsOpen(false);
  };

  const open = (props) => {
    setIsOpen(true);
    setModalProps(props);
  };

  return { isOpen, open, onConfirm: onConfirmHandler, onClose: onCloseHandler, modalProps };
};

export default useModal;
