import { useMemo } from 'react';
import { FormGroup, FormSelect } from '@UI/index';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import { useForm } from 'react-hook-form';

import './modals.scss';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectPrincipalTeamsForSchoolyear } from '@store/userAndSchool/userAndSchoolSelectors';
import { requiredValidation } from '@utils/validations';
import iconAddWhite from '@assets/img/add_white.svg';

const DistributeMultipleTeamsModal = ({ curricula, version, school, onConfirm, onClose }) => {
  const teams = useSelector(selectPrincipalTeamsForSchoolyear);
  const teamSelectOptions = useMemo(
    () => teams.map((e) => ({ name: e.$$displayName, value: e.$$meta.permalink })),
    [teams]
  );

  const { control, handleSubmit, formState } = useForm({ mode: 'onChange' });

  return (
    <>
      <Modal.Header title="Funderend leerplan verdelen" icon={iconAddWhite} onClose={onClose} />

      <Modal.Body>
        <FormGroup label="School" subtitle="Voor welke school wil je dit leerplan maken?">
          <span className="modal-school-name">{school?.$$displayName}</span>
        </FormGroup>
        <FormGroup
          label="Groep"
          subtitle="Voor welke groep wil je dit funderend leerplan verdelen?"
          required
        >
          <FormSelect
            name="team"
            placeholder="Selecteer een groep"
            options={teamSelectOptions}
            control={control}
            error={formState.errors?.team}
            validations={{
              required: (value) => requiredValidation(value),
            }}
          />
        </FormGroup>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outlined" onClick={onClose}>
          Annuleren
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit((form) => {
            onConfirm({ ...form, curricula, version, school });
          })}
        >
          Doorgaan
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DistributeMultipleTeamsModal;
