import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FavButton, Checkbox, Highlight, CurriculaTypeIcon, HoveredPopover } from '@UI/index';
import { leerplanListActions } from '@store/leerplanList/leerplanListState';
import { getCurriculum } from '@store/contentApi/contentApiDataAccess';
import useHoverItem from '@hooks/useHoverItem';
import { startLog } from '@utils/loggerHelper';
import './leerplanListItem.scss';
import { CircularProgress } from '@mui/material';
import expandCloseImg from '@assets/img/expand_close.png';
import expandOpenImg from '@assets/img/expand_open.png';
import { useNavigate } from 'react-router-dom';
import LeerplanListItemActions from './LeerplanListItemActions';

const TYPES = {
  PARENT: 'PARENT',
  CHILD: 'CHILD',
};

const testObj = {};

const LeerplanListItem = ({
  item,
  type,
  onFav,
  onSelect,
  selectionMode,
  expired,
  onEditNonderived,
  onCreateSchoolCurricula,
  onCreateTeamCurricula,
  onCreatePersonalCurricula,
  onDistributeMultipleTeams,
  onDelete,
}) => {
  const [curriculumClicked, setCurriculumClicked] = useState(false);
  const filter = useSelector((state) => state.leerplanList.filters.name);
  const dispatch = useDispatch();
  const isChild = type === TYPES.CHILD;
  const onFavHandler = () => onFav(item);
  const selectionMultiple = false;
  const navigate = useNavigate();

  const { hoverRef } = useHoverItem((isHoveringWithIntent) => {
    if (isHoveringWithIntent) {
      testObj[item.key] = new Date();
      // console.log({ item, isHovering: isHoveringWithIntent });
      if (item.id !== 'nonderived') {
        getCurriculum(item.id); // we want to prefetch, but not yet fill the state.
        // dispatch(loadCurriculum({ curriculumKey: item.id }));
      }
    }
  });

  const toggleExpand = React.useCallback(() => {
    dispatch(leerplanListActions.toggleListItemExpand(item));
  }, [dispatch, item]);

  const goToCurr = () => {
    setCurriculumClicked(true);
    const saved = new Date() - testObj[item.key];
    console.log('saved', saved, 'ms');
    startLog('open curriculum');

    const paramsObj = {
      custid: item.customCurriculumParameter,
      setid: item.customCurriculaGroupParameter,
      groupid: item.groupid,
      studid: item.type !== 'PLAN' ? item.studid : null,
    };

    // Filter out undefined or null properties
    const filteredParams = Object.fromEntries(
      Object.entries(paramsObj).filter(([_, value]) => value)
    );

    const params = new URLSearchParams(filteredParams);
    const url = `/leerplan/${item.id}/doelenlijst?${params.toString()}`;
    navigate(url);
  };
  const onSelectHandler = ({ redirect }) => {
    if (!selectionMode) {
      if (redirect) goToCurr();
      return;
    }
    onSelect(item);
  };

  return (
    <article ref={hoverRef} className="leerplan-list-item" data-testid="leerplan-list-item">
      <div className="list-item">
        {selectionMode && selectionMultiple && (
          <Checkbox checked={item.selected} onChange={onSelectHandler} />
        )}
        {!isChild && <FavButton faved={item.fav} readOnly={selectionMode} onClick={onFavHandler} />}
        <div
          className={`expand-icon ${type !== TYPES.PARENT ? 'hidden' : ''} ${
            isChild ? 'child' : ''
          }`}
          data-testid="expand-icon"
          onClick={toggleExpand}
        >
          <img src={!item.expand ? expandCloseImg : expandOpenImg} />
        </div>

        <CurriculaTypeIcon
          type={isChild ? type : item.type}
          nonderived={item.id === 'nonderived'}
          onClick={onSelectHandler}
        />

        <div
          className={`item-text ${item.latest ? 'latest' : ''} ${
            selectionMode ? 'full-width' : ''
          }`}
          onClick={() => onSelectHandler({ redirect: true })}
        >
          <Highlight text={item.name} search={filter} highlightClass="highlight" />
          {item.versionNumber && <span className="version"> ({item.versionNumber})</span>}
          {item.version && <span className="version">({item.version.name})</span>}
          {item.valid === false && (
            <HoveredPopover
              className="curricula-disappear-popover"
              content="Dit leerplan is niet geldig is in de huidige context."
            >
              ⚠️
            </HoveredPopover>
          )}
        </div>

        {curriculumClicked && <CircularProgress color="primary" size={20} sx={{ ml: 2, mr: 2 }} />}

        {!selectionMode && (
          <LeerplanListItemActions
            key={item.key}
            item={item}
            expired={expired}
            onEditNonderived={onEditNonderived}
            onCreateSchoolCurricula={onCreateSchoolCurricula}
            onCreateTeamCurricula={onCreateTeamCurricula}
            onCreatePersonalCurricula={onCreatePersonalCurricula}
            onDistributeMultipleTeams={onDistributeMultipleTeams}
            onDelete={onDelete}
          />
        )}
      </div>
      {item.expand &&
        item.curricula?.map((curr) => (
          <LeerplanListItem
            key={curr.key}
            item={curr}
            type={TYPES.CHILD}
            onFav={onFav}
            onSelect={onSelect}
            onEditNonderived={onEditNonderived}
            onCreateSchoolCurricula={onCreateSchoolCurricula}
            onCreateTeamCurricula={onCreateTeamCurricula}
            onCreatePersonalCurricula={onCreatePersonalCurricula}
            onDistributeMultipleTeams={onDistributeMultipleTeams}
            onDelete={onDelete}
            selectionMode={selectionMode}
          />
        ))}
    </article>
  );
};

export default LeerplanListItem;
