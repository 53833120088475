import { createSelector } from '@reduxjs/toolkit';
import {
  selectCurriculaWithMultipleActiveVersions,
  selectLlinkidCurriculaRootsAndGoalLists,
} from '@store/contentApi/contentApiSelectors';
import {
  selectAnnotatedCurriculumGoalListData,
  selectBaseCurriculumViewModel,
  selectBaseNonDerivedCurriculumViewModel,
  selectCanEditCustomCur,
  selectCreator,
  selectCurriculumGoalHrefs,
  selectCustomCurriculaFromUrlOrParams,
  selectFullAnnotatedCurriculumViewModel,
  selectIsBaseCurriculum,
  selectIsCurriculumFoundational,
  selectSelectedStudyPrograms,
} from '@store/curriculum/curriculumSelectors';
import {
  selectCurrentSchoolyear,
  selectIsSchoolyearReadOnly,
  selectMemberTeamsForSchoolyear,
  selectMiniSchoolVM,
  selectUserOrgsForSchool,
} from '@store/userAndSchool/userAndSchoolSelectors';

import { selectCustomCurriculaData } from '@store/llinkidApis/llinkidApiSelectors';
import {
  filterByCreator,
  filterByOrgs,
  filterBySchoolyear,
  filterCustomCurriculaWithFilters,
} from '@utils/filters';
import { getNonDerivedGroupKey } from '@utils/utils';
import { getKeyFromHref } from '@utils/getKeyFromHref';
import { creatorType } from '../../constants/creatorType';
import { CONTENTTYPES, CUSTOMCURRICULATYPES, CUSTOMTYPES } from '../../helpers/curriculumHelper';

import {
  buildDistribution,
  excludeOwnDistribution,
  filterCurriculaBySelectedPrograms,
  getCurriculaBySubjects,
  getEmptyOrLowPrioDistributionsStatus,
} from './distributeHelper';

function getAllNonDerivedCustomCurriculas(orgs, schoolyear, allCurricula) {
  const curricula = filterCustomCurriculaWithFilters(allCurricula, [
    filterByOrgs(orgs),
    filterBySchoolyear(schoolyear),
    (e) => !e.source,
  ]);

  const ids = new Set();
  curricula.forEach((c) => ids.add(getNonDerivedGroupKey(allCurricula, c)));

  const groupedCurrs = [];

  for (const id of ids) {
    const item = { ...curricula.find((e) => getNonDerivedGroupKey(allCurricula, e) === id) };
    const allOfEm = curricula.filter((e) => getNonDerivedGroupKey(allCurricula, e) === id);
    item.applicability = {
      ...item.applicability,
      studyProgrammes: allOfEm.map((e) => e.applicability.studyProgrammes[0]),
    };
    item.sources = allOfEm.map((e) => ({
      studyProgram: e.applicability.studyProgrammes[0].href,
      href: e.$$meta.permalink,
    }));

    item.groupKey = id;
    groupedCurrs.push(item);
  }

  return groupedCurrs;
}

function getAllDistributions(orgs, schoolyear, goalHrefs, customCurricula, annotations) {
  // /get all the distributions that have happened to the goals of this curricula.
  // /0) The school/team selects a studyprogram/group of studyprograms (basically an array) for which they want to do distribution.
  // /0.0) this translates to a list of LLINKID_CURRICULUM in content api which we are working for.
  // /1) find all customcurs of the school and the team. (that are in this list of LLINKID_CUR above)
  // /2) find all REFERENCES with reference = any of the goals or root
  let results = [];
  const type = CUSTOMTYPES.goalReference;
  const types = new Set(orgs.map((e) => e.creatorType));
  const targetsSet = new Set(goalHrefs);

  for (const cType of types) {
    const allCustCur = filterCustomCurriculaWithFilters(customCurricula, [
      filterByOrgs(orgs.filter((e) => e.creatorType === cType)),
      filterBySchoolyear(schoolyear),
    ]);
    let allReferencesList = [];
    // /find all REFERENCES for each.
    if (allCustCur.length) {
      const custCurHrefs = new Set(allCustCur.map((cur) => cur.$$meta.permalink));

      allReferencesList = annotations.filter(
        (e) => e.type === type && custCurHrefs.has(e.curriculum.href)
      );
      if (targetsSet.size) {
        allReferencesList = allReferencesList.filter((e) => targetsSet.has(e.target.href));
      }
    }

    results = results.concat(
      allReferencesList.map((e) => {
        return { ...e, creatortype: cType };
      })
    );
  }

  return results;
}

function getrootItemNonDerived(reference, allCustomCurricula) {
  // TODO: this function also exist in the utils as getNonDerivedRoot, but it doesn't use a map there.
  // refactor getNonDerivedRoot to use a map.
  let customCur = allCustomCurricula[getKeyFromHref(reference.curriculum.href)];

  if (customCur && customCur.type === CUSTOMCURRICULATYPES.adaptation) {
    // /so this is a derived from a non-derived. find its source.
    customCur = allCustomCurricula[getKeyFromHref(customCur.source.href)];
  }

  return customCur;
}

function add$$IdentifierAndRootItem(reference, customCurriculaMap, roots) {
  const customCur = customCurriculaMap[getKeyFromHref(reference.curriculum.href)];
  let rootItem;
  let $$identifier;

  if (customCur.source && customCur.source.href.startsWith('/content')) {
    rootItem = roots.find((e) => e.$$meta.permalink === customCur.source.href);

    // This is a fix for deleted curricula that is still distributed
    if (rootItem) {
      $$identifier = rootItem.identifiers.join();
    }
  } else if (customCur.source && customCur.source.href.startsWith('/llinkid')) {
    // /this is a derived from a non-dirived
    rootItem = getrootItemNonDerived(reference, customCurriculaMap);
  } else if (customCur.type === CUSTOMCURRICULATYPES.custom) {
    // no source. non-derived
    rootItem = getrootItemNonDerived(reference, customCurriculaMap);
  }
  return { ...reference, rootItem, $$identifier };
}

export const selectDistributionData = createSelector(
  [
    (state) => state.curriculum?.curriculumKey,
    selectCreator,
    selectMiniSchoolVM,
    selectMemberTeamsForSchoolyear,
    selectCurrentSchoolyear,
    (state) => selectUserOrgsForSchool(state),
    (state) =>
      selectLlinkidCurriculaRootsAndGoalLists(state, {
        schoolyearKey: state.userAndSchools.currentSchoolyear,
      }),
    (state) =>
      selectCurriculaWithMultipleActiveVersions(state, {
        schoolyearKey: state.userAndSchools.currentSchoolyear,
      }),
    (state) => state.customCurriculaData.customCurricula,
    (state) => state.customCurriculaData.annotations,
    selectAnnotatedCurriculumGoalListData,
  ],
  (
    curriculumKey,
    currentOrg,
    currentSchool,
    memberTeams,
    schoolyear,
    allUserOrgs,
    llinkidCurriculaRoots,
    multiActiveVersionsMap,
    customCurriculaMap,
    annotationsMap,
    extractedCurriculumNodes
  ) => {
    if (!currentOrg || !curriculumKey || !extractedCurriculumNodes) return null;
    console.time('selectDistributionData');
    const annotations = Object.values(annotationsMap);
    const customCurricula = Object.values(customCurriculaMap);
    let nonDerivedOrgs = [currentOrg];
    let allSubjects = [...llinkidCurriculaRoots];
    if (currentOrg.creatorType !== creatorType.school) {
      nonDerivedOrgs.push(currentSchool);
    }

    if (currentOrg.creatorType === creatorType.teacher) {
      // ??? why is this creatorType.teacher? A: because we already added the curOrg to the array by default.
      nonDerivedOrgs = nonDerivedOrgs.concat(memberTeams);
    }

    if (multiActiveVersionsMap[curriculumKey]) {
      const startingOrEnding =
        multiActiveVersionsMap[curriculumKey].ending === true ? 'ending' : 'starting';
      allSubjects = llinkidCurriculaRoots.filter(
        (e) =>
          !multiActiveVersionsMap[e.key] ||
          multiActiveVersionsMap[curriculumKey][startingOrEnding] ===
            multiActiveVersionsMap[e.key][startingOrEnding]
      );
    }

    if (multiActiveVersionsMap) {
      allSubjects.forEach((e) => {
        if (multiActiveVersionsMap[e.key]) {
          e.showVersion = true;
        }
      });
    }

    const allNonDerived = getAllNonDerivedCustomCurriculas(
      nonDerivedOrgs,
      schoolyear,
      customCurricula
    );

    allSubjects = allSubjects.concat(allNonDerived.map((e) => ({ ...e, showVersion: true })));
    allSubjects = allSubjects.filter(
      (curricula) => !curricula.foundational && curricula.applicability
    ); // filter foundationals out
    // we need to filter out more of the allSubjects. we only need those that make logical sense to distribute to (regarding versions)

    // console.timeEnd('dataService.getAllNonDerivedCustomCurriculas');
    // console.time('dataService.getAllDistributions');
    const goalHrefs = Object.values(extractedCurriculumNodes)
      .filter(
        (e) =>
          e.type === CONTENTTYPES.goal ||
          e.type === CUSTOMTYPES.goal ||
          e.type === CUSTOMTYPES.section
      )
      .map((e) => e.href);

    const allReferences = getAllDistributions(
      // TODO: something is wrong here when working in a derived non-derived.
      allUserOrgs,
      schoolyear,
      goalHrefs,
      customCurricula,
      annotations
    );
    // console.timeEnd('dataService.getAllDistributions');

    const allCustomCurriculas = filterCustomCurriculaWithFilters(customCurricula, [
      filterByOrgs(allUserOrgs),
      filterBySchoolyear(schoolyear),
    ]);

    // const customCurriculasForCurrentOrg = filterCustomCurriculaWithFilters(allCustomCurriculas, [
    //   filterByOrgs([currentOrg]),
    // ]);

    // const sourcesForCurrentOrg = new Set(
    //   customCurriculasForCurrentOrg
    //     .filter((e) => e.source && e.source.href.startsWith('/content'))
    //     .map((e) => e.source.href)
    // );

    // // mark allSubjects that have no corresponding customcurriculum
    // allSubjects = allSubjects.map((e) => {
    //   const hasCustomCurriculum = sourcesForCurrentOrg.has(e.$$meta.permalink) || e.type === CUSTOMCURRICULATYPES.custom;
    //   return { ...e, hasCustomCurriculum };
    // });

    // console.time('dataService.getCustomCurriculas');

    // console.timeEnd('dataService.getCustomCurriculas');

    const allSources = new Set(
      allCustomCurriculas
        .filter((e) => e.source && e.source.href.startsWith('/content'))
        .map((e) => e.source.href)
    );
    const roots = allSubjects.filter((cur) => allSources.has(cur.$$meta.permalink));
    // console.time('add$$Identifier');

    const allReferencesWithIdentifier = allReferences.map((e) => {
      return add$$IdentifierAndRootItem(e, customCurriculaMap, roots);
    });
    console.timeEnd('selectDistributionData');
    return {
      allSubjects,
      allReferences: allReferencesWithIdentifier,
      orgs: allUserOrgs,
      multiActiveVersionsMap,
    }; // why do we need to return orgs?
  }
);

const selectShowAll = (state) => {
  // true when it's foundational,
  // or we're looking at a regular curricula, whilst not in "distribute" mode
  // this is to display where all goals in the regular curricula have been also distributed to.
  // it has to be false when the slider Toon verdeling leerplandoelen is enabled.

  const { distributionMode } = state.distribute;
  const { foundational } = selectFullAnnotatedCurriculumViewModel(state) || {};
  if (foundational) return true;

  return !distributionMode;
};

const selectExcludeOwnDistrib = (state) => {
  // true when we are not in distribution mode.
  // in that case, we are displaying the little distribtutions in the goals. there it makes no sense to show that the goal is distributed into the curricula you are in, since it is obvious.
  // this feature is for teachers to see if this goal is also distributed in OTHER curricula, hence this filter/check.
  // in distribution mode, we need to include the own distribution, for the distribution window checkboxes.
  const { distributionMode } = state.distribute;
  return !distributionMode;
};

export const selectIsCurriculaDistributable = (state) => {
  const readOnly = selectIsSchoolyearReadOnly(state);
  if (readOnly) {
    return false;
  }

  const canEdit = selectCanEditCustomCur(state);
  const foundational = selectIsCurriculumFoundational(state);
  const isBaseCurriculum = selectIsBaseCurriculum(state);

  return Boolean(canEdit || foundational || isBaseCurriculum);
};

export const selectIsDistributionMode = (state) => {
  return state.distribute.distributionMode;
};

/**
 * the key of the curriculum, or the nonderivedGroupKey if it's a nonderived curriculum
 * used for excluding its own distribution in the distribute vm
 */
const selectCurriculumKey = createSelector(
  [
    (state) =>
      state.curriculum.curriculumKey !== 'nonderived'
        ? selectBaseCurriculumViewModel(state)
        : selectBaseNonDerivedCurriculumViewModel(state),
  ],
  (curriculum) => (curriculum?.key ? curriculum?.key : curriculum?.nonderivedGroupKey)
);

const selectDistributeVM = createSelector(
  [
    (state) => selectDistributionData(state),
    (state) => selectSelectedStudyPrograms(state),
    (state) => state.customCurriculaData.customCurricula,
    (state) => selectCurriculumGoalHrefs(state),
    selectCurrentSchoolyear,
    selectShowAll,
    selectExcludeOwnDistrib,
    selectCurriculumKey,
  ],
  (
    data,
    selectedPrograms,
    customCurriculaMap,
    curriculumGoalHrefs,
    schoolyear,
    showAll,
    excludeOwnDistrib,
    curriculumKey
  ) => {
    if (!curriculumGoalHrefs || !data) return {};
    let subjects = data.allSubjects;
    if (!showAll) {
      // if distribution from non-foundational. only allow to non-derived.
      subjects = subjects.filter((e) => e.type === CUSTOMCURRICULATYPES.custom);
    }
    const selectedStudyProgramHrefs = selectedPrograms.map((e) => e.$$meta.permalink);
    const applicableCurricula = filterCurriculaBySelectedPrograms(
      subjects,
      selectedStudyProgramHrefs
    );

    const orgTypeCustCurs = {};
    for (const org of data.orgs) {
      const customCurs = filterCustomCurriculaWithFilters(Object.values(customCurriculaMap), [
        filterByCreator(org.href),
        filterBySchoolyear(schoolyear),
      ]);
      orgTypeCustCurs[org.creatorType] = orgTypeCustCurs[org.creatorType] || [];
      orgTypeCustCurs[org.creatorType].push(...customCurs);
    }

    let goalDistributions = buildDistribution(
      curriculumGoalHrefs,
      applicableCurricula,
      data.allReferences,
      orgTypeCustCurs,
      selectedStudyProgramHrefs,
      selectedPrograms
    );

    if (excludeOwnDistrib) {
      goalDistributions = excludeOwnDistribution(goalDistributions, [curriculumKey]);
    }

    return { goalDistributions, allSubjects: applicableCurricula };
  }
);

/**
 * selects the hrefs (in a set) that have no distributions or only low prio distributions.
 */
export const selectEmptyOrLowPrioGoals = createSelector([selectDistributeVM], (distributionVM) => {
  if (!distributionVM || !distributionVM.goalDistributions) return new Set();

  return getEmptyOrLowPrioDistributionsStatus(distributionVM.goalDistributions);
});

const selectCustomCurriculaGroupKey = createSelector(
  [
    (state) => selectCustomCurriculaData(state),
    (state) => selectCustomCurriculaFromUrlOrParams(state),
  ],
  (customCurricula, customCurriculaFromUrlOrParams) => {
    if (!customCurriculaFromUrlOrParams?.length) return null;
    const [customcur] = customCurriculaFromUrlOrParams;
    const customCurriculaGroupKey = getNonDerivedGroupKey(customCurricula, customcur);
    return customCurriculaGroupKey;
  }
);

export const selectDistributionWindowData = createSelector(
  [
    selectDistributeVM,
    selectCreator,
    (state) => selectDistributionData(state),
    (state) => selectCustomCurriculaGroupKey(state),
    (state) => state.studyProgrammeApiData.allPrograms,
    (state, params) => params.goalHref,
  ],
  (
    distributionVM,
    curriculumCreator,
    distributionData,
    currentCurrGroupKey,
    allPrograms,
    goalHref
  ) => {
    const currentOrgCreatorType = curriculumCreator?.creatorType;
    // filter out the non-derived that aren't from the current org.
    const filteredSubjects = distributionVM.allSubjects.filter(
      (e) =>
        e.type !== CUSTOMCURRICULATYPES.custom ||
        (e.type === CUSTOMCURRICULATYPES.custom &&
          e.creator.href === curriculumCreator.$$meta.permalink &&
          e.groupKey !== currentCurrGroupKey) // remove the current non-derived from the list.1
    );

    const initialCurr = getCurriculaBySubjects(
      filteredSubjects,
      distributionData.multiActiveVersionsMap,
      allPrograms,
      distributionData.allReferences
    );

    const curriculaInfo = distributionVM.goalDistributions?.[goalHref];

    const newCurricula = [...initialCurr];
    curriculaInfo[currentOrgCreatorType]?.forEach((elem) => {
      const foundIndex = newCurricula.findIndex((cu) => cu.key === elem.key);
      if (foundIndex !== -1) {
        newCurricula[foundIndex] = {
          ...newCurricula[foundIndex],
          selected: true,
          partial: elem.partial,
        };
      }
    });

    return newCurricula;
  }
);

export const selectDistributeVMForGoal = createSelector(
  [selectDistributeVM, (state, params) => params.goalHref],
  (distributionVM, goalHref) => {
    const distribution = distributionVM || {};
    if (!distribution.goalDistributions) return null;

    const goalDistributions = distribution.goalDistributions[goalHref] || {};

    const distributionList = Object.keys(goalDistributions).map((type) => {
      const items = goalDistributions[type];
      const hash = type + items.map((e) => e.key + e.partial).join('');
      return { items, type, hash };
    });

    return distributionList;
  }
);
