import { Modal } from '@kathondvla/react-shared-components/src/components';
import { checkHasDerived, getCurriculaCustIds } from '@utils/homeHelper';

import './modals.scss';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { selectCustomCurriculaData } from '@store/llinkidApis/llinkidApiSelectors';
import { selectCalendarExistsForCurriculum } from '@store/calendar/calendarSelectors';

import iconDeleteWhite from '@assets/img/delete_white.svg';

const DeleteCurriculaModal = ({ curricula, onClose, onConfirm }) => {
  const customCurricula = useSelector(selectCustomCurriculaData);

  const custIds = getCurriculaCustIds(curricula, customCurricula);
  const hasDerived = checkHasDerived(custIds, customCurricula);
  const hasCalendar = useSelector((state) => selectCalendarExistsForCurriculum(state, { custIds }));

  let warning = 'Ben je zeker dat je dit leerplan wil verwijderen?';
  if (hasCalendar)
    warning =
      'Je kan dit leerplan niet verwijderen. Verwijder eerst alle vorderingsplannen gebaseerd op dit leerplan.';
  if (hasDerived)
    warning = 'Je kan dit leerplan niet verwijderen. Verwijder eerst alle gelinkte leerplannen.';

  return (
    <>
      <Modal.Header title="Leerplan verwijderen?" icon={iconDeleteWhite} onClose={onClose} />
      <Modal.Body>
        <span className="delete-text">{warning}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={onClose}>
          Annuleren
        </Button>
        {!hasCalendar && !hasDerived && (
          <Button variant="contained" onClick={() => onConfirm({ curricula })}>
            <DeleteIcon />
            Verwijderen
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

export default DeleteCurriculaModal;
