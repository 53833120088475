import GoalList from '@components/leerplan/goals/GoalList';
import LeerplanHeader from '@components/leerplan/section/header/LeerplanHeader';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import { AddBox } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import {
  selectFullAnnotatedCurriculumViewModel,
  selectIsCurriculumLoaded,
} from '@store/curriculum/curriculumSelectors';
import { curriculumActions } from '@store/curriculum/curriculumState';
import { RootState } from '@store/storeSetup';
import { selectCurrentSchoolyear } from '@store/userAndSchool/userAndSchoolSelectors';

import { getParamsFromCurriculum } from '@utils/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const GoalSelectorModal = ({ onClose, onConfirm, params, readonlyMode }) => {
  const dispatch = useDispatch();
  const schoolyear = useSelector(selectCurrentSchoolyear);
  const paramsForSelector = getParamsFromCurriculum(params, schoolyear?.key as string);
  const isLoaded = useSelector((state: RootState) =>
    // @ts-expect-error type inferrence not correct
    selectIsCurriculumLoaded(state, paramsForSelector)
  );
  const selectedItems = useSelector((state: RootState) => state.curriculum.selectedItems);

  const goalList = useSelector(
    // @ts-expect-error type inferrence not correct
    (state) => selectFullAnnotatedCurriculumViewModel(state, paramsForSelector)?.doelenlijst
  );

  const selectedItemsCount = selectedItems?.length ?? 0;

  useEffect(() => {
    dispatch(curriculumActions.setSelectionMode(true));
    dispatch(
      curriculumActions.setSelectionOptions({
        allowSubitem: true,
        allowOptional: false,
        readonlyMode,
      })
    );

    return () => {
      dispatch(curriculumActions.setSelectionMode(false));
    };
  }, [dispatch, readonlyMode]);

  return (
    <>
      {!isLoaded && (
        <Modal.Body>
          <CircularProgress size={40} sx={{ m: 'auto', display: 'block' }} />
        </Modal.Body>
      )}

      {isLoaded && (
        <Modal.Body>
          <div className="goal-selector-modal">
            <LeerplanHeader curriculumParams={paramsForSelector} />
            <div className="goals-tab-background nopadding">
              <GoalList
                goalList={goalList}
                onOpenEditGoalModal={null}
                nonDerivedOptions={null}
                removeSection={null}
              />
            </div>
          </div>
        </Modal.Body>
      )}

      <Modal.Footer>
        {readonlyMode && (
          <Button variant="contained" onClick={onClose}>
            Ok
          </Button>
        )}
        {!readonlyMode && (
          <>
            <span>
              Je hebt <strong>{selectedItemsCount}</strong> leerplandoel(en) geselecteerd.
            </span>
            <Button variant="outlined" onClick={onClose}>
              Annuleren
            </Button>
            <Button
              variant="contained"
              startIcon={<AddBox />}
              onClick={() => {
                onConfirm?.(selectedItems?.map((z) => z.href));
              }}
            >
              Toevoegen
            </Button>
          </>
        )}
      </Modal.Footer>
    </>
  );
};

export default GoalSelectorModal;
