import * as commonUtils from '@kathondvla/sri-client/common-utils';
import demo from '@config/demo';
import director from './director.testperson.json';
import teamlead from './teamlead.testperson.json';
import teacherOne from './teacherone.testperson.json';
import teacherTwo from './teachertwo.testperson.json';
import teacherThree from './teacherthree.testperson.json';

import demoHenk from './demo-person-henk.json';
import demoGreet from './demo-person-greet.json';
import demoStefan from './demo-person-stefan.json';
import demoAhmed from './demo-person-ahmed.json';

const getLocalStorageItem = (paramName) => {
  const value = localStorage.getItem(paramName);
  if (value === 'null') {
    return null;
  }

  return value || undefined;
};

export const getDefaultUsernameForDemoOrTest = () => {
  if (demo.enableDemoMode) {
    return demoHenk.username;
  }
  return null;
};

/**
 * Get the usernameForTest from the URL or from the global object, depending on whether we are running in a browser or in node.
 * usage: in browser: http://localhost:8080/#!/home/leerplan?usernameForTest=director.testperson
 * usage: in node: globalThis.usernameForTest = 'director.testperson.json';
 * @returns {string} usernameForTest
 */
export const getUsernameForDemoOrTest = () => {
  if (globalThis && globalThis.usernameForTest) {
    return globalThis.usernameForTest;
  }

  if (window && window.usernameForTest) {
    return window.usernameForTest;
  }

  // for in-browser msw
  const username =
    localStorage && demo.enableDemoMode
      ? getLocalStorageItem('usernameForDemo')
      : getLocalStorageItem('usernameForTest');

  if (username === undefined) {
    return getDefaultUsernameForDemoOrTest();
  }

  return username;
};

/**
 * this will set the enddate to the school and all classes and teacherGroups in the school.
 * if you include the Responsibilities parameter, it will also set the enddate to the Responsibilities.
 * ie: ?schoolEndDate=2024-08-31&endResponsibilities=true
 * @returns {string} an enddate
 */
export const getEndDateForSchool = () => {
  if (globalThis && globalThis.schoolEndDate) {
    return globalThis.schoolEndDate;
  }

  if (window && window.schoolEndDate) {
    return window.schoolEndDate;
  }

  const schoolEndDate = sessionStorage.getItem('schoolEndDate');

  return schoolEndDate || null;
};

const isEndateAlsoForResponsibilities = () => {
  if (globalThis && globalThis.endResponsibilities) {
    return globalThis.endResponsibilities;
  }

  if (window && window.endResponsibilities) {
    return window.endResponsibilities;
  }

  const endResponsibilities = sessionStorage.getItem('endResponsibilities');

  return endResponsibilities || false;
};

export const getEndDateForResponsibilities = () => {
  if (isEndateAlsoForResponsibilities()) {
    return getEndDateForSchool();
  }
  return null;
};

export const setEndDateForResults = (results, newEndDate) => {
  return results.map((res) => {
    let { startDate, endDate } = res.$$expanded;
    if (startDate > endDate) {
      console.warn('set startdate to enddate because startdate > enddate', res);
      startDate = endDate;
    }
    if (!endDate || endDate > newEndDate) {
      endDate = newEndDate;
    }
    return { ...res, $$expanded: { ...res.$$expanded, startDate, endDate } };
  });
};

export const getPersonForDemoOrTest = (username) => {
  switch (username) {
    case director.username:
      return director;

    case teamlead.username:
      return teamlead;

    case teacherOne.username:
      return teacherOne;

    case teacherTwo.username:
      return teacherTwo;

    case teacherThree.username:
      return teacherThree;

    case demoHenk.username:
      return demoHenk;

    case demoGreet.username:
      return demoGreet;

    case demoStefan.username:
      return demoStefan;

    case demoAhmed.username:
      return demoAhmed;

    default:
      return null;
  }
};

export const getPersonHrefForDemoOrTest = () => {
  const username = getUsernameForDemoOrTest();
  const person = getPersonForDemoOrTest(username);
  if (person) {
    return person.href;
  }
  return null;
};

export const getAllPersonsForTest = (demoMode) => {
  if (demoMode) {
    return [demoHenk, demoGreet, demoStefan, demoAhmed];
  }
  return [director, teamlead, teacherOne, teacherTwo, teacherThree];
};

const school1KeyString = 'school1Key';
const school2KeyString = 'school2Key';
const demoVersionString = 'demoVersion';

const isSameDemoVersion = () => {
  const { demoVersion } = demo.demo;
  return localStorage.getItem(demoVersionString) === demoVersion;
};

const setDemoVersion = () => {
  localStorage.setItem(demoVersionString, demo.demo.demoVersion);
};

const getDemoSchool1Key = () => {
  if (localStorage.getItem(school1KeyString) === null) {
    localStorage.setItem(school1KeyString, commonUtils.generateUUID());
  }
  return localStorage.getItem(school1KeyString);
};

const getDemoSchool2Key = () => {
  if (localStorage.getItem(school2KeyString) === null) {
    localStorage.setItem(school2KeyString, commonUtils.generateUUID());
  }
  return localStorage.getItem(school2KeyString);
};

export const getDemoSchool1Href = () => {
  return `/sam/organisationalunits/${getDemoSchool1Key()}`;
};

export const getDemoSchool2Href = () => {
  return `/sam/organisationalunits/${getDemoSchool2Key()}`;
};

export const resetDemoSchoolKeys = () => {
  localStorage.removeItem(school1KeyString);
  localStorage.removeItem(school2KeyString);
};

export const replaceDemoSchoolKeys = (obj) => {
  if (!demo.enableDemoMode) {
    return obj;
  }

  const school1Key = '1c23a031-05e9-4775-b625-41a4de625394';
  const school2Key = '5880b335-9aed-4112-b82b-39716baa9aca';
  const school1LocalKey = getDemoSchool1Key();
  const school2LocalKey = getDemoSchool2Key();

  return JSON.parse(
    JSON.stringify(obj)
      .replaceAll(school1Key, school1LocalKey)
      .replaceAll(school2Key, school2LocalKey)
  );
};

if (demo.enableDemoMode && !isSameDemoVersion()) {
  setDemoVersion();
  resetDemoSchoolKeys();
}
