import { rest } from 'msw';
import apis from '@config/apis';
import demo from '@config/demo';
import { getEndDateForSchool, replaceDemoSchoolKeys } from '../persons/helpers';
import { fetchJsonPath } from '../../helpers/fetchJson';

const fetchJson = fetchJsonPath('api/sam');

const schoolView = 'school_view_test_school.json';
const demoSchoolView = 'school_view_DEMO.json';

export const samApiHandlers = [
  rest.get(`${apis.api}/sam/organisationalunits/view`, async (req, res, ctx) => {
    if (demo.enableDemoMode) {
      const result = await fetchJson(ctx, demoSchoolView);
      return res(ctx.json(replaceDemoSchoolKeys(result)), ctx.delay(150));
    }
    const result = await fetchJson(ctx, schoolView);
    if (getEndDateForSchool()) {
      const endDate = getEndDateForSchool();
      result.results.forEach((ou) => {
        ou.endDate = endDate;
        ou.teacherGroups.forEach((tg) => {
          if (!tg.endDate || tg.endDate > endDate) {
            tg.endDate = endDate;
          }

          if (tg.startDate > endDate) {
            tg.startDate = endDate;
          }
        });
        ou.classes.forEach((c) => {
          if (!c.endDate || c.endDate > endDate) {
            c.endDate = endDate;
          }

          if (c.startDate > endDate) {
            c.startDate = endDate;
          }
        });
      });
    }

    return res(ctx.json(result), ctx.delay(150));
  }),
];
