import { forwardRef } from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

import './textEditor.scss';

// eslint-disable-next-line react/display-name
const TextEditor = forwardRef(({ value, required, error, disabled, onChange }, ref) => {
  const onQuillChange = (content, delta, source, editor) => {
    if (source === 'user') {
      const text = editor.getSemanticHTML();
      /**
       * we really should be using editor.getSemanticHTML() to get the content, because
       * the content contains quill-specific props and values.
       *
       * the problem right now is that these are controlled components.
       */
      if (text === '<p></p>') {
        onChange?.(null);
      } else {
        onChange?.(content);
      }
    }
  };

  return (
    <div className={`text-editor`}>
      <ReactQuill
        ref={ref}
        className={`editor ${error ? 'error' : ''}`}
        theme="snow"
        required={required}
        value={value}
        readOnly={disabled}
        onChange={onQuillChange}
      />
      {error && <span className="error-msg">{error?.message}</span>}
    </div>
  );
});

export default TextEditor;
