import commonUtils from '@kathondvla/sri-client/common-utils';
import sriFetchClient from '@kathondvla/sri-client/fetch-sri-client';
import settings from '@config/settings';
import { apiEndpoints } from '../constants/apiConstants';
import { SPECIAL_TAB_ID } from '../constants/leidraadTabs';
import {
  ApiActivity,
  ApiActivityPlan,
  ApiAnnotation,
  ApiCustomCurriculaGroup,
  ApiCustomCurriculum,
  ApiCustomItem,
} from '../types/llinkidApiTypes';
import { Schoolyear } from '../types/schoolyear';
import { LlinkidAPiCacheStatePerYear } from './llinkidApis/llinkidApiTypes';

const basicConfig = {
  name: 'api',
  baseUrl: settings.apisAndUrls.api,
};

const fastlyConfig = { name: 'cachedApi', ...settings.apisAndUrls.cachedApi };
const customCurConfig = { name: 'customCurApi', ...settings.apisAndUrls.customcurapi };
const activityPlanConfig = {
  name: 'activityPlansApi',
  ...settings.apisAndUrls.activityplansapi,
};
const oauthConfig = { name: 'oauth', baseUrl: settings.oauth.oauthURL };

export const api = sriFetchClient(basicConfig);
export const oauth = sriFetchClient(oauthConfig);
export const cachedApi = sriFetchClient(fastlyConfig);
export const activityPlansApi = sriFetchClient(activityPlanConfig);
export const customCurApi = sriFetchClient(customCurConfig);

export async function getAllCustomCurriculaProm(
  cacheSettings: LlinkidAPiCacheStatePerYear & { endpoint: string },
  schoolhref: string,
  schoolyear: Schoolyear
): Promise<ApiCustomCurriculum[]> {
  const params = {
    limit: 5000,
    'context.href': schoolhref,
    'issued.startDateBefore': schoolyear.isoDates.startDate,
    'issued.endDateAfterOrNull': schoolyear.isoDates.endDate,
    modifiedSince: '',
  };

  if (cacheSettings.modifiedSince) {
    params.modifiedSince = cacheSettings.modifiedSince;
    params['$$meta.deleted'] = 'any';
  }

  const result = await customCurApi.getAll(cacheSettings.endpoint, params);
  return result;
}

export async function getCustomCurriculaFromParams(
  curriculumKey: string,
  custids: string[],
  setid: string | null
): Promise<ApiCustomCurriculum[]> {
  const params = {
    keyIn: custids.length ? custids : undefined,
    customCurriculaGroup: setid ? apiEndpoints.customCurriculaGroups + setid : undefined,
    'source.href': curriculumKey === 'nonderived' ? undefined : `/content/${curriculumKey}`,
  };

  const result = await customCurApi.getList(apiEndpoints.customCurricula, params);
  return result;
}

export async function getActivityPlansFromParams(keys: string[]): Promise<ApiActivityPlan[]> {
  const params = {
    keyIn: keys,
  };

  const result = await activityPlansApi.getList(apiEndpoints.activityPlans, params);
  return result;
}

export async function getApiItemsCount(apiInstance, endpoint, contextParam): Promise<number> {
  const params = {
    limit: '0',
    $$includeCount: true,
    ...contextParam,
  };
  const result = await apiInstance.getList(endpoint, params);
  // console.log(result.count);
  return result.count;
}

export async function getAllAnnotationsProm(
  cacheSettings: LlinkidAPiCacheStatePerYear & { endpoint: string },
  schoolhref: string,
  schoolyear: Schoolyear
): Promise<ApiAnnotation[]> {
  const params = {
    limit: 5000,
    rootWithContextContains: schoolhref,
    rootIssuedStartDateBefore: schoolyear.isoDates.startDate,
    rootIssuedEndDateAfterOrNull: schoolyear.isoDates.endDate,
    modifiedSince: '',
  };

  if (cacheSettings.modifiedSince) {
    params.modifiedSince = cacheSettings.modifiedSince;
    params['$$meta.deleted'] = 'any';
  }

  return customCurApi.getAll(cacheSettings.endpoint, params);
}

export async function getAllCustomItemsProm(cacheSettings, schoolhref): Promise<ApiCustomItem[]> {
  const params = {
    limit: 5000,
    'context.href': schoolhref,
    modifiedSince: '',
  };

  if (cacheSettings.modifiedSince) {
    params.modifiedSince = cacheSettings.modifiedSince;
    params['$$meta.deleted'] = 'any';
  }

  return customCurApi.getAll(cacheSettings.endpoint, params);
}

export async function getAllCustomCurriculaGroupsProm(
  cacheSettings: LlinkidAPiCacheStatePerYear & { endpoint: string },
  schoolhref: string,
  schoolyear: Schoolyear
): Promise<ApiCustomCurriculaGroup[]> {
  const params = {
    limit: 5000,
    customCurriculaContextContains: schoolhref,
    rootIssuedStartDateBefore: schoolyear.isoDates.startDate,
    rootIssuedEndDateAfterOrNull: schoolyear.isoDates.endDate,
    modifiedSince: '',
  };

  if (cacheSettings.modifiedSince) {
    params.modifiedSince = cacheSettings.modifiedSince;
    params['$$meta.deleted'] = 'any';
  }

  return customCurApi.getAll(cacheSettings.endpoint, params);
}

export async function getAllActivityPlansProm(
  cacheSettings: LlinkidAPiCacheStatePerYear & { endpoint: string },
  schoolhref: string,
  schoolyear: Schoolyear
): Promise<ApiActivityPlan[]> {
  const params = {
    'context.href': schoolhref,
    limit: 5000,
    'issued.startDateAfter': schoolyear.isoDates.startDate,
    'issued.endDateBefore': schoolyear.isoDates.endDate,
    modifiedSince: '',
  };

  if (cacheSettings.modifiedSince) {
    params.modifiedSince = cacheSettings.modifiedSince;
    params['$$meta.deleted'] = 'any';
  }

  return activityPlansApi.getAll(cacheSettings.endpoint, params);
}

export async function getAllActivitiesProm(
  cacheSettings: LlinkidAPiCacheStatePerYear & { endpoint: string },
  schoolhref: string,
  schoolyear: Schoolyear,
  lastActiveDate: string | null
): Promise<ApiActivity[]> {
  const params = {
    rootWithContextContains: schoolhref,
    limit: 50000,
    rootIssuedStartDateAfter: schoolyear.isoDates.startDate,
    rootIssuedEndDateBefore: schoolyear.isoDates.endDate,
    rootIssuedStartDateBefore: '',
    modifiedSince: '',
  };

  if (cacheSettings.modifiedSince) {
    params.modifiedSince = cacheSettings.modifiedSince;
    params['$$meta.deleted'] = 'any';
  }

  /**
   * we add the lastActiveDate to the URL purely for performance.
   * adding it allows the security-plugin to check the security via URL rather than DB.
   */
  if (lastActiveDate) {
    [params.rootIssuedStartDateBefore] = lastActiveDate.split('T');
  }

  return activityPlansApi.getAll(cacheSettings.endpoint, params);
}

function removeSmartSchoolParams(url) {
  const splitedUrl = url.split('?');
  const [base, params] = splitedUrl;
  const isSmartSchoolParams = (param) =>
    param.includes('forceLogin') ||
    param.includes('forceLogout') ||
    param.includes('loginProvider') ||
    param.includes('loginProviderArgument');

  if (params) {
    const filteredParams = params.split('&').filter((p) => !isSmartSchoolParams(p));
    return `${base}?${filteredParams.join('&')}`;
  }

  return base;
}

export function getLoginUrl(returnUrl) {
  const url = `${settings.oauth.authenticate}?scope=${
    settings.oauth.scope
  }&response_type=none&client_id=${settings.oauth.clientID}&redirect_uri=${
    settings.oauth.redirectUri
  }&state=${encodeURIComponent(removeSmartSchoolParams(returnUrl))}`;

  return url;
}

export function forceLogin(returnUrl, fresh, loginProvider, loginProviderArgument) {
  let url = `${settings.oauth.authenticate}?scope=${settings.oauth.scope}&response_type=none&client_id=${settings.oauth.clientID}&fresh=${fresh}`;

  if (loginProvider) url += `&login_provider=${loginProvider}`;
  if (loginProviderArgument) url += `&login_provider_argument=${loginProviderArgument}`;

  url += `&redirect_uri=${settings.oauth.redirectUri}&state=${encodeURIComponent(
    removeSmartSchoolParams(returnUrl)
  )}`;

  return url;
}

/**
 *
 * @param {*} href
 * @returns {import('../types/privateStateApiTypes').PrivateState}
 */
export function generatePreference(href) {
  const key = commonUtils.generateUUID();

  return {
    $$meta: {
      permalink: `/privatestates/${key}`,
    },
    key,
    owner: {
      href,
    },
    context: settings.component,
    state: {},
  };
}

export function getHeaderHashForTheUncachable(refresh) {
  if (refresh) {
    return { 'VSKO-Resource-Hash': `${Math.round(+new Date() / 1000)}` };
  }
  return {};
}

export function generateSectionChoices(school, sectionKey) {
  const key = commonUtils.generateUUID();
  const isSpecialPage = sectionKey === SPECIAL_TAB_ID;
  const applicableList = isSpecialPage ? 'coreItems' : 'priorities';
  const response = { [applicableList]: [], approach: '' };
  if (!isSpecialPage) response.evaluation = '';

  return {
    $$meta: { permalink: `/llinkid/qualityinstrument/choices/${key}` },
    key,
    creator: {
      href: school,
    },
    section: {
      href: `/content/${sectionKey}`,
    },
    response,
  };
}

const re = /\/([a-zA-Z]+)\/([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12})/i;
export function extractResourceName(value) {
  // the RegEx will match the first occurrence of the pattern
  const match = re.exec(value);

  // result is an array containing:
  // [0] the entire string that was matched by our RegEx
  // [1] the first (only) group within our match, specified by the

  return match ? match[1] : null;
}

/** why so complicated? whent we could just use pop()?
 * because a href could also be to an attachment like /llinkid/activityplanning/activityplans/activities/de60c9bd-e3f9-45d3-a8c0-2a61954e43ce/attachments/special-name___with_spaces_and_____and_also__.jpg
 */
function getApiResourceName(href) {
  return extractResourceName(href);
}

export function isCustomCurricula(href) {
  return getApiResourceName(href) === 'customcurricula';
}

export function isAnnotation(href) {
  return getApiResourceName(href) === 'annotations';
}

export function isCustomItem(href) {
  return getApiResourceName(href) === 'customitems';
}

export function isCustomcurriculagroup(href) {
  return getApiResourceName(href) === 'customcurriculagroups';
}

export function isActivity(href) {
  return getApiResourceName(href) === 'activities';
}

export function isActivityPlan(href) {
  return getApiResourceName(href) === 'activityplans';
}

function isActivityPlanGroup(href) {
  return getApiResourceName(href) === 'activityplangroups';
}

export function getApiAndUrlForBatch(batch) {
  if (
    isActivityPlanGroup(batch[0]?.href) ||
    isActivity(batch[0]?.href) ||
    isActivityPlan(batch[0]?.href)
  ) {
    return { api: activityPlansApi, url: '/llinkid/activityplanning/batch' };
  }
  return { api: customCurApi, url: '/llinkid/customcurriculum/batch' };
}
