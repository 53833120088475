import { RadioButton, TextField } from '@UI/index';
import { validateUrl } from '@store/calendar/calendarHelper';
import { useState } from 'react';

import iconAddGrey from '@assets/img/add_grey.svg';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button } from '@mui/material';
import settings from '@config/settings';
import './addAttachmentForm.scss';

const AddAttachmentForm = ({ onAdd }) => {
  const [type, setType] = useState('file');
  const [attachment, setAttachment] = useState({ description: '', href: '' });
  const fileLimit = attachment.size && attachment.size > settings.hardcodings.fileLimitSize;
  const urlError = type === 'link' && attachment.href !== '' && !validateUrl(attachment.href);
  const valid =
    ((!urlError && attachment.href !== '' && type === 'link') ||
      (type === 'file' && attachment instanceof File)) &&
    !fileLimit;

  const onAddHandler = () => {
    const file = type === 'file' ? attachment : null;

    onAdd?.({
      file,
      type: attachment.type ?? type,
      href: attachment.href,
      name: attachment.name,
      description: attachment.description,
      uploaded: false,
    });
  };

  const toggleType = (nextType) => {
    setAttachment(nextType === 'link' ? { description: '', href: '' } : {});
    setType(nextType);
  };

  return (
    <div className="add-attacment-form">
      <header>
        <RadioButton
          label="Een bijlage toevoegen"
          checked={type === 'file'}
          onChange={() => toggleType('file')}
        />
        <RadioButton
          label="Een link toevoegen"
          checked={type === 'link'}
          onChange={() => toggleType('link')}
        />
      </header>
      <main>
        {type === 'file' && (
          <section className="file-wrapper">
            <span className="label">Bestand selecteren:</span>
            <div className="file-placeholder">
              <div className="file-icon">
                <img src={iconAddGrey} height={20} />
              </div>
              <div className="file-name">{!!attachment.name && <span>{attachment.name}</span>}</div>
            </div>
            <input
              type="file"
              className="file-selector"
              placeholder="Bestand selecteren"
              onChange={(event) =>
                setAttachment(event.target.files[0] || { description: '', href: '' })
              }
            />
            {fileLimit && (
              <p className="upload-limit">De maximum grootte voor bestanden is 100MB</p>
            )}
          </section>
        )}
        {type === 'link' && (
          <div className="link-wrapper">
            <div className="att-title">
              <span className="label">Titel:</span>
              <TextField
                value={attachment.description}
                onChange={(val) => setAttachment((prev) => ({ ...prev, description: val }))}
              />
            </div>
            <div className="att-url">
              <span className="label">Link:</span>
              <TextField
                value={attachment.url}
                error={urlError}
                onChange={(val) => setAttachment((prev) => ({ ...prev, href: val }))}
              />
            </div>
          </div>
        )}

        <Button
          variant="contained"
          startIcon={<AddBoxIcon />}
          disabled={!valid}
          onClick={onAddHandler}
        >
          Voeg toe
        </Button>
      </main>
    </div>
  );
};

export default AddAttachmentForm;
