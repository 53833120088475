import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import Watermark from '@UI/watermark/Watermark';
import useCountdown from '@hooks/useCountdown';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import {
  selectCalendarList,
  selectHasCalendarListError,
  selectIsCalendarListLoading,
} from '@store/calendarList/calendarListSelectors';
import {
  deleteCalendar,
  editCalendar,
  undeleteCalendar,
} from '@store/calendarList/calendarListState';
import { init } from '@store/llinkidApis/llinkidApiState';
import {
  selectCurrentSchoolHref,
  selectCurrentSchoolyear,
} from '@store/userAndSchool/userAndSchoolSelectors';
import apiFormatters from '@utils/apiFormatters';
import { endLog, startLog } from '@utils/loggerHelper';
import { formatCalendarSelection } from '@utils/utils';

import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso as VirtualizedList } from 'react-virtuoso';
import usePostMessage from '@hooks/usePostMessage';
import { useNavigate } from 'react-router-dom';
import { POST_MESSAGE_EVENT, POST_MESSAGE_STATE } from '../../../constants/postMessageEvents';
import CopyCalendarModal from '../modals/CopyCalendarModal';
import CreateOrEditCalendarModal from '../modals/CreateOrEditCalendarModal';
import DeleteCalendarModal from '../modals/DeleteCalendarModal';
import UndeleteCalendarModal from '../modals/UndeleteCalendarModal';
import CalendarListLoader from './CalendarListLoader';
import CalendarListItem from './calendarListItem/CalendarListItem';

import './calendarList.scss';

const CalendarList = () => {
  const calendarList = useSelector(selectCalendarList);
  const editCalendarModalRef = useRef();
  const copyCalendarModalRef = useRef();
  const deleteCalendarModalRef = useRef();
  const undeleteCalendarModalRef = useRef();
  const loading = useSelector(selectIsCalendarListLoading);
  const error = useSelector(selectHasCalendarListError);
  const selectionMode = useSelector((state) => state.calendarList.selectionMode);
  const deletedCalendars = useSelector((state) => state.calendarList.filters.deletedCalendars);
  const schoolHref = useSelector(selectCurrentSchoolHref);
  const schoolyear = useSelector(selectCurrentSchoolyear);
  const [countDown, setCountDown] = useCountdown();
  const [sendMessage] = usePostMessage();
  const navigate = useNavigate();
  const copyModalOpen = useSelector(
    (state) =>
      state.calendarList.copyCalendar.status === 'OPEN' ||
      state.calendarList.copyCalendar.status === 'SAVING'
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && calendarList) {
      endLog('load home.vorderingsplannen');
    }
  }, [loading, calendarList]);

  const retryLoadData = (e) => {
    e.preventDefault();
    if (countDown > 0) return;
    setCountDown(60);
    dispatch(init(schoolHref));
  };

  const goToCalendar = useCallback(
    (item) => {
      const ids = item.keys.join();
      startLog('open yearplan');
      const params = new URLSearchParams({
        ids,
        merge: true,
      });
      const url = `/vorderingsplannen?${params.toString()}`;
      navigate(url);
    },
    [navigate]
  );

  const selectCalendar = useCallback(
    (calendar) => {
      const selection = formatCalendarSelection(calendar);
      const data = {
        state: POST_MESSAGE_STATE.CORRECT,
        event: POST_MESSAGE_EVENT.SELECTION_DONE,
        selection,
      };

      sendMessage(data);
    },
    [sendMessage]
  );

  const openEditModal = (school, item) => {
    editCalendarModalRef.current.open({
      school,
      plan: item,
      onConfirm: (formValues) => {
        const formattedFormValues = apiFormatters.formatCalendarCreationAPI({
          ...formValues,
          schoolyear,
          school,
        });
        const plans = item?.plans?.map((plan) => apiFormatters.formatPlanAPI(plan));
        return dispatch(editCalendar({ plans, formValues: formattedFormValues }));
      },
    });
  };

  // const openCopyModal = (school, item) => {
  //   copyCalendarModalRef.current.open({
  //     school,
  //     plan: item,
  //     onConfirm: () => {},
  //   });
  // };

  const openDeleteModal = (item) => {
    deleteCalendarModalRef.current.open({
      plan: item,
      onConfirm: () => dispatch(deleteCalendar(item)),
    });
  };

  const openUndeleteModal = (item) => {
    undeleteCalendarModalRef.current.open({
      plan: item,
      onConfirm: () => dispatch(undeleteCalendar(item)),
    });
  };

  const handleItemClick = (item) => (selectionMode ? selectCalendar(item) : goToCalendar(item));

  const InnerItem = React.memo(function Item({ item }) {
    return (
      <CalendarListItem
        key={item.keys.join('')}
        item={item}
        onClick={item.deleted ? null : () => handleItemClick(item)}
        onEdit={openEditModal}
        onDelete={openDeleteModal}
        onUndelete={openUndeleteModal}
      />
    );
  });

  // The callback is executed often - don't inline complex components in here.
  const itemContent = (index, listitem) => {
    return <InnerItem item={listitem} />;
  };

  return (
    <div className="calendar-list">
      <div className={`calendar-list-header ${deletedCalendars ? 'deleted' : ''}`}>
        <div className="header-class">Klas(sen)</div>
        <div className="header-name">Naam</div>
        {deletedCalendars && <div className="header-deleted">Verwijderd</div>}
        <div className="header-creator">Leraar / Leraren</div>
      </div>
      {loading && <CalendarListLoader />}
      {!loading && calendarList.length > 0 && (
        <div
          data-testid="virtualized-list"
          className={`calendar-list-content ${selectionMode ? 'selection' : ''}`}
        >
          <VirtualizedList
            style={{ height: '100%' }}
            data={calendarList}
            itemContent={itemContent}
            overscan={100}
          />
        </div>
      )}
      <Watermark />
      {!loading && calendarList.length === 0 && !selectionMode && (
        <div className="empty-results">
          {!error && (
            <span>
              Er zijn nog geen {deletedCalendars && 'verwijderde'} vorderingsplannen voor{' '}
              {schoolyear.name.toLowerCase()}
            </span>
          )}
          {error && (
            <span>
              Er is een probleem opgetreden bij het inladen van de vorderingsplannen.
              <br />
              Mogelijk zijn de vorderingsplannen tijdelijk niet beschikbaar.{' '}
              <a href="#" className={`${countDown > 0 ? 'disabled' : ''}`} onClick={retryLoadData}>
                Probeer opnieuw {countDown > 0 && `binnen ${countDown}s`}
              </a>
            </span>
          )}
        </div>
      )}

      <Modal
        ref={editCalendarModalRef}
        component={<CreateOrEditCalendarModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={copyCalendarModalRef}
        open={copyModalOpen}
        component={<CopyCalendarModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={deleteCalendarModalRef}
        component={<DeleteCalendarModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={undeleteCalendarModalRef}
        component={<UndeleteCalendarModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </div>
  );
};

export default CalendarList;
