const H5 = ({ title, highlight }) => {
  const titleArray = title.split(' ');

  return (
    <h5>
      {titleArray.map((word, index) => {
        if (word === highlight) {
          return <b key={index}>{word} </b>;
        }
        return `${word} `;
      })}
    </h5>
  );
};
export default H5;
