import { printAddress } from '@utils/utils';

import './calendarInfoCard.scss';

const CalendarInfoCard = ({ calendar }) => {
  if (!calendar) return null;
  return (
    <div className="calendar-info-card">
      <section className="title block">
        <span className={`title ${calendar.latest ? 'latest' : ''}`}>{calendar.plan}</span>
      </section>

      <section className="school block">
        <span className="school">
          {calendar.school?.$$displayName}
          {calendar.school?.$$addresses?.map((address) => (
            <span key={address.key}>{printAddress(address?.address)}</span>
          ))}
        </span>
      </section>

      <section className="classes block">
        <span className="classes">{calendar.classes?.map((c) => c.$$displayName).join(', ')}</span>
      </section>

      <section className="curricula block">
        {calendar.curricula.map((curr, i) => (
          <div className="curriculum" key={i}>
            <div className="result-icon">
              <img width={15} src={curr.src} />
            </div>
            <span className="title">{curr.name}</span>
            {curr.versionNumber && <span className="version">{curr.versionNumber}</span>}
          </div>
        ))}
      </section>
    </div>
  );
};

export default CalendarInfoCard;
