export type PendingBatchesStatus = {
  status: 'UPLOADING_ITEM' | 'UPLOADING_FILE' | 'COMPLETE' | 'ERROR_AND_RETRY';
  timer?: number;
};

export const UPLOADING_ITEM: PendingBatchesStatus = {
  status: 'UPLOADING_ITEM',
};

export const UPLOADING_FILE: PendingBatchesStatus = {
  status: 'UPLOADING_FILE',
};

export const COMPLETE: PendingBatchesStatus = {
  status: 'COMPLETE',
  timer: 10,
};

export const ERROR_AND_RETRY: PendingBatchesStatus = {
  status: 'ERROR_AND_RETRY',
};

export const pendingBatchesStatusMap = {
  UPLOADING_ITEM,
  UPLOADING_FILE,
  COMPLETE,
  ERROR_AND_RETRY,
};
