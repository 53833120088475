import { useEffect } from 'react';
import { useHoverIntent } from 'react-use-hoverintent';

const useHoverItem = (callback) => {
  const [isHovering, ref] = useHoverIntent({
    timeout: 0,
    sensitivity: 5,
    interval: 150,
  });

  useEffect(() => {
    callback?.(isHovering);
  }, [isHovering, callback]);

  return { hoverRef: ref };
};

export default useHoverItem;
