import { GRADE_1, GRADE_2, GRADE_3 } from '../constants/grades';
import { studyProgrammeConstants } from '../constants/apiConstants';

// const filterProgramsBasedOnGrade = (grade) => {
//   const originalLists = this.homeService.getOriginalFilterLists();
//   this.filters.studyProgrammes.list = this.filterStudyProgrammes(
//     originalLists.studyProgrammes.list,
//     grade
//   );
//   this.filters.streams.list = this.filterStreams(originalLists.streams.list, grade);
//   this.emptyValueIfNotInList(this.filters.studyProgrammes);
//   this.emptyValueIfNotInList(this.filters.streams);
// };

// const filterProgramsBasedOnStream = (finality) => {
//   const originalLists = this.homeService.getOriginalFilterLists();
//   this.filters.studyProgrammes.list = this.filterStudyProgrammes(
//     originalLists.studyProgrammes.list,
//     finality
//   );
//   this.emptyValueIfNotInList(this.filters.studyProgrammes);
// };

// const filterStudyProgrammes = (list, value) => {
//   if (value) {
//     return list.filter((item) =>
//       item.studyProgrammeGroups.some((group) => group.studyProgrammeGroup.href === value)
//     );
//   }
//   return list;
// };

// const filterStreams = (list, value) => {
//   if (value) {
//     const grade =
//       value === this.studyProgramsService.firstGradeHref
//         ? this.grades.GRADE_1
//         : this.grades.GRADE_2;
//     return list.filter((item) => item.grade === grade);
//   }
//   return list;
// };

// const emptyValueIfNotInList = (filterGroup) => {
//   const foundItem = filterGroup.list.find((item) => item.href === filterGroup.value);
//   if (!foundItem) {
//     filterGroup.value = '';
//   }
// };

export const filterData = (
  { grades, streams, studyProgrammes, versions, name, relevantCurricula },
  list
) => {
  let filteredList = [...list];

  if (relevantCurricula) filteredList = filteredList.filter((e) => e.relevant);

  if (name && name !== '') {
    filteredList = filteredList.filter((e) =>
      e.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
    );
  }

  if (grades && grades !== '') {
    if (grades === studyProgrammeConstants.firstGradeHref)
      filteredList = filteredList.filter((e) => e.grade === GRADE_1);
    else if (grades === studyProgrammeConstants.secondGradeHref)
      filteredList = filteredList.filter((e) => e.grade === GRADE_2);
    else if (grades === studyProgrammeConstants.thirdGradeHref)
      filteredList = filteredList.filter((e) => e.grade === GRADE_3);
  }

  if (studyProgrammes && studyProgrammes !== '') {
    filteredList = filteredList.filter((d) => d.studyProgram.indexOf(studyProgrammes) !== -1);
  }

  if (streams && streams !== '') {
    filteredList = filteredList.filter((d) => {
      let stream;

      if (d.curricula) {
        stream = d.curricula.find((c) => {
          if (!c.stream) return false;
          return c.stream.includes(streams);
        });
      } else if (d.stream && d.stream.length > 0) {
        stream = d.stream.find((c) => c === streams);
      }

      return stream != null;
    });
  }

  if (versions && versions !== '') {
    filteredList = filteredList.filter((d) => !d.version || d.version.key === versions);
  }

  return filteredList;
};
