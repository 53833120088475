import { createSelector } from '@reduxjs/toolkit';
import { IApiResouce } from '../types/sriTypes';
import { RootState } from './storeSetup';

export const arrayToObjectMap = <T extends IApiResouce>(array: T[]): Record<string, T> => {
  const objMap = Object.fromEntries(array.map((elem) => [elem.$$meta.permalink, elem]));
  return objMap;
};

export const createTypedSelector = createSelector.withTypes<RootState>();
