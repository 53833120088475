import iconBlueTone from '@assets/img/logo_icoon_bluetone.svg';
import CalendarPrintActivity from './CalendarPrintActivity';
import './calendarPrint.scss';

const CalendarPrintItem = ({ calendar }) => {
  if (!calendar) return null;
  const { title, creators, class: classes, activities, calendarRange } = calendar;
  const teachersName = creators?.map((t) => t.$$displayName).join(', ');

  return (
    <div className="calendar-print draft-sheet">
      <header className="print-header">
        <img className="logo" src={iconBlueTone} />
        <div className="title-wrapper">
          <h1 className="title">{title}</h1>
          <span className="teachers">
            <b>Krabbelblaadje</b> voor {teachersName}
          </span>
        </div>
        <div className="classes">
          <div className="calendar-range">{calendarRange}</div>
          <div key={classes.key} className="class-button">
            {classes.$$displayName}
          </div>
        </div>
      </header>
      <main className="print-activities">
        {activities?.map((activity) => (
          <CalendarPrintActivity key={activity.key} activity={activity} />
        ))}
      </main>
    </div>
  );
};

export default CalendarPrintItem;
