import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import settings from '@config/settings';
import { isIframe } from '@utils/utils';
import { initAnalytics, removeAnalytics } from '../../analytics';

const MyCookiesModal = () => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleCompleted = (ev) => {
      const cookies = ev.detail;

      console.log(`cookie `, cookies);

      if (cookies.cookies__analytic) {
        initAnalytics();
      } else {
        removeAnalytics();
      }
    };
    const div = modalRef.current;
    div?.addEventListener('onSubmitCookies', handleCompleted);
    return () => {
      div?.removeEventListener('onSubmitCookies', handleCompleted);
    };
  }, []);

  // don't inject the cookies modal in the iframe
  if (isIframe()) {
    return null;
  }

  return (
    <kov-cookie-modal
      ref={modalRef}
      id="CookiesModal"
      essential-id={settings.cookies.essentialId}
      analytic-id={settings.cookies.analyticId}
      content-href={settings.cookies.contentHref}
    ></kov-cookie-modal>
  );
};

MyCookiesModal.propTypes = {
  completeCookies: PropTypes.func,
};
export default MyCookiesModal;
