import {
  selectActiveCurricula,
  selectCurriculaWithMultipleActiveVersions,
  selectLlinkidCurriculaRoots,
} from '@store/contentApi/contentApiSelectors';
import {
  selectCustomCurriculaData,
  selectIsDataFreshNoSpinner,
  selectSettingsForCurrentSchoolyear,
} from '@store/llinkidApis/llinkidApiSelectors';
import {
  selectCurrentSchoolHref,
  selectCurrentSchoolyearKey,
  selectOrgsInfoForLeerplanList,
  selectPrincipalSchoolHrefs,
  selectPrincipalTeamHrefsForSchoolyear,
  selectSchoolyearsForSchool,
  selectUserHref,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { orderBy } from 'lodash-es';
import { createSelectorCreator, lruMemoize } from 'reselect';

import { createTypedSelector } from '@store/genericHelpers';
import { selectStudyProgrammesPerGradeForSchool } from '@store/studyProgrammesApi/studyProgrammeSelectors';
import { getPreviousSchoolyear } from '@utils/utils';
import { filterData } from '../../helpers/leerplanFilteringHelper';
import { CurriculumRoot } from '../../types/contentApiTypes';
import { generateGroupedHomePageList } from './leerplanListHelpers';

// @ts-expect-error not used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createDebugSelector = createSelectorCreator(lruMemoize, {
  equalityCheck: (previousVal, currentVal) => {
    const rv = currentVal === previousVal;
    if (!rv) console.log('Selector param value changed', currentVal);
    return rv;
  },
});

export const selectIsCurriculaListLoading = createTypedSelector(
  [
    selectCurrentSchoolHref,
    (state) => state.customCurriculaData.hasStartedInitializing,
    (state) => selectSettingsForCurrentSchoolyear(state).customCurricula,
    (state) => state.contentApiData.curriculaRootsLoading,
    (state) =>
      selectIsDataFreshNoSpinner(state, ['customCurricula'], selectCurrentSchoolyearKey(state)),
  ],
  (
    currentSchool,
    hasStartedInitializing,
    customCurriculaSettings,
    baseCurriculaLoading,
    isCustomCurriculaLastRefreshValid
  ) => {
    return (
      (currentSchool && !hasStartedInitializing) ||
      (currentSchool &&
        hasStartedInitializing &&
        customCurriculaSettings.isLoading &&
        !isCustomCurriculaLastRefreshValid) ||
      baseCurriculaLoading
    );
  }
);

let groupedHomePageListSelectorCount = 0;

export const groupedHomePageListSelector = createTypedSelector(
  [
    selectOrgsInfoForLeerplanList,
    (state, schoolyear?: string) =>
      selectActiveCurricula(state, {
        schoolyearKey: schoolyear || state.userAndSchools.currentSchoolyear,
      }),
    selectLlinkidCurriculaRoots,
    selectCustomCurriculaData,
    selectPrincipalSchoolHrefs,
    selectPrincipalTeamHrefsForSchoolyear,
    (state) => state.studyProgrammeApiData.allPrograms,
    (state) => state.studyProgrammeApiData.firstGradeStreams,
    (state) => state.studyProgrammeApiData.secondGradeFinalities,
    (state) => state.userAndSchools.currentSchoolyear,
    selectUserHref,
    (state, schoolyear?: string) => schoolyear,
    (state, schoolyear?: string, getAll?: boolean) => getAll,
    selectSchoolyearsForSchool,
    (state, schoolyear) =>
      selectCurriculaWithMultipleActiveVersions(state, {
        schoolyearKey: schoolyear || state.userAndSchools.currentSchoolyear,
      }),
    selectIsCurriculaListLoading,
  ],
  (
    $orgsInfo,
    $activeCurriculaRoots,
    $allCurriculaRoots,
    customCurricula,
    principalSchoolHrefs,
    principalTeamHrefs,
    allPrograms,
    firstGradeStreams,
    secondGradeFinalities,
    currentSchoolyearKey,
    $userHref,
    customSchoolyearKey, // this is used in the case where you need the curricula list, but not for the current schoolyear.
    getAll,
    schoolyears,
    curriculaMultipleActiveVersionsMap,
    isCurriculaListLoading
  ) => {
    // console.log(
    //   'groupedHomePageListSelector called. schoolyear:',
    //   customSchoolyear,
    //   'getAll:',
    //   getAll
    // );
    console.log(`groupedHomePageListSelector: ${(groupedHomePageListSelectorCount += 1)}`);

    if (isCurriculaListLoading) {
      return [];
    }

    const activeRootKeys = new Set($activeCurriculaRoots.map((z) => z.key));

    const schoolyearKey = customSchoolyearKey || currentSchoolyearKey;
    const groupedList = generateGroupedHomePageList(
      $allCurriculaRoots,
      customCurricula,
      $orgsInfo,
      principalSchoolHrefs,
      principalTeamHrefs,
      allPrograms,
      firstGradeStreams,
      secondGradeFinalities,
      schoolyearKey,
      $userHref,
      getAll,
      schoolyears,
      curriculaMultipleActiveVersionsMap
    );

    // the valid logic below is because we are going to make basisopties (v1) disappear in 2022-2023.
    // this should not be needed anymore in the future. it should be a one-off.
    return groupedList
      .map((z) => {
        if (!activeRootKeys.has(z.id) && z.id !== 'nonderived') {
          return { ...z, valid: false };
        }
        return { ...z, valid: true };
      })
      .filter((z) => !(z.valid === false && z.type === 'PLAN')); // filter out the invalid base plans
  }
);

export const ungroupedHomePageListSelector = createTypedSelector(
  [groupedHomePageListSelector],
  (groupedHomePageList) => {
    const flatCurriculaList: Array<
      CurriculumRoot & { valid?: boolean; id: string; creator: string } // TODO: complete the type!!
    > = [];
    const curricula = groupedHomePageList;
    curricula.forEach((c) => {
      flatCurriculaList.push(c);
      if (c.curricula) {
        c.curricula.forEach((curr) => {
          flatCurriculaList.push(curr);
        });
      }
    });

    return flatCurriculaList;
  }
);

export const selectUngroupedHomePageListIncludingPreviousYearList = createTypedSelector(
  [
    (state, schoolyear) => ungroupedHomePageListSelector(state, schoolyear),
    (state, schoolyear) =>
      ungroupedHomePageListSelector(
        state,
        getPreviousSchoolyear(schoolyear || state.userAndSchools.currentSchoolyear)?.key
      ),
  ],
  (currentUngroupedHomePageList, previousYearUngroupedHomePageList) => {
    const validKeys = new Set(currentUngroupedHomePageList.map((z) => z.key));
    const flatCurriculaList = [...currentUngroupedHomePageList];

    previousYearUngroupedHomePageList.forEach((item) => {
      if (!validKeys.has(item.key)) {
        flatCurriculaList.push({ ...item, valid: false });
      }
    });

    return flatCurriculaList;
  }
);

export const selectLeerplanList = createTypedSelector(
  [
    groupedHomePageListSelector,
    (state) => state.leerplanList.filters,
    (state) => state.leerplanList.expandedItems,
    (state) => state.userAndSchools.privateState?.state?.starredCurricula,
  ],
  (list, filters, expandedItems, starredCurrs) => {
    const starredCurricula = starredCurrs || [];
    const extraItems: Array<CurriculumRoot> = [];
    // TODO: this should probably be a .reduce...
    const listWithFavs = list.map((curriculum) => {
      // If it doesn't have a custom curricula id, we need to compare it to the base curriculum key instead.
      const applicableLayer = curriculum.setid || curriculum.custid || curriculum.id;

      if (curriculum.curricula) {
        curriculum.curricula.forEach((cc) => {
          const ncc = { ...cc, fav: starredCurricula.includes(cc.setid || cc.custid || cc.id) };
          if (ncc.fav) {
            extraItems.push(ncc);
          }
        });
      }

      return { ...curriculum, fav: starredCurricula.includes(applicableLayer) };
    });

    let filteredList = filterData(filters, [...listWithFavs, ...extraItems]);
    filteredList = filteredList.map((curriculum) => {
      return { ...curriculum, expand: expandedItems.includes(curriculum.key) };
    });

    filteredList = orderBy(filteredList, ['fav', 'order', 'name'], ['desc', 'asc', 'asc']);
    console.log('leerplanListSelector has ran, ', filteredList.length, 'results');
    return filteredList;
  }
);

export const selectSchoolStudyProgrammes = (state) =>
  state.userAndSchools.schoolsData?.[state.userAndSchools.currentSchoolHref]?.studyProgrammes;

export const selectSchoolStudyProgrammeGroups = (state) =>
  state.userAndSchools.schoolsData?.[state.userAndSchools.currentSchoolHref]?.studyProgrammeGroups;

export const selectHasCurrentSchoolStudyProgrammes = createTypedSelector(
  [selectSchoolStudyProgrammes],
  (studyProgrammes) => {
    return Boolean(studyProgrammes?.length > 0);
  }
);

export const selectDefaultRelevantCheckValue = createTypedSelector(
  [selectStudyProgrammesPerGradeForSchool],
  (studyProgrammesPerGradeForSchool) => {
    if (!studyProgrammesPerGradeForSchool) return false;
    const defaultRelevantCheckValue = Object.values(studyProgrammesPerGradeForSchool).every(
      (gradeSPs) => {
        return gradeSPs.length > 0;
      }
    );
    return defaultRelevantCheckValue;
  }
);
