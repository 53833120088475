import { Box, Skeleton } from '@mui/material';

import './leerplanHeaderSkeleton.scss';

const TwoLinesBullet = () => (
  <>
    <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '1.5rem' }}>
      <Skeleton variant="circular" animation="wave" width={7} height={7} />
      <Skeleton
        variant="text"
        animation="wave"
        sx={{ fontSize: '1.7rem', flexGrow: 1, marginLeft: 2 }}
      />
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '1.5rem' }}>
      <Skeleton
        variant="text"
        animation="wave"
        sx={{ fontSize: '1.7rem', flexGrow: 0.5, marginLeft: 3 }}
      />
    </Box>
  </>
);

const OneLineBullet = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '1.5rem' }}>
    <Skeleton variant="circular" animation="wave" width={7} height={7} />
    <Skeleton
      variant="text"
      animation="wave"
      sx={{ fontSize: '1.7rem', flexGrow: 0.8, marginLeft: 2 }}
    />
  </Box>
);

const LeerplanHeaderSkeleton = () => {
  return (
    <div className="header">
      <div className="left">
        <Box sx={{ display: 'flex', alignItems: 'baseline', height: '50px' }}>
          <Skeleton variant="rounded" animation="wave" width={30} height={32} />
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ fontSize: '2.6rem', flexGrow: 0.8, marginLeft: 1 }}
          />
        </Box>
        <TwoLinesBullet />
        <TwoLinesBullet />
        <OneLineBullet />
        <TwoLinesBullet />
      </div>
      <div className="right">
        <Box sx={{ height: '50px', width: '200px' }}>
          <Skeleton className="title" animation="wave" variant="text" sx={{ fontSize: '2.6rem' }} />
        </Box>
        <TwoLinesBullet />
        <TwoLinesBullet />
        <OneLineBullet />
        <TwoLinesBullet />
      </div>
    </div>
  );
};
export default LeerplanHeaderSkeleton;
