import {
  selectCreator,
  selectCreatorFromCustomCurricula,
  selectCustomCurriculaFromUrlOrParams,
  selectGoalsAndSectionAndGoalListFromCurriculum,
  selectParentTreeMap,
  selectSelectedStudyPrograms,
} from '@store/curriculum/curriculumSelectors';
import { makeDistributionWindowObject } from '@store/distribute/distributeHelper';
import { selectDistributionData } from '@store/distribute/distributeSelectors';
import { selectSchoolStudyProgrammeGroups } from '@store/leerplanList/leerplanListSelectors';
import { RootState } from '@store/storeSetup';
import { StudyProgrammeApiState } from '@store/studyProgrammesApi/studyProgrammeTypes';
import {
  selectCurrentSchoolHref,
  selectCurrentSchoolyear,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { getPositioningObject } from '@utils/annotationHelper';
import {
  CONTENTTYPES,
  createCustomItem,
  CUSTOMCREATIONTYPES,
  CUSTOMCURRICULATYPES,
  CUSTOMTYPES,
} from '@utils/curriculumHelper';
import {
  generateAnnotationsBatch,
  generateDistributeSectionBatch,
  getFilteredCustomCurriculaHrefs,
} from '@utils/distributionHelper';
import {
  filterByApplicability,
  filterByCreator,
  filterByIdentifier,
  filterByNonDerivedGroupKey,
  filterBySchoolyear,
  filterBySources,
  filterByTitle,
  filterCustomCurriculaWithFilters,
} from '@utils/filters';
import { getGoalsSubset, getSectionsSubset } from '@utils/nonDerivedHelper';
import { getNonDerivedGroupKey } from '@utils/utils';
import { ApiCustomCurriculum } from '../../types/llinkidApiTypes';
import { Schoolyear } from '../../types/schoolyear';
import { BatchRequest } from '../../types/sriTypes';
import {
  createCustomCurriculaGroup,
  generateCustomCurricula,
  generateCustomItemBatch,
  groupCurricula,
} from './llinkidApiHelper';
import {
  selectCustomCurriculaData,
  selectCustomCurriculaForCurrentSchoolyear,
} from './llinkidApiSelectors';
import { PositioningObject } from './userActionTypes';

export const selectCustomCurriculaFromFilters = (
  state: RootState,
  {
    curriculum,
    studyPrograms,
    creator,
    schoolyear,
  }: {
    curriculum: any;
    studyPrograms: StudyProgrammeApiState[];
    creator?: string;
    schoolyear?: Schoolyear;
  }
) => {
  const allCustomCurricula = selectCustomCurriculaData(state);
  const creatorHref = creator || selectCreator(state)?.href;
  const schoolYear = schoolyear || selectCurrentSchoolyear(state);

  const filters = [
    filterByCreator(creatorHref),
    filterByApplicability(studyPrograms),
    filterBySchoolyear(schoolYear),
  ];

  if (curriculum.nonDerivedGroupKey) {
    // used for distribution of non-derived on multiple levels. (root or linked non-derived)
    filters.push(filterByNonDerivedGroupKey(curriculum.nonDerivedGroupKey, allCustomCurricula));
  } else if (CUSTOMCURRICULATYPES.custom === curriculum.curriculumType) {
    // /used ONLY for creating new non-derived via the modal.
    filters.push(filterByIdentifier(curriculum.identifiers));
    filters.push(filterByTitle(curriculum.title));
  } else {
    filters.push(filterBySources(curriculum.sources.map((s) => s.href)));
  }

  return filterCustomCurriculaWithFilters(allCustomCurricula, filters);
};

const selectCustomCurriculaFromCreatorAndSchoolYear = (state) => {
  const creatorHref = selectCreator(state)?.href;
  const schoolYear = selectCurrentSchoolyear(state);
  const allCustomCurriculas = selectCustomCurriculaData(state);

  const customCurs = filterCustomCurriculaWithFilters(allCustomCurriculas, [
    filterByCreator(creatorHref),
    filterBySchoolyear(schoolYear),
  ]);

  return customCurs;
};

export const selectTryCreateCustomCurriculaBatch = (
  state: RootState,
  { curriculum, creatorHref, studyPrograms }
) => {
  const batch: BatchRequest = [];
  let custCurs: ApiCustomCurriculum[] = [];
  const school = selectCurrentSchoolHref(state);
  const schoolyear = selectCurrentSchoolyear(state);

  const customCurricula = selectCustomCurriculaFromFilters(state, {
    curriculum,
    studyPrograms,
    creator: creatorHref,
  });
  for (const studyProgram of studyPrograms) {
    let customCurriculum = customCurricula.find((c) =>
      c.applicability.studyProgrammes.find((s) => s.href === studyProgram)
    );

    if (!customCurriculum) {
      customCurriculum = generateCustomCurricula(
        curriculum.sources
          ? curriculum.sources.find((s) => s.studyProgram === studyProgram).href
          : null,
        curriculum.curriculumType,
        [studyProgram],
        creatorHref,
        school,
        schoolyear,
        curriculum.version
      );

      if (curriculum.curriculumType === CUSTOMCURRICULATYPES.custom) {
        customCurriculum.title = curriculum.title || null;
        customCurriculum.identifier = curriculum.identifiers;
      }

      batch.push({
        verb: 'PUT',
        href: customCurriculum.$$meta.permalink,
        body: customCurriculum,
      });
    } else {
      console.log('Custom curricula already exists');
    }

    custCurs.push(customCurriculum);
  }

  if (curriculum.curriculumType === CUSTOMCURRICULATYPES.custom) {
    // pre-grpup the non-derived. they might have A+B stream together.
    const group = createCustomCurriculaGroup();
    batch.push({ verb: 'PUT', href: group.$$meta.permalink, body: group });
    custCurs.forEach((e) => {
      e.customCurriculaGroup = { href: group.$$meta.permalink };
    });
  }

  const studyProgrammeGroups = selectSchoolStudyProgrammeGroups(state);
  const allCustomCurricula = selectCustomCurriculaForCurrentSchoolyear(state);
  const batchWithGroups = groupCurricula(studyProgrammeGroups, allCustomCurricula, batch);
  // the auto-grouping needs some work, it's a bit hacky.
  // the batchitems have groups now, but our custCurs do not. so we need to update our custCurs

  custCurs = custCurs.map((e) => {
    const custCurWithGroup = batchWithGroups.find((b) => b.href === e.$$meta.permalink)?.body;
    if (custCurWithGroup) {
      return custCurWithGroup;
    }
    return e;
  });

  return { custCurs, batch: batchWithGroups };
};

export const selectDistributeGoalBatch = (
  state: RootState,
  {
    resources,
    goalHref,
    goalReadorder,
    goalReference,
  }: {
    resources: Array<{
      studyProgrammes: Array<{ href: string }>;
      selected: boolean;
      curriculumType?: string;
    }>;
    goalHref: string;
    goalReadorder?: number;
    goalReference?: string | undefined;
  }
) => {
  const type = CUSTOMTYPES.goalReference;

  let batch: BatchRequest = [];
  const creatorHref = selectCreator(state)?.href;

  const distributionData = selectDistributionData(state);
  const allReferences = distributionData?.allReferences;

  if (!allReferences) {
    console.error('allReferencesList needs to be defined');
    throw new Error('allReferencesList needs to be defined');
  }

  const customCurricula = selectCustomCurriculaData(state);
  const allCustomCurriculas = selectCustomCurriculaFromCreatorAndSchoolYear(state);

  resources.forEach((resource) => {
    // /the resource is the checkbox item that has been set/unset.
    const studyProgramHrefs = resource.studyProgrammes.map((e) => e.href);

    const selectedStudyProgramHrefs = selectSelectedStudyPrograms(state).map(
      (z) => z.$$meta.permalink
    );
    const selectedProgramsForCur = studyProgramHrefs.filter((href) =>
      selectedStudyProgramHrefs.includes(href)
    );

    const mappedCustCurHrefs = getFilteredCustomCurriculaHrefs(
      resource,
      allCustomCurriculas,
      selectedProgramsForCur,
      customCurricula
    );

    const allReferencesForCurs = allReferences.filter(
      (ref) =>
        ref.type === CUSTOMTYPES.goalReference && mappedCustCurHrefs.includes(ref.curriculum.href)
    );

    const existing = allReferencesForCurs.filter(
      (ref) => goalHref === ref.target.href && ref.type === type
    );

    if (!resource.selected) {
      // do a delete
      console.log('wanting to delete:');
      existing.forEach((elem) => {
        console.log(elem.$$meta.permalink);
        batch.push({ verb: 'DELETE', href: elem.$$meta.permalink });
      });
    } else {
      resource.curriculumType = CUSTOMCURRICULATYPES.adaptation; // this is dirty. we can only create adaptations. yet it could be that a root nonderived is selected. the curriculumType must not confuse the findCurricula in dataservice. I've fixed it by checking/finding on nonderivedgroupkey first. that seems to be ok. no clean fix can be made since this code will be deleted with new specs.
      const response = selectTryCreateCustomCurriculaBatch(state, {
        curriculum: resource,
        creatorHref,
        studyPrograms: selectedProgramsForCur,
      });
      const customCurs = response.custCurs;
      batch = batch.concat(response.batch);
      // do a create
      selectedProgramsForCur.forEach((studyProgHref) => {
        const customCurriculum = customCurs.find(
          (elem) => elem.applicability.studyProgrammes[0].href === studyProgHref
        );

        if (!customCurriculum) {
          console.error(
            `custom curricula not found for distribution Studyprogramme:${studyProgHref}`
          );
        }
        const annotationsBatch = generateAnnotationsBatch(
          resource,
          customCurriculum,
          allReferences,
          goalReference,
          existing,
          goalHref,
          goalReadorder,
          type
        );
        batch = batch.concat(annotationsBatch);
      });
    }
  });

  return batch;
};

export const selectDistributeGoalFromAnnotationsBatch = (
  state: RootState,
  {
    goalsPositions,
    batch = [],
  }: {
    goalsPositions: Array<{ goal: any; position: { readOrder: number } | PositioningObject }>;
    batch?: BatchRequest;
  }
): BatchRequest => {
  console.time('distributeGoalFromAnnotations');
  // /we need to use the distribution code because it also distributes its pedagogical tips. the "move" operation can't do that. so we need to make the batch via this. then get the right positioning.
  const distributionData = selectDistributionData(state);
  if (!distributionData) {
    console.error('distributionData needs to be defined');
    throw new Error('distributionData needs to be defined');
  }
  let cur;
  if (state.curriculum.curriculumKey !== 'nonderived') {
    cur = distributionData.allSubjects.find((c) => c.key === state.curriculum.curriculumKey);
  } else {
    const customCurricula = selectCustomCurriculaFromUrlOrParams(state);
    const allCustomCurricula = selectCustomCurriculaData(state);
    const customCur = customCurricula[0];

    cur = distributionData.allSubjects.find(
      // @ts-expect-error groupKey exists on nonderived subjects, but the type is not inferred correctly yet.
      (c) => getNonDerivedGroupKey(allCustomCurricula, customCur) === c.groupKey
    );
  }

  let allBatches: BatchRequest = [...batch];

  for (const goalPos of goalsPositions) {
    const { goal } = goalPos;
    const { position } = goalPos;
    const curWindowObj = makeDistributionWindowObject(cur, distributionData.allReferences);
    curWindowObj.selected = !(
      goal.foundationallyEditable === true && goal.partialDistribution === false
    ); // if it's editable and not partial, it means we're doing a delete. else we're doing a distribution.

    const goalReference = 'parent' in position ? position.parent : undefined;
    const goalReadorder = position ? position.readOrder : goal.readOrder;

    const newBatch = selectDistributeGoalBatch(state, {
      resources: [curWindowObj],
      goalHref: goal.$$meta.permalink,
      goalReadorder,
      goalReference,
    });

    allBatches = allBatches.concat(newBatch);
  }

  return allBatches;
};

const selectDistributeSectionFromAnnotationsBatch = (
  state: RootState,
  { section, originator, creationType }
): BatchRequest => {
  const batch = generateCustomItemBatch(section);
  const positions = getPositioningObject(
    originator,
    [section],
    creationType,
    selectGoalsAndSectionAndGoalListFromCurriculum(state)
  );
  const [position] = positions;

  const distributionData = selectDistributionData(state);
  if (!distributionData) {
    console.error('distributionData needs to be defined');
    throw new Error('distributionData needs to be defined');
  }
  const allReferencesList = distributionData.allReferences;

  const customCurricula = selectCustomCurriculaFromUrlOrParams(state);
  const customCur = customCurricula[0];
  const allCustomCurricula = selectCustomCurriculaData(state);
  const cur = distributionData.allSubjects.find(
    // @ts-expect-error groupkey exists for nonderived subjects, but the type is not inferred correctly yet.
    (c) => getNonDerivedGroupKey(allCustomCurricula, customCur) === c.groupKey
  );

  let allBatches: BatchRequest = [...batch];
  const curWindowObj = makeDistributionWindowObject(cur, distributionData.allReferences);
  const readorder = position ? position.readOrder : section.readOrder;

  const selectedStudyProgramHrefs = selectSelectedStudyPrograms(state).map(
    (e) => e.$$meta.permalink
  );

  const selectedProgramsForCur = curWindowObj.studyProgrammes
    .map((e) => e.href)
    .filter((e) => selectedStudyProgramHrefs.includes(e));

  const creatorHref = selectCreatorFromCustomCurricula(state)?.href;
  curWindowObj.curriculumType = CUSTOMCURRICULATYPES.adaptation;

  const response = selectTryCreateCustomCurriculaBatch(state, {
    curriculum: curWindowObj,
    creatorHref,
    studyPrograms: selectedProgramsForCur,
  });
  const customCurs = response.custCurs;
  allBatches.concat(response.batch);

  const distBatch = generateDistributeSectionBatch({
    section,
    sectionReadorder: readorder,
    customCurs,
    selectedProgramsForCur,
    allReferencesList,
    creationType,
  });

  allBatches = allBatches.concat(distBatch);
  return allBatches;
};

export const selectAddCustomSectionBatch = (
  state: RootState,
  { parentItem, creationType, customItem = {} }
) => {
  const parentVMap = selectParentTreeMap(state);
  const schoolHref = selectCurrentSchoolHref(state);
  const creatorHref = selectCreatorFromCustomCurricula(state)?.href;
  let type;
  switch (creationType) {
    case CUSTOMCREATIONTYPES.section:
      type = CUSTOMTYPES.section;
      break;
    case CUSTOMCREATIONTYPES.subSection:
      type = CUSTOMTYPES.section;
      break;
    default:
      break;
  }

  const customItemProperties = {
    ...customItem,
    type,
  };
  const customItemObject = createCustomItem(schoolHref, creatorHref, customItemProperties);

  let item;
  let parentNode;
  let parent;

  let result;

  switch (parentItem.type) {
    case CUSTOMTYPES.goalList:
      item = {
        ...customItemObject,
        sections: [],
        concordance: undefined,
        goals: parentItem.goals,
      };

      result = selectDistributeSectionFromAnnotationsBatch(state, {
        section: item,
        originator: parentItem,
        creationType: CUSTOMCREATIONTYPES.section,
      });
      break;

    case CONTENTTYPES.goal:
    case CUSTOMTYPES.goal:
      // eslint-disable-next-line no-case-declarations
      const p = parentVMap.get(parentItem.key).next.value;
      if (p && (p.type === CUSTOMTYPES.goal || p.type === CONTENTTYPES.goal)) {
        return selectAddCustomSectionBatch(state, {
          parentItem: p,
          creationType,
        });
      }

      parentNode = parentVMap.get(parentItem.key).next;
      parent = parentNode.value;
      // eslint-disable-next-line no-case-declarations
      const grandParent = parentNode.next;

      item = {
        ...customItemObject,
        goals: getGoalsSubset(parentItem.key, parent),
        concordance: undefined,
        sections:
          creationType === CUSTOMCREATIONTYPES.section
            ? getSectionsSubset(parent, grandParent ? grandParent.value : null)
            : [],
        $$parent: grandParent && grandParent.value.key ? grandParent.value : parent,
      };

      result = selectDistributeSectionFromAnnotationsBatch(state, {
        section: item,
        originator: parentItem,
        creationType,
      });
      break;

    case CONTENTTYPES.tip:
    case CONTENTTYPES.goalExplanation:
    case CONTENTTYPES.goalExtra:
    case CONTENTTYPES.initialSituation:
      // eslint-disable-next-line no-case-declarations
      const goal = parentVMap.get(parentItem.key).next.value;
      result = selectAddCustomSectionBatch(state, { parentItem: goal, creationType });
      break;

    case CUSTOMTYPES.section:
      parentNode = parentVMap.get(parentItem.key).next;
      parent = parentNode.value;

      item = {
        ...customItemObject,
        goals: parentItem.goals ? [...parentItem.goals] : [],
        sections:
          creationType === CUSTOMCREATIONTYPES.section ? getSectionsSubset(parentItem, parent) : [],
        $$parent: parent && parent.key ? parent : parentItem,
      };
      result = selectDistributeSectionFromAnnotationsBatch(state, {
        section: item,
        originator: parentItem,
        creationType,
      });
      break;
    default:
      break;
  }

  return result;
};
