import EducationalPointer from '@UI/educationalPointer/EducationalPointer';
import Title from './title/Title';
import EducationalComponentsList from './educationalComponentsList/EducationalComponentsList';

import './leerplanHeaderSection.scss';

const LeerplanHeaderSection = ({
  className,
  title,
  version,
  subtitle,
  curriculumType,
  educationalPointers,
  principles,
  educationalComponents,
  validityPeriod,
  studyProgram,
  isNonderived,
}) => {
  return (
    <div className={className}>
      <div className="leerplan-header-top">
        <Title
          title={title}
          subtitle={subtitle}
          curriculumType={curriculumType}
          version={version}
          validityPeriod={validityPeriod}
          studyProgram={studyProgram}
          isNonderived={isNonderived}
        />
        <div className="educational-pointers">
          {educationalPointers?.map((pointer, index) => (
            <EducationalPointer key={index} pointer={pointer} />
          ))}
        </div>
      </div>

      <div className="leerplan-header-content">
        {principles?.length > 0 && (
          <div className="principles">
            <ul>
              {principles?.map((principle, index) => (
                <li
                  key={principle.key || index}
                  dangerouslySetInnerHTML={{ __html: principle.description }}
                ></li>
              ))}
            </ul>
          </div>
        )}

        <EducationalComponentsList educationalComponents={educationalComponents} />
      </div>
    </div>
  );
};
export default LeerplanHeaderSection;
