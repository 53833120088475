import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { leerplanListActions } from '@store/leerplanList/leerplanListState';
import {
  selectLeerplanList,
  selectDefaultRelevantCheckValue,
} from '@store/leerplanList/leerplanListSelectors';
import useModal from '@hooks/useModal';
import { selectIsSchoolyearReadOnly } from '@store/userAndSchool/userAndSchoolSelectors';

import './leerplanHome.scss';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import { Button } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { endLog } from '@utils/loggerHelper';
import CreateNonderivedModal from './modals/CreateNonderivedModal';
import LeerplanList from './leerplanList/LeerplanList';
import LeerplanListFilter from './leerplanListFilter/LeerplanListFilter';
import { selectShowNonDerived } from './LeerplanHomeSelectors';

const LeerplanHome = () => {
  const createNonDerivedModalRef = useRef();
  const readOnly = useSelector(selectIsSchoolyearReadOnly);
  const selectionMode = useSelector((state) => state.leerplanList.selectionMode);
  const leerplanList = useSelector(selectLeerplanList);
  const dispatch = useDispatch();

  const showNonDerived = useSelector(selectShowNonDerived);
  const defaultRelevantCheckValue = useSelector(selectDefaultRelevantCheckValue);
  const createNonderivedHandler = (formValue) =>
    dispatch(leerplanListActions.createNonderivedCurricula(formValue));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { open: openCreateNonderivedModal, ...createNonderivedModalProps } =
    useModal(createNonderivedHandler);

  useEffect(() => {
    if (leerplanList && leerplanList.length) {
      endLog('change schoolyear');
      endLog('load home.leerplan');
    }
  }, [leerplanList]);

  useEffect(() => {
    dispatch(
      leerplanListActions.setFilter({
        property: 'relevantCurricula',
        value: defaultRelevantCheckValue,
      })
    );
  }, [defaultRelevantCheckValue, dispatch]);

  const onCreateHandler = () => {
    createNonDerivedModalRef.current?.open({ ...createNonderivedModalProps });
  };

  return (
    <div className="leerplan-home">
      {!selectionMode && (
        <div className="leerplan-home-header">
          <div className="leerplan-title">
            Leerplannen
            {showNonDerived && (
              <Button
                variant="contained"
                disableElevation
                startIcon={<AddBoxIcon />}
                onClick={onCreateHandler}
                disabled={readOnly}
              >
                Doelenkader
              </Button>
            )}
          </div>
        </div>
      )}

      <LeerplanListFilter />
      <LeerplanList list={leerplanList} />
      {!selectionMode && (
        <Button
          variant="outlined"
          href="https://leerplannenso.katholiekonderwijs.vlaanderen/lele/leerplannen.asp"
          target="_blank"
          rel="noreferrer"
          startIcon={<OpenInNewIcon />}
        >
          Naar de leerplannen van de oude structuur
        </Button>
      )}

      <Modal
        ref={createNonDerivedModalRef}
        size="md"
        component={<CreateNonderivedModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </div>
  );
};

export default LeerplanHome;
