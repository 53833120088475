import { rest } from 'msw'; // https://api.katholiekonderwijs.vlaanderen/sam/commons/studyprogrammegroups?type=%2Fsam%2Fcommons%2Fstudyprogrammegroups%2Ftypes%2F16212f35-a563-48a0-a453-76bf06f0b6ee&limit=500

import apis from '@config/apis';
import { studyProgrammeConstants } from '../../../constants/apiConstants';
import { fetchJsonPath } from '../../helpers/fetchJson';

const fetchJson = fetchJsonPath('api/studyProgrammes');
const studyProgrammesPath = 'studyprogrammes.json'; // https://api.katholiekonderwijs.vlaanderen/sam/commons/studyprogrammes?limit=500
const educationalActivityTypesPath = 'educationalActivityTypes.json'; // https://api.katholiekonderwijs.vlaanderen/sam/commons/educationalactivitytypes/?limit=500
const finalitiesPath = 'finalities.json'; // https://api.katholiekonderwijs.vlaanderen/sam/commons/studyprogrammegroups?type=%2Fsam%2Fcommons%2Fstudyprogrammegroups%2Ftypes%2F064df602-d7f6-40c1-be84-36cb935a309f&limit=500
const streamsPath = 'streams.json'; // https://api.katholiekonderwijs.vlaanderen/sam/commons/studyprogrammegroups?type=%2Fsam%2Fcommons%2Fstudyprogrammegroups%2Ftypes%2F8807fba6-4cd5-43fd-ad20-b4237c29c02e&limit=500
const gradesPath = 'grades.json';

export const studyProgrammeApiHandlers = [
  rest.get(`${apis.cachedApi.baseUrl}/sam/commons/studyprogrammes`, async (req, res, ctx) => {
    const studyProgrammes = await fetchJson(ctx, studyProgrammesPath);
    return res(ctx.json(studyProgrammes), ctx.delay(150));
  }),
  rest.get(
    `${apis.cachedApi.baseUrl}/sam/commons/educationalactivitytypes`,
    async (req, res, ctx) => {
      const educationalActivityTypes = await fetchJson(ctx, educationalActivityTypesPath);
      return res(ctx.json(educationalActivityTypes), ctx.delay(150));
    }
  ),
  rest.get(`${apis.cachedApi.baseUrl}/sam/commons/studyprogrammegroups`, async (req, res, ctx) => {
    switch (req.url.searchParams.get('type')) {
      case studyProgrammeConstants.firstGradeStreamGroup: {
        const streams = await fetchJson(ctx, streamsPath);
        return res(ctx.json(streams), ctx.delay(150));
      }
      case studyProgrammeConstants.secondAndThirdGradeFinalityGroup: {
        const finalities = await fetchJson(ctx, finalitiesPath);
        return res(ctx.json(finalities), ctx.delay(150));
      }
      case studyProgrammeConstants.gradeGroup: {
        const grades = await fetchJson(ctx, gradesPath);
        return res(ctx.json(grades), ctx.delay(150));
      }
      default:
        return res(ctx.status(404));
    }
  }),
];
