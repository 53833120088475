import TextEditor from '@UI/textEditor/TextEditor';
import { Controller } from 'react-hook-form';

const FormTextEditorField = ({
  name,
  value = '',
  disabled = false,
  required = false,
  validations,
  control,
}) => {
  if (name == null)
    throw new Error('[FormTextEditorField]: Please provide a unique form input name.');
  if (control == null) throw new Error('[FormTextEditorField]: UseForm control not provided.');

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={{
        required,
        validate: validations,
      }}
      render={({ field, fieldState: { error } }) => {
        return <TextEditor disabled={disabled} error={error} {...field} />;
      }}
    />
  );
};

export default FormTextEditorField;
