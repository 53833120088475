const Week = {
  id: undefined,
  activityPlans: [],
  holidays: null,
  isFirstWeek: false,
  dateRange: undefined,
  startDate: undefined,
  endDate: undefined,
};

export function createWeek(
  id,
  dateRange,
  isFirstWeek = false,
  holidays = null,
  activityPlans = []
) {
  return {
    ...Week,
    id,
    dateRange,
    isFirstWeek,
    holidays,
    activityPlans,
    startDate: dateRange.start,
    endDate: dateRange.end,
  };
}
