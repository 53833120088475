import './durationArrow.scss';

const STATES = {
  SINGLE: 'SINGLE',
  START: 'START',
  MIDDLE: 'MIDDLE',
  END: 'END',
};

const DurationArrow = ({ state, color }) => {
  let arrow;

  if (state === STATES.SINGLE || state === STATES.END) {
    arrow = (
      <div className={`${state.toLowerCase()} visible`}>
        <div className="body" style={{ backgroundColor: color.background }}></div>
        <div className="arrow-pointer" style={{ borderLeftColor: color.background }}></div>
      </div>
    );
  } else if (state === STATES.START) {
    arrow = <div className="start" style={{ backgroundColor: color.background }}></div>;
  } else if (state === STATES.MIDDLE) {
    arrow = <div className="middle" style={{ backgroundColor: color.background }}></div>;
  }

  return <div className="duration-arrow">{arrow}</div>;
};

export default DurationArrow;
