import ReactGA from 'react-ga4';
import settings from '@config/settings';

export const initAnalytics = () => {
  // Initialize React Ga with your tracking ID
  ReactGA.initialize([{ trackingId: settings.googlAnalytics.token }]);

  ReactGA.gtag('consent', 'update', {
    analytics_storage: 'granted',
  });
};

export const removeAnalytics = () => {
  ReactGA.gtag('consent', 'update', {
    analytics_storage: 'denied',
  });

  // Delete all cookies that start with _ga
  document.cookie.split(';').forEach((cookie) => {
    const cookieName = cookie.trim().split('=')[0];
    if (cookieName.startsWith('_ga')) {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  });

  window.location.reload();
};

if (settings.googlAnalytics.enabled) {
  ReactGA.gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
  });

  const analyticCookie = document.cookie
    .split(';')
    .find((cookie) => cookie.trim().startsWith('cookies__analytic='));

  if (analyticCookie) {
    const cookieValue = analyticCookie.split('=')[1];
    if (cookieValue === 'true') {
      initAnalytics();
    }
  }
}
