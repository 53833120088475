import { useSelector } from 'react-redux';
import { selectIsActivityPlanReadOnly } from '@store/calendar/calendarSelectors';
import ArrowRightGrey from '@assets/img/arrow-right-grey.svg?react';
import ArrowRight from '@assets/img/icon_arrow_right.svg?react';

import './addWeekButton.scss';

const AddWeekButton = ({ onMoveWeek }) => {
  const isSelection = useSelector((state) => state.calendar.selection);
  const readOnly = useSelector(selectIsActivityPlanReadOnly);
  if (readOnly || isSelection) return null;

  return (
    <div className="add-week-button">
      <div className={'add-week-btn'} title="Alle volgende activiteiten 1 week later inplannen">
        <div className="dots top">
          <ArrowRightGrey className="arrow-top" />
          <div className="line"></div>
        </div>
        <div className="plus" onClick={onMoveWeek}>
          <ArrowRight className="arrow-right" />
        </div>
        <div className="dots bottom">
          <div className="line"></div>
          <ArrowRightGrey className="arrow-bottom" />
        </div>
      </div>
    </div>
  );
};

export default AddWeekButton;
