import { Compiler } from '@kathondvla/react-shared-components/src/components';
import { HoveredPopover } from '@UI/index';
import UserIcon from '@assets/img/icon_user_grey.svg?react';
import PeopleIcon from '@assets/img/icon_people_grey.svg?react';
import SchoolIcon from '@assets/img/icon_school_so_grey.svg?react';
import CalendarIcon from '@assets/img/icon_curr_calendar.svg?react';

import './title.scss';

const Title = ({
  title,
  version,
  subtitle,
  curriculumType,
  validityPeriod,
  studyProgram,
  isNonderived,
}) => {
  const titleIcon = () => {
    const tooltip = isNonderived ? 'eigen doelenkader' : 'leerplan';
    switch (curriculumType) {
      case 'TEACHER':
        return (
          <HoveredPopover content={`${tooltip} (persoonlijk)`}>
            <UserIcon />
          </HoveredPopover>
        );
      case 'TEAM':
        return (
          <HoveredPopover content={`${tooltip} (groep)`}>
            <PeopleIcon />
          </HoveredPopover>
        );
      case 'SCHOOL':
        return (
          <HoveredPopover content={`${tooltip} (school)`}>
            <SchoolIcon />
          </HoveredPopover>
        );
      case 'PLAN':
      default:
        return (
          <HoveredPopover content="leerplan">
            <CalendarIcon />
          </HoveredPopover>
        );
    }
  };

  return title ? (
    <div className="composed-title">
      <div className="composed-title-row">
        <span className={`icon${isNonderived ? ' nonderived' : ''}`}>{titleIcon()}</span>

        <div className="title">
          <Compiler text={title} />
          {version && <span className="version">({version})</span>}
        </div>
      </div>

      <div className="composed-title-row">
        {subtitle && (
          <h4 className="subtitle">
            <Compiler text={subtitle} />
          </h4>
        )}
      </div>
      <div className="composed-title-row">
        <h4 className="study-validity">
          {studyProgram ? `${studyProgram} - ${validityPeriod}` : validityPeriod}
        </h4>
      </div>
    </div>
  ) : (
    <div className="composed-title">
      <h1 className="title title--black">Schoolkeuzes</h1>
    </div>
  );
};

export default Title;
