import { interceptFactory } from '@kathondvla/fetch-oauth-interceptor';
import settings from '@config/settings';
import { selectUser } from './userAndSchoolSelectors';

export const install = (store) => {
  console.log('oauth interceptor installed');
  interceptFactory({
    urlPatterns: [
      new RegExp(settings.apisAndUrls.api, 'g'),
      new RegExp(settings.apisAndUrls.activityplansapi.baseUrl, 'g'),
      new RegExp(settings.apisAndUrls.customcurapi.baseUrl, 'g'),
      new RegExp(settings.apisAndUrls.securityapi.baseUrl, 'g'),
    ],
    oauth: settings.oauth,
    ignore: {
      get: [],
      post: [],
    },
    onUserChanged: (newUser) => {
      console.log('OAUTH:', 'user changed', newUser);
      const user = selectUser(store.getState());
      if (user && newUser.uuid !== user.key) {
        window.location.reload();
      }
    },
    onLoggedOut: () => {
      console.log('OAUTH:', 'we appear to be logged out');
      const user = selectUser(store.getState());
      if (user) {
        // locally we're still logged in...
        console.log('OAUTH:', 'we were still logged in, in llinkid');
        window.location.reload();
      }
    },
  });
};
