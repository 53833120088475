import AlertMessage from '@UI/alertMessage/AlertMessage';
import { selectAlertKeys } from '@store/alerts/alertSelectors';

import { useSelector } from 'react-redux';

import './alertList.scss';

const AlertList = () => {
  const alerts = useSelector(selectAlertKeys);

  return (
    <div className="alert-list">
      {alerts.map((key) => {
        return <AlertMessage key={key} alertKey={key} />;
      })}
    </div>
  );
};

export default AlertList;
