/**
 * about awaitableState.js and stateMatch
 * in LLINKID we are using redux-awaitable-state to wait for the state to contain the data we need
 * this is done by using the stateMatch function
 *
 * there is however one problem. the redux-awaitable-state always looks at the latest state.
 * during testing, we are initializing the store/state many times, and the awaitable state will always look at the latest state.
 * to not run into issues whilst awaiting something on a new instance of the state, i've implemented a version of stateMatch that takes a store.
 */

let subscribers = [];

export function futureStateMatch(evalFn, store) {
  let resolve;
  const p = new Promise((res) => {
    resolve = res;
  });

  const unsub = store.subscribe(() => {
    const currState = store.getState();
    if (evalFn(currState)) {
      resolve();
      unsub();
    }
  });
  subscribers.push(unsub);
  return p;
}

export function stateMatch(evalFn, store) {
  const currState = store.getState();
  if (evalFn(currState)) {
    return Promise.resolve();
  }
  return futureStateMatch(evalFn, store);
}

export function resetSubscribers() {
  subscribers.forEach((e) => e());
  subscribers = [];
}

resetSubscribers();
