import Footer from '@components/layout/footer/Footer';
import HeaderWrapper from '@components/layout/header/headerWrapper';

import { Outlet, useLoaderData } from 'react-router-dom';

import { CurriculumParams } from '@store/curriculum/CurriculumParams';
import './curriculum.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@store/storeSetup';
import LeerplanHeader from './section/header/LeerplanHeader';
import CurriculumTabs from './tabs/CurriculumTabs';

const Curriculum: React.FC = () => {
  const data = useLoaderData() as { params?: CurriculumParams };
  const isSelectionMode = useSelector((state: RootState) => state.curriculum.selectionMode);

  return (
    <>
      {!isSelectionMode && (
        <div id="fixed-header" className="fixed-header">
          <HeaderWrapper></HeaderWrapper>
        </div>
      )}

      {'preview' in data && data.preview && <div className="preview-example">VOORBEELD</div>}

      <LeerplanHeader />

      {!isSelectionMode && <CurriculumTabs />}

      <div className="curriculum-container">
        <Outlet context={{ params: data.params }} />
      </div>

      <Footer />
    </>
  );
};

export default Curriculum;
