import { selectCurriculumTabs } from '@store/curriculum/curriculumSelectors';
import { useSelector } from 'react-redux';

import './curriculumTabs.scss';

import { NavLink, useLocation } from 'react-router-dom';
import CurriculumTabsSkeleton from './curriculumTabsSkeleton/CurriculumTabsSkeleton';

const CurriculumTabs = () => {
  const tabs = useSelector((state) => selectCurriculumTabs(state));
  const location = useLocation();

  return (
    <div className="curriculum-tabs">
      {tabs ? (
        tabs?.map((tab) => (
          <NavLink
            to={{ pathname: tab.route, search: location.search }}
            key={tab.key}
            className={`curriculum-tab`}
          >
            <span>{tab.label}</span>
          </NavLink>
        ))
      ) : (
        <CurriculumTabsSkeleton />
      )}
    </div>
  );
};

export default CurriculumTabs;
