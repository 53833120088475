import './curriculaTypeIcon.scss';
import iconCurrCalendar from '@assets/img/icon_curr_calendar.svg';
import iconUserGrey from '@assets/img/icon_user_grey.svg';
import iconPeopleGrey from '@assets/img/icon_people_grey.svg';
import iconSchoolSoGrey from '@assets/img/icon_school_so_grey.svg';

const CurriculaTypeIcon = ({ nonderived, type, onClick }) => {
  return (
    <figure
      className={`curricula-type-icon ${nonderived && type !== 'CHILD' ? 'nonderived' : ''}`}
      onClick={onClick}
    >
      {type === 'TEACHER' && (
        <img
          src={iconUserGrey}
          title={nonderived ? 'eigen doelenkader (persoonlijk)' : 'leerplan (persoonlijk)'}
        />
      )}
      {type === 'TEAM' && (
        <img
          src={iconPeopleGrey}
          title={nonderived ? 'eigen doelenkader (groep)' : 'leerplan (groep)'}
        />
      )}
      {type === 'SCHOOL' && (
        <img
          src={iconSchoolSoGrey}
          title={nonderived ? 'eigen doelenkader (school)' : 'leerplan (school)'}
        />
      )}
      {type === 'PLAN' && <img src={iconCurrCalendar} title="leerplan" />}
      {type === 'CHILD' && (
        <div className="divider-placeholder">
          <div className="divider"></div>
        </div>
      )}
    </figure>
  );
};

export default CurriculaTypeIcon;
