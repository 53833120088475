import kovlLogo from '@assets/img/logo_ko_vl.png';
import {
  KovFooter,
  KovFooterLeftSection,
  KovFooterRightSection,
} from '@kathondvla/react-shared-components/src';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Tooltip } from '@mui/material';
import { selectShouldShowWhatIsNewModal } from '@store/userAndSchool/userAndSchoolSelectors';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import ResetDemoModal from '@UI/resetDemoModal/ResetDemoModal';
import WhatIsNewModal from '@UI/whatIsNewModal/WhatIsNewModal';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import settings from '@config/settings';

const footerData = {
  href: 'https://pro.katholiekonderwijs.vlaanderen/',
  imageSrc: kovlLogo,
};

const Footer = () => {
  const shouldShowWhatIsNewModal = useSelector(selectShouldShowWhatIsNewModal);

  const whatIsNewModalRef = useRef();
  const resetDemoModalRef = useRef();

  const showWhatIsNewModal = async () => {
    whatIsNewModalRef.current?.open({});
  };

  useEffect(() => {
    if (shouldShowWhatIsNewModal) {
      showWhatIsNewModal();
    }
  }, [shouldShowWhatIsNewModal]);

  const helpSectionLink = {
    href: 'https://pro.katholiekonderwijs.vlaanderen/ondersteuning-leerplantool',
    text: 'Ondersteuning',
  };

  const onClickResetDemoHandler = () => {
    resetDemoModalRef.current?.open({});
  };

  const openCookiesModal = () => {
    document.dispatchEvent(
      new CustomEvent('openKovCookieModal', { bubbles: true, composed: true })
    );
  };

  return (
    <>
      <KovFooter>
        <KovFooterLeftSection logoSection={footerData} />
        <div className="footerbar-text">Voor u ontwikkeld door KATHOLIEK ONDERWIJS VLAANDEREN</div>
        <KovFooterRightSection helpSectionLink={helpSectionLink}>
          {settings.enableDemoMode && (
            <Button
              data-testid="reset-demo-button"
              variant="contained"
              onClick={onClickResetDemoHandler}
              sx={{ margin: '0 1rem' }}
            >
              <Tooltip title="Hierbij verwijder je alle door jou ingebrachte gegevens, en start je met een schone lei">
                <HelpOutlineIcon sx={{ marginRight: '.5rem' }} fontSize="small" />
              </Tooltip>
              <span>Reset mijn demo-omgeving</span>
            </Button>
          )}
          {settings.whatIsNewModal.showFooterWhatIsNewLink && (
            <div className="extra">
              <span className="separator"> | </span>
              <a data-testid="what-is-new-link" className="help-text" onClick={showWhatIsNewModal}>
                Wat is nieuw?
              </a>
            </div>
          )}
          <div className="extra">
            <span className="separator"> | </span>
            <a data-testid="cookie-modal-link" className="help-text" onClick={openCookiesModal}>
              Cookie-instellingen
            </a>
          </div>
        </KovFooterRightSection>
      </KovFooter>
      <Modal
        ref={resetDemoModalRef}
        size="sm"
        component={<ResetDemoModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={whatIsNewModalRef}
        size="md"
        component={<WhatIsNewModal title="Wat is nieuw?" />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </>
  );
};

export default Footer;
