export default function changeCreatorsForPlan(creators, mergedMap) {
  if (!creators) {
    return [];
  }
  const creatorSet = new Set(creators.map((e) => e.href));
  [...creatorSet].forEach((c) => {
    if (mergedMap.get(c)) {
      // for every user that's in the mergedmap, we replace the href
      creatorSet.add(mergedMap.get(c));
      creatorSet.delete(c);
    }
  });

  return [...creatorSet].map((e) => {
    return { href: e };
  });
}
