import { createSelector } from '@reduxjs/toolkit';
import {
  selectCreatorFromCustomCurricula,
  selectDoCustomCurriculumStudyProgrammesMatchSchoolStudyProgrammes,
  selectIsBaseCurriculum,
} from '@store/curriculum/curriculumSelectors';
import {
  selectIsCurriculaDistributable,
  selectIsDistributionMode,
} from '@store/distribute/distributeSelectors';
import { selectSchoolVMs, selectUser } from '@store/userAndSchool/userAndSchoolSelectors';
import settings from '@config/settings';
import { creatorType } from '../../../constants/creatorType';

export const selectSchoolsWithMissingOption = createSelector(selectSchoolVMs, (schools) => {
  return [...schools, { missing: true, $$displayName: 'Ontbreekt er een schoolteam?' }];
});

export const selectUserProfileImageUrl = createSelector(selectUser, (user) => {
  return `${settings.apisAndUrls.api}${
    user?.$$attachments?.find(
      (att) => att.$$expanded.type === 'PROFILE_IMAGE' && att.$$expanded.width === 128
    )?.$$expanded.href
  }`;
});

export const selectShouldRenderHideOptionalElements = createSelector(
  [selectIsDistributionMode, selectIsBaseCurriculum],
  (isDistributionMode, isBaseCurriculum) => {
    return !isDistributionMode && !isBaseCurriculum;
  }
);

export const selectShouldRenderDistributionMode = (state) => {
  const currentCurriculumCreator = selectCreatorFromCustomCurricula(state);
  const isDistributable = selectIsCurriculaDistributable(state);
  const doCurriculumStudyProgrammesMatchSchool =
    selectDoCustomCurriculumStudyProgrammesMatchSchoolStudyProgrammes(state);

  return (
    isDistributable &&
    currentCurriculumCreator?.creatorType !== creatorType.teacher &&
    doCurriculumStudyProgrammesMatchSchool
  );
};
