import { rest } from 'msw';
import apis from '@config/apis';
import { fetchJsonPath } from '../../helpers/fetchJson';

const fetchJson = fetchJsonPath('api/content');
const fetchJsonForLlinkidGoal = fetchJsonPath('api/content/llinkidGoal');

const contentPath = 'content__type=llinkid_curriculum&expand=summary.json'; // https://api.katholiekonderwijs.vlaanderen/content/?type=LLINKID_CURRICULUM&limit=5000&expand=summary
const contentGoalListsPath = 'content__type=llinkid_goal_list.json'; // https://api.katholiekonderwijs.vlaanderen/content?type=LLINKID_GOAL_LIST&limit=5000
const relationsPath =
  'relations__contentType=LLINKID_CURRICULUM&limit=50000&relationtype=REPLACES.json'; // https://api.katholiekonderwijs.vlaanderen/content/relations?contentType=LLINKID_CURRICULUM&limit=50000&relationtype=REPLACES
const curAardrijkskunde1Av2Path = 'llinkidCurricula_Aardrijkskunde - I A_v2.json';
const curBakkerijS2Av2Path = 'llinkidCurricula_Bakkerij S - II - A_v2.json';
const llinkidEducationalComponentsPath = 'LLINKID educational components.json';
const llinkidEducationalPointersPath = 'LLINKID educational pointers.json';
const llinkidIntroductionPath = 'LLINKID Introduction_1st_grade.json'; // https://api.katholiekonderwijs.vlaanderen/content?root=f6370bbd-6a0a-41e8-9b9b-c342e1cd0c45&limit=5000
const gemeenschappelijkFundLeerplIIV3Path =
  'llinkidCurricula_Gemeenschappelijk funderend leerplan - II - DDAA_v3.json';
const gemeenschappelijkFundLeerplIv2Path =
  'llinkidCurricula_Gemeenschappelijk_funderend_leerplan - I - AB_v2.json';

export const contentApiListRequestsWithDelay = (ms) => {
  return rest.get(`${apis.cachedApi.baseUrl}/content`, async (req, res, ctx) => {
    if (req.url.searchParams.get('type')) {
      if (
        req.url.searchParams.get('type').toLowerCase() === 'llinkid_curriculum' &&
        req.url.searchParams.get('expand').toLowerCase() === 'summary'
      ) {
        const content = await fetchJson(ctx, contentPath);
        return res(ctx.json(content), ctx.delay(ms));
      }

      if (req.url.searchParams.get('type').toLowerCase() === 'llinkid_goal_list') {
        const contentGoalLists = await fetchJson(ctx, contentGoalListsPath);
        return res(ctx.json(contentGoalLists), ctx.delay(ms));
      }
    }
    if (req.url.searchParams.get('root')) {
      if (
        req.url.searchParams.get('root').toLowerCase() === '8669afe3-aa83-437b-b624-742e93e8de5a'
      ) {
        const llinkidEducationalComponents = await fetchJson(ctx, llinkidEducationalComponentsPath);
        return res(ctx.json(llinkidEducationalComponents), ctx.delay(ms));
      }
      if (
        req.url.searchParams.get('root').toLowerCase() === '8f39910a-5db7-4306-9443-74a318aba0da'
      ) {
        const llinkidEducationalPointers = await fetchJson(ctx, llinkidEducationalPointersPath);
        return res(ctx.json(llinkidEducationalPointers), ctx.delay(ms));
      }
      if (
        req.url.searchParams.get('root').toLowerCase() === 'f6370bbd-6a0a-41e8-9b9b-c342e1cd0c45' ||
        req.url.searchParams.get('root').toLowerCase() === 'a9beef4f-3bd4-4b73-87f8-d7c83c149fb5' ||
        req.url.searchParams.get('root').toLowerCase() === '7a7d03c1-da3d-450f-97a6-10ed4170c124'
      ) {
        // mocked the introductions for the 3 grades as the same.
        const llinkidIntroduction = await fetchJson(ctx, llinkidIntroductionPath);
        return res(ctx.json(llinkidIntroduction), ctx.delay(ms));
      }
    }

    return req.passthrough();
  });
};

export const contentApiHandlers = [
  contentApiListRequestsWithDelay(150),
  rest.get(`${apis.cachedApi.baseUrl}/content/:key/llinkidCurricula`, async (req, res, ctx) => {
    const { key } = req.params;
    if (key === '3d01433b-4e58-49a4-92e2-f0fd88349692') {
      const curBakkerijS2Av2 = await fetchJson(ctx, curBakkerijS2Av2Path);
      return res(ctx.json(curBakkerijS2Av2), ctx.delay(50));
    }
    if (key === 'b6f20605-d5e5-408b-aab5-0a4b60851dc7') {
      const curAardrijkskunde1Av2 = await fetchJson(ctx, curAardrijkskunde1Av2Path);
      return res(ctx.json(curAardrijkskunde1Av2), ctx.delay(50));
    }
    if (key === 'ae15741f-65d3-468a-ac7e-4a3e6496d998') {
      const gemeenschappelijkFundLeerplIv2 = await fetchJson(
        ctx,
        gemeenschappelijkFundLeerplIv2Path
      );
      return res(ctx.json(gemeenschappelijkFundLeerplIv2), ctx.delay(50));
    }
    if (key === 'f9db3eef-7293-45c2-9f69-339044638e1a7') {
      const gemeenschappelijkFundLeerplIIV3 = await fetchJson(
        ctx,
        gemeenschappelijkFundLeerplIIV3Path
      );
      return res(ctx.json(gemeenschappelijkFundLeerplIIV3), ctx.delay(50));
    }
    return req.passthrough();
  }),
  rest.get(`${apis.cachedApi.baseUrl}/content/:key/llinkidGoal`, async (req, res, ctx) => {
    const { key } = req.params;
    try {
      const goalJson = await fetchJsonForLlinkidGoal(ctx, `${key}.json`);
      return res(ctx.json(goalJson), ctx.delay(50));
    } catch (e) {
      console.log(e);
    }

    return req.passthrough();
  }),
  rest.get(`${apis.cachedApi.baseUrl}/content/:key/llinkidCurricula/hash`, (req, res, ctx) => {
    return res(ctx.json({ hash: 'mockedhashstring' }), ctx.delay(50));
  }),
  rest.get(`${apis.cachedApi.baseUrl}/content/:key/llinkidGoal/hash`, (req, res, ctx) => {
    return res(ctx.json({ hash: 'mockedhashstring' }), ctx.delay(50));
  }),
  rest.get(`${apis.cachedApi.baseUrl}/content/:key/hash`, (req, res, ctx) => {
    return res(ctx.json({ hash: 'mockedhashstring' }), ctx.delay(50));
  }),
  rest.get(`${apis.cachedApi.baseUrl}/content/relations`, async (req, res, ctx) => {
    if (
      req.url.searchParams.get('contentType').toLowerCase() === 'llinkid_curriculum' &&
      req.url.searchParams.get('limit').toLowerCase() === '50000' &&
      req.url.searchParams.get('relationtype').toLowerCase() === 'replaces'
    ) {
      const relations = await fetchJson(ctx, relationsPath);
      return res(ctx.json(relations), ctx.delay(150));
    }

    return req.passthrough();
  }),
];
