import '@assets/css/main.scss';
import BigLoader from '@components/BigLoader';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';
import store from '@store/index';
import { HomeErrorBoundary } from '@UI/index';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import theme from '@assets/css/theme';
import './analytics';
import { routerWithStore } from './router'; // needs to be first to make sure te store is initialized before the router is created
import { install } from './store/userAndSchool/oauthInterceptor';

install(store);

const router = routerWithStore(store);

globalThis.router = router; // this is a hack so that the sagas can also use router.navigate with the correct store.

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <HomeErrorBoundary>
            <RouterProvider router={router} fallbackElement={<BigLoader />} />
          </HomeErrorBoundary>
        </DndProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
