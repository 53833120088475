import { put, takeLatest } from 'redux-saga/effects';
import { customCurApi } from '@store/apihelpers';
import { saveLeidraadChoices } from '@store/userAndSchool/usersAndSchoolState';
import { setAlert } from '@store/alerts/alertsState';
import { generalSaveError } from '@store/alerts/alertConstants';
import { logAndCaptureException } from '@utils/logAndCaptureException';

function* saveLeidraad({ payload }) {
  try {
    const { leidraadChoices } = payload;
    const batch = leidraadChoices.map((e) => ({ href: e.$$meta.permalink, verb: 'PUT', body: e }));
    yield customCurApi.post('/llinkid/qualityinstrument/choices/batch', batch);
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalSaveError));
  }
}

export function* watchInitDataSaga() {
  yield takeLatest(saveLeidraadChoices, saveLeidraad);
}
