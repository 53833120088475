import { PayloadAction } from '@reduxjs/toolkit';
import { PendingBatchesStatus } from '../../constants/status';
import {
  ApiActivity,
  ApiActivityPlan,
  ApiAnnotation,
  ApiCustomCurriculaGroup,
  ApiCustomCurriculum,
  ApiCustomItem,
} from '../../types/llinkidApiTypes';
import { Schoolyear } from '../../types/schoolyear';
import { BatchRequest, BatchResult } from '../../types/sriTypes';

export type LlinkidApiStateType =
  | 'customCurricula'
  | 'annotations'
  | 'customItems'
  | 'customCurriculaGroups'
  | 'activityPlans'
  | 'activities';

export type LlinkidAPiCacheStatePerYear = {
  modifiedSince: string | null;
  lastFullRefresh: string | null;
  isUninitialized: boolean; // Query has not started yet.
  isLoading: boolean; // Query is currently loading for the first time. No data yet.
  isFetching: boolean; // Query is currently fetching, but might have data from an earlier request.
  isSuccess: boolean; // Query has data from a successful load.
  isError: boolean; // Query is currently in an "error" state.
};

type LlinkidApiCacheState = Record<Schoolyear['value'], LlinkidAPiCacheStatePerYear>;

type LlinkidApiBatchState = {
  isStarted: boolean;
  isFailed: boolean;
  isCompleted: boolean;
  response?: BatchResult;
};

export type ApiCallFunction = (
  cacheSettings: LlinkidAPiCacheStatePerYear,
  schoolhref: string,
  schoolyear: Schoolyear,
  lastActiveDate: string | null
) => Promise<any>;

export type FileUploadMeta = {
  file: string;
  attachment: {
    key: string;
    href: string;
  };
  resource: {
    href: string;
  };
};

export type FileBatch = {
  files: File[];
  body: Array<FileUploadMeta>;
};

export type PendingBatchItem =
  | {
      batchKey: string;
      batch: BatchRequest;
      fileBatch?: never;
    }
  | {
      batchKey: string;
      batch?: never;
      fileBatch: FileBatch;
    };

type LlinkidApiStateBase = {
  version?: 'v2';
  customCurricula: {
    [key: string]: ApiCustomCurriculum;
  };
  annotations: {
    [key: string]: ApiAnnotation;
  };
  customItems: {
    [key: string]: ApiCustomItem;
  };
  customCurriculaGroups: {
    [key: string]: ApiCustomCurriculaGroup;
  };
  activityPlans: {
    [key: string]: ApiActivityPlan;
  };
  activities: {
    [key: string]: ApiActivity;
  };
  batches: {
    [key: string]: LlinkidApiBatchState;
  };
  pendingBatches: Array<PendingBatchItem>;
  pendingBatchesStatus: { status: PendingBatchesStatus['status']; delay?: number } | null;
  hasStartedInitializing: boolean;
  autoSyncEnabled: boolean;
  settings: Record<LlinkidApiStateType, LlinkidApiCacheState>;
};

export type LlinkidApiState = Readonly<LlinkidApiStateBase>;

type OldMiniSettings = {
  modifiedSincePerSchoolyear?: {
    [key: Schoolyear['value']]: string;
  };
  lastFullRefreshPerYear?: {
    [key: Schoolyear['value']]: string;
  };
};

export type LlinkidApiStateDataPart = Pick<LlinkidApiStateBase, LlinkidApiStateType>;

export type OldSavedLlinkidApiState = LlinkidApiStateDataPart & {
  settings: {
    customCurricula: OldMiniSettings;
    annotations: OldMiniSettings;
    customItems: OldMiniSettings;
    customCurriculaGroups: OldMiniSettings;
    activityPlans: OldMiniSettings;
    activities: OldMiniSettings;
  };
};

type MiniSettings = Record<string, { modifiedSince: string }>;

export type SavedLlinkidApiState = LlinkidApiStateDataPart & {
  settings: {
    customCurricula: MiniSettings;
    annotations: MiniSettings;
    customItems: MiniSettings;
    customCurriculaGroups: MiniSettings;
    activityPlans: MiniSettings;
    activities: MiniSettings;
  };
};

export function isSavedLlinkidApiState(state: any): state is SavedLlinkidApiState {
  return state && state.version === 'v2';
}

export function isOldSavedLlinkidApiState(state: any): state is OldSavedLlinkidApiState {
  return state && state.version === undefined;
}

export type SaveLlinkidApiBatchAction = PayloadAction<{
  batch: BatchRequest;
  batchKey?: string;
  applyInstantly: boolean;
}>;

export type AddPendingBatchAction = PayloadAction<PendingBatchItem>;

export type DeletePendingBatchAction = PayloadAction<{
  batchKey: string;
}>;

export type SetPendingBatchesStatusAction = PayloadAction<{
  status: PendingBatchesStatus['status'];
  delay?: number;
} | null>;

export type InitPayloadAction = PayloadAction<{
  schoolHref: string;
  schoolyear: string;
  force?: boolean;
}>;

export type ReferenceGoalPayloadAction = PayloadAction<{ goals: any[]; parent: any }>;
export type AddCustomGoalPayloadAction = PayloadAction<{
  parentItem: any;
  customItem: { identifier: string; description: string; mandatory: boolean };
}>;

export type AddCustomSectionPayloadAction = PayloadAction<{
  parent: any;
  type: string;
}>;
