const apisAndUrls = {
  cachedApi: {
    baseUrl: "https://cached-api.katholiekonderwijs.vlaanderen",
  },
  api: "https://api.katholiekonderwijs.vlaanderen",
  customcurapi: {
    baseUrl: "https://customcurricula-api.katholiekonderwijs.vlaanderen",
    groupBy: 70,
  },
  activityplansapi: {
    baseUrl: "https://activityplans-api.katholiekonderwijs.vlaanderen",
  },
  securityapi: {
    baseUrl: "https://beveiliging-nodejs.katholiekonderwijs.vlaanderen",
  },
  myVsko: "http://mijn.katholiekonderwijs.vlaanderen",
};

export default apisAndUrls;
