import { pick, isEqual } from 'lodash-es';
import {
  selectCurrentSchoolyear,
  selectCurrentSchoolyearKey,
  selectSchoolyearsForSchool,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { CUSTOMTYPES } from '@utils/curriculumHelper';
import { transformCustomGoalToGoalFormat } from '@store/curriculum/curriculumAndAnnotationHelper';
import { filterBySchoolyear, filterCustomCurriculaWithFilters } from '@utils/filters';
import { arrayToObjectMap, createTypedSelector } from '@store/genericHelpers';
import { RootState } from '@store/storeSetup';
import settings from '@config/settings';
import {
  LlinkidAPiCacheStatePerYear,
  LlinkidApiStateType,
  SavedLlinkidApiState,
} from './llinkidApiTypes';

const selectLastFullRefreshForPerTypeAndSchoolyear = (
  state: RootState,
  type: LlinkidApiStateType,
  schoolyear: string
) => {
  return state.customCurriculaData.settings[type][schoolyear].lastFullRefresh;
};

/**
 * This selector will return true if the last refresh for the selected year and type was done recently enough to not show the "spinner" on the screen while navigating between years
 * @param {T} state
 * @returns
 */
export const selectIsDataFreshNoSpinner = (
  state: RootState,
  types: LlinkidApiStateType[],
  schoolyear: string
) => {
  const validityInSeconds = settings.noSpinnerDataValidityInSeconds;

  const lastRefreshValidDate = new Date(new Date().getTime() - validityInSeconds * 1000);

  return types.every((type) => {
    const lastFullRefreshPerYear = selectLastFullRefreshForPerTypeAndSchoolyear(
      state,
      type,
      schoolyear
    );
    if (!lastFullRefreshPerYear) {
      return false;
    }
    return new Date(lastFullRefreshPerYear) > lastRefreshValidDate;
  });
};

/**
 * This selector will return true if the last refresh for the selected year and type was done within the delta sync time.
 * In that case we will not make API calls to update our local state, since the data is still fresh.
 * @param {*} state
 * @param {*} types
 * @param {*} schoolyear
 * @returns
 */
export const selectIsLastRefreshValidPerTypeAndSchoolyear = (
  state: RootState,
  types: LlinkidApiStateType[],
  schoolyear: string
) => {
  const schoolyears = selectSchoolyearsForSchool(state);
  const isSchoolyearExpired =
    schoolyears.find((schlyear) => schlyear.value === schoolyear)?.expired ?? true;
  const validityInSeconds = isSchoolyearExpired
    ? settings.cacheTimeout.updateCacheExpiredSchoolyear
    : settings.cacheTimeout.updateCacheEvery;

  const lastRefreshValidDate = new Date(new Date().getTime() - validityInSeconds * 1000);

  return types.every((type) => {
    const lastFullRefreshPerYear = selectLastFullRefreshForPerTypeAndSchoolyear(
      state,
      type,
      schoolyear
    );
    if (!lastFullRefreshPerYear) {
      return false;
    }
    return new Date(lastFullRefreshPerYear) > lastRefreshValidDate;
  });
};

/**
 * used for the routes to make sure all data is present.
 */
export const selectIsDataLoadedForTypes = (state: RootState, types: LlinkidApiStateType[]) => {
  const { hasStartedInitializing } = state.customCurriculaData;
  const { settings: apiSettings } = state.customCurriculaData;
  const schoolyear = selectCurrentSchoolyearKey(state);

  if (!hasStartedInitializing) {
    return false;
  }

  return types.every((type) => {
    return (
      apiSettings[type][schoolyear].isLoading === false &&
      apiSettings[type][schoolyear].isSuccess === true
    );
  });
};

export const selectCustomCurriculaData = createTypedSelector(
  [(state) => state.customCurriculaData.customCurricula],
  (customCurricula) => {
    return Object.values(customCurricula);
  }
);

export const selectCustomCurriculaForCurrentSchoolyear = createTypedSelector(
  [(state) => state.customCurriculaData.customCurricula, (state) => selectCurrentSchoolyear(state)],
  (customCurriculaMap, currentSchoolyear) => {
    return arrayToObjectMap(
      filterCustomCurriculaWithFilters(Object.values(customCurriculaMap), [
        filterBySchoolyear(currentSchoolyear),
      ])
    );
  }
);

export const selectCustomCurriculaIdentifiers = createTypedSelector(
  [(state) => state.customCurriculaData.customCurricula],
  (customCurricula) => {
    return new Set(
      Object.values(customCurricula)
        .map((curr) => curr.identifier)
        .filter(Boolean)
    );
  }
);

export const selectActivityPlansData = createTypedSelector(
  [(state) => state.customCurriculaData.activityPlans],
  (activityPlans) => {
    return Object.values(activityPlans);
  }
);

export const selectActivityPlansFilteredByKeys = (state: RootState, params) => {
  const { activityPlans } = state.customCurriculaData;
  return Object.values(activityPlans).filter((p) => params.keys.includes(p.key));
};

export const selectActivitiesData = createTypedSelector(
  [(state) => state.customCurriculaData.activities],
  (activities) => {
    return Object.values(activities);
  }
);

export const selectCustomItemsInFormat = createTypedSelector(
  [(state) => state.customCurriculaData.customItems],
  (customItems) => {
    const itemsInFormat = {};

    Object.values(customItems).forEach((item) => {
      if (item.type === CUSTOMTYPES.goal) {
        itemsInFormat[item.key] = transformCustomGoalToGoalFormat(item);
      } else {
        itemsInFormat[item.key] = {
          ...item,
          href: item.$$meta.permalink,
        };
      }
    });

    return itemsInFormat;
  }
);

export const selectLlinkidStateForFile = createTypedSelector(
  [(state) => state.customCurriculaData],
  (customCurriculaData): SavedLlinkidApiState => {
    const data = {
      ...pick(customCurriculaData, [
        'version',
        'customCurricula',
        'annotations',
        'customCurriculaGroups',
        'customItems',
        'activityPlans',
        'activities',
      ]),
    };
    const saveSettings: SavedLlinkidApiState['settings'] = {
      customCurricula: {},
      annotations: {},
      customItems: {},
      customCurriculaGroups: {},
      activityPlans: {},
      activities: {},
    };
    // for each of the schoolyears in each of the settings, pick those fields to persist.
    Object.entries(customCurriculaData.settings).forEach(([key, setting]) => {
      Object.entries(setting).forEach(([schoolyear, value]) => {
        saveSettings[key][schoolyear] = pick(value, [
          'lastFullRefresh',
          'modifiedSince',
          'isUninitialized',
          // 'isSuccess',
          // 'isError',
        ]);
      });
    });

    return { ...data, settings: saveSettings };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectIsLlinkidInitializing = createTypedSelector(
  [
    (state) => state.customCurriculaData.settings,
    (state) => state.userAndSchools.currentSchoolyear,
  ],
  (apiSettings, schoolyear) => {
    return Object.values(apiSettings).some((setting) => setting[schoolyear].isLoading === true);
  }
);

export const selectIsLlinkidInitialized = createTypedSelector(
  [
    (state) => state.customCurriculaData.settings,
    (state) => state.customCurriculaData.hasStartedInitializing,
    (state) => state.userAndSchools.currentSchoolyear,
  ],
  (apiSettings, hasStartedInitializing, schoolyear) => {
    if (hasStartedInitializing) {
      return Object.keys(apiSettings).every(
        (key) =>
          apiSettings[key as LlinkidApiStateType][schoolyear].isLoading === false &&
          apiSettings[key as LlinkidApiStateType][schoolyear].isSuccess === true
      );
    }
    return false;
  }
);

export const selectSettingsForCurrentSchoolyear = createTypedSelector(
  [(state) => state.customCurriculaData.settings, (state) => selectCurrentSchoolyearKey(state)],
  (apiSettings, schoolyear): Record<LlinkidApiStateType, LlinkidAPiCacheStatePerYear> => {
    const settingsForSchoolyear = Object.keys(apiSettings).reduce((acc, setting) => {
      acc[setting as LlinkidApiStateType] = apiSettings[setting as LlinkidApiStateType][schoolyear];
      return acc;
    }, {} as Record<LlinkidApiStateType, LlinkidAPiCacheStatePerYear>);
    return settingsForSchoolyear;
  }
);

export const selectHaveActivitiesBeenLoadedRecently = (state: RootState) => {
  const { currentSchoolyear } = state.userAndSchools;
  const { isError } = selectSettingsForCurrentSchoolyear(state).activities;
  return (
    Boolean(selectLastFullRefreshForPerTypeAndSchoolyear(state, 'activities', currentSchoolyear)) &&
    isError === false
  );
};

export const selectLlinkidBatchByKey = (state: RootState, params) => {
  const { batches } = state.customCurriculaData;
  return batches[params.batchKey];
};

export const selectPendingBatches = (state: RootState) => state.customCurriculaData.pendingBatches;

export const selectPendingBatchesStatus = (state: RootState) =>
  state.customCurriculaData.pendingBatchesStatus?.status || null;
