import { Component } from 'react';
import logoLlinkid from '@assets/img/llinkid-logo.svg';
import './errorBoundaries.scss';

class HomeErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (!this.state.hasError) return this.props.children;

    return (
      <div className="home-error-boundary">
        <img src={logoLlinkid} alt="llinkid" />
        <p>Oeps, er is een fout opgetreden!</p>
      </div>
    );
  }
}

export default HomeErrorBoundary;
