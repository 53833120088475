import { Modal } from '@kathondvla/react-shared-components/src/components';
import {
  KovContentBlock,
  KovContentBlockProvider,
} from '@kathondvla/react-shared-components/src/components/contentBlock';
import { Button } from '@mui/material';
import settings from '@config/settings';

import './TermModal.scss';

const TermModal = ({ term, onClose }) => {
  return (
    <>
      <Modal.Header title={term.title} onClose={onClose} />

      <Modal.Body>
        <div
          className="term-description"
          dangerouslySetInnerHTML={{ __html: term.description }}
        ></div>
        <KovContentBlockProvider baseUrl={settings.apisAndUrls.api}>
          <KovContentBlock
            className="term-children"
            item={term}
            itemList={term.children}
            showCollapsed={false}
            displayRootItem={false}
          />
        </KovContentBlockProvider>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="contained" onClick={onClose}>
          OK
        </Button>
      </Modal.Footer>
    </>
  );
};
export default TermModal;
