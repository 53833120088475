import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  isActivity,
  isActivityPlan,
  isAnnotation,
  isCustomCurricula,
  isCustomcurriculagroup,
  isCustomItem,
} from '@store/apihelpers';
import { CUSTOMTYPES } from '@utils/curriculumHelper';
import { getMaxModifiedSince } from '@utils/sharedutils';
import { getSchoolyears, strip$$Properties } from '@utils/utils';
import { getKeyFromHref } from '@utils/getKeyFromHref';
import { merge } from 'lodash-es';
import { BatchRequest, BatchResult } from '../../types/sriTypes';
import {
  AddCustomGoalPayloadAction,
  AddCustomSectionPayloadAction,
  AddPendingBatchAction,
  DeletePendingBatchAction,
  InitPayloadAction,
  isOldSavedLlinkidApiState,
  isSavedLlinkidApiState,
  LlinkidAPiCacheStatePerYear,
  LlinkidApiState,
  LlinkidApiStateType,
  OldSavedLlinkidApiState,
  ReferenceGoalPayloadAction,
  SavedLlinkidApiState,
  SaveLlinkidApiBatchAction,
  SetPendingBatchesStatusAction,
} from './llinkidApiTypes';

const initCacheSettingYear = (): LlinkidAPiCacheStatePerYear => {
  return {
    modifiedSince: null,
    lastFullRefresh: null,
    isUninitialized: true, // Query has not started yet.
    isLoading: false, // Query is currently loading for the first time. No data yet.
    isFetching: false, // Query is currently fetching, but might have data from an earlier request.
    isSuccess: false, // Query has data from a successful load.
    isError: false, // Query is currently in an "error" state.
  };
};

const initCacheSettingsForYears = (
  schoolyears: string[]
): Record<string, LlinkidAPiCacheStatePerYear> => {
  const settings: Record<string, LlinkidAPiCacheStatePerYear> = {};
  schoolyears.forEach((schoolyear) => {
    settings[schoolyear] = initCacheSettingYear();
  });
  return settings;
};

function initCacheStorage(): LlinkidApiState {
  const schoolyears = getSchoolyears()
    .filter((z) => z.initialized)
    .map((z) => z.key);

  return {
    version: 'v2',
    customCurricula: {},
    annotations: {},
    customCurriculaGroups: {},
    customItems: {},
    activityPlans: {},
    activities: {},
    batches: {},
    pendingBatches: [],
    pendingBatchesStatus: null,
    hasStartedInitializing: false,
    autoSyncEnabled: true,
    settings: {
      customCurricula: initCacheSettingsForYears(schoolyears),
      annotations: initCacheSettingsForYears(schoolyears),
      customItems: initCacheSettingsForYears(schoolyears),
      customCurriculaGroups: initCacheSettingsForYears(schoolyears),
      activityPlans: initCacheSettingsForYears(schoolyears),
      activities: initCacheSettingsForYears(schoolyears),
    },
  };
}

const initialState = initCacheStorage();

const initApiPartSetting = (
  state: LlinkidApiState,
  type: LlinkidApiStateType,
  schoolyear: string
) => {
  if (!state.settings[type][schoolyear]) {
    // if the schoolyear is unitilialized.
    state.settings[type][schoolyear] = initCacheSettingYear();
  }
  if (state.settings[type][schoolyear].isUninitialized) {
    state.settings[type][schoolyear].isLoading = true;
  }
  state.settings[type][schoolyear].isFetching = true;
  state.settings[type][schoolyear].isError = false;
  state.settings[type][schoolyear].isUninitialized = false;
};

const customCurriculaDataState = createSlice({
  name: 'customCurriculaData',
  initialState,
  reducers: {
    init: () => {
      return initCacheStorage(); // when returning a whole new state, you need to return it. https://immerjs.github.io/immer/return/
    },
    initCustomCurricula: (state, action: InitPayloadAction) => {
      const { schoolyear } = action.payload;
      initApiPartSetting(state, 'customCurricula', schoolyear);
    },
    initAnnotations: (state, action: InitPayloadAction) => {
      const { schoolyear } = action.payload;
      initApiPartSetting(state, 'annotations', schoolyear);
    },
    initCustomItems: (state, action: InitPayloadAction) => {
      const { schoolyear } = action.payload;
      initApiPartSetting(state, 'customItems', schoolyear);
    },
    initCustomCurriculaGroups: (state, action: InitPayloadAction) => {
      const { schoolyear } = action.payload;
      initApiPartSetting(state, 'customCurriculaGroups', schoolyear);
    },
    initActivityPlans: (state, action: InitPayloadAction) => {
      const { schoolyear } = action.payload;
      initApiPartSetting(state, 'activityPlans', schoolyear);
    },
    initActivities: (state, action: InitPayloadAction) => {
      const { schoolyear } = action.payload;
      initApiPartSetting(state, 'activities', schoolyear);
    },
    setHasStartedInitializing: (state) => {
      state.hasStartedInitializing = true;
    },
    setStateFromFile: (
      state,
      action: PayloadAction<{
        customCurriculaData: OldSavedLlinkidApiState | SavedLlinkidApiState | null;
      }>
    ) => {
      const { customCurriculaData } = action.payload;

      if (customCurriculaData) {
        Object.entries(customCurriculaData).forEach(([key, value]) => {
          if (key !== 'settings' && key !== 'version') {
            state[key] = value;
          }
        });

        if (isSavedLlinkidApiState(customCurriculaData)) {
          Object.keys(state.settings).forEach((key) => {
            state.settings[key] = merge(state.settings[key], customCurriculaData.settings[key]);
          });
        } else if (isOldSavedLlinkidApiState(customCurriculaData)) {
          // the logic below is to transition from the old settings state to the new one.
          Object.keys(state.settings).forEach((key) => {
            const param = key as LlinkidApiStateType;
            const sett = customCurriculaData.settings[param];
            if (sett) {
              Object.keys(sett.lastFullRefreshPerYear || {}).forEach((schoolyear) => {
                if (!state.settings[param][schoolyear]) {
                  state.settings[param][schoolyear] = initCacheSettingYear();
                }
                state.settings[param][schoolyear].lastFullRefresh =
                  sett.lastFullRefreshPerYear?.[schoolyear] || null;
              });

              Object.keys(sett.modifiedSincePerSchoolyear || {}).forEach((schoolyear) => {
                if (!state.settings[param][schoolyear]) {
                  state.settings[param][schoolyear] = initCacheSettingYear();
                }
                state.settings[param][schoolyear].modifiedSince =
                  sett.modifiedSincePerSchoolyear?.[schoolyear] || null;
              });
            }
          });
        }
      }
    },
    restoreCache: (
      state,
      action: PayloadAction<{
        cacheList: any;
        type: LlinkidApiStateType;
      }>
    ) => {
      const { cacheList, type } = action.payload;
      state[type] = cacheList;
    },
    deltaUpdateCache: (
      state,
      action: PayloadAction<{ items: any; type: LlinkidApiStateType; schoolyear: string }>
    ) => {
      const { items, type, schoolyear } = action.payload;
      items.forEach((e) => {
        if (e.$$meta.deleted === true) {
          delete state[type][e.key];
        } else {
          state[type][e.key] = e;
        }
      });
      state.settings[type][schoolyear].isLoading = false;
      state.settings[type][schoolyear].isFetching = false;
      state.settings[type][schoolyear].isSuccess = true;
      state.settings[type][schoolyear].lastFullRefresh = new Date().toISOString();
      if (schoolyear && items.length > 0) {
        const maxPlusOne = getMaxModifiedSince(items);
        state.settings[type][schoolyear].modifiedSince = maxPlusOne;
      }
    },
    deltaUpdateSkipped: (
      state,
      action: PayloadAction<{ type: LlinkidApiStateType; schoolyear: string }>
    ) => {
      const { type, schoolyear } = action.payload;
      state.settings[type][schoolyear].isLoading = false;
      state.settings[type][schoolyear].isFetching = false;
      state.settings[type][schoolyear].isSuccess = true;
    },
    initOfTypeFailed: (state, action: PayloadAction<{ type: string; schoolyear: string }>) => {
      const { type, schoolyear } = action.payload;
      state.settings[type][schoolyear].isLoading = false;
      state.settings[type][schoolyear].isFetching = false;
      state.settings[type][schoolyear].isError = true;
    },
    setAutoSync: (state, action: PayloadAction<boolean>) => {
      state.autoSyncEnabled = action.payload;
    },
    applyBatch: (state, action: PayloadAction<{ batch: BatchRequest }>) => {
      const { batch } = action.payload;
      batch.forEach((elem) => {
        let item;
        if (elem.body)
          item = strip$$Properties(elem.body, ['$$meta', '$$attachments', '$$version']);

        if (item?.type === CUSTOMTYPES.goalReference && !item.reference) {
          item.reference = null; // this is important for the non-derived where the reference of the goal list is null.
        }
        const itemKey: string = getKeyFromHref(elem.href);

        if (isAnnotation(elem.href)) {
          if (elem.verb === 'PUT') {
            state.annotations[itemKey] = item;
          } else if (elem.verb === 'DELETE') {
            delete state.annotations[itemKey];
          }
        } else if (isCustomcurriculagroup(elem.href)) {
          if (elem.verb === 'PUT') {
            state.customCurriculaGroups[itemKey] = item;
          } else if (elem.verb === 'DELETE') {
            delete state.customCurriculaGroups[itemKey];
          }
        } else if (isCustomCurricula(elem.href)) {
          if (elem.verb === 'PUT') {
            state.customCurricula[itemKey] = item;
          } else if (elem.verb === 'DELETE') {
            delete state.customCurricula[itemKey];
          }
        } else if (isCustomItem(elem.href)) {
          if (elem.verb === 'PUT') {
            state.customItems[itemKey] = item;
          } else if (elem.verb === 'DELETE') {
            delete state.customItems[itemKey];
          }
        } else if (isActivity(elem.href)) {
          if (elem.verb === 'PUT') {
            state.activities[itemKey] = item;
          } else if (elem.verb === 'DELETE') {
            delete state.activities[itemKey];
          }
        } else if (isActivityPlan(elem.href)) {
          if (elem.verb === 'PUT') {
            state.activityPlans[itemKey] = item;
          } else if (elem.verb === 'DELETE') {
            delete state.activityPlans[itemKey];
          }
        }
      });
    },
    addCacheItem: (state, action) => {
      const { property, item } = action.payload;
      state[property][item.key] = item;
    },
    removeCacheItem: (state, action) => {
      const { property, key } = action.payload;
      delete state[property][key];
    },
    setPendingBatchesStatus: (state, action: SetPendingBatchesStatusAction) => {
      if (action.payload === null) {
        state.pendingBatchesStatus = null;
      } else {
        state.pendingBatchesStatus = action.payload;
      }
    },
    clearPendingBatches: (state) => {
      state.pendingBatches = [];
    },
    addPendingBatch: (state, action: AddPendingBatchAction) => {
      const pendingBatch = action.payload;
      state.pendingBatches.push(pendingBatch);
    },
    removePendingBatch: (state, action: DeletePendingBatchAction) => {
      const { batchKey } = action.payload;
      if (batchKey) {
        state.pendingBatches = state.pendingBatches.filter((b) => b.batchKey !== batchKey);
      }
    },
    saveLlinkidApiBatch: (state, action: SaveLlinkidApiBatchAction) => {
      const { batchKey } = action.payload;
      if (batchKey)
        state.batches[batchKey] = { isStarted: false, isFailed: false, isCompleted: false };
    },
    setBatchCompleted: (
      state,
      action: PayloadAction<{ response?: BatchResult; batchKey: string }>
    ) => {
      const { batchKey, response } = action.payload;
      state.batches[batchKey].isCompleted = true;
      if (response) state.batches[batchKey].response = response;
    },
    setBatchFailed: (
      state,
      action: PayloadAction<{ response?: BatchResult; batchKey: string }>
    ) => {
      const { batchKey, response } = action.payload;
      state.batches[batchKey].isFailed = true;
      if (response) state.batches[batchKey].response = response;
    },
    setBatchStarted: (state, action: PayloadAction<{ batchKey: string }>) => {
      const { batchKey } = action.payload;
      state.batches[batchKey].isStarted = true;
    },
    includeItem: () => {},
    moveItem: () => {},
    completeDistributionOrDeleteItem: () => {},
    changeItemTitle: () => {},
    editCustomGoal: () => {},
    distributeGoal: () => {},
    referenceGoal: (_state, _action: ReferenceGoalPayloadAction) => {},
    addCustomGoal: (_state, _action: AddCustomGoalPayloadAction) => {},
    addCustomSection: (_state, _action: AddCustomSectionPayloadAction) => {},
  },
});

export const {
  init,
  setAutoSync,
  addCacheItem,
  applyBatch,
  setHasStartedInitializing,
  initActivities,
  initActivityPlans,
  initCustomCurriculaGroups,
  initCustomItems,
  initAnnotations,
  initCustomCurricula,
  deltaUpdateCache,
  deltaUpdateSkipped,
  restoreCache,
  initOfTypeFailed,
  saveLlinkidApiBatch,
  addPendingBatch,
  removePendingBatch,
  setPendingBatchesStatus,
  setBatchCompleted,
  setBatchFailed,
  setBatchStarted,
  includeItem,
  moveItem,
  completeDistributionOrDeleteItem,
  changeItemTitle,
  editCustomGoal,
  distributeGoal,
  setStateFromFile,
  referenceGoal,
  addCustomGoal,
  addCustomSection,
} = customCurriculaDataState.actions;

export default customCurriculaDataState;
