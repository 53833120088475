import './toggableButton.scss';
import { Button } from '@mui/material';

const ToggableButton = ({
  className,
  isActive = true,
  activeColor = '#9B009B',
  unactiveColor = '#E0E0E0',
  children,
  onClick = () => {},
  ...llinkidButtonProps
}) => {
  const color = isActive ? activeColor : unactiveColor;

  return (
    <Button
      variant="contained"
      className={`toggable-button ${className}`}
      sx={{
        backgroundColor: color,
        borderRadius: '0px',
        '&:hover': {
          backgroundColor: color,
        },
      }}
      {...llinkidButtonProps}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default ToggableButton;
