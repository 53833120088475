import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Virtuoso as VirtualizedList } from 'react-virtuoso';
import { setStarredCurriculaPreferences } from '@store/userAndSchool/usersAndSchoolState';
import { selectIsCurriculaListLoading } from '@store/leerplanList/leerplanListSelectors';
import { selectIsSchoolyearReadOnly } from '@store/userAndSchool/userAndSchoolSelectors';
import Watermark from '@UI/watermark/Watermark';
import usePostMessage from '@hooks/usePostMessage';
import { POST_MESSAGE_EVENT, POST_MESSAGE_STATE } from '../../../constants/postMessageEvents';
import LeerplanListItem from './leerplanListItem/LeerplanListItem';
import LeerplanListModals from './LeerplanListModals';

import './leerplanList.scss';
import LeerplanListLoader from './LeerplanListLoader';

const LeerplanList = ({ list }) => {
  const modals = useRef();
  const loading = useSelector(selectIsCurriculaListLoading);
  const readOnly = useSelector(selectIsSchoolyearReadOnly);
  const selectionMode = useSelector((state) => state.leerplanList.selectionMode);
  const dispatch = useDispatch();

  const [sendMessage] = usePostMessage();

  const onFav = React.useCallback(
    (curriculum) => {
      const curriculumHref = curriculum.setid || curriculum.custid || curriculum.id;
      dispatch(
        setStarredCurriculaPreferences({
          curriculumHref,
          starred: !curriculum.fav,
        })
      );
    },
    [dispatch]
  );
  const onSelect = React.useCallback(
    (item) => {
      const response = {
        base: `/content/${item.id}`,
      };
      if (item.custid)
        response.customcurricula = item.custid
          .split(',')
          .map((id) => `llinkid/customcurriculum/customcurricula/${id}`);
      if (item.setid) {
        response.customcurriculagroup = `/llinkid/customcurriculum/customcurriculagroups/${item.setid}`;
        response.customcurricula = item.curricula.map((c) => c.customCurriculum);
      }

      const selection = [response];

      const data = {
        state: POST_MESSAGE_STATE.CORRECT,
        event: POST_MESSAGE_EVENT.SELECTION_DONE,
        selection,
      };

      sendMessage(data);
    },
    [sendMessage]
  );
  const onEditNonderived = (item) => {
    modals.current?.open('editNonderived', { item });
  };
  const onCreateSchoolCurricula = (item) => {
    modals.current?.open('createSchoolCurricula', { item });
  };
  const onCreateTeamCurricula = (item) => {
    modals.current?.open('createTeamCurricula', { item });
  };
  const onCreatePersonalCurricula = (item) => {
    modals.current?.open('createPersonalCurricula', { item });
  };
  const onDistributeMultipleTeams = (item, version) => {
    modals.current?.open('distributeMultipleTeams', { item, version });
  };
  const onDelete = (item) => {
    modals.current?.open('delete', { item });
  };

  const InnerItem = React.memo(({ item }) => {
    return (
      <LeerplanListItem
        key={item.key}
        item={item}
        type={item?.curricula?.length ? 'PARENT' : ''}
        expired={readOnly}
        selectionMode={selectionMode}
        onFav={onFav}
        onSelect={onSelect}
        onEditNonderived={onEditNonderived}
        onCreateSchoolCurricula={onCreateSchoolCurricula}
        onCreateTeamCurricula={onCreateTeamCurricula}
        onCreatePersonalCurricula={onCreatePersonalCurricula}
        onDistributeMultipleTeams={onDistributeMultipleTeams}
        onDelete={onDelete}
      />
    );
  });

  InnerItem.displayName = 'InnerItem';

  // The callback is executed often - don't inline complex components in here.
  const itemContent = (index, listitem) => {
    return <InnerItem item={listitem} />;
  };

  return (
    <div className="leerplan-list" data-testid="leerplan-list">
      {loading && <LeerplanListLoader />}
      {!loading && (
        <VirtualizedList
          style={{ height: '100%' }}
          data={list}
          // totalCount={list.length ?? 0}
          itemContent={itemContent}
          overscan={100}
        />
      )}
      <Watermark />
      <LeerplanListModals ref={modals} />
    </div>
  );
};

export default LeerplanList;
