import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/storeSetup';
import { getNextVersions, getSchoolyears, mapRelationsForNextVersions } from '@utils/utils';
import * as he from 'he';
import settings from '@config/settings';
import { CONTENTAPI_DOCUMENT_KEYS } from './contentApiDataAccess';

type SchoolyearParams = {
  schoolyearKey: string;
};

export const selectLlinkidCurriculaRoots = createSelector(
  [(state: RootState) => state.contentApiData.curriculaRoots],
  (curriculaRoots) => {
    return curriculaRoots.map((cur) => {
      const applicability = cur.applicability ?? { studyProgrammes: [] };
      const sources = cur.applicability?.studyProgrammes?.map((sp) => ({
        studyProgram: sp.href,
        href: cur.$$meta.permalink,
      }));
      const title: string = he.decode(cur.title);
      return { ...cur, title, applicability, sources };
    });
  }
);

/**
 * selects the active base curricula for a given schoolyear.
 */
export const selectActiveCurricula = createSelector(
  [selectLlinkidCurriculaRoots, (state, params: SchoolyearParams) => params.schoolyearKey],
  ($curriculaRoots, schoolyearKey) => {
    const schoolyears = getSchoolyears();
    const schoolyear = schoolyears.find((e) => e.key === schoolyearKey);
    if (!schoolyear) return [];

    return $curriculaRoots.filter(
      (root) =>
        root.$$validityPeriod.startDate &&
        schoolyear.isoDates.startDate >= root.$$validityPeriod.startDate &&
        (!root.$$validityPeriod.endDate ||
          root.$$validityPeriod.endDate > schoolyear.isoDates.startDate)
    );
  }
);

export const selectLlinkidCurriculaRootsAndGoalLists = createSelector(
  [selectActiveCurricula, (state: RootState) => state.contentApiData.curriculaGoalLists],
  ($curriculaRoots, curriculaGoalLists) => {
    if (!curriculaGoalLists.length) {
      return [];
    }
    return $curriculaRoots.map((cur) => {
      return {
        ...cur,
        goalList: curriculaGoalLists.find(
          (gl) =>
            gl.$$relationsFrom.find((z) => z.$$expanded.relationtype === 'IS_PART_OF')?.$$expanded
              .to.href === cur.$$meta.permalink
        ),
      };
    });
  }
);

export const selectNextVersionsForCurricula = createSelector(
  [selectLlinkidCurriculaRoots, (state: RootState) => state.contentApiData.curriculaRelations],
  ($curriculaRoots, curriculaRelations) => {
    // console.time('selectNextVersionsForCurricula');
    const relationsMap = new Map<string, string[]>();
    const curriculaRelationsMap = mapRelationsForNextVersions(curriculaRelations);
    $curriculaRoots.forEach((item) => {
      const { key } = item;
      const versions = getNextVersions(key, curriculaRelationsMap);
      relationsMap.set(key, versions);
    });
    // console.timeEnd('selectNextVersionsForCurricula');
    return relationsMap;
  }
);

/**
 * returns a map of active base curricula that have multiple active versions in the given schoolyear.
 * the values of the map state if that curriculum is ending (old version) or starting (new version).
 * there is a one year overlap period where both the old and new version are active.
 * this is because the curricula are for 2 years, and the children who started with the old one last year, continue with it this year.
 */
export const selectCurriculaWithMultipleActiveVersions = createSelector(
  [
    selectActiveCurricula,
    selectNextVersionsForCurricula,
    (state: RootState, params: SchoolyearParams) => params.schoolyearKey,
  ],
  ($curriculaRoots, curriculaRelationsMap) => {
    const curriculaRootsMap = new Map($curriculaRoots.map((e) => [e.key, e]));

    const curriculaWithMultipleActiveVersions: Record<
      string,
      {
        starting?: boolean;
        ending?: boolean;
        basisOptie: boolean;
      }
    > = {};

    $curriculaRoots.forEach((root) => {
      // if (root.title.includes('Basisoptie')) return;
      const relationmap = curriculaRelationsMap.get(root.key);
      if (relationmap) {
        if (relationmap.some((e) => curriculaRootsMap.get(e))) {
          // if the new version is found in the active list.
          curriculaWithMultipleActiveVersions[root.key] = {
            ending: true,
            basisOptie: root.title.includes('Basisoptie'),
          };
          relationmap.forEach((e) => {
            const foundNewVersion = curriculaRootsMap.get(e);
            if (foundNewVersion)
              curriculaWithMultipleActiveVersions[e] = {
                starting: true,
                basisOptie: root.title.includes('Basisoptie'),
              };
          });
        }
      }
    });

    return curriculaWithMultipleActiveVersions;
  }
);

export const selectEducationalPointers = createSelector(
  [
    (state: RootState) =>
      state.contentApiData.documents[CONTENTAPI_DOCUMENT_KEYS.EDUCATIONAL_POINTERS],
  ],
  (edPointers) => {
    const pointers =
      edPointers
        ?.filter((z) => z.type === 'THEME')
        .map((pointer) => {
          const attachment = pointer.attachments[0];

          return {
            $$meta: { permalink: pointer.$$meta.permalink },
            key: pointer.key,
            href: pointer.$$meta.permalink,
            title: pointer.title,
            imageUrl: settings.apisAndUrls.cachedApi.baseUrl + attachment.href,
          };
        }) ?? [];
    return pointers;
  }
);

export const selectEducationalComponents = createSelector(
  [
    (state: RootState) =>
      state.contentApiData.documents[CONTENTAPI_DOCUMENT_KEYS.EDUCATIONAL_COMPONENTS],
  ],
  (edComponents) => {
    return (
      edComponents
        ?.filter((z) => z.type === 'THEME')
        .map((component) => {
          const attachment = component.attachments[0];

          return {
            $$meta: { permalink: component.$$meta.permalink },
            key: component.key,
            href: component.$$meta.permalink,
            title: component.title,
            imageUrl: settings.apisAndUrls.cachedApi.baseUrl + attachment.href,
          };
        }) ?? []
    );
  }
);
