import { call, takeLatest, put } from 'redux-saga/effects';
import { setAllData, initFailed, init } from '@store/studyProgrammesApi/studyProgrammesApiState';
import { loadStudyProgrammes } from './studyProgrammeDataAccess';

function* doInit() {
  try {
    const result = yield call(loadStudyProgrammes);
    yield put(setAllData(result));
  } catch (ex) {
    yield put(initFailed(ex.message));
  }
}

export function* watchInitStudyProgrammeSaga() {
  yield takeLatest(init, doInit);
}
