import { HoveredPopover, ToggableButton } from '@UI/index';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import {
  selectCalendarCurricula,
  selectCalendarHeaderViewModel,
  selectCalendarViewModel,
  selectCurrentActivityPlans,
  selectDraftSheetVM,
} from '@store/calendar/calendarSelectors';
import {
  selectClass,
  setMerge,
  setSelectedClasses,
  setUnSelectedClasses,
  unselectClass,
} from '@store/calendar/calendarState';
import { filterCalendarVMWeeks } from '@store/calendar/calendarVMHelper';
import store from '@store/index';
import { func } from 'prop-types';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import GoalSelectorModal from '@components/leerplan/modals/goalSelectorModal/GoalSelectorModal';
import usePostMessage from '@hooks/usePostMessage';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Menu, MenuItem } from '@mui/material';
import { setBaseGoalOccurence } from '@store/curriculum/curriculumState';
import { mapCurriculaToParams } from '@store/leerplanList/leerplanListHelpers';
import { getCalendarWeeks } from '@utils/dateHelpers';
import { useNavigate } from 'react-router-dom';
import { POST_MESSAGE_ACTION, POST_MESSAGE_EVENT } from '../../../constants/postMessageEvents';
import PrintModal from '../modals/PrintModal';
import './calendarHeader.scss';
import CalendarHeaderSkeleton from './calendarHeaderSkeleton/CalendarHeaderSkeleton';

const CalendarHeader = ({ onPrint, onToggleClass }) => {
  const printCalendarModalRef = useRef();
  const goalSelectorModalRef = useRef();
  const isSelection = useSelector((state) => state.calendar.selectionMode);
  const header = useSelector(selectCalendarHeaderViewModel);
  const calendarCurricula = useSelector(selectCalendarCurricula);
  const calendarVM = useSelector(selectCalendarViewModel);
  const plans = useSelector(selectCurrentActivityPlans);
  const dispatch = useDispatch();
  const [goalSelectorAnchorEl, setgoalSelectorAnchorEl] = useState(null);
  const [cancelLabel, setCancelLabel] = useState('Annuleren');
  const [sendMessage] = usePostMessage({
    [POST_MESSAGE_ACTION.SELECT_YEAR_PLAN_ACTIVITIES]: (data) => {
      if (data.cancelButton) setCancelLabel(data.cancelButton);
    },
  });
  const navigate = useNavigate();

  const homeClickHandler = () => {
    navigate('/home/vorderingsplannen');
  };

  const toggleClassHandler = (cls) => {
    if (cls.selected) {
      dispatch(unselectClass({ classHref: cls.href }));
    } else {
      dispatch(selectClass({ classHref: cls.href }));
    }

    onToggleClass?.();
  };

  const printModalHandler = async (formValues) => {
    const { from, to, classes, view: viewType } = formValues;
    const selectedClasses = classes.filter((c) => c.selected).map((c) => c.href);
    const selectedPlans = plans
      .filter((p) => selectedClasses.includes(p.class.href))
      .map((p) => p.key);

    let vm;
    if (viewType === 'regular') {
      const { unselectedClasses, merge } = store.getState().calendar; // get the old values
      dispatch(
        setSelectedClasses({
          classes: classes.map((c) => {
            return { href: c.href, selected: c.selected };
          }),
        })
      );
      dispatch(setMerge({ merge: false }));
      const state = store.getState();
      vm = selectCalendarViewModel(state);
      vm = { ...vm, header: selectCalendarHeaderViewModel(state) };
      vm = filterCalendarVMWeeks(vm, {
        from,
        to,
      });
      // set the old values back
      dispatch(setMerge({ merge }));
      dispatch(setUnSelectedClasses({ unselectedClasses }));
    } else {
      const state = store.getState();
      vm = selectDraftSheetVM(state, {
        keys: selectedPlans,
        start: from,
        end: to,
      });
    }

    onPrint?.(vm, viewType);
  };

  const openPrintCalendarModal = () => {
    const weeks = getCalendarWeeks(plans);

    return printCalendarModalRef.current.open({
      weeks,
      classes: header?.classes,
      onConfirm: printModalHandler,
    });
  };

  if (!header) {
    return <CalendarHeaderSkeleton />;
  }

  const openGoalSelector = Boolean(goalSelectorAnchorEl);

  const handleGoalSelectorClose = () => {
    setgoalSelectorAnchorEl(null);
  };

  const selectLeerplan = (cur) => {
    handleGoalSelectorClose();
    const goalOccurence = Object.fromEntries(calendarVM.occurencesPerGoal);
    dispatch(setBaseGoalOccurence({ goalOccurence }));

    const [curParams] = mapCurriculaToParams([cur]);

    return goalSelectorModalRef.current.open({
      params: curParams,
      readonlyMode: true,
      onClose: () => {},
    });
  };

  const handleGoalSelectorClick = (event) => {
    if (header.curricula.length > 1) {
      setgoalSelectorAnchorEl(event.currentTarget);
    } else {
      selectLeerplan(header.curricula[0]?.curriculum);
    }
  };

  return (
    <header className={`calendar-header ${isSelection ? 'iframe-header' : ''}`}>
      <div className="content">
        {header?.title && (
          <p className="title">
            <span>{header.title} </span>
            <span className="sub">Vorderingsplan {header.schoolyear}</span>
          </p>
        )}
        {header?.curricula && (
          <div className="subtitle">
            {header.curricula.map((cur, index) => (
              <span key={index} className="subtitle-item">
                {cur.name}
                {!cur.valid && (
                  <HoveredPopover className="curricula-disappear-popover" content={cur.hoverText}>
                    ⚠️
                  </HoveredPopover>
                )}
              </span>
            ))}
          </div>
        )}
        {header?.teachers && <p className="subtitle">{header.teachers}</p>}
      </div>

      <div className="footer">
        {!isSelection && (
          <div>
            <Button variant="contained" onClick={homeClickHandler}>
              <NavigateBeforeIcon />
              <CalendarTodayIcon />
            </Button>
          </div>
        )}
        <div className="classes">
          {header?.classes.map((cls) => (
            <ToggableButton
              key={cls.href}
              isActive={cls.selected}
              activeColor={cls.color.background}
              onClick={() => toggleClassHandler(cls)}
            >
              {cls.$$displayName}
            </ToggableButton>
          ))}
        </div>

        <div className="actions">
          {!isSelection && (
            <>
              <div className="goals-counter">
                <Button
                  variant="outlined"
                  startIcon={<VisibilityIcon />}
                  onClick={handleGoalSelectorClick}
                >
                  Doelenverdeling
                </Button>
                {calendarCurricula.length > 1 && (
                  <Menu
                    open={openGoalSelector}
                    anchorEl={goalSelectorAnchorEl}
                    onClose={handleGoalSelectorClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    {calendarCurricula.map((curr) => (
                      <MenuItem
                        className="goal-selector-item"
                        key={curr.name}
                        onClick={() => selectLeerplan(curr)}
                      >
                        {curr.name}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              </div>
              <div>
                <Button
                  variant="outlined"
                  startIcon={<PrintIcon />}
                  onClick={openPrintCalendarModal}
                  disabled={!header?.classes?.some((e) => e.selected)} // TODO: somehow when no classes are selected, the component here is not called again.
                >
                  Afdrukken
                </Button>
              </div>
            </>
          )}
          {isSelection && (
            <Button
              variant="contained"
              onClick={() => {
                sendMessage({ event: POST_MESSAGE_EVENT.SELECTION_CANCELLED });
              }}
            >
              {cancelLabel}
            </Button>
          )}
        </div>
      </div>

      <Modal
        ref={printCalendarModalRef}
        component={<PrintModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />

      <Modal
        ref={goalSelectorModalRef}
        component={<GoalSelectorModal />}
        errorBoundaryComponent={ModalErrorBoundary}
        size="xl"
      />
    </header>
  );
};

CalendarHeader.propTypes = {
  onPrint: func,
  onToggleClass: func,
};

export default CalendarHeader;
