import commonUtils from '@kathondvla/sri-client/common-utils';
import { loadCurriculum, loadGoals } from '@store/contentApi/contentApiState';
import { selectExtraGoalsToLoad } from '@store/curriculum/curriculumSelectors';
import { selectLlinkidBatchByKey } from '@store/llinkidApis/llinkidApiSelectors';
import { saveLlinkidApiBatch } from '@store/llinkidApis/llinkidApiState';
import { call, delay, fork, put, race, select, take } from 'redux-saga/effects';
import { getKeyFromHref } from './getKeyFromHref';

export function* waitForSaga(selector) {
  if (yield select(selector)) return;

  while (true) {
    yield take('*');
    if (yield select(selector)) return;
  }
}

function* waitForBaseCurricula(curriculumKeys) {
  return yield call(waitForSaga, (state) =>
    curriculumKeys?.every(
      (curriculumKey) =>
        curriculumKey === 'nonderived' ||
        state.contentApiData.curricula[curriculumKey] ||
        state.contentApiData.curriculaToLoad[curriculumKey]?.isFailed === true
    )
  );
}

function* waitForBaseCurriculum(curriculumKey) {
  return yield waitForBaseCurricula([curriculumKey]);
}

export function* loadGoalsFromParamsSaga(params) {
  const refresh = yield select((state) => state.curriculum.preview);
  if (params.curriculumKey && params.curriculumKey !== 'nonderived') {
    yield put(loadCurriculum({ curriculumKey: params.curriculumKey, refresh }));
  }

  yield waitForBaseCurriculum(params.curriculumKey);
  const goalsToLoad = yield select(selectExtraGoalsToLoad, params);
  if (goalsToLoad.length) {
    yield put(loadGoals({ keys: goalsToLoad.map((e) => getKeyFromHref(e)), refresh }));
  }
}

export function* saveLlinkidApiBatchAndWaitForResponse(batch) {
  const batchKey = commonUtils.generateUUID();
  yield put(saveLlinkidApiBatch({ batch, applyInstantly: false, batchKey }));

  yield call(
    waitForSaga,
    (state) =>
      selectLlinkidBatchByKey(state, { batchKey }).isCompleted ||
      selectLlinkidBatchByKey(state, { batchKey }).isFailed
  );

  return yield select((state) => selectLlinkidBatchByKey(state, { batchKey }));
}

export const blockingDebounce = (ms, pattern, task, ...args) =>
  // eslint-disable-next-line func-names
  fork(function* () {
    while (true) {
      let action = yield take(pattern);

      while (true) {
        const { debounced, latestAction } = yield race({
          debounced: delay(ms),
          latestAction: take(pattern),
        });

        if (debounced) {
          yield call(task, ...args, action);
          break;
        }

        action = latestAction;
      }
    }
  });

export function* runCallbackWhenSelectorChanges(selector, callback) {
  let previousValue = yield select(selector);
  while (true) {
    yield take('*');
    const currentValue = yield select(selector);
    if (previousValue !== currentValue) {
      yield callback(currentValue);
      previousValue = currentValue;
    }
  }
}

export function* dispatchWhenSelectorChanges(selector, action) {
  let previousValue = yield select(selector);
  while (true) {
    yield take('*');
    const currentValue = yield select(selector);
    if (previousValue !== currentValue) {
      yield put(action);
      previousValue = currentValue;
    }
  }
}

export function* channelSelectorEmitter(selector, chan) {
  let previousValue = yield select(selector);
  while (true) {
    yield take('*');
    const currentValue = yield select(selector);
    if (previousValue !== currentValue) {
      yield put(chan, currentValue);
      previousValue = currentValue;
    }
  }
}
