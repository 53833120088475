import GoalSimplified from '@UI/goal/GoalSimplified';
import './missingGoals.scss';

const MissingGoals = ({ goals, total, loading }) => {
  if (loading)
    return (
      <div className="missing-goals goals-loading ghost">
        <div>
          <div className="loading">Even geduld...</div>
        </div>
      </div>
    );

  if (goals?.length > 0) {
    const ratio = goals.length / total;
    return (
      <div className="missing-goals">
        <div className="goals-wrap">
          {ratio < 0.3 && (
            <div className="title">
              Let op: Volgende {goals.length} leerplandoelen zijn niet meer geldig, en zul je{' '}
              <strong>manueel moeten corrigeren</strong>:
            </div>
          )}
          {ratio >= 0.3 && (
            <div className="title warn">
              Let op: Volgende {goals.length} leerplandoelen zijn niet meer geldig, en zul je{' '}
              <strong>manueel moeten corrigeren</strong>:
            </div>
          )}
          <div className="goal-list">
            {goals.map((goal) => (
              <GoalSimplified key={goal.key} goal={goal} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default MissingGoals;
