import { useLlinkidPostMessage, useNavigatePostMessage } from '@hooks/usePostMessage';
import useSameHeight from '@hooks/useSameHeight';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import {
  selectActivitiesForSelection,
  selectAreActivitiesLoadedForSchoolyear,
  selectCalendarViewModel,
  selectIsActivityEditModalOpen,
  selectIsActivityPlanReadOnly,
} from '@store/calendar/calendarSelectors';
import {
  editActivityModalClose,
  editActivityModalOpen,
  moveWeek,
  setSelectionOptions,
} from '@store/calendar/calendarState';
import store from '@store/index';
import { selectPendingBatches } from '@store/llinkidApis/llinkidApiSelectors';
import AddWeekButton from '@UI/addWeekButton/AddWeekButton';
import AsyncStatus from '@UI/asyncStatus/AsyncStatus';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import { endLog } from '@utils/loggerHelper';
import printCalendar from '@utils/print';
import { getUnixTime } from 'date-fns/getUnixTime';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@store/storeSetup';

import CalendarHeader from './header/CalendarHeader';
import EditActivityModal from './modals/EditActivityModal';
import CalendarPrintView from './print/CalendarPrintView';
import Week from './week/Week';
import {
  POST_MESSAGE_ACTION,
  POST_MESSAGE_EVENT,
  POST_MESSAGE_STATE,
} from '../../constants/postMessageEvents';
import WeekSkeleton from './week/weekSkeleton/WeekSkeleton';

import './calendar.scss';

const Calendar = () => {
  const [print, setPrint] = useState<{ model: any; view: any } | null>(null);
  const calendar = useSelector(selectCalendarViewModel);
  const areActivitiesLoaded = useSelector(selectAreActivitiesLoadedForSchoolyear);
  const isSelection = useSelector((state: RootState) => state.calendar.selectionMode);
  const readOnly = useSelector(selectIsActivityPlanReadOnly);
  const pendingBatches = useSelector(selectPendingBatches);
  const { selectionType } = useSelector((state: RootState) => state.calendar.selectionOptions);
  const isActivityEditModalOpen = useSelector(selectIsActivityEditModalOpen);
  const ActivityModalRef = useRef();
  const dispatch = useDispatch();
  const selectionClassName = `${isSelection ? 'selection' : ''} ${
    selectionType === 'mini' ? 'selection-mini' : ''
  }`;
  const [sendMessage] = useLlinkidPostMessage({
    [POST_MESSAGE_ACTION.SELECT_YEAR_PLAN_ACTIVITIES]: (message) => {
      dispatch(setSelectionOptions(message));
    },
  });

  useNavigatePostMessage();

  const pendingBatchesRef = useRef(pendingBatches);

  useEffect(() => {
    pendingBatchesRef.current = pendingBatches;
  }, [pendingBatches]);

  useEffect(() => {
    window.onbeforeunload = (event) => {
      console.log(event);
      if (pendingBatchesRef.current?.length) {
        const e = event || window.event;
        if (e)
          e.returnValue = 'Uw aanpassingen zijn nog niet bewaard. Wilt u toch de pagina verlaten?';
        return 'Uw aanpassingen zijn nog niet bewaard. Wilt u toch de pagina verlaten?';
      }

      return null;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [dispatch]);

  useEffect(() => {
    if (print === null) {
      const $today = document.getElementById('today');
      $today?.scrollIntoView({ block: 'center' });
    } else {
      const title = print.view === 'regular' ? print.model.header.title : print.model[0].title;
      printCalendar(title, () => setPrint(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [print?.model]);

  useEffect(() => {
    if (calendar) {
      endLog('open yearplan');
    }
  }, [calendar]);

  const forceSetSameHeight = useSameHeight([calendar]);

  const onToggleClass = () => {
    forceSetSameHeight();
  };

  const onPrint = (model, view) => {
    setPrint({ model, view });
    forceSetSameHeight();
  };

  const onAddWeek = (week) => {
    dispatch(
      moveWeek({
        startDate: getUnixTime(week.dateRange.start),
        type: 'ADD',
      })
    );
  };

  const onSubtractWeek = (week) => {
    dispatch(
      moveWeek({
        startDate: getUnixTime(week.dateRange.start),
        type: 'SUBTRACT',
      })
    );
  };

  const onAddActivityHandler = ({ plan, week }) => {
    dispatch(
      editActivityModalOpen({
        week: {
          startDate: week.startDate.toISOString(),
          endDate: week.endDate.toISOString(),
        },
        isCreate: true,
        createForClasses: plan.classes,
      })
    );
  };

  const onModifyActivityHandler = ({ activity, plan }) => {
    dispatch(
      editActivityModalOpen({
        parentHrefs: activity.parentActivities,
        isCreate: false,
        createForClasses: plan.classes,
        key: activity.id,
      })
    );
  };

  const onSelectActivityHandler = (activity) => {
    const state = store.getState();
    const children = activity.activities_del;

    const selection = selectActivitiesForSelection(state, {
      childrenKeys: children.map((act) => act.$$meta.permalink),
    });

    if (selection.length > 0) {
      sendMessage({
        state: POST_MESSAGE_STATE.CORRECT,
        event: POST_MESSAGE_EVENT.SELECTION_DONE,
        selection,
      });
    }

    return selection;
  };

  return (
    <>
      <CalendarHeader onPrint={onPrint} onToggleClass={onToggleClass} />
      <AsyncStatus />

      {print === null && (
        <main className={`calendar ${selectionClassName}`}>
          {areActivitiesLoaded ? (
            calendar.weeks.map((week) => (
              <div key={week.id} className="week-container">
                {!isSelection && !readOnly && <AddWeekButton onMoveWeek={() => onAddWeek(week)} />}

                <Week
                  week={week}
                  display={calendar.settings.weekTitle}
                  onMoveWeek={onSubtractWeek}
                  onAddActivity={onAddActivityHandler}
                  onModifyActivity={onModifyActivityHandler}
                  onSelectActivity={onSelectActivityHandler}
                />
              </div>
            ))
          ) : (
            <WeekSkeleton />
          )}
        </main>
      )}

      {print !== null && print.model && <CalendarPrintView model={print.model} view={print.view} />}

      <Modal
        ref={ActivityModalRef}
        size="lg"
        open={isActivityEditModalOpen}
        component={<EditActivityModal />}
        onClose={() => {
          dispatch(editActivityModalClose());
        }}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </>
  );
};

export default Calendar;
