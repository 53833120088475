import { object } from 'prop-types';
import { limitWords, removeLinks } from '@utils/utils';
import Divider from '@UI/divider/Divider';
import HoveredPopover from '@UI/popovers/HoveredPopover';
import './goal.scss';

const GoalSimplified = ({ goal, count }) => {
  return (
    <div className={`goal-simplified ${goal.foundational ? 'foundational' : ''}`}>
      <div data-testid="goal-count-wrapper" className="goal-count-wrapper">
        {typeof count === 'number' && count !== 0 && (
          <HoveredPopover content={`${count} opgenomen in vorderingsplan`}>
            <div data-testid="goal-count" className="goal-count">
              {count}
            </div>
          </HoveredPopover>
        )}
      </div>

      <div className="content-container">
        <div className="left-section">
          <div className="identifier">{goal.identifier}</div>
          <Divider mandatory={goal.mandatory} />
        </div>
        <div className="description">
          <div
            data-testid="goal-description"
            className="goal-description"
            dangerouslySetInnerHTML={{ __html: limitWords(removeLinks(goal.description), 20) }}
          />
          {goal.disappeared && (
            <div data-testid="goal-disappear" className="goal-disappear">
              <HoveredPopover
                className="goal-disappear-popover"
                content="Dit leerplandoel is geselecteerd in een leerplan dat niet geldig is in de huidige context"
              >
                ⚠️
              </HoveredPopover>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

GoalSimplified.propTypes = {
  goal: object,
};

export default GoalSimplified;
