import * as Sentry from '@sentry/react';
import settings from '@config/settings';

const Transactions: Record<string, Sentry.Span> = {};

// const { fetch } = window;
// window.fetch = (...args) =>
//   (async (params) => {
//     const url = params[0] instanceof Request ? params[0].url : params[0].toString();
//     return Sentry.startSpan({ op: 'http', name: url }, async (span) => {
//       return await fetch(...params);
//     });
//   })(args);

export function startLog(eventName) {
  if (!settings.logging.sentry.enabled) return undefined;
  console.time(`[logtime] ${eventName}`);
  Transactions[eventName] = Sentry.startInactiveSpan({
    name: eventName,
    forceTransaction: false,
    op: 'event',
  });
  return Transactions[eventName];
}

export function endLog(eventName) {
  if (!settings.logging.sentry.enabled) return;

  if (Transactions[eventName]) {
    console.timeEnd(`[logtime] ${eventName}`);

    Transactions[eventName].end();
    delete Transactions[eventName];
  }
}
