import { cachedApi, getHeaderHashForTheUncachable, api } from '@store/apihelpers';
import { CONTENTTYPES, RELATION_TYPES } from '@utils/curriculumHelper';
import { getVskoReqHeader } from '@utils/utils';
import { last } from 'lodash-es';

export const CONTENTAPI_DOCUMENT_KEYS = {
  LEIDRAAD: '3b754443-dc47-4ebf-8ec5-58d6da24eabe',
  EDUCATIONAL_POINTERS: '8f39910a-5db7-4306-9443-74a318aba0da',
  EDUCATIONAL_COMPONENTS: '8669afe3-aa83-437b-b624-742e93e8de5a',
  INTRODUCTION_GRADE: {
    1: 'f6370bbd-6a0a-41e8-9b9b-c342e1cd0c45',
    2: 'a9beef4f-3bd4-4b73-87f8-d7c83c149fb5',
    3: '7a7d03c1-da3d-450f-97a6-10ed4170c124',
  },
};

export async function getMinimalCurricula() {
  const baseCurricula = await cachedApi.getAll(
    `/content/`,
    { type: CONTENTTYPES.curriculum, limit: 5000, expand: 'summary' },
    getVskoReqHeader({
      headers: getHeaderHashForTheUncachable(),
    })
  );
  return baseCurricula.filter((elem) => elem.issued);
}

export async function getCurriculaGoalLists() {
  const goalLists = await cachedApi.getAll(
    `/content`,
    { type: CONTENTTYPES.goalList, limit: 5000 },
    getVskoReqHeader({
      headers: getHeaderHashForTheUncachable(),
    })
  );
  return goalLists;
}

export async function getCurriculaRelations() {
  const curriculumRelations = await cachedApi.getAll(
    `/content/relations`,
    {
      contentType: CONTENTTYPES.curriculum,
      limit: 50000,
      relationtype: RELATION_TYPES.replaces,
    },
    getVskoReqHeader({
      headers: getHeaderHashForTheUncachable(),
    })
  );

  return curriculumRelations;
}

// export async function getCurriculumCorrelations({ from, to }) {
//   const curriculumCorrelations = await api.getRaw(`/content/curriculumCorrelation`, {
//     from: `/content/${from}`,
//     to: `/content/${to}`,
//   });

//   return curriculumCorrelations;
// }

export async function getLlinkidGoalCorrelations() {
  const curriculumCorrelations = await cachedApi.getRaw(`/content/llinkidGoalCorrelation`);
  return curriculumCorrelations;
}

function appendHashToAttachments(items, hash) {
  items.forEach((item) => {
    item.attachments?.forEach((att) => {
      att.href = `${att.href}?hash=${hash}`;
    });
  });
}

async function getCachedContent(key, refresh, params, addHashToAttachments = true) {
  const hashTimestamp = refresh ? `?t=${Math.round(+new Date() / 1000)}` : '';
  const hash = await cachedApi.get(`/content/${key}/hash${hashTimestamp}`);
  const response = await cachedApi.getAll(
    '/content',
    params,
    getVskoReqHeader({
      headers: { 'VSKO-Resource-Hash': hash.hash },
    })
  );
  if (addHashToAttachments) {
    appendHashToAttachments(response, hash.hash);
  }
  return response;
}

export async function getDocument(key, refresh) {
  const root = key;
  return getCachedContent(root, refresh, {
    root,
    limit: 5000,
  });
}

export async function getCurriculum(keyOrHref, refresh) {
  const key = keyOrHref.includes('/') ? last(keyOrHref.split('/')) : keyOrHref; // in case of href, get the key.
  let response;
  if (refresh) {
    response = await api.get(`/content/${key}/llinkidCurricula/preview`);
  } else {
    const hash = await cachedApi.get(`/content/${key}/llinkidCurricula/hash`);
    response = await cachedApi.get(
      `/content/${key}/llinkidCurricula`,
      {},
      getVskoReqHeader({
        headers: { 'VSKO-Resource-Hash': hash.hash },
      })
    );
  }

  return response;
}

export async function getGoal(key, refresh) {
  if (refresh) {
    return api.get(`/content/${key}/llinkidGoal/preview`);
  }
  const hash = await cachedApi.get(`/content/${key}/llinkidGoal/hash`);
  return cachedApi.get(
    `/content/${key}/llinkidGoal`,
    {},
    getVskoReqHeader({
      headers: { 'VSKO-Resource-Hash': hash.hash },
    })
  );
}
