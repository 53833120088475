import SchoolYearSelector from '@components/layout/header/schoolYearSelector/schoolYearSelector';
import { useLlinkidPostMessage, useNavigatePostMessage } from '@hooks/usePostMessage';
import { Button } from '@mui/material';
import { selectCurrentSchool } from '@store/userAndSchool/userAndSchoolSelectors';
import { printAddress } from '@utils/utils';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { POST_MESSAGE_ACTION, POST_MESSAGE_EVENT } from '../../../constants/postMessageEvents';
import LeerplanHome from '../LeerplanHome';
import './LeerplanSelector.scss';

const LeerplanSelector: React.FC = () => {
  const [cancelLabel, setCancelLabel] = useState<string>('Annuleren');

  const [sendMessage] = useLlinkidPostMessage({
    [POST_MESSAGE_ACTION.SELECT_FROM_CURRICULUMS]: (data) => {
      if (data.cancelButton) setCancelLabel(data.cancelButton);
    },
  });

  useNavigatePostMessage();

  const school = useSelector(selectCurrentSchool);
  const navigate = useNavigate();

  const toSchoolSelector = () => {
    navigate('/schools');
  };

  return (
    <div className="curriculum-selector">
      <div className="school-button">
        {school && (
          <span className="school-link" onClick={toSchoolSelector}>
            {school.$$displayName}
            {school.$$addresses.map((z) => (
              <span key={z.key}>; {printAddress(z.address)}</span>
            ))}
          </span>
        )}

        <div className="school-year-selector">
          <SchoolYearSelector />
        </div>
      </div>
      <div className="curriculum-selector-content">
        <LeerplanHome></LeerplanHome>
      </div>

      <div className="controls">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            sendMessage({ event: POST_MESSAGE_EVENT.SELECTION_CANCELLED });
          }}
        >
          {cancelLabel}
        </Button>
      </div>
    </div>
  );
};
export default LeerplanSelector;
