const WhatIsNewContent = () => {
  return (
    <>
      <div data-testid="whatisnew-content" className="demo-modal-wrapper">
        <div className="subtitle">Volgend schooljaar</div>
        <div className="section">
          <div className="section-description">
            Het nieuwe schooljaar 24-25 werd geïnitialiseerd zodat nu kan gewerkt worden met de
            allerlaatste versies van de leerplannen van de 1ste, 2de en 3de graad.
            <br />
            Bij de vorderingsplannen vind je een knop "doelenverdeling" waarmee je de tellers te
            zien krijgt naast elk doel om aan te geven hoe vaak dit doel werd opgenomen in het
            doelenkader. Deze verdeling is ook zichtbaar voor de collega's waarmee het
            vorderingsplan werd gedeeld.
            <br /> Voortaan kan je in een doelenkader niet alleen doelen uit andere leerplannen
            kopiëren maar ook (school)eigen doelen aanbrengen.
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatIsNewContent;
