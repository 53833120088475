import { CUSTOMTYPES } from './curriculumHelper';

export function getGoalsWithChildren(item) {
  if (!item) return [];

  let flatList = [item];

  if (item.sections?.length) {
    flatList = flatList.concat(item.sections.flatMap((section) => getGoalsWithChildren(section)));
  }
  if (item.subItems?.length) {
    flatList = flatList.concat(item.subItems);
  }
  if (item.goals?.length) {
    flatList = flatList.concat(item.goals.flatMap((goal) => getGoalsWithChildren(goal)));
  }

  return flatList;
}

export function getGoalsSubset(key, parent) {
  const itemIndex = parent.goals.findIndex((g) => g.key === key);
  return parent.goals.splice(itemIndex + 1);
}

export function getSectionsSubset(parent, grandParent) {
  if (
    parent.type === CUSTOMTYPES.section &&
    grandParent &&
    grandParent.type === CUSTOMTYPES.goalList
  ) {
    return parent.sections.splice(0);
  }

  if (
    parent.type === CUSTOMTYPES.section &&
    grandParent &&
    grandParent.type === CUSTOMTYPES.section
  ) {
    const index = grandParent.sections.findIndex((s) => s.key === parent.key) || 0;
    return grandParent.sections.splice(index + 1);
  }

  return [];
  // return parent.goals.splice(itemIndex + 1);
}

export function hasParentSection(key, parentMap) {
  if (!key || parentMap.size === 0) return false;
  let parentNode = parentMap.get(key);
  if (!parentNode) return false;

  while (
    parentNode.value.type !== CUSTOMTYPES.goalList &&
    parentNode.value.type !== CUSTOMTYPES.section
  ) {
    parentNode = parentNode.next;
  }

  return parentNode.value.type === CUSTOMTYPES.section;
}
