import { KovResourcePicker } from '@kathondvla/react-shared-components/src';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import { closeAlert, setAlert } from '@store/alerts/alertsState';
import {
  selectCurrentSchoolyear,
  selectSchoolyearsForSchool,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { setCurrentSchoolyear } from '@store/userAndSchool/usersAndSchoolState';
import AlertModal from '@UI/alertModal/AlertModal';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import { startLog } from '@utils/loggerHelper';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SchoolYearOption from '../selectorOption/schoolYearOption';

const SchoolYearSelector = () => {
  const schoolYearAlertModalRef = useRef();
  const dispatch = useDispatch();
  const schoolyears = useSelector(selectSchoolyearsForSchool);
  const selectedSchoolyear = useSelector(selectCurrentSchoolyear);

  const changeSchoolyear = (item) => {
    if (item.initialized) {
      startLog('change schoolyear');
      dispatch(setCurrentSchoolyear(item.value));
      // TODO: Move this to a saga ?
      sessionStorage.setItem('currentSchoolyear', item.value);
      if (item.value !== selectedSchoolyear) {
        if (item.expired) {
          const alert = {
            key: 'schoolyear-expired-warning',
            msg: 'Je kan geen aanpassingen meer maken in voorgaande schooljaren',
            type: 'warning',
            delay: -1,
          };
          dispatch(setAlert(alert));
        } else {
          dispatch(
            closeAlert({
              key: 'schoolyear-expired-warning',
            })
          );
        }
      }
    } else {
      const isFutureSchoolyear = item.startDate > new Date();
      if (isFutureSchoolyear) {
        schoolYearAlertModalRef.current.open({
          title: `${item.name} aanmaken`,
          text: 'Het komende schooljaar zal begin augustus beschikbaar zijn.',
        });
      }
    }
  };

  return (
    <>
      {schoolyears && (
        <div data-testid="schoolyear-selector" className="selector picker schoolyear">
          <KovResourcePicker
            localData={schoolyears}
            value={selectedSchoolyear}
            display={() => selectedSchoolyear.name}
            optionTemplate={(option) => <SchoolYearOption option={option} />}
            multiple={false}
            clearable={false}
            disableSearch={true}
            onChange={changeSchoolyear}
            style={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
          />
        </div>
      )}
      <Modal
        ref={schoolYearAlertModalRef}
        size="sm"
        component={<AlertModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </>
  );
};

export default SchoolYearSelector;
