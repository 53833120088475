import { useForm } from 'react-hook-form';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import { FormGroup, FormSelect } from '@UI/index';
import { sortBy } from 'lodash-es';

import './modals.scss';
import { Button } from '@mui/material';
import { getSelectableProgramsForCurriculumListItem, getRichting } from '@utils/homeHelper';
import { useSelector } from 'react-redux';
import { selectCurrentSchool, selectUser } from '@store/userAndSchool/userAndSchoolSelectors';
import { requiredValidation } from '@utils/validations';

import iconAddWhite from '@assets/img/add_white.svg';

const TYPES = {
  SCHOOL: 'school',
  TEAM: 'team',
  PERSONAL: 'personal',
};

const getTitle = (type) => {
  if (type === TYPES.PERSONAL) return 'Een persoonlijk leerplan maken';
  return type === TYPES.TEAM ? 'Een groepsleerplan maken' : 'Een schoolleerplan maken';
};

const CreateCurriculaModal = ({ curricula, school, teams, type, onConfirm, onClose }) => {
  const currentSchool = useSelector(selectCurrentSchool);
  const user = useSelector(selectUser);
  const selectablePrograms = getSelectableProgramsForCurriculumListItem(curricula, currentSchool);
  const teamOptions = teams?.map((e) => ({
    name: e.$$displayName,
    value: e.$$meta.permalink,
  }));
  const studyProgramOptions = sortBy(selectablePrograms, 'name');
  const getCreator = (group) => {
    if (type === TYPES.PERSONAL) return user.$$meta.permalink;
    if (type === TYPES.TEAM) return group;
    if (type === TYPES.SCHOOL) return school.href;
    return group;
  };

  const { control, handleSubmit, formState } = useForm({ mode: 'onChange' });

  return (
    <>
      <Modal.Header title={getTitle(type)} icon={iconAddWhite} onClose={onClose} />

      <Modal.Body>
        <FormGroup label="School" subtitle="Voor welke school wil je dit leerplan maken?">
          <span className="modal-school-name">{school?.$$displayName}</span>
        </FormGroup>

        {type === TYPES.TEAM && (
          <FormGroup label="Groep" subtitle="Voor welke groep wil je dit leerplan maken?" required>
            <FormSelect
              name="team"
              placeholder="Selecteer een groep"
              control={control}
              options={teamOptions}
              error={formState.errors?.team}
              validations={{
                required: (value) => requiredValidation(value),
              }}
            />
          </FormGroup>
        )}

        <FormGroup
          label="Richting"
          subtitle="Voor welke richting wil je dit leerplan maken?"
          required
        >
          <FormSelect
            name="studyProgram"
            placeholder="Selecteer een richting"
            value={type === TYPES.SCHOOL ? null : getRichting(curricula, currentSchool)}
            options={studyProgramOptions}
            control={control}
            error={formState.errors?.studyProgram}
            validations={{
              required: (value) => requiredValidation(value),
            }}
          />
        </FormGroup>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outlined" onClick={onClose}>
          Annuleren
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit((form) => {
            onConfirm({ ...form, creator: getCreator(form.team), curricula });
          })}
        >
          Aanmaken
        </Button>
      </Modal.Footer>
    </>
  );
};

export default CreateCurriculaModal;
