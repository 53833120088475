import { forwardRef, useState } from 'react';
import uniqueId from 'lodash.uniqueid';
import './radioButton.scss';

const RadioButton = ({ label, value, name, checked, children, onChange = () => {} }, ref) => {
  const onChangeHandler = (ev) => {
    onChange(ev);
  };

  const [id] = useState(() => uniqueId('radio-'), []);

  return (
    <div className="custom-radio-button">
      <section className="radio-container">
        <input
          id={id}
          type="radio"
          name={name}
          ref={ref}
          value={value}
          checked={checked}
          onChange={onChangeHandler}
        />
        <div className="checkmark">
          <div className="check"></div>
        </div>
        {!children && (
          <span className="label" htmlFor={id}>
            {label}
          </span>
        )}
        {children && (
          <div className="label" htmlFor={id}>
            {children}
          </div>
        )}
      </section>
    </div>
  );
};

export default forwardRef(RadioButton);
