import { CUSTOMCURRICULATYPES, translateStudyProgrammesToString } from '@utils/curriculumHelper';
import { formatVersionNumber, getNonDerivedGroupKey } from '@utils/utils';
import { every, groupBy } from 'lodash-es';
import settings from '@config/settings';

export function filterCurriculaBySelectedPrograms(curriculaList, studyPrograms) {
  return curriculaList.filter((curricula) =>
    curricula.applicability.studyProgrammes.some((program) => studyPrograms.includes(program.href))
  );
}

export function hasGoalDistributions(goalDistribution) {
  return goalDistribution && goalDistribution.some((val) => val.items.length > 0);
}

// it returns the studyprogrammes (with filtering of selectedPrograms) for which the distributions (distributions param) (to the curriculum of curHref) has happened.
function distributedStudyProgrammes(curHref, distributions, allCustomCurriculas, selectedPrograms) {
  const distCusrs = distributions.map((e) => e.curriculum.href); // all customcur hrefs that have an annotation type REFERENCE for this goal

  let applicableCurr = allCustomCurriculas
    .filter(
      (elem) =>
        distCusrs.includes(elem.$$meta.permalink) &&
        elem.applicability &&
        elem.applicability.studyProgrammes
    )
    .map((elem) => elem.applicability.studyProgrammes[0].href);
  // /all customcurricula for this curricula (like french) that have a href in the list of distCurs above, and have a applicability.

  applicableCurr = [...new Set(applicableCurr.filter((e) => selectedPrograms.includes(e)))]; // filter on the selectedProgrames filter
  return applicableCurr;
}

function buildDistributionForGoal(
  goalHref,
  creatorType,
  references,
  allCustomCurriculas,
  selectedStudyProgramHrefs,
  allPrograms,
  goalDistributions,
  allSubjects
) {
  goalDistributions[goalHref] = goalDistributions[goalHref] || {};
  goalDistributions[goalHref][creatorType] = goalDistributions[goalHref][creatorType] || [];

  const groupByCurricula = groupBy(
    references.filter((e) => e.rootItem && e.rootItem.$$meta.permalink.startsWith('/content')),
    'rootItem.key'
  );

  const groupByNonDerived = groupBy(
    references.filter((e) => e.rootItem && e.rootItem.$$meta.permalink.startsWith('/llinkid')),
    (item) => getNonDerivedGroupKey(allCustomCurriculas, item.rootItem)
  );

  // Remember allSubjects is the ones the user has selected, so if we don't find them, return null and filter them out
  let distributionsGroupedByCurricula = Object.keys(groupByCurricula)
    .map((key) => {
      const curricula = allSubjects.find((e) => e.key === key);
      const distributions = references.filter(
        (reference) => reference.rootItem && reference.rootItem.key === key
      );
      const distStudyProgrammes = curricula
        ? distributedStudyProgrammes(
            curricula.$$meta.permalink,
            distributions,
            allCustomCurriculas,
            selectedStudyProgramHrefs
          )
        : null;
      return distStudyProgrammes && distStudyProgrammes.length > 0
        ? {
            key,
            // href: curricula.$$meta.permalink,
            identifiers: curricula.identifier || curricula.identifiers.join(''),
            version: curricula.showVersion
              ? formatVersionNumber({ version: curricula.$$version })
              : null,
            // distributions,
            distStudyProgrammes,
          }
        : null;
    })
    .filter((e) => e);

  // /TODO CLEAN THIS COPY PASTED CODE up later.
  distributionsGroupedByCurricula = distributionsGroupedByCurricula.concat(
    Object.keys(groupByNonDerived)
      .map((key) => {
        const curricula = allSubjects.find(
          (e) => getNonDerivedGroupKey(allCustomCurriculas, e) === key
        );
        const distributions = references.filter(
          (reference) =>
            reference.rootItem &&
            getNonDerivedGroupKey(allCustomCurriculas, reference.rootItem) === key
        );
        const distStudyProgrammes = curricula
          ? distributedStudyProgrammes(
              null,
              distributions,
              allCustomCurriculas,
              selectedStudyProgramHrefs
            )
          : null;
        return distStudyProgrammes && distStudyProgrammes.length > 0
          ? {
              key,
              // href: curricula.$$meta.permalink,
              identifiers: curricula.identifier || curricula.identifiers.join(''),
              version: curricula.showVersion
                ? formatVersionNumber({ version: curricula.$$version })
                : null,
              // distributions,
              distStudyProgrammes,
            }
          : null;
      })
      .filter((e) => e)
  );

  if (distributionsGroupedByCurricula.length > 0) {
    for (const dist of distributionsGroupedByCurricula) {
      const cucu = allSubjects.find(
        (e) => e.key === dist.key || getNonDerivedGroupKey(allCustomCurriculas, e) === dist.key
      );
      if (cucu) {
        // /stupid catching inexisting keys. can remove later?
        // /a button is partial WHEN:
        // it is not distributed in all studyprogrammes (for the curricula/subject) and
        // the studyprogrammes are both in the _selectedProgrammes and curriculum.studyProgrammes

        const selectedProgramsForCur = cucu.applicability.studyProgrammes
          .map((e) => e.href)
          .filter((e) => selectedStudyProgramHrefs.includes(e)); // /the selected programmes filtered from the applicability of this content-api curriculum.

        dist.partial = selectedProgramsForCur.some(
          (e) => !dist.distStudyProgrammes.find((z) => z === e)
        );

        dist.description = cucu.title + (dist.version ? ` (${dist.version})` : '');

        if (dist.partial) {
          dist.description += ' - deze keuze geldt voor ';
          dist.description += translateStudyProgrammesToString(
            dist.distStudyProgrammes,
            allPrograms
          );
        }
      }
    }

    /**
     * this part of the code takes care of the following spec:
     * if a goal is distributed to all (both) active versions, then you don't have to show two buttons (with versions), but you show only one (without version)
     */
    const toAddItems = [];
    const groupedByIdentfiers = groupBy(
      distributionsGroupedByCurricula.filter((e) => e.version),
      'identifiers'
    );
    Object.keys(groupedByIdentfiers).forEach((identifier) => {
      if (groupedByIdentfiers[identifier].length > 1) {
        const here = groupedByIdentfiers[identifier];
        const newItem = {
          key: here[0].key + here[1].key,
          identifiers: identifier,
          version: null,
          distStudyProgrammes: null,
          partial: here[0].partial || here[1].partial,
          description: `${here[0].description}, ${here[1].description}`,
        };

        here[0].merged = true; // merged items will not be shown in the button list. we couldn't remove them from the array because the checkboxes in distributionwindow depend on this.
        here[1].merged = true;
        toAddItems.push(newItem);
      }
    });

    if (toAddItems.length) {
      distributionsGroupedByCurricula = distributionsGroupedByCurricula.concat(toAddItems);
    }

    // Sort false partials to the back, then sort by key (name) (which is distinct so cant be equal/0)
    distributionsGroupedByCurricula.sort((x, y) => {
      if (x.partial === y.partial) {
        return x.identifiers > y.identifiers ? 1 : -1;
      }
      return x.partial ? 1 : -1;
    });

    goalDistributions[goalHref][creatorType] = distributionsGroupedByCurricula;
  }
}

function checkLowPriorityDistributions(distributionLayer) {
  return distributionLayer.every((item) =>
    settings.hardcodings.distribution.includes(`/content/${item.key}`)
  );
}

export function getEmptyOrLowPrioDistributionsStatus(goalDistributions) {
  const lowPrioSet = new Set();
  Object.keys(goalDistributions).forEach((key) => {
    const dist = goalDistributions[key];
    const lowPrioOnly = every(
      dist,
      (value) => !value.length || checkLowPriorityDistributions(value)
    );
    if (lowPrioOnly) {
      lowPrioSet.add(key);
    }
  });
  return lowPrioSet;
}

export function buildDistribution(
  goalHrefs,
  allSubjects,
  allReferences,
  orgTypeCustCurs,
  selectedStudyProgramHrefs,
  allPrograms
) {
  const goalDistributions = {};
  const allReferencesList = allReferences;

  for (const cType of Object.keys(orgTypeCustCurs)) {
    for (const goalHref of goalHrefs) {
      const filtRef = allReferencesList.filter(
        (elem) => elem.target.href === goalHref && elem.creatortype === cType
      );
      buildDistributionForGoal(
        goalHref,
        cType,
        filtRef,
        orgTypeCustCurs[cType],
        selectedStudyProgramHrefs,
        allPrograms,
        goalDistributions,
        allSubjects
      );
    }
  }

  return goalDistributions;
}

export function excludeOwnDistribution(goalDistributions, keys) {
  const keysSet = new Set(keys);
  let newGoalDistributions = { ...goalDistributions };

  Object.keys(newGoalDistributions).forEach((key) => {
    const distribForKey = { ...newGoalDistributions[key] };
    Object.keys(distribForKey).forEach((type) => {
      distribForKey[type] = [...distribForKey[type].filter((e) => !keysSet.has(e.key))];
    });
    newGoalDistributions = { ...newGoalDistributions, [key]: distribForKey };
  });
  return newGoalDistributions;
}

export function makeDistributionWindowObject(curricula, allReferences) {
  const isNonderived = curricula.type === CUSTOMCURRICULATYPES.custom;

  return {
    selected: false,
    key: isNonderived ? getNonDerivedGroupKey(allReferences, curricula) : curricula.key,
    href: curricula.$$meta.permalink,
    title: curricula.title,
    $$name: `${curricula.identifier || curricula?.identifiers?.join('') || ''} - ${
      curricula.title
    }`.toLocaleLowerCase(),
    identifiers:
      curricula.identifier || (curricula.identifiers && curricula.identifiers.join('')) || '',
    goalList: curricula.goalList,
    studyProgrammes: curricula.applicability.studyProgrammes,
    sources: curricula.sources,
    nonDerivedGroupKey: isNonderived ? getNonDerivedGroupKey(allReferences, curricula) : undefined,
  };
}

const getVersionAndLeerjaarForCurr = (curr, multiActiveVersionsMap, allPrograms) => {
  let studyPrograms = null;

  if (!curr.grade && curr.applicability) {
    const spHrefs = new Set(curr.applicability.studyProgrammes.map((e) => e.href));
    studyPrograms = allPrograms.filter((item) => spHrefs.has(item.$$meta.permalink));
  }
  const grade = studyPrograms?.[0].$$grade || '';

  const versionNumber = formatVersionNumber({
    version: curr.version,
    grade,
    versionStatus: multiActiveVersionsMap?.[curr.key],
  });

  return versionNumber;
};

export function getCurriculaBySubjects(
  subjects,
  multiActiveVersionsMap,
  allPrograms,
  allReferences
) {
  if (!subjects) return [];
  return subjects
    .filter((curr) => curr.identifier || curr.identifiers)
    .map((curr) => {
      const distributionObj = makeDistributionWindowObject(curr, allReferences);
      distributionObj.versionNumber = getVersionAndLeerjaarForCurr(
        curr,
        multiActiveVersionsMap,
        allPrograms
      );
      return distributionObj;
    })
    .sort((a, b) => {
      if (a.identifiers < b.identifiers) return -1;
      if (a.identifiers > b.identifiers) return 1;
      return 0;
    });
}
