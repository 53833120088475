import SchoolIcon from '@assets/img/icon_school_so_grey.svg?react';
import TeamIcon from '@assets/img/icon_people_grey.svg?react';
import TeacherIcon from '@assets/img/icon_user_grey.svg?react';
import './distributeIcon.scss';

const DistributeIcon = ({ type = 'SCHOOL' }) => {
  if (type === 'SCHOOL') {
    return (
      <div className="distribute-button-icon-wrapper" data-testid="distribute-school-icon">
        <SchoolIcon />;
      </div>
    );
  }

  if (type === 'TEAM') {
    return (
      <div className="distribute-button-icon-wrapper" data-testid="distribute-team-icon">
        <TeamIcon />;
      </div>
    );
  }

  if (type === 'TEACHER') {
    return (
      <div className="distribute-button-icon-wrapper" data-testid="distribute-teacher-icon">
        <TeacherIcon />;
      </div>
    );
  }

  return null;
};

export default DistributeIcon;
