import { useSelector, useDispatch } from 'react-redux';
import { calendarListActions } from '@store/calendarList/calendarListState';
import SearchInput from '@UI/searchInput/SearchInput';
import Checkbox from '@UI/checkbox/Checkbox';

import './calendarListFilter.scss';
import { selectCurrentSchool } from '@store/userAndSchool/userAndSchoolSelectors';

const CalendarListFilter = () => {
  const dispatch = useDispatch();
  const { name, deletedCalendars } = useSelector((state) => state.calendarList.filters);
  const updateFilter = (property, value) => {
    dispatch(calendarListActions.setFilter({ property, value }));
  };
  const removeAll = () => {
    dispatch(calendarListActions.removeAllFilters());
  };
  const currentSchool = useSelector(selectCurrentSchool);

  return (
    <div className="calendar-list-filter">
      <div className="remove-all">
        {' '}
        <span onClick={removeAll}>Huidige zoekopdracht wissen.</span>
      </div>
      <div className="filter-wrapper">
        <div className="filter w-25">
          <SearchInput
            value={name}
            placeholder="(deel van) klasnaam, naam jaarvordering, studierichting/basisoptie, naam leerkracht"
            onChange={updateFilter.bind(null, 'name')}
          />
        </div>
      </div>
      {currentSchool && (
        <div className="deleted-calendars-checkbox-wrapper">
          <div className="deleted-calendars-checkbox">
            <Checkbox
              small
              label="Toon verwijderde vorderingsplannen"
              checked={deletedCalendars}
              onChange={updateFilter.bind(null, 'deletedCalendars')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarListFilter;
