import { Checkbox, SearchInput } from '@UI/index';
import { filterCurrByName, getStudyProgrammeTypeNameForGrade } from '@utils/curriculumHelper';
import { func, object } from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import useOnClickOutside from '@hooks/useClickOutside';
import { Button } from '@mui/material';
import { selectCurriculumGrade } from '@store/curriculum/curriculumSelectors';
import { selectDistributionWindowData } from '@store/distribute/distributeSelectors';
import { setFilter } from '@store/distribute/distributeState';
import { useDispatch, useSelector } from 'react-redux';
import './distributeWindow.scss';

const DistributeWindow = ({ goal, onClose, onDistribute }) => {
  const curricula = useSelector((state) =>
    selectDistributionWindowData(state, { goalHref: goal?.$$meta?.permalink })
  );
  const grade = useSelector(selectCurriculumGrade);
  const gradeName = getStudyProgrammeTypeNameForGrade(grade);
  const [filteredCurricula, setFilteredCurricula] = useState([]);
  const filterText = useSelector((state) => state.distribute.filters.text);
  const distributeWindow = useRef();
  const dispatch = useDispatch();

  const filterHandler = (text) => {
    dispatch(setFilter({ property: 'text', value: text }));
  };

  useOnClickOutside(distributeWindow, () => onClose?.());

  const distributeAllCurricula = (distribute) => {
    const resourceArr = [];
    const filtered = filteredCurricula?.map((curr) => {
      if (curr.selected !== distribute || curr.partial) {
        curr.selected = distribute;
        curr.partial = false;
        resourceArr.push(curr);
      }
      return curr;
    });
    setFilteredCurricula(filtered);
    onDistribute(resourceArr);
  };

  const distributeCurriculum = (resource) => {
    resource.selected = resource.partial ? resource.selected : !resource.selected; // if we have partial distribution, the checkbox needs to remain checked when you click it.
    onDistribute?.([resource]);
    setFilteredCurricula((prev) => {
      const filtered = prev.map((curr) => {
        if (curr.key === resource.key) {
          curr.partial = false;
          curr.selected = resource.selected;
        }
        return curr;
      });

      return filtered;
    });
  };

  useEffect(() => {
    if (filterText.length) {
      const filtered = filterCurrByName(curricula, filterText);
      setFilteredCurricula(filtered);
    } else {
      setFilteredCurricula(curricula);
    }
  }, [filterText, curricula]);

  return (
    <div
      className="distribute-window distribute-window-top"
      data-testid="distribute-window"
      ref={distributeWindow}
    >
      {curricula?.length === 0 && (
        <div className="empty">Er zijn geen eigen doelenkaders voor deze {gradeName}</div>
      )}
      {curricula?.length > 0 && (
        <>
          <header>
            <div className="title">Realiseren in:</div>
            <div
              className="close-window"
              data-testid="distribute-close-window"
              onClick={() => onClose?.()}
            >
              Sluiten&nbsp;<span>X</span>
            </div>
          </header>
          <SearchInput
            placeholder="Naam van vak..."
            value={filterText}
            autoFocus={true}
            onChange={filterHandler}
          />
          <div className="buttons">
            <Button variant="contained" onClick={() => distributeAllCurricula(false)}>
              Alles wissen
            </Button>
          </div>
          <div className="list" role="list">
            {filteredCurricula.map((curr) => (
              <div key={curr.key} className="list-item">
                <Checkbox
                  label={`${curr.identifiers} - ${curr.title} (${curr.versionNumber})`}
                  checked={curr.selected}
                  role="listitem"
                  className={curr.partial ? 'partial' : ''}
                  small
                  onChange={() => distributeCurriculum(curr)}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

DistributeWindow.propTypes = {
  goal: object,
  onDistribute: func,
  onClose: func,
};

export default DistributeWindow;
