import usePostMessage, { useNavigatePostMessage } from '@hooks/usePostMessage';
import { AddBox } from '@mui/icons-material';
import { Button } from '@mui/material';
import { loadGoals } from '@store/contentApi/contentApiState';
import {
  selectGoalsFromCurriculum,
  selectIsCurriculumLoaded,
  selectSelectedGoalsForPostmessage,
} from '@store/curriculum/curriculumSelectors';
import { setSelectedItems } from '@store/curriculum/curriculumState';
import { RootState } from '@store/storeSetup';
import { getKeyFromHref } from '@utils/getKeyFromHref';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  POST_MESSAGE_ACTION,
  POST_MESSAGE_EVENT,
  POST_MESSAGE_STATE,
} from '../../../constants/postMessageEvents';
import './SelectionFooter.scss';

interface Props {}

const SelectionFooter: React.FC<Props> = () => {
  const [confirmLabel, setConfirmLabel] = useState('Toevoegen');
  const [cancelLabel, setCancelLabel] = useState('Annuleren');
  const [extraLabel, setExtraLabel] = useState<null | string>(null);

  const isCurriculumLoaded = useSelector((state: RootState) => selectIsCurriculumLoaded(state));
  const curriculumGoals = useSelector(selectGoalsFromCurriculum);
  const selection = useSelector(selectSelectedGoalsForPostmessage);

  const dispatch = useDispatch();
  const selectedItemsCount = useSelector(
    (state: RootState) => state.curriculum.selectedItems?.length ?? 0
  );

  const [sendMessage] = usePostMessage({
    [POST_MESSAGE_ACTION.SELECT_CURRICULUM_GOALS]: (data) => {
      if (!data.selection) throw new Error('Data has no selection property or it is not an array');
      if (data.confirmButton) setConfirmLabel(data.confirmButton);
      if (data.cancelButton) setCancelLabel(data.cancelButton);
      if (data.extraButton) setExtraLabel(data.extraButton);

      const goalsFound = [] as string[];
      const goalsNotFound = [] as string[];
      data.selection.forEach(async (goal) => {
        let goalHref = goal;
        if (typeof goal !== 'string') {
          goalHref = goal.href;
        }

        const currentGoal = curriculumGoals.find((entity) => entity.$$meta.permalink === goalHref);
        if (currentGoal) {
          goalsFound.push(goalHref);
        } else {
          goalsNotFound.push(goalHref);
        }
      });

      dispatch(loadGoals({ keys: goalsNotFound.map((e) => getKeyFromHref(e)), refresh: false }));
      dispatch(setSelectedItems([...goalsFound, ...goalsNotFound].map((z) => ({ href: z }))));
    },
  });

  useEffect(() => {
    if (isCurriculumLoaded) {
      sendMessage({
        state: POST_MESSAGE_STATE.CORRECT,
        event: POST_MESSAGE_EVENT.LOADED,
      });
    }
  }, [isCurriculumLoaded, sendMessage]);

  useNavigatePostMessage();

  return (
    <div className="curriculum-selection-footer">
      <div className="footer-actions">
        <span>
          Je hebt <b>{selectedItemsCount}</b> leerplandoel(en) geselecteerd.
        </span>
        <Button
          variant="outlined"
          onClick={() => {
            sendMessage({ event: POST_MESSAGE_EVENT.SELECTION_CANCELLED });
          }}
        >
          {cancelLabel}
        </Button>
        {extraLabel && (
          <Button
            variant="contained"
            onClick={() => {
              const data = {
                state: POST_MESSAGE_STATE.CORRECT,
                event: POST_MESSAGE_EVENT.SELECTION_EXTRA,
                selection,
              };

              sendMessage(data);
            }}
          >
            {extraLabel}
          </Button>
        )}
        <Button
          variant="contained"
          startIcon={<AddBox />}
          onClick={() => {
            const data = {
              state: POST_MESSAGE_STATE.CORRECT,
              event: POST_MESSAGE_EVENT.SELECTION_DONE,
              selection,
            };

            sendMessage(data);
          }}
        >
          {confirmLabel}
        </Button>
      </div>
    </div>
  );
};

export default SelectionFooter;
