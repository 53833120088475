import { createSelector } from '@reduxjs/toolkit';
import {
  selectCurrentSchoolData,
  selectCurrentSchoolHref,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { sortBy } from 'lodash-es';
import { streamsOrderMap } from '../../constants/apiConstants';
import * as grades from '../../constants/grades';
import { getGradeOfStudyProgram, getProgramsForGrade } from './studyProgrammeHelpers';

export const selectIsStudyProgrammeDataLoaded = (state) => {
  return (
    !state.studyProgrammeApiData.isLoading && state.studyProgrammeApiData.allPrograms.length > 0
  );
};

export const selectStudyProgrammesPerGradeForSchool = createSelector(
  [
    (state) => state.studyProgrammeApiData.allPrograms,
    (state) => state.userAndSchools.schoolsData?.[selectCurrentSchoolHref(state)]?.studyProgrammes,
  ],
  (allPrograms, schoolProgrammes) => {
    const programmesPerGrade = {};
    [grades.GRADE_1, grades.GRADE_2, grades.GRADE_3].forEach((grade) => {
      const gradePrograms = getProgramsForGrade(grade, allPrograms);
      const gradeProgramsForSchool = gradePrograms.filter((program) => {
        return schoolProgrammes?.some((schoolProgram) => schoolProgram.key === program.key);
      });
      programmesPerGrade[grade] = gradeProgramsForSchool.map((z) => ({
        name: z.title,
        value: z.$$meta.permalink,
      }));
    });

    return programmesPerGrade;
  }
);

export const selectStudyProgrammeGroupsPerGradeForSchool = createSelector(
  [
    selectStudyProgrammesPerGradeForSchool,
    (state) => selectCurrentSchoolData(state).studyProgrammeGroups,
  ],
  (studyProgrammesPerGrade, schoolProgrammeGroups) => {
    const programmeGroupsPerGrade = {};
    Object.keys(studyProgrammesPerGrade).forEach((grade) => {
      const studyProgrammeGroups = [
        {
          name: 'Voor de hele graad',
          description: 'Maak keuzes voor de hele graad.',
          value: null,
          studyProgrammes: studyProgrammesPerGrade[grade].map((s) => s.value),
        },
      ];
      sortBy(schoolProgrammeGroups, [(spg) => streamsOrderMap.get(spg.title)]).forEach((group) => {
        if (
          group.studyProgrammes.some((sp) =>
            studyProgrammesPerGrade[grade].some((sel) => sel.value === sp.href)
          )
        ) {
          studyProgrammeGroups.push({
            name: `Voor de ${group.title}`,
            description: `Maak keuzes voor de ${group.title}.`,
            value: group.$$meta.permalink,
            studyProgrammes: group.studyProgrammes.map((s) => s.href),
          });
        }
      });
      programmeGroupsPerGrade[grade] = studyProgrammeGroups;
    });

    return programmeGroupsPerGrade;
  }
);

export const selectAllGrades = createSelector(
  [(state) => state.studyProgrammeApiData.allGrades],
  (allGrades) => {
    return allGrades.map((grade) => ({
      name: grade.title,
      value: grade.$$meta.permalink,
    }));
  }
);

export const selectAllProgrammes = createSelector(
  [(state) => state.studyProgrammeApiData.allPrograms],
  (allPrograms) => {
    return allPrograms.map((program) => {
      const grade = getGradeOfStudyProgram(program);
      return {
        name: `${program.title} (${grade}${grade === 1 ? 'ste' : 'de'} graad)`,
        value: program.$$meta.permalink,
      };
    });
  }
);

export const selectStudyProgrammesFromKeys = (state, selectablePrograms, studyProgram) => {
  const currentSchool = selectCurrentSchoolData(state);
  const studyPrograms = [];
  const selectableProgramHrefs = selectablePrograms.map((e) => e.value);
  studyProgram.forEach((sp) => {
    if (sp.startsWith('/llinkid/customstudyprogrammegroups')) {
      // /we have a studyprogrammegroup
      const group = currentSchool.studyProgrammeGroups.find((e) => e.$$meta.permalink === sp);
      group.studyProgrammes.forEach((gsp) => {
        if (selectableProgramHrefs.includes(gsp.href)) {
          studyPrograms.push(gsp.href);
        }
      });
    } else if (sp.startsWith('/sam/')) {
      studyPrograms.push(sp);
    }
  });
  return studyPrograms;
};

export const selectAllStreamsAndFinalities = createSelector(
  [
    (state) => state.studyProgrammeApiData.firstGradeStreams,
    (state) => state.studyProgrammeApiData.secondGradeFinalities,
  ],
  (firstGradeStreams, secondGradeFinalities) => {
    const streamsAndFinalities = [];
    [...firstGradeStreams, ...secondGradeFinalities].forEach((stream) => {
      streamsAndFinalities.push({
        name: stream.title,
        value: stream.$$meta.permalink,
      });
    });
    return streamsAndFinalities;
  }
);
